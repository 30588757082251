import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Avatar,
  CircularProgress,
} from "@mui/material";
import DoneImage from "../../Images/doneicon.png";
import { useLocation } from "react-router-dom";
import { esignDocumentAPICall } from "../../shared/APICalls";
import { useDispatch, useSelector } from "react-redux";
import ErrorImage from "../../Images/errorImage.png";

export default function SuccessEsignPage() {
  const [countDown, setCountDown] = useState(3);
  const [displayError, setDisplayError] = useState(false);
  const [loading, setloading] = useState(false);
  const pathName = useLocation().search;
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlParams.entries());
  const code = params.id;
  const state = params.state;
  const status = params.status;
  // console.log(urlParams,"urlParams",window.location.href );
  sessionStorage.setItem("access-token", state);
  // if (window.location.href.includes("Cancelled")) {
  //   console.log(window.location.href);
  // }

  function senEsignDetails() {
    // let obj: any = {
    //   status: "success",
    //   doc_id: code,
    // };
    let obj: any = {
      esign_id: code,
    };
    setloading(true);
    esignDocumentAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        setloading(false);
        setTimeout(() => {
          window.close();
        }, 3000);

        //  console.log("response", response);
      })
      .catch((error: any) => {
        setloading(false);
        window.close();
        setDisplayError(true);
      });
  }

  useEffect(() => {
    if (window.location.href.includes("Cancelled")) {
      console.log("cancel");
      setDisplayError(true);
    } else {
      setDisplayError(false);
      senEsignDetails();
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountDown((prevCountDown) => prevCountDown - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (countDown < 0) {
      setCountDown(3);
    }
  }, [countDown]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
      }}
    >
      {loading ? (
        <CircularProgress style={{ width: "100px", height: "100px" }} />
      ) : (
        <img src={DoneImage} style={{ width: "100px", height: "100px" }} />
      )}

      {!displayError && (
        <div style={{ textAlign: "center", padding: "10px 10px" }}>
          <Typography variant="h5">
            {" "}
            {loading ? "Inprogress..." : "Success"}{" "}
          </Typography>
          {!loading && (
            <Typography style={{ marginTop: "10px" }}>
              {loading
                ? "  Please wait..., we are fetching your Documents. Please don't Close the Window."
                : "We have obtained your documents"}
            </Typography>
          )}

          {!loading && (
            <Typography style={{ fontSize: "12px", padding: "0 5px" }}>
              The page will close automatically in {countDown} seconds, if not
              close manually to continue.
            </Typography>
          )}
        </div>
      )}
      {displayError && (
        <div style={{ textAlign: "center", padding: "10px 10px" }}>
          <Typography variant="h5"> Error </Typography>
          <Typography style={{ marginTop: "10px" }}>
            Error fetching your Documents
          </Typography>
        </div>
      )}
    </div>
  );
}
