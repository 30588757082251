import React, { useEffect,useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Avatar,
} from "@mui/material";
import ErrorImage from "../../Images/errorImage.png";
import { useDispatch, useSelector } from "react-redux";

import { useLocation } from "react-router-dom";

export default function ErrorEsignPage() {
  const [countDown, setCountDown] = useState(3);
  const pathName = useLocation().search;
  const dispatch = useDispatch();
 // console.log(pathName)
  const urlParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlParams.entries());
  const error = params.error;
  const errorDescription = params.error_description
  const state = params.state;


  useEffect(() => {
    setTimeout(() => {
      window.close();
    }, 3000);
    const intervalId = setInterval(() => {
      setCountDown((prevCountDown) => prevCountDown - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (countDown < 0) {
      setCountDown(3)
    }
  }, [countDown]);
 
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
      }}
    >
      <img src={ErrorImage} style={{ width: "100px", height: "100px" }} />
      <div style={{ textAlign: "center", padding: "10px 10px" }}>
        <Typography variant="h5"> Error </Typography>
        <Typography style={{ marginTop: "10px" }}>
          Unable to Fetch Documents
        </Typography>
      </div>
      <div style={{textAlign:'center'}}>
        <Typography style={{ fontSize: "12px", padding: "0 5px" }}>
          The page will close automatically in {countDown} seconds, if not close manually
          to continue.
        </Typography>
      </div>
    </div>
  );
}
