import Styles from "../../pages/Styles/NomineeStyles.module.scss";
import {
  Stack,
  TextField,
  Typography,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Checkbox,
  Grid,
  FormHelperText,
  IconButton,
  RadioGroup,
  Radio,
  FormControlLabel,
  RadioGroupProps,
  TextFieldProps,
  OutlinedTextFieldProps,
  ButtonProps,
} from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import calenderImage from "../../Components/assets/Images/calendar.svg";
import NomineeBar from "./NomineeBar";
import SelectProof from "./SelectProof";
import { ISelectProofProps, INomineeFormProps } from "../../shared/dtos";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import DropdownComp from "./DropDownComp";
import DatePickerComp from "./DatePickerComp";
import { nomineeID } from "../../shared/commonData";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

function DateIcon(props) {
  return <img src={calenderImage} style={{ width: "25px" }} />;
}

const maxDate = dayjs("1920-01-01");
const eighteenYearsAgo = dayjs().subtract(18, "year");
const todayDate = dayjs();

export default function NomineeForm({
  nominee,
  errorData,
  touchedData,
  index,
  currentIndex,
  handleChangeField,
  setFieldValue,
  values,
  handleBlur,
  relationError,
  ageError,
  relationShipData,
  handleNomineeDelete,
  handleNomineeEdit,
  setErrors,
  errors,
  address,
  gaurdianData,
  statesData,
}: INomineeFormProps) {
  const [disableStateField, setDisableStateField] = useState({
    id: "",
    sequence: "",
    value: "",
  });
  const updatedRelationData = relationShipData.filter(
    (obj) => obj.value !== "Others"
  );
  // console.log("updatedRelationData", updatedRelationData);
  const SelectedStateNew = statesData.filter(
    (data: any) => data.value.toUpperCase() === address.State.toUpperCase()
  )[0];
  const handleSetAddress = (
    values: any,
    callBack: any,
    index: any,
    value: any
  ) => {
    //  console.log("values", values);
    const SelectedState = statesData.filter(
      (data: any) => data.value.toUpperCase() === address.State.toUpperCase()
    )[0];
    console.log("SelectedState", SelectedState, statesData, address.State);
    const nomineeCopy: any = [...values.nominees];
    nomineeCopy[index]["nomineeAddress"] = value ? address.address : "";
    nomineeCopy[index]["nomineeAddressCity"] = value ? address.city : "";
    nomineeCopy[index]["nomineeAddressPinCode"] = value ? address.pincode : "";
    nomineeCopy[index]["nomineeAddressState"] = value ? SelectedState : "";
    callBack("nominees", nomineeCopy);
  };
  const handleSetGaurdianAddress = (
    values: any,
    callBack: any,
    index: any,
    value: any
  ) => {
    //  console.log("values", values);
    const SelectedStateGaurdian = statesData.filter(
      (data: any) => data.value.toUpperCase() === address.State.toUpperCase()
    )[0];
    setDisableStateField(SelectedStateGaurdian);
    console.log("SelectedState", SelectedStateGaurdian, address.State);
    const nomineeCopyguardian: any = [...values.nominees];
    nomineeCopyguardian[index]["gaurdianAddress"] = value
      ? address.address
      : "";
    nomineeCopyguardian[index]["gaurdianAddressCity"] = value
      ? address.city
      : "";
    nomineeCopyguardian[index]["gaurdianAddressPinCode"] = value
      ? address.pincode
      : "";
    nomineeCopyguardian[index]["gaurdianAddressState"] = value
      ? SelectedStateGaurdian
      : "";
    callBack("nominees", nomineeCopyguardian);
  };

  const handleDelete = () => {
    handleNomineeDelete(values, setFieldValue, index);
  };
  const handleEdit = () => {
    handleNomineeEdit(index);
  };

  const percentageTotal = values.nominees
    .map((a: any) =>
      a.percentageofShare === "" ? 0 : parseFloat(a.percentageofShare)
    )
    .reduce((sum: any, current: any) => sum + current, 0);
  // console.log("percentageTotal + percentageTotal", percentageTotal);
  const percentageTotalError =
    percentageTotal === 100
      ? ""
      : percentageTotal > 100
      ? "Total Percentage of Share cannot be above 100"
      : "Total Percentage of Share cannot be below 100";
  // console.log("errorData", errorData);
  // console.log("touchedData", touchedData);
  const check18YearOfAge = (dob: any) => {
    if (dob) {
      const dobLimit = new Date();
      dobLimit.setFullYear(dobLimit.getFullYear() - 18);
      if (dob > dobLimit) {
        return false;
      }
      return true;
    }
    return true;
  };
  const dateCheck = nominee.relationShip
    ? nominee.relationShip.isGuardianReq
      ? todayDate
      : eighteenYearsAgo
    : eighteenYearsAgo;
  const dateCheckError = nominee.relationShip
    ? !nominee.relationShip.isGuardianReq
      ? check18YearOfAge(nominee.nomineeDateofBirth)
        ? ""
        : "Age should be above 18 years"
      : ""
    : "";

  //  console.log("dateCheckError", dateCheckError);

  return (
    <>
      <div className={Styles.leftSubContainer}>
        <div>
          <NomineeBar
            index={index}
            onEditClick={handleEdit}
            onDeleteClick={handleDelete}
            percentageofShare={nominee.percentageofShare}
          />
          <div
            style={{
              display: currentIndex !== index ? "none" : "block",
            }}
            className={Styles.fieldsSection}
          >
            <div className={Styles.fields}>
              <div>
                <TextField
                  className={Styles.textfields}
                  id="outlined-basic"
                  label="Nominee's Full name"
                  variant="outlined"
                  name="nomineeFullName"
                  value={nominee.nomineeFullName}
                  error={errorData && Boolean(errorData["nomineeFullName"])}
                  helperText={errorData && errorData["nomineeFullName"]}
                  onChange={(event) => {
                    handleChangeField(
                      "nomineeFullName",
                      event.target.value.toUpperCase(),
                      setFieldValue,
                      index,
                      values,
                      setErrors,
                      errors
                    );
                  }}
                  onBlur={handleBlur}
                  inputProps={{ maxLength: 50 }}
                />
                <FormHelperText style={{ color: "#d32f2f" }}>
                  {relationError ? "Nominees Cannot be same" : ""}
                </FormHelperText>
              </div>
              <div>
                <div className={Styles.textfields}>
                  {/* <Typography>RelationShip</Typography> */}
                  <DropdownComp
                    LabelName="Relationship"
                    menuItems={relationShipData}
                    name="relationShip"
                    value={nominee.relationShip}
                    handleChange={(event: any) => {
                      handleChangeField(
                        "relationShip",
                        event.target.value,
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    error={errorData && Boolean(errorData["relationShip"])}
                    helperText={errorData && errorData["relationShip"]}
                    //   setOthers={setOccupationOthers}
                  />
                </div>
              </div>
              <div>
                <TextField
                  className={Styles.textfields}
                  id="outlined-basic"
                  label="Percentage of Share"
                  variant="outlined"
                  name="percentageofShare"
                  value={nominee.percentageofShare}
                  error={
                    (errorData && Boolean(errorData["percentageofShare"])) ||
                    percentageTotalError
                  }
                  helperText={
                    (errorData && errorData["percentageofShare"]) ||
                    percentageTotalError
                  }
                  onChange={(event) => {
                    handleChangeField(
                      "percentageofShare",
                      event.target.value,
                      setFieldValue,
                      index,
                      values,
                      setErrors,
                      errors
                    );
                  }}
                  onBlur={handleBlur}
                  inputProps={{
                    maxLength: 3,
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    onInput: (event: React.ChangeEvent<HTMLInputElement>) => {
                      const value: any = event.target.value;
                      if (value > 100) {
                        event.target.value = "100";
                      }
                      if (value <= 0) {
                        event.target.value = "";
                      }
                    },
                  }}
                  onKeyPress={(event) => {
                    const keyCode = event.keyCode || event.which;
                    const keyValue = String.fromCharCode(keyCode);
                    const isValidKey = /^\d+$/.test(keyValue);

                    if (!isValidKey) {
                      event.preventDefault();
                    }
                  }}
                />

                <FormHelperText style={{ color: "#d32f2f" }}>
                  {values.percentageTotal > 100 &&
                    "Percenatge of Share should not exceed 100%"}
                </FormHelperText>
              </div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    minDate={maxDate}
                    maxDate={dateCheck}
                    disableFuture
                    components={{
                      OpenPickerIcon: DateIcon,
                    }}
                    id="dateofbirthNomineeDateofBirth"
                    label="Nominee Date of Birth"
                    name="nomineeDateofBirth"
                    format="DD/MM/YYYY"
                    views={["year", "month", "day"]}
                    value={nominee.nomineeDateofBirth}
                    className={Styles.datepicker}
                    onChange={(date) => {
                      //console.log("date", date);
                      handleChangeField(
                        "nomineeDateofBirth",
                        date,
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    onBlur={handleBlur}
                    error={
                      (errorData && Boolean(errorData["nomineeDateofBirth"])) ||
                      dateCheckError
                    }
                    helperText={
                      (errorData && errorData["nomineeDateofBirth"]) ||
                      dateCheckError
                    }
                    renderInput={(params: any) => <TextField {...params} />}
                    // views={undefined}
                    {...(DatePicker as any)}
                  />
                </LocalizationProvider>
                <FormHelperText
                  style={{ color: "#d32f2f", marginLeft: "13px" }}
                >
                  {(errorData && errorData["nomineeDateofBirth"]) ||
                    dateCheckError}
                </FormHelperText>
              </div>
              {/* <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    minDate={maxDate}
                    maxDate={dateCheck}
                    disableFuture
                    components={{
                      OpenPickerIcon: DateIcon,
                    }}
                    id="dateofbirthNomineeDateofBirth"
                    label="Nominee Date of Birth"
                    name="nomineeDateofBirth"
                    format="DD/MM/YYYY"
                    views={["year", "month", "day"]}
                    value={nominee.nomineeDateofBirth}
                    className={Styles.datepicker}
                    onChange={(date) => {
                      //console.log("date", date);
                      handleChangeField(
                        "nomineeDateofBirth",
                        date,
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    onBlur={handleBlur}
                    error={
                      (errorData && Boolean(errorData["nomineeDateofBirth"])) ||
                      dateCheckError
                    }
                    helperText={
                      (errorData && errorData["nomineeDateofBirth"]) ||
                      dateCheckError
                    }
                    renderInput={(params: any) => <TextField {...params} />}
                    // views={undefined}
                    {...(DatePicker as any)}
                  />
                </LocalizationProvider>
                <FormHelperText
                  style={{ color: "#d32f2f", marginLeft: "13px" }}
                >
                  {(errorData && errorData["nomineeDateofBirth"]) ||
                    dateCheckError}
                </FormHelperText>
              </div> */}
            </div>

            <div>
              <div className={Styles.fields}>
                {/* <div>
                  <div className={Styles.textfields}>
                 
                    <DropdownComp
                      LabelName="Relationship"
                      menuItems={relationShipData}
                      name="relationShip"
                      value={nominee.relationShip}
                      handleChange={(event: any) => {
                        handleChangeField(
                          "relationShip",
                          event.target.value,
                          setFieldValue,
                          index,
                          values,
                          setErrors,
                          errors
                        );
                      }}
                      error={errorData && Boolean(errorData["relationShip"])}
                      helperText={errorData && errorData["relationShip"]}
                      //   setOthers={setOccupationOthers}
                    />
                  </div>
                </div> */}
              </div>

              {nominee.relationShip.isGuardianReq &&
              !check18YearOfAge(nominee.nomineeDateofBirth) ? (
                <>
                  <div className={Styles.fields}>
                    <div>
                      <TextField
                        className={Styles.textfields}
                        id="outlined-basic"
                        label="Guardian's Fullname"
                        variant="outlined"
                        name="gaurdianFullName"
                        value={nominee.gaurdianFullName}
                        error={
                          errorData && Boolean(errorData["gaurdianFullName"])
                        }
                        helperText={errorData && errorData["gaurdianFullName"]}
                        onChange={(event) => {
                          handleChangeField(
                            "gaurdianFullName",
                            event.target.value.toUpperCase(),
                            setFieldValue,
                            index,
                            values,
                            setErrors,
                            errors
                          );
                        }}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </div>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          disableFuture
                          components={{
                            OpenPickerIcon: DateIcon,
                          }}
                          minDate={maxDate}
                          maxDate={eighteenYearsAgo}
                          views={["year", "month", "day"]}
                          id="dateofbirthGaurdianDateofBirth"
                          label="Guardian Date of Birth"
                          name="gaurdianDateofBirth"
                          format="DD/MM/YYYY"
                          value={nominee.gaurdianDateofBirth}
                          className={Styles.datepicker}
                          onChange={(date) => {
                            //console.log("date", date);
                            handleChangeField(
                              "gaurdianDateofBirth",
                              date,
                              setFieldValue,
                              index,
                              values,
                              setErrors,
                              errors
                            );
                          }}
                          onBlur={handleBlur}
                          error={
                            errorData &&
                            Boolean(errorData["nomineeDateofBirth"])
                          }
                          helperText={
                            errorData && errorData["gaurdianDateofBirth"]
                          }
                          renderInput={(params: any) => (
                            <TextField {...params} />
                          )}
                          {...(DatePicker as any)}
                        />
                      </LocalizationProvider>
                      <FormHelperText
                        style={{ color: "#d32f2f", marginLeft: "13px" }}
                      >
                        {values.dobError}
                      </FormHelperText>
                    </div>
                    <div>
                      <TextField
                        className={Styles.textfields}
                        id="outlined-basic"
                        label="Guardian's Email"
                        variant="outlined"
                        name="gaurdianEmail"
                        value={nominee.gaurdianEmail}
                        error={errorData && Boolean(errorData["gaurdianEmail"])}
                        helperText={errorData && errorData["gaurdianEmail"]}
                        onChange={(event) => {
                          handleChangeField(
                            "gaurdianEmail",
                            event.target.value,
                            setFieldValue,
                            index,
                            values,
                            setErrors,
                            errors
                          );
                        }}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        className={Styles.textfields}
                        id="outlined-basic"
                        label="Guardian's Mobile Number"
                        variant="outlined"
                        name="gaurdianMobileNumber"
                        value={nominee.gaurdianMobileNumber}
                        error={
                          errorData &&
                          Boolean(errorData["gaurdianMobileNumber"])
                        }
                        helperText={
                          errorData && errorData["gaurdianMobileNumber"]
                        }
                        onChange={(event) => {
                          handleChangeField(
                            "gaurdianMobileNumber",
                            event.target.value,
                            setFieldValue,
                            index,
                            values,
                            setErrors,
                            errors
                          );
                        }}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 10,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        onKeyPress={(event) => {
                          const keyCode = event.keyCode || event.which;
                          const keyValue = String.fromCharCode(keyCode);
                          const isValidKey = /^\d+$/.test(keyValue);

                          if (!isValidKey) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={Styles.fields}></div>
                  {/* <div>
                    <TextField
                      className={Styles.gaurdianRelation}
                      id="outlined-basic"
                      label="Gaurdian Relation with Nominee"
                      variant="outlined"
                      name="gaurdianRelationWithNominee"
                      value={nominee.gaurdianRelationWithNominee}
                      error={
                        errorData &&
                        Boolean(errorData["gaurdianRelationWithNominee"])
                      }
                      helperText={
                        errorData && errorData["gaurdianRelationWithNominee"]
                      }
                      onChange={(event) => {
                        handleChangeField(
                          "gaurdianRelationWithNominee",
                          event.target.value,
                          setFieldValue,
                          index,
                          values,
                          setErrors,
                          errors
                        );
                      }}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 50,
                      }}
                    />
                  </div> */}

                  <div className={Styles.fields}>
                    <div className={Styles.textfields}>
                      {/* <TextField
                        className={Styles.gaurdianRelation}
                        id="outlined-basic"
                        label="Gaurdian Relation with Nominee"
                        variant="outlined"
                        name="gaurdianRelationWithNominee"
                        value={nominee.gaurdianRelationWithNominee}
                        error={
                          errorData &&
                          Boolean(errorData["gaurdianRelationWithNominee"])
                        }
                        helperText={
                          errorData && errorData["gaurdianRelationWithNominee"]
                        }
                        onChange={(event) => {
                          handleChangeField(
                            "gaurdianRelationWithNominee",
                            event.target.value.toUpperCase(),
                            setFieldValue,
                            index,
                            values,
                            setErrors,
                            errors
                          );
                        }}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 50,
                        }}
                      /> */}
                      <DropdownComp
                        LabelName="Guardian Relation with Nominee"
                        menuItems={
                          relationShipData
                            ? relationShipData.filter(
                                (obj) => obj.value !== "Others"
                              )
                            : []
                        }
                        name="gaurdianRelationWithNominee"
                        value={nominee.gaurdianRelationWithNominee}
                        handleChange={(event: any) => {
                          handleChangeField(
                            "gaurdianRelationWithNominee",
                            event.target.value,
                            setFieldValue,
                            index,
                            values,
                            setErrors,
                            errors
                          );
                        }}
                        error={
                          errorData &&
                          Boolean(errorData["gaurdianRelationWithNominee"])
                        }
                        helperText={
                          errorData && errorData["gaurdianRelationWithNominee"]
                        }
                        //   setOthers={setOccupationOthers}
                      />
                    </div>
                  </div>
                  <div className={Styles.checkBox}>
                    <Checkbox
                      checked={address.address === nominee.gaurdianAddress}
                      onChange={(event) => {
                        handleSetGaurdianAddress(
                          values,
                          setFieldValue,
                          index,
                          event.target.checked
                        );
                      }}
                    />
                    <Typography sx={{ color: "grey" }}>
                      Make Guardian address same as mine <span style={{fontSize:'8px', color:"red"}}>* Edit if required</span>
                    </Typography>
                  </div>

                  <TextField
                    className={Styles.addressfields}
                    id="outlined-basic"
                    label="Guardian Address"
                    variant="outlined"
                    name="gaurdianAddress"
                    value={
                      nominee.gaurdianAddress ? nominee.gaurdianAddress : ""
                    }
                    InputLabelProps={{ shrink: true }}
                    error={errorData && Boolean(errorData["gaurdianAddress"])}
                    helperText={errorData && errorData["gaurdianAddress"]}
                    onChange={(event) => {
                      handleChangeField(
                        "gaurdianAddress",
                        event.target.value.toUpperCase(),
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    onBlur={handleBlur}
                    inputProps={{
                      maxLength: 250,
                    }}
                    // disabled={address.address === nominee.gaurdianAddress}
                  />
                  <>
                    <div
                      className={Styles.fields}
                      style={{ marginTop: "20px" }}
                    >
                      <div>
                        <TextField
                          className={Styles.textfields}
                          id="outlined-basic"
                          label="Guardian Pincode"
                          variant="outlined"
                          name="gaurdianAddressPinCode"
                          value={nominee.gaurdianAddressPinCode}
                          InputLabelProps={{ shrink: true }}
                          error={
                            errorData &&
                            Boolean(errorData["gaurdianAddressPinCode"])
                          }
                          helperText={
                            errorData && errorData["gaurdianAddressPinCode"]
                          }
                          onChange={(event) => {
                            handleChangeField(
                              "gaurdianAddressPinCode",
                              event.target.value,
                              setFieldValue,
                              index,
                              values,
                              setErrors,
                              errors
                            );
                          }}
                          onBlur={handleBlur}
                          // disabled={
                          //   address.pincode === nominee.gaurdianAddressPinCode
                          // }
                          inputProps={{
                            maxLength: 6,
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          onKeyPress={(event) => {
                            const keyCode = event.keyCode || event.which;
                            const keyValue = String.fromCharCode(keyCode);
                            const isValidKey = /^\d+$/.test(keyValue);

                            if (!isValidKey) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </div>
                      <div>
                        <TextField
                          className={Styles.textfields}
                          id="outlined-basic"
                          label="Guardian City"
                          variant="outlined"
                          name="gaurdianAddressCity"
                          value={nominee.gaurdianAddressCity}
                          InputLabelProps={{ shrink: true }}
                          error={
                            errorData &&
                            Boolean(errorData["gaurdianAddressCity"])
                          }
                          helperText={
                            errorData && errorData["gaurdianAddressCity"]
                          }
                          onChange={(event) => {
                            handleChangeField(
                              "gaurdianAddressCity",
                              event.target.value.toUpperCase(),
                              setFieldValue,
                              index,
                              values,
                              setErrors,
                              errors
                            );
                          }}
                          onBlur={handleBlur}
                          // disabled={
                          //   address.city === nominee.gaurdianAddressCity
                          // }
                          inputProps={{ maxLength: 50 }}
                        />
                      </div>
                      <div className={Styles.textfields}>
                        <DropdownComp
                          LabelName="Guardian State"
                          menuItems={statesData}
                          name="gaurdianAddressState"
                          value={nominee.gaurdianAddressState}
                          handleChange={(event: any) => {
                            handleChangeField(
                              "gaurdianAddressState",
                              event.target.value,
                              setFieldValue,
                              index,
                              values,
                              setErrors,
                              errors
                            );
                          }}
                          error={
                            errorData &&
                            Boolean(errorData["gaurdianAddressState"])
                          }
                          helperText={
                            errorData && errorData["gaurdianAddressState"]
                          }
                          //   setOthers={setOccupationOthers}
                          // disabled={
                          //   disableStateField === nominee.gaurdianAddressState
                          // }
                        />
                      </div>
                    </div>
                  </>
                </>
              ) : (
                ""
              )}

              <div>
                {nominee.relationShip.value === "Others" ? (
                  <TextField
                    className={Styles.textfieldsOthers}
                    id="outlined-basic"
                    label="RelationShip Others"
                    variant="outlined"
                    name="relationshipIfOthers"
                    value={nominee.relationshipIfOthers.toUpperCase()}
                    error={
                      errorData && Boolean(errorData["relationshipIfOthers"])
                    }
                    sx={{ marginTop: "15px" }}
                    helperText={errorData && errorData["relationshipIfOthers"]}
                    onChange={(event) => {
                      handleChangeField(
                        "relationshipIfOthers",
                        event.target.value,
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    onBlur={handleBlur}
                    inputProps={{ maxLength: 50 }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <p className={Styles.nomineeContactDetails}>
              Nominee Contact Details
            </p>
            <div className={Styles.checkBox}>
              <Checkbox
                checked={address.address === nominee.nomineeAddress}
                onChange={(event) => {
                  handleSetAddress(
                    values,
                    setFieldValue,
                    index,
                    event.target.checked
                  );
                }}
              />
              <Typography sx={{ color: "grey" }}>
                Make Nominee address same as mine <span style={{fontSize:'8px', color:"red"}}>* Edit if required</span>
              </Typography>
            </div>
            <div className={Styles.NomineeAddressSection}>
              <div className={Styles.Addressdiv}>
                <TextField
                  id="nomineeAddress"
                  className={Styles.addressfields}
                  name="nomineeAddress"
                  label="Nominee Address"
                  variant="outlined"
                  placeholder="Nominee Address"
                  value={nominee.nomineeAddress.toUpperCase()}
                  onChange={(event) => {
                    handleChangeField(
                      "nomineeAddress",
                      event.target.value,
                      setFieldValue,
                      index,
                      values,
                      setErrors,
                      errors
                    );
                  }}
                  error={errorData && Boolean(errorData["nomineeAddress"])}
                  helperText={errorData && errorData["nomineeAddress"]}
                  // disabled={address.address === nominee.nomineeAddress}
                  inputProps={{ maxLength: 250 }}
                />
              </div>
              <div className={Styles.PinCodediv}>
                <TextField
                  className={Styles.pincodeField}
                  id="outlined-basic"
                  label="Nominee Pincode"
                  variant="outlined"
                  name="nomineeAddressPinCode"
                  value={nominee.nomineeAddressPinCode}
                  error={
                    errorData && Boolean(errorData["nomineeAddressPinCode"])
                  }
                  helperText={errorData && errorData["nomineeAddressPinCode"]}
                  onChange={(event) => {
                    handleChangeField(
                      "nomineeAddressPinCode",
                      event.target.value,
                      setFieldValue,
                      index,
                      values,
                      setErrors,
                      errors
                    );
                  }}
                  onBlur={handleBlur}
                  // disabled={address.pincode === nominee.nomineeAddressPinCode}
                  inputProps={{
                    maxLength: 6,
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  onKeyPress={(event) => {
                    const keyCode = event.keyCode || event.which;
                    const keyValue = String.fromCharCode(keyCode);
                    const isValidKey = /^\d+$/.test(keyValue);

                    if (!isValidKey) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <>
              <div className={Styles.fields} style={{ marginTop: "20px" }}>
                <div>
                  <TextField
                    className={Styles.textfields}
                    id="outlined-basic"
                    label="Nominee City"
                    variant="outlined"
                    name="nomineeAddressCity"
                    value={nominee.nomineeAddressCity.toUpperCase()}
                    error={
                      errorData && Boolean(errorData["nomineeAddressCity"])
                    }
                    helperText={errorData && errorData["nomineeAddressCity"]}
                    onChange={(event) => {
                      handleChangeField(
                        "nomineeAddressCity",
                        event.target.value,
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    onBlur={handleBlur}
                    // disabled={address.city === nominee.nomineeAddressCity}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>

                <div className={Styles.textfields}>
                  <DropdownComp
                    LabelName="Nominee State"
                    menuItems={statesData}
                    name="nomineeAddressState"
                    value={nominee.nomineeAddressState}
                    // disabled={SelectedStateNew === nominee.nomineeAddressState}
                    handleChange={(event: any) => {
                      handleChangeField(
                        "nomineeAddressState",
                        event.target.value,
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    error={
                      errorData && Boolean(errorData["nomineeAddressState"])
                    }
                    helperText={errorData && errorData["nomineeAddressState"]}
                    //   setOthers={setOccupationOthers}
                  />
                </div>
                <div>
                  <TextField
                    className={Styles.textfields}
                    id="outlined-basic"
                    type="email"
                    label="Nominee's Email"
                    variant="outlined"
                    name="nomineeEmail"
                    value={nominee.nomineeEmail}
                    error={errorData && Boolean(errorData["nomineeEmail"])}
                    helperText={errorData && errorData["nomineeEmail"]}
                    onChange={(event) => {
                      handleChangeField(
                        "nomineeEmail",
                        event.target.value,
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    onBlur={handleBlur}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div>
                  <TextField
                    className={Styles.textfields}
                    id="outlined-basic"
                    label="Nominee's Mobile Number"
                    variant="outlined"
                    name="nomineeMobileNumber"
                    value={nominee.nomineeMobileNumber}
                    error={
                      errorData && Boolean(errorData["nomineeMobileNumber"])
                    }
                    helperText={errorData && errorData["nomineeMobileNumber"]}
                    onChange={(event) => {
                      handleChangeField(
                        "nomineeMobileNumber",
                        event.target.value,
                        setFieldValue,
                        index,
                        values,
                        setErrors,
                        errors
                      );
                    }}
                    onBlur={handleBlur}
                    inputProps={{
                      maxLength: 10,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    onKeyPress={(event) => {
                      const keyCode = event.keyCode || event.which;
                      const keyValue = String.fromCharCode(keyCode);
                      const isValidKey = /^\d+$/.test(keyValue);

                      if (!isValidKey) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      <SelectProof
        textFieldName={"nomineeIDProofNo"}
        radioGroupValue={nominee.nomineeIDProof}
        textFieldValue={nominee.nomineeIDProofNo.toUpperCase()}
        textFieldLabel={nominee.noimineeIDProofNo}
        textFieldError={errorData && Boolean(errorData["nomineeIDProofNo"])}
        onTextFieldChange={(event) => {
          handleChangeField(
            "nomineeIDProofNo",
            event.target.value,
            setFieldValue,
            index,
            values,
            setErrors,
            errors
          );
        }}
        textFieldHelperText={errorData && errorData["nomineeIDProofNo"]}
        radioGroups={nomineeID}
        index={index}
        currentIndex={currentIndex}
        name={"nomineeIDProof"}
        values={values}
        handleRadioButtonFieldChange={(event) => {
          handleChangeField(
            "nomineeIDProof",
            event.target.value,
            setFieldValue,
            index,
            values,
            setErrors,
            errors
          );
        }}
        helperText={errorData && errorData["nomineeIDProof"]}
        variant={"outlined"}
      />
    </>
  );
}
