import axios, { AxiosResponse } from "axios";

import { EKYCURL } from "./constants";
import {
  IMobile,
  IMobileOtpValidation,
  IReSendMobileOTP,
  ISendEmail,
  IEmailOTPValidation,
  IReSendEmailOTP,
  IPanDetails,
  IYourProfileDetails,
  IWebImage,
  ITermsandConditions,
  IBankDetails,
  IDeleteDocument,
  IGetReversePennyDropStatus,
  IRevesePennyDropBankDetails,
  IAdminLogin,
  IGetDocumentDigilocker,
  IFetchDataAccountAggregtor,
} from "../shared/dtos";

const axiosInterceptor = axios.create({
  baseURL: EKYCURL,
});

axiosInterceptor.interceptors.request.use(
  async (req) => {
    const token = sessionStorage.getItem("access-token");

    req.headers.Authorization = `Bearer ${token}`;
    return req;
  },
  (error) => Promise.reject(error)
);

axiosInterceptor.interceptors.response.use(
  (res) => res,
  (err) => {
    const status = err.response ? err.response.status : null;
    if (status === 401) {
      handleLogout();
    }
    return Promise.reject(err);
  }
);
const handleLogout = () => {
  window.location.href = "/";
};

export const sendMobileNoAPICall = (requestBody: IMobile) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/validation/mobileOTP/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const locationCheckAPICall = (ip_address: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/common/ip_location_check/${ip_address}`,{params:{ip_address:ip_address}})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const validateMobileOTPAPICall = (requestBody: IMobileOtpValidation) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/validation/validateMobileOTP/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const reSendMobileOTPAPICall = (requestBody: IReSendMobileOTP) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/validation/resendMobileOTP/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const sendEmialAPICall = (requestBody: ISendEmail) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/validation/emailOTP/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const validateEmailOTPAPICall = (requestBody: IEmailOTPValidation) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/validation/validateEmailOTP/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const reSendEmailOTPAPICall = (requestBody: IReSendEmailOTP) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/validation/resendEmailOTP/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const panDetailsAPICall = (requestBody: IPanDetails) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/panDetails/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const digiLockerInitAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/digilocker/init`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const yourProfileDetailsAPICall = (requestBody: IYourProfileDetails) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/profileDetails/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const digiLockerStatusAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/digilocker/status/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const startOverAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/common/startover/`)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const segmentDetailsAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/dropDown/segmentDetails/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const segmentDetailsPostAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/segmentdetails/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const webImageBase64APICall = (requestBody: IWebImage) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/webimage/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const nomineeDetailsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/nomineedetails/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const termsAndConditionsAPICall = (requestBody: ITermsandConditions) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .put(`/ekyc/tandc/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const esignAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/esign/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const bankDetailsAPICall = (requestBody: IBankDetails) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/bankdetails/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const uploadDocumentsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/documentupload/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const documentupdateAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/documentupdate/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const deleteDocumentAPICall = ({ document_purpose }) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/ekyc/documentdelete/`, {
        data: { document_purpose },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const getDocumentTypeDropDownAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/dropDown/doctypedropdown/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const KraesignAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/kraesign/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const requestEsignAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/esign/request_esign`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const CreatePdfEsignAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/esign/create_pdf_esign`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const CreateEsignRequestAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/esign/create_esign_req/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getDocumentEsignAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/esign/get_document/${requestBody}`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const fetchGetDropdownValuesAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/dropDown/profile/`)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const fetchGetNomineeDropdownValuesAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/dropDown/nominee/`)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const stageDetailsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/stagedetails/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const nomineeAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/nomineedetails/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const KRAUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`ekyc/fetchdetails/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const NewReversePennyDropRequestAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`ekyc/pennydroprequest/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetReversePennyDropStatusAPICall = (
  requestBody: IGetReversePennyDropStatus
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`ekyc/getpennydropstatus/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const SubmitReversePennyDropAPICall = (
  requestBody: IRevesePennyDropBankDetails
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/reversepennydrop/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const AdminLoginToClientAccountAPICall = (requestBody: IAdminLogin) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/validation/admin_login/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const PanDocumentRequestAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/documnet/request/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const IncomeProofRequestAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/account_aggregator/create_consent/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const AccountAggregatorFetchDataAPICall = (requestBody:IFetchDataAccountAggregtor) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/account_aggregator/fetch_data/`,requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const PanDigilockerDocumentGetAPICall = (
  requestBody: IGetDocumentDigilocker
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(
        `/ekyc/digilocker/document/get/`,
        "",
        {
          params: {
            id: requestBody.id,
            doc_type: requestBody.doc_type,
          },
        }
      )
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};


//--------------------------Redirect-------------------------------------------

export const digiLockerAuthenticationAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/digilocker/authentication/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};


export const esignDocumentAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/esign/complete_esign`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
