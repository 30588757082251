import React, { useEffect, useState } from "react";

import { BrowserRouter, Outlet, useNavigate } from "react-router-dom";

import { Box, Chip, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepButton from "@mui/material/StepButton";
import { useSelector, Provider } from "react-redux";
import store from "../Components/redux/store";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { theme } from "../Components/ThemeProvider/ThemeProvider";
import { styled } from "@mui/material/styles";

import { StepIconProps } from "@mui/material/StepIcon";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";

import Check from "@mui/icons-material/Check";
import AppStyles from "../App.module.scss";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import backIcon from "../Components/assets/Images/backIcon.svg";

import { stageDetailsAPICall } from "../shared/APICalls";
import routesData from "../shared/routes.json";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "rgba(3, 152, 85, 1)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "rgba(3, 152, 85, 1)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 5,
    alignItems: "center",
    ...(ownerState.active && {
      color: "rgba(3, 152, 85, 1)",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "rgba(3, 152, 85, 1)",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 5,
  margin: "0px 0px 0px 0px",
  // width: "93vw",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#B1DDC3" : "#308fe8",
  },
}));

interface Props {
  completedStages: any;
  stageName: string;
  navigatePath: string;
  esignBackButtonEnable?: any;
}

export default function StageLayoutEkyc({
  completedStages,
  stageName,
  navigatePath,
  esignBackButtonEnable,
}: Props) {
  //console.log("completedStages", completedStages);

  const navigate = useNavigate();

  const [step, setStep] = useState<any>(1);
  const [stageDetailsApi, setStageDetailsApi] = useState<any>(0);
  const [showSteps, setshowSteps] = useState(false);
  const navigationData = routesData;
  const pathName = useSelector((state: any) => state.PATHNAME.pathname);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);


  //console.log(pathName);

  const pathToStepMap = {
    "/": -4,
    "/confirm_otp": -3,
    "/yourdetails": -2,
    "/confirmemailotp": -1,
    "/panverification": 1,
    "/aadhaarkyc": 2,
    "/tradingPreference": 3,
    "/yourprofile": 4,
    "/linkusingifsc": 5,
    "/cameramodule": 6,
    "/uploaddocuments": 7,
    "/nomineepage": 8,
    "/termsandconditions": 9,
    "/esign": 10,
    "/congratulations": 11,
  };

  const completed = {
    pan_details: 0,
    fetch_details: 1,
    segment_details: 2,
    profile_page: 3,
    bank_account: 4,
    web_image: 5,
    document_upload: 6,
    nominee_details: 7,
    t_and_c: 8,
    e_sign: 9,
    application_completed: 10,
  };
  const completedStageDetails = {
    pan_details: {
      title: "Pan Verification",
      path: "/panverification",
    },
    fetch_details: {
      title: "Aadhaar",
      path: "/aadhaar",
    },
    profile_page: {
      title: "Profile Details",
      path: "/yourprofile",
    },
    segment_details: {
      title: "Trading Preference",
      path: "/tradingPreference",
    },
    bank_account: {
      title: "Bank Account",
      path: "/linkusingifsc",
    },
    t_and_c: {
      title: "Terms and Conditions",
      path: "/termsandconditions",
    },
    document_upload: {
      title: "Documents Upload",
      path: "/uploaddocuments",
    },
    web_image: {
      title: "IPV",
      path: "/cameramodule",
    },
    nominee_details: {
      title: "Nominee",
      path: "/nomineePage",
    },
    e_sign: {
      title: "Esign",
      path: "/esign",
    },
  };

  useEffect(() => {
    Object.keys(pathToStepMap).forEach((path) => {
      if (pathName.includes(path)) {
        //  setStep(pathToStepMap[path]);
        setStageDetailsApi(pathToStepMap[path]);
        //console.log(pathToStepMap[path]);
      }
    });
  }, [pathName]);

  useEffect(() => {
    if (stageDetailsApi > 0) {
      let obj: any = {
        stage: "document_upload",
      };

      if (completedStages) {
        const matchedRoutes: any = [];
        completedStages.forEach((route) => {
          if (completed.hasOwnProperty(route)) {
            matchedRoutes.push(completed[route]);
          }
          // console.log("matchedRoutes", matchedRoutes.length);
          // setStep(matchedRoutes.length);
          setStep(matchedRoutes.length);
        });
      }
    }
  }, [stageDetailsApi, completedStages]);

  useEffect(() => {
    if (stageDetailsApi > -1) {
      setshowSteps(true);
    }
  }, [step]);

  const handleClick = (index) => {
    // console.log("path", index);

    if (index <= step) {
      const path = Object.keys(pathToStepMap).find(
        (key) => pathToStepMap[key] === index + 1
      );
      if (path) {
        // window.location.href = path;
        navigate(path);
      }
    }
  };

  // const handleBackClick = () => {
  //   if (pathName.includes("cameramodule")) {
  //     window.location.href = navigatePath;
  //   } else {
  //     navigate(navigatePath);
  //   }
  // };

  const handleClose = (path: any) => {
    setAnchorEl(null);

    if (pathName.includes("cameramodule")) {
      window.location.href = path.path;
    } else {
      navigate(path.path);
    }
  };
  const handleBackClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const steps = [
    "Pan",
    "Aadhaar",
    "Trading",
    "Profile",
    "Bank",
    "Cam",
    "Documents",
    "Nominee",
    "T&C",
    "Esign",
  ];

  const stepMobile = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  const FilteredStages =  completedStages?.filter((a:any)=>a !== "pan_details" && a !== "fetch_details")

  // console.log("completedStages",completedStages)
  return (
    <>
      <Stack style={{ overflowX: "hidden" }}>
        {showSteps && (
          <>
            {step >= 0 && step <= 9 && (
              <>
                <Box
                  sx={{
                    width: "100%",
                    margin: { xs: "0 2px", md: "0 10px" },
                    zIndex: 100,
                    position: "relative",
                    // top: "50px",
                    // paddingTop: "5px",
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={step * 11}
                    />
                  </Box>
                </Box>
                <Stack alignItems={"flex-start"}>
                  <div style={{ marginTop: "5px", marginLeft: "3%" }}>
                    {esignBackButtonEnable === "DocumentReceived" ? (
                      ""
                    ) : (
                      <>
                        <IconButton
                          onClick={(event: any) => {
                            handleBackClick(event);
                          }}
                        >
                          <img
                            src={backIcon}
                            style={{
                              background: "white",
                              transform: open ? "rotate(-90deg)" : "",
                              transition: "all 0.3s",
                            }}
                          />
                        </IconButton>
                        {FilteredStages?.length > 0 && (
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            {FilteredStages &&
                              FilteredStages.map((a: any) => (
                                <MenuItem
                                  onClick={() => {
                                    handleClose(completedStageDetails[a]);
                                  }}
                                >
                                  {completedStageDetails[a].title}
                                </MenuItem>
                              ))}
                          </Menu>
                        )}
                      </>
                    )}
                    <Chip
                      sx={{
                        border: "1px solid #006779",
                        backgroundColor: "#CCE1E4",
                        marginTop:
                          pathName.includes("panverification") ||
                          pathName.includes("aadhaar") ||
                          pathName.includes("yourprofile") ||
                          esignBackButtonEnable === "DocumentReceived"
                            ? "5px"
                            : 0,
                      }}
                      label={stageName}
                      variant="outlined"
                    />
                  </div>
                </Stack>
              </>
            )}
          </>
        )}
      </Stack>
    </>
  );
}
