import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Avatar,
} from "@mui/material";
import DoneImage from "../Images/doneicon.png";
import { useLocation } from "react-router-dom";


export default function PageNotFoundEsign() {
  const pathName = useLocation().search;


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
      }}
    >
      <h2>Page Not Found</h2>
    </div>
  );
}
