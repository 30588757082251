import React, { useEffect, useState, useRef } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Avatar,
  Divider,
  Modal,
  Fade,
  Box,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import DoneImage from "../Images/doneicon.png";
import { checkUserLoggedIn } from "../shared/commonfunctions";
import PdfModal from "../Components/PdfModal/PdfModal";
import WebcamDemo from "../Components/CameraModule/WebCamFaceDetection";
import {
  startOverAPICall,
  esignAPICall,
  requestEsignAPICall,
  getDocumentEsignAPICall,
  stageDetailsAPICall,
  CreatePdfEsignAPICall,
  CreateEsignRequestAPICall,
} from "../shared/APICalls";
import routesData from "../shared/routes.json";
import ESignStyles from "../pages/Styles/Esign.module.scss";
import AadhaarImage from "../Components/assets/Images/esignnewimage.svg";
import PDFViewerIframe from "../Components/PDFIframe/PDFWithIframe";
import CloseIcon from "@mui/icons-material/Close";
import StageLayout from "../Layouts/StageLayout";
import icon from "../Components/assets/Images/iconnew.svg";
import SnackBar from "../Components/SnackBars/SnackBar";
import StartOverBackDrop from "../Components/StartOverBackDrop";
import ErrorSnackBar from "../Components/SnackBars/ErrorSnackBar";
import StageLayoutEkyc from "../Layouts/StageLayoutEkyc";
import esignImage from "../Components/assets/Images/new esign_page-0001 - Copy - Copy.jpg";
import PDFViewerIframeEsign from "../Components/PDFIframe/PDFIframeEsign";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vW",
  height: "90vH",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  padding: "10px 0px 0px 5px",
};

export default function EsignPage() {
  const navigate = useNavigate();
  const [showModal, setshowModal] = useState(false);
  const [open, setOpen] = useState(true);
  const [loading, setloading] = useState(false);
  const [startOverloading, setstartOverloading] = useState(false);
  const [completedStages, setCompletedStages] = useState<any>();
  const [backButton, setBackButton] = useState("");
  const [backdrop, setBackDrop] = useState(false);

  const navigationData = routesData;

  useEffect(() => {
    window.scrollTo(0, 0);

    checkUserLoggedIn(navigate);
  }, []);

  const handleEsignClick = () => {
    // navigate("/congratulations");

    setshowModal(true);
  };

  const handleClose = () => {
    setOpen(false);
    eSign();
  };

  function eSign() {
    setloading(true);
    esignAPICall()
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", response);
        setloading(false);

        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage]);
        }
      })
      .catch((error: any) => {
        //  console.log("error", error);
        setloading(false);
      });
  }

  const handleStartOver = () => {
    setstartOverloading(true);
    startOverAPICall()
      .then((response: any) => {
        const responseData = response.data;
        //  console.log("responseData", response);
        setstartOverloading(false);
        navigate("/");
      })
      .catch((error: any) => {
        setstartOverloading(false);
      });
  };

  const handleData = (data: any) => {
    //  console.log(data);
    setCompletedStages(data);
  };

  const handleDocumentData = (data: any) => {
    setBackButton(data);
  };

  return (
    <div className={ESignStyles.mainWrapperTop}>
      <StageLayoutEkyc
        completedStages={completedStages}
        stageName={"Esign Section"}
        navigatePath={"/cameramodule"}
        esignBackButtonEnable={backButton}
      />
      <div className={ESignStyles.wrapper}>
        <div className={ESignStyles.titleSection}>
          <p className={ESignStyles.title}>
            {" "}
            Esign Your Form Using Your Aadhaar Number{" "}
          </p>
        </div>
        <div className={ESignStyles.container}>
          <div className={ESignStyles.leftcontainer}>
            <div>
              <p className={ESignStyles.leftcontainerText}>
                1.Click on the Esign Button Below.
              </p>
              <p className={ESignStyles.leftcontainerText}>
                2.Enter Your Aadhaar Number.
              </p>
              <p className={ESignStyles.leftcontainerText}>
                3.Enter the OTP received on your Mobile Number Linked.
              </p>
              <div className={ESignStyles.rightmobileViewcontainer}>
                <img src={AadhaarImage} className={ESignStyles.image} />
              </div>
              <div>
                <MyComponent
                  completedStages={handleData}
                  documentdata={handleDocumentData}
                />
              </div>
            </div>
          </div>
          <div className={ESignStyles.rightcontainer}>
            <img src={AadhaarImage} className={ESignStyles.image} />
          </div>
        </div>
      </div>
    </div>
  );
}

function MyComponent({ completedStages, documentdata }) {
  const [childWindowRef, setchildWindowRef] = useState<any>(null);
  const [closetTimer, setclosetTimer] = useState(false);
  const [loading, setloading] = useState(false);
  const [startOverloading, setstartOverloading] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [showModal, setshowModal] = useState(false);
  const [documentURL, setDocumentURL] = useState<any>();
  const [docId, setDocId] = useState("");
  const [pdfUrl, setPdfUrl] = useState<any>("");
  const [continueOption, setcontinueOption] = useState(true);
  const [backdrop, setBackDrop] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [continueEsignLoading, setcontinueEsignLoading] = useState(false);
  const [startloading, setstartloading] = useState(false);

  const navigationData = routesData;
  const [navigatePath, setNavigate] = useState<any>();

  const [message, setMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const timerRef = useRef<any>(null);

  useEffect(() => {
    setloading(true);

    let obj: any = {
      stage: "e_sign",
    };

    stageDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        setloading(false);
        completedStages(responseData.completed_stages);
        //  console.log(responseData);
        if (response.data.data.StageDetails.ErrorMessage) {
          setRejectMessage(response.data.data.StageDetails.ErrorMessage);
        }

        if (
          responseData.data.StageDetails.Documents.e_sign_pdf.e_sign_pdf_url
        ) {
          setcontinueOption(false);
          documentdata("DocumentReceived");
          const meta = "data:application/pdf;base64,";

          setPdfUrl(
            responseData.data.StageDetails.Documents.e_sign_pdf.e_sign_pdf_url
          );
        }
      })
      .catch((error: any) => {
        //  console.log("error", error);
      });
    GenerateEsignPdf();
  }, []);

  const GenerateEsignPdf = () => {
    // setloading(true);
    CreatePdfEsignAPICall()
      .then((response: any) => {
        const responseData = response.data.data.url;
        console.log("responseData", responseData);
        setPdfUrl(response?.data?.data?.url ? response.data.data.url : "");
        // setloading(false);
      })
      .catch((error: any) => {
        // setloading(false);
      });
  };

  const handleEsignNow = () => {
    if (pdfUrl) {
      setshowModal(true);
      setloading(false);
    } else {
      handleEsignNow();
    }
  };

  useEffect(() => {
    if (startloading) {
      setloading(true);
      if (pdfUrl) {
        setshowModal(true);
        setloading(false);
        setstartloading(false);
      }
    }
  }, [pdfUrl, startloading]);

  const handleOpenClick = () => {
    let digilink: string = "";
    setcontinueEsignLoading(true);
    CreateEsignRequestAPICall()
      .then((response: any) => {
        const responseData = response.data;

        console.log("responseData", responseData.data);

        var url = responseData.data.url;
        var trimmedUrl = url.replace(":444", "");
        digilink = url;
        setTimeout(() => {
          const childWindow: any = window.open(
            digilink,
            "_blank",
            "width=1000,height=700"
          );
          setchildWindowRef(childWindow);
          setcontinueEsignLoading(false);
        });
      })
      .catch((error: any) => {
        setloading(false);
        setcontinueEsignLoading(false);
        setErrorMessage(
          error.message.includes("Network")
            ? "Error occured, Please try again"
            : error.response.data.error.message
        );
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      });
  };
  const handleContinue = () => {
    setOpen(false);
    setloading(false);
    eSign();
  };

  function eSign() {
    setloading(true);
    esignAPICall()
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", response);
        setloading(false);

        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage]);
        }
      })
      .catch((error: any) => {
        //  console.log("error", error);
        setloading(false);
      });
  }

  useEffect(() => {
    if (!closetTimer && childWindowRef) {
      //  console.log("timer trigger");
      timerRef.current = setInterval(checkChild, 500);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [childWindowRef, closetTimer]);

  function checkChild() {
    let token: any = sessionStorage.getItem("access-token");
    // console.log("timer");

    if (childWindowRef && childWindowRef.closed) {
      clearInterval(timerRef.current);

      let obj: any = {
        stage: "e_sign",
      };

      stageDetailsAPICall(obj)
        .then((response: any) => {
          const responseData = response.data;
          setloading(false);
          if (
            responseData.data.StageDetails.Documents.e_sign_pdf.e_sign_pdf_url
          ) {
            eSign();
            setloading(true);
            // setcontinueOption(false);
          }

          const meta = "data:application/pdf;base64,";
          const data =
            meta +
            responseData.data.StageDetails.Documents.e_sign_pdf
              .e_sign_pdf_base64;

          setPdfUrl(
            responseData.data.StageDetails.Documents.e_sign_pdf.e_sign_pdf_url
          );
          setloading(false);
          documentdata("DocumentReceived");
        })
        .catch((error: any) => {
          //  console.log("error", error);
        });
    }
  }

  const handlePreviewClick = () => {
    setshowModal(true);
  };
  const handlePreviewClose = () => {
    setshowModal(false);
    setloading(false);
  };

  const handleStartOver = () => {
    if (
      window.confirm(
        "Are you sure you want to restart, on pressing Yes all the Data will be deleted"
      )
    ) {
      setBackDrop(true);
      setstartOverloading(true);
      startOverAPICall()
        .then((response: any) => {
          const responseData = response.data;
          //  console.log("responseData", response);
          setstartOverloading(false);
          setBackDrop(false);
          navigate("/");
          window.location.reload();
        })
        .catch((error: any) => {
          setBackDrop(false);
          setstartOverloading(false);
        });
    }
  };

  return (
    <>
      <SnackBar message={ErrorMessage} />
      <ErrorSnackBar message={rejectMessage} />
      <div className={ESignStyles.buttonStyles}>
        <div>
          {continueOption ? (
            <LoadingButton
              type="submit"
              variant="contained"
              onClick={() => {
                setstartloading(true);
              }}
              loading={loading}
              id="aadharOpenLinkButton"
              className={ESignStyles.esignButton}
            >
              Esign Now
            </LoadingButton>
          ) : (
            <>
              <div className={ESignStyles.sectionButton}>
                <LoadingButton
                  className={ESignStyles.continue}
                  type="submit"
                  variant="contained"
                  sx={{ width: "200px" }}
                  onClick={handleContinue}
                  loading={loading}
                  id="aadharOpenLinkButton"
                >
                  Continue
                </LoadingButton>
                <Button
                  className={ESignStyles.esignPreview}
                  type="submit"
                  variant="outlined"
                  sx={{ color: "rgba(18, 183, 106, 1)", borderRadius: "20px" }}
                  onClick={handlePreviewClick}
                  id="aadharOpenLinkButton"
                >
                  View E-sign Document
                </Button>
              </div>
            </>
          )}
        </div>
        {backdrop && <StartOverBackDrop />}
        <div className={ESignStyles.startOver}>
          <div className={ESignStyles.startOverSection}>
            <img src={icon} className={ESignStyles.iconImage} />
            <p className={ESignStyles.paratext}>
              {" "}
              if the above mentioned info not yours
              <span
                className={ESignStyles.startoverbutton}
                onClick={handleStartOver}
              >
                Start Over
              </span>
            </p>
          </div>
        </div>
      </div>

      {showModal && (
        <Stack>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Stack
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  marginRight={"10px"}
                  marginTop={"5px"}
                  direction={"row"}
                >
                  <Typography>Esign Preview</Typography>
                  <IconButton onClick={handlePreviewClose}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <div className={ESignStyles.pdfMobilePreview}>
                  <img
                    src={esignImage}
                    style={{
                      width: "95%",
                      height: "100%",
                      objectFit: "inherit",
                      filter: "brightness(80%)",
                      marginBottom: "20px",
                    }}
                  />

                  <a
                    href={pdfUrl}
                    download="Esign-PDF-document"
                    target="_blank"
                    rel="noreferrer"
                    className={ESignStyles.previewdownlaodesign}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        background: "#1976d2",
                        "&:hover": { background: "#1976d2" },
                      }}
                    >
                      View Esign Document
                    </Button>
                  </a>
                </div>
                <div className={ESignStyles.pdfDesktopPreview}>
                  <PDFViewerIframeEsign urldata={pdfUrl} />
                </div>
                <Stack marginTop={"5px"}>
                  <LoadingButton
                    loading={continueEsignLoading}
                    variant="contained"
                    sx={{ width: "200px", margin: "auto", height: "40px" }}
                    onClick={handleOpenClick}
                  >
                    Continue with Esign
                  </LoadingButton>
                </Stack>
              </Box>
            </Fade>
          </Modal>
        </Stack>
      )}
    </>
  );
}
