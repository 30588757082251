import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { browserName } from "react-device-detect";
import CaptchaTest from "../Components/Captcha/CaptchaTest";
import {
  sendMobileNoAPICall,
  locationCheckAPICall,
  AdminLoginToClientAccountAPICall,
} from "../shared/APICalls";
import { IAdminLogin, IMobile } from "../shared/dtos";
import routesData from "../shared/routes.json";
import SignupStyles from "../pages/Styles/SignUp.module.scss";
import SignUpImage from "../Components/assets/Images/newLeftImage.svg";
import videonew from "../Components/assets/Images/ekycvideo.mp4";
import SnackBar from "../Components/SnackBars/SnackBar";
import ErrorSnackBar from "../Components/SnackBars/ErrorSnackBar";
import mobileviewimage from "../Components/assets/Images/mobileviewgiftbox.svg";
import axios from "axios";

export default function SignupPage() {
  const navigate = useNavigate();
  const pathName = useLocation().pathname;
  const [userDeniedLocation, setuserDeniedLocation] = useState(false);
  const [active, setactive] = useState(false);
  const [isSafari, setisSafari] = useState(false);
  const [intervalId, setintervalId] = useState<any>(null);
  const [isFireFox, setisFireFox] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [captchSubmit, setcaptchSubmit] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [loading, setloading] = useState(false);
  const [showContent, setshowContent] = useState(true);
  const [message, setMessage] = useState("");
  const [latitudeAccess, setLocationAccess] = useState<any>();
  const [loadingcontent, setloadingcontent] = useState(true);
  const [sebiCheck, setsebiCheck] = useState(false);
  const [IPAddress, setIPAddress] = useState("");

  const navigationData = routesData;

  const urlParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlParams.entries());
  const source = Object.keys(params)[0];
  const id = (params.c && params.c) || (params.p && params.p);

  //  console.log(Object.keys(params)[0]);

  const client_id = params.client_id;
  const user_id = params.user_id;

  if (source) {
    sessionStorage.setItem("source", source);
    sessionStorage.setItem("id", id);
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchIPAddress = async () => {
      try {
        const response = await axios.get("https://checkip.amazonaws.com/");
        setIPAddress(response.data);
      } catch (err: any) {}
    };
    fetchIPAddress();
  }, []);

  useEffect(() => {
    if (IPAddress !== "") {
      locationCheckAPICall(IPAddress?.trim())
        .then((response: any) => {
          // console.log("response", response.data);
        })
        .catch((error: any) => {
          // console.log("error", error);
          setshowContent(false);
        });
    }
  }, [IPAddress]);

  useEffect(() => {
    if (client_id && client_id) {
      let obj: IAdminLogin = {
        client_id: client_id,
        user_id: user_id,
      };

      AdminLoginToClientAccountAPICall(obj)
        .then((response: any) => {
          const responseData = response.data;
          sessionStorage.setItem(
            "access-token",
            responseData.data.access_token
          );
          if (
            responseData.data.stage &&
            navigationData.hasOwnProperty(responseData.data.stage)
          ) {
            if (responseData.data.stage === "your_details") {
              navigate(navigationData[responseData.data.stage], {
                state: { validationID: undefined },
              });
            } else {
              navigate(navigationData[responseData.data.stage]);
            }
          }
        })
        .catch((error: any) => {
          setMessage(error.response.data.error.message);
          setTimeout(() => {
            setMessage("");
          }, 5000);
        });
    }
  }, [client_id, user_id]);

  function sendMobileNumber(data: any) {
    setloading(true);
    let obj: IMobile = {
      mobile: data,
      ip_address: IPAddress?.trim(),
    };
    sendMobileNoAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;

        setloading(false);
        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage], {
            state: { mobile: data },
          });
        }
      })
      .catch((error: any) => {
        setloading(false);

        setMessage(
          error.message.includes("Network")
            ? "Error occured, Please try again"
            : error.response.data.error.message
        );
        setTimeout(() => {
          setMessage("");
        }, 5000);
      });
  }

  // useEffect(() => {

  //   getLocation();

  //   return () => clearInterval(intervalId);
  // }, [pathName]);

  // useEffect(() => {
  //   if (
  //     userDeniedLocation &&
  //     intervalId === null &&
  //     browserName !== "Firefox"
  //   ) {
  //     let interval = setInterval(() => {
  //       getLocation();
  //     }, 1000);
  //     setintervalId(interval);
  //   }
  // }, [userDeniedLocation]);

  // useEffect(() => {
  //   if (captcha === "Captcha Matched") {
  //     //sendMobileNumber();
  //   } else {
  //     setcaptchSubmit(false);
  //   }
  // }, [captcha]);

  // function getLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(showPosition, showError);
  //   } else {

  //   }
  // }

  // function showPosition(position) {
  //   setuserDeniedLocation(false);

  //   const latitudeCoords = position.coords.latitude;
  //   const longitudeCoords = position.coords.longitude;
  //   setLocationAccess(latitudeCoords);

  //   sessionStorage.setItem("latitude", latitudeCoords);
  //   sessionStorage.setItem("longitude", longitudeCoords);

  //   locationCheckAPICall(position.coords.latitude, position.coords.longitude)
  //     .then((response: any) => {
  //       const responseData = response.data;
  //     })
  //     .catch((error: any) => {});

  //   if (intervalId) {
  //     clearInterval(intervalId);
  //   }
  // }

  // function showError(error) {
  //   switch (error.code) {
  //     case error.PERMISSION_DENIED:
  //       // console.log("User denied the request for Geolocation.");
  //       setuserDeniedLocation(true);
  //       if (browserName === "Firefox") {
  //         setisFireFox(true);
  //       }

  //       break;

  //     case error.POSITION_UNAVAILABLE:
  //       // console.log("Location information is unavailable.");

  //       break;
  //     case error.TIMEOUT:
  //       // console.log("The request to get user location timed out.");

  //       break;
  //     case error.UNKNOWN_ERROR:
  //       // console.log("An unknown error occurred.");

  //       break;
  //   }
  // }

  function validatePhoneNumber(value: any) {
    let error: any;
    if (!value) {
      error = "Mobile Number Required";
    } else if (!/^[0-9]{10}$/.test(value)) {
      error = "Must be a valid 10 digit mobile number";
    }
    return error;
  }

  useEffect(() => {
    setTimeout(() => {
      setloadingcontent(false);
    }, 1000);
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        // console.log("Enter key was pressed. Run your function.");
        const onFormSubmit = (values) => {
          setcaptchSubmit(true);
          setMobileNumber(values.phoneNumber);
          sendMobileNumber(values.phoneNumber);
        };

        event.preventDefault();
        // callMyFunction();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <>
      <div className={SignupStyles.mobileHeader}>
        <p className={SignupStyles.LeftContainerHeadermobileHeader}>
          Invest with Confidence, Anytime, Anywhere With Firstock
        </p>
        <p className={SignupStyles.LeftContainerSubTextmobileHeader}>
          "Seamless. Secure. Smart Investing for Everyone."
        </p>
      </div>
      <div className={SignupStyles.wrapper}>
        <div className={SignupStyles.LeftContainer}>
          {loadingcontent ? (
            <div className={SignupStyles.loaderSection}>
              <span className={SignupStyles.loader}></span>
            </div>
          ) : (
            <>
              <p className={SignupStyles.LeftContainerHeader}>
                Invest with Confidence, Anytime, Anywhere With Firstock
              </p>
              <p className={SignupStyles.LeftContainerSubText}>
                "Seamless. Secure. Smart Investing for Everyone."
              </p>
              <video
                autoPlay={true}
                loop
                muted
                playsInline
                className={SignupStyles.image}
              >
                <source
                  src="https://firstock-common.s3.ap-south-1.amazonaws.com/projects/signup/ekycvideo.mp4"
                  type="video/mp4"
                />
              </video>
              {/* <img src={SignUpImage} alt="Image" className={SignupStyles.image} /> */}
            </>
          )}
        </div>
        <div className={SignupStyles.RightContainer}>
          <div className={SignupStyles.headerSection}>
            <p className={SignupStyles.signup}>
              Signup now by Entering Your Contact Details
            </p>
            <p className={SignupStyles.subText}>
              Or track your existing application
            </p>
          </div>

          <div>
            {showContent ? (
              <Formik
                initialValues={{
                  phoneNumber: "",
                }}
                onSubmit={(values) => {
                  // console.log(values);
                  setcaptchSubmit(true);
                  setMobileNumber(values.phoneNumber);
                  sendMobileNumber(values.phoneNumber);
                  // if (captcha === "Captcha Matched") {
                  //   navigate("/confirm_otp");
                  // }
                }}
              >
                {({ errors, touched, handleChange, values, handleSubmit }) => (
                  <Form
                    onKeyDown={(event) => {
                      event.code === "Enter" && handleSubmit();

                      // event.code.includes("Enter") && handleSubmit()
                    }}
                  >
                    {!userDeniedLocation ? (
                      <>
                        <div className="">
                          <Field
                            name="phoneNumber"
                            validate={validatePhoneNumber}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label=""
                                type="numeric"
                                sx={{
                                  fieldset: { borderColor: "#007585" },
                                }}
                                placeholder="Enter Mobile Number"
                                className={SignupStyles.textfield}
                                // style={{ width: "300px", margin: "10px 0px" }}
                                variant="outlined"
                                value={values.phoneNumber}
                                helperText={
                                  errors.phoneNumber &&
                                  touched.phoneNumber && (
                                    <FormHelperText
                                      style={{ color: "#d32f2f" }}
                                    >
                                      {errors.phoneNumber}
                                    </FormHelperText>
                                  )
                                }
                                onChange={handleChange}
                                inputProps={{
                                  sx: {
                                    "&::placeholder": {
                                      paddingLeft: "10px",
                                    },
                                    borderLeft: "1px solid #007585",
                                    paddingLeft: "10px",
                                  },
                                  maxLength: 10,
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      +91
                                    </InputAdornment>
                                  ),
                                }}
                                onKeyPress={(event) => {
                                  const keyCode = event.keyCode || event.which;
                                  const keyValue = String.fromCharCode(keyCode);
                                  const isValidKey = /^\d+$/.test(keyValue);

                                  if (!isValidKey) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            )}
                          />
                          <p className={SignupStyles.smallText}>
                            You will receive an OTP on your number
                          </p>
                          <FormGroup
                            sx={{ width: "max-content", marginLeft: "15px" }}
                          >
                            <FormControlLabel
                              sx={{ alignItems: "flex-start" }}
                              control={
                                <Checkbox
                                  name="fatcacondition1"
                                  sx={{ padding: "0px" }}
                                  checked={sebiCheck}
                                />
                              }
                              onChange={(event: any) => {
                                const { checked } = event.target;
                                setsebiCheck(checked);
                              }}
                              label={
                                <span
                                  className={SignupStyles.smallText}
                                  style={{ padding: 0 }}
                                >
                                  <span style={{ fontWeight: 600 }}>
                                    I express my consent in opening online KYC
                                    process{" "}
                                  </span>
                                </span>
                              }
                            />
                          </FormGroup>
                          {/* <CaptchaTest
                handleCaptcha={(data: any) => {
                  setCaptcha(data);
                  
                }}
                handleCaptchaSubmit={captchSubmit}
              /> */}
                          {/* <ErrorSnackBar message={"jjdhiiahufiahufihufihf"} /> */}
                          <SnackBar message={message} />
                          <div className={SignupStyles.mobileImage}>
                            <img
                              src={mobileviewimage}
                              alt="Image"
                              className={SignupStyles.imageMobileView}
                            />
                          </div>
                          <div
                            // width="150px"
                            //  marginTop={"5px"}
                            className={SignupStyles.buttonSection}
                          >
                            {values.phoneNumber.length === 10 && sebiCheck ? (
                              <LoadingButton
                                variant="contained"
                                type="submit"
                                loading={loading}
                                id="signupContinueButton"
                                className={SignupStyles.button}
                                onClick={() => {
                                  handleSubmit();
                                }}
                              >
                                Continue
                              </LoadingButton>
                            ) : (
                              <Button
                                variant="contained"
                                disabled
                                className={SignupStyles.button}
                              >
                                Continue
                              </Button>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                        // alignItems={"center"}
                        // justifyContent={"center"}
                        // marginTop={"200px"}
                        >
                          <Typography>
                            Location Access is blocked, please grant the
                            Loaction access in browser settings to proceed
                            further{" "}
                          </Typography>
                        </div>
                        {isFireFox && (
                          <div
                          // alignItems={"center"}
                          // justifyContent={"center"}
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                window.location.reload();
                              }}
                              id="signupReloadButton"
                            >
                              Reload
                            </Button>
                          </div>
                        )}
                        {isSafari ? (
                          <div
                          // alignItems={"center"}
                          // justifyContent={"center"}
                          >
                            <Typography>
                              Please Press Reload Button after granting camera
                              access
                            </Typography>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                window.location.reload();
                              }}
                              id="signupSafariReloadButton"
                            >
                              Reload
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </Form>
                )}
              </Formik>
            ) : (
              <div
              // marginTop={'150px'}
              >
                Access Allowed Only in India{" "}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={SignupStyles.bottomContentContainer}>
        <p className={SignupStyles.bottomContent}>
          Open a Trading cum Demat Account with NCO Securities and Share broking
          Pvt Ltd.
        </p>
        <p className={SignupStyles.bottomContent}>
          By submitting your contact details, you authorise Firstock to call or
          text you for the purpose of account opening even though you may be
          registered on DND.
        </p>
      </div>
    </>
  );
}
