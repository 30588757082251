import React, { useEffect, useState, useRef } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Avatar,
  Divider,
  Modal,
  Fade,
  Box,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import DoneImage from "../Images/doneicon.png";
import { checkUserLoggedIn } from "../../shared/commonfunctions";
import PdfModal from "../../Components/PdfModal/PdfModal";
import WebcamDemo from "../../Components/CameraModule/WebCamFaceDetection";
import {
  startOverAPICall,
  esignAPICall,
  requestEsignAPICall,
  getDocumentEsignAPICall,
} from "../../shared/APICalls";
import routesData from "../../shared/routes.json";
import ESignStyles from "../../pages/Styles/Esign.module.scss";
import AadhaarImage from "../../Components/assets/Images/esignnewimage.svg";
import PDFViewerIframe from "../../Components/PDFIframe/PDFWithIframe";
import CloseIcon from "@mui/icons-material/Close";
import StageLayout from "../Finupdate/StageLayout";
import icon from "../Components/assets/Images/iconnew.svg";
import SnackBar from "../../Components/SnackBars/SnackBar";
import StartOverBackDrop from "../../Components/StartOverBackDrop";
import ErrorSnackBar from "../../Components/SnackBars/ErrorSnackBar";
import {
  RequestEsignAPICall,
  esignsubmitAPICall,
  stageDetailsAPICall,
} from "./APICallsAccountClosure";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vW",
  height: "90vH",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  padding: "10px 0px 0px 5px",
};

export default function AccountClosureEsign() {
  const navigate = useNavigate();
  const [showModal, setshowModal] = useState(false);
  const [open, setOpen] = useState(true);
  const [loading, setloading] = useState(false);
  const [startOverloading, setstartOverloading] = useState(false);
  const [completedStages, setCompletedStages] = useState<any>();
  const [backButton, setBackButton] = useState("");
  const [backdrop, setBackDrop] = useState(false);

  const navigationData = routesData;

  useEffect(() => {
    window.scrollTo(0, 0);

    // checkUserLoggedIn(navigate);
  }, []);

  const handleData = (data: any) => {
    //  console.log(data);
    setCompletedStages(data);
  };

  const handleDocumentData = (data: any) => {
    setBackButton(data);
  };

  return (
    <div className={ESignStyles.mainWrapperTop}>
      <StageLayout
        completedStages={100}
        stageName={"Esign Section"}
        navigatePath={""}
        esignBackButtonEnable={backButton}
      />
      <div className={ESignStyles.wrapper}>
        <div className={ESignStyles.titleSection}>
          <p className={ESignStyles.title}>
            {" "}
            Esign Your Closure Form Using Your Aadhaar Number{" "}
          </p>
        </div>
        <div className={ESignStyles.container}>
          <div className={ESignStyles.leftcontainer}>
            <div>
              <p className={ESignStyles.leftcontainerText}>
                1.Click on the Esign Button Below.
              </p>
              <p className={ESignStyles.leftcontainerText}>
                2.Enter Your Aadhaar Number.
              </p>
              <p className={ESignStyles.leftcontainerText}>
                3.Enter the OTP received on your Mobile Number Linked.
              </p>
              <div className={ESignStyles.rightmobileViewcontainer}>
                <img src={AadhaarImage} className={ESignStyles.image} />
              </div>
              <div>
                <MyComponent
                  completedStages={handleData}
                  documentdata={handleDocumentData}
                />
              </div>
            </div>
          </div>
          <div className={ESignStyles.rightcontainer}>
            <img src={AadhaarImage} className={ESignStyles.image} />
          </div>
        </div>
      </div>
    </div>
  );
}

function MyComponent({ completedStages, documentdata }) {
  const [childWindowRef, setchildWindowRef] = useState<any>(null);
  const [closetTimer, setclosetTimer] = useState(false);
  const [loading, setloading] = useState(false);
  const [startOverloading, setstartOverloading] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [showModal, setshowModal] = useState(false);
  const [documentURL, setDocumentURL] = useState<any>();
  const [docId, setDocId] = useState("");
  const [pdfUrl, setPdfUrl] = useState<any>("");
  const [continueOption, setcontinueOption] = useState(true);
  const [backdrop, setBackDrop] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [accountClosureReason, setAccountClosureReason] = useState("");

  const navigationData = routesData;
  const [navigatePath, setNavigate] = useState<any>();

  const [message, setMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const timerRef = useRef<any>(null);

  useEffect(() => {
    StageDetails();

    let obj: any = {
      stage: "e_sign",
    };
    // setloading(true);
    // stageDetailsAPICall(obj)
    //   .then((response: any) => {
    //     const responseData = response.data;
    //     setloading(false);
    //     completedStages(responseData.completed_stages);
    //     //  console.log(responseData);
    //     if (response.data.data.StageDetails.ErrorMessage) {
    //       setRejectMessage(response.data.data.StageDetails.ErrorMessage);
    //     }

    //     if (
    //       responseData.data.StageDetails.Documents.e_sign_pdf.e_sign_pdf_url
    //     ) {
    //       setcontinueOption(false);
    //       documentdata("DocumentReceived");
    //       const meta = "data:application/pdf;base64,";

    //       setPdfUrl(
    //         responseData.data.StageDetails.Documents.e_sign_pdf.e_sign_pdf_url
    //       );
    //       setloading(false);
    //     }
    //   })
    //   .catch((error: any) => {
    //     //  console.log("error", error);
    //   });
  }, []);

  const StageDetails = () => {
    let obj: any = {
      stage: "esign_stage",
    };
    stageDetailsAPICall(obj)
      .then((response: any) => {
        // console.log("response", response.data.data);
        if (response.data.data.StageDetails.rejectionMessage) {
          setRejectMessage(response.data.data.StageDetails.rejectionMessage);
        }
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  };

  const handleOpenClick = () => {
    setloading(true);
    let digilink: string = accountClosureReason;

    let obj: any = {
      reason_for_closure: accountClosureReason,
    };

    RequestEsignAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;

        // console.log("responseData", responseData.data);
        setDocId(responseData.data);
        var url = responseData.link;
        var trimmedUrl = url.replace(":444", "");
        digilink = trimmedUrl;
        //  console.log("digilink", digilink);
        setTimeout(()=>{
          const childWindow: any = window.open(
            digilink,
            "_blank",
            "width=500,height=700"
          );
          setchildWindowRef(childWindow);
        })

      })
      .catch((error: any) => {
        setloading(false);
        setErrorMessage(
          error.message.includes("Network")
            ? "Error occured, Please try again"
            : error.response.data.error.message
        );
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      });
  };
  const handleContinue = () => {
    setOpen(false);
    setloading(false);
    eSign();
  };

  function eSign() {
    setloading(true);
    esignsubmitAPICall()
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setloading(false);

        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage]);
        }
      })
      .catch((error: any) => {
        //  console.log("error", error);
        setloading(false);
      });
  }

  useEffect(() => {
    if (!closetTimer && childWindowRef) {
      //  console.log("timer trigger");
      timerRef.current = setInterval(checkChild, 500);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [childWindowRef, closetTimer]);

  function checkChild() {
    let token: any = sessionStorage.getItem("access-token");
    // console.log("timer");

    if (childWindowRef && childWindowRef.closed) {
      clearInterval(timerRef.current);

      let obj: any = {
        stage: "e_sign",
      };
      eSign();
    }
  }

  const handlePreviewClick = () => {
    setshowModal(true);
  };
  const handlePreviewClose = () => {
    setshowModal(false);
  };

  const handleStartOver = () => {
    if (
      window.confirm(
        "Are you sure you want to restart, on pressing Yes all the Data will be deleted"
      )
    ) {
      setBackDrop(true);
      setstartOverloading(true);
      startOverAPICall()
        .then((response: any) => {
          const responseData = response.data;
          //  console.log("responseData", response);
          setstartOverloading(false);
          setBackDrop(false);
          navigate("/");
          window.location.reload();
        })
        .catch((error: any) => {
          setBackDrop(false);
          setstartOverloading(false);
        });
    }
  };

  return (
    <>
      <SnackBar message={ErrorMessage} />
      <ErrorSnackBar message={rejectMessage} />
      <div className={ESignStyles.buttonStyles}>
        <div>
          <TextField
            className={ESignStyles.textfield}
            id="outlined-basic"
            label="Reason for Account closure"
            variant="outlined"
            name="fatherFullName"
            value={accountClosureReason}
            onChange={(event: any) =>
              setAccountClosureReason(event.target.value)
            }
            inputProps={{
              maxLength: 100,
              inputMode: "text",
            }}
          />
        </div>
        <div>
          {continueOption ? (
            <LoadingButton
              sx={{ marginTop: "20px" }}
              type="submit"
              variant="contained"
              onClick={handleOpenClick}
              loading={loading}
              id="aadharOpenLinkButton"
              className={ESignStyles.esignButton}
              disabled={accountClosureReason === ""}
            >
              Esign Now
            </LoadingButton>
          ) : (
            <>
              <div className={ESignStyles.sectionButton}>
                <LoadingButton
                  className={ESignStyles.continue}
                  type="submit"
                  variant="contained"
                  sx={{ width: "200px" }}
                  onClick={handleContinue}
                  loading={loading}
                  id="aadharOpenLinkButton"
                >
                  Continue
                </LoadingButton>
                <Button
                  className={ESignStyles.esignPreview}
                  type="submit"
                  variant="outlined"
                  sx={{ color: "rgba(18, 183, 106, 1)", borderRadius: "20px" }}
                  onClick={handlePreviewClick}
                  id="aadharOpenLinkButton"
                >
                  View E-sign Document
                </Button>
              </div>
            </>
          )}
        </div>
        {backdrop && <StartOverBackDrop />}
        {/* <div className={ESignStyles.startOver}>
          <div className={ESignStyles.startOverSection}>
            <img src={icon} className={ESignStyles.iconImage} />
            <p className={ESignStyles.paratext}>
              {" "}
              if the above mentioned info not yours
              <span
                className={ESignStyles.startoverbutton}
                onClick={handleStartOver}
              >
                Start Over
              </span>
            </p>
          </div>
        </div> */}
      </div>

      {showModal && (
        <Stack>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Stack
                  alignItems={"flex-end"}
                  marginRight={"10px"}
                  marginTop={"5px"}
                >
                  <IconButton onClick={handlePreviewClose}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <div className={ESignStyles.pdfMobilePreview}>
                  <PDFViewerIframe urldata={pdfUrl} />
                </div>
                <div className={ESignStyles.pdfDesktopPreview}>
                  <PDFViewerIframe urldata={pdfUrl} />
                </div>
              </Box>
            </Fade>
          </Modal>
        </Stack>
      )}
    </>
  );
}
