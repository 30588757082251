import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Avatar,
  CircularProgress,
} from "@mui/material";
import DoneImage from "../Images/doneicon.png";
import { useLocation } from "react-router-dom";

import { digiLockerAuthenticationAPICall } from "../shared/APICalls";
import { useDispatch, useSelector } from "react-redux";

export default function SuccessPage() {
  const [countDown, setCountDown] = useState(5);
  const [loading, setloading] = useState(false);

  const pathName = useLocation().search;
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlParams.entries());
  const setu_id = params.id;
  const state = params.state;

  // const shareCode = params.shareCode;
  //  console.log("params",params);
  //  console.log(pathName)
  sessionStorage.setItem("access-token", state);

  function sendDetails() {
    let obj: any = {
      status: "success",
      setu_id: setu_id,
      // share_code: shareCode,
    };
    setloading(true);
    digiLockerAuthenticationAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        setloading(false);
        setTimeout(() => {
          window.close();
        }, 3000);
      })
      .catch((error: any) => {
        setloading(false);
        window.close();
      });
  }

  useEffect(() => {
    sendDetails();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountDown((prevCountDown) => prevCountDown - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (countDown < 0) {
      setCountDown(5);
    }
  }, [countDown]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
      }}
    >
      {loading ? (
        <CircularProgress style={{ width: "100px", height: "100px" }} />
      ) : (
        <img src={DoneImage} style={{ width: "100px", height: "100px" }} />
      )}
      <div style={{ textAlign: "center", padding: "10px 10px" }}>
        <Typography variant="h5">
          {" "}
          {loading ? "Inprogress..." : "Success"}{" "}
        </Typography>
        {!loading && (
          <Typography style={{ marginTop: "10px" }}>
            We have obtained your documents
          </Typography>
        )}
      </div>
      <div style={{ textAlign: "center" }}>
        {!loading && (
          <Typography style={{ fontSize: "12px", padding: "0 5px" }}>
            The page will close automatically in {countDown} seconds, if not
            close manually to continue.
          </Typography>
        )}
      </div>
    </div>
  );
}
