import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { Box, Stack } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepButton from "@mui/material/StepButton";
import { useSelector, Provider } from "react-redux";
import store from "./Components/redux/store";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { theme } from "./Components/ThemeProvider/ThemeProvider";
import Router from "./routes";
import Header from "./pages/Header";

import { StepIconProps } from "@mui/material/StepIcon";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import AppStyles from "./App.module.scss";
import BackButtonListener from "./Components/PreventBackNavigation/PreventBackNavigation";
import { stageDetailsAPICall } from "./shared/APICalls";
import routesData from "./shared/routes.json";
import Footer from "./Components/Footer/Footer";
import BackToTopButton from "./Components/BacktoTop/BackToTopComp";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "rgba(3, 152, 85, 1)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "rgba(3, 152, 85, 1)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 5,
    alignItems: "center",
    ...(ownerState.active && {
      color: "rgba(3, 152, 85, 1)",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "rgba(3, 152, 85, 1)",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

function App() {
  // const [step, setStep] = useState<any>();
  // const [stageDetailsApi, setStageDetailsApi] = useState<any>(0);
  // const [showSteps, setshowSteps] = useState(false);
  // const navigationData = routesData;
  // const pathName = useSelector((state: any) => state.PATHNAME.pathname);
  // console.log(pathName);

  // const pathToStepMap = {
  //   "/": -4,
  //   "/confirm_otp": -3,
  //   "/yourdetails": -2,
  //   "/confirmemailotp": -1,
  //   "/panverification": 1,
  //   "/aadhaarkyc": 2,
  //   "/tradingPreference": 3,
  //   "/yourprofile": 4,
  //   "/linkusingifsc": 5,
  //   "/cameramodule": 6,
  //   "/uploaddocuments": 7,
  //   "/nomineepage": 8,
  //   "/termsandconditions": 9,
  //   "/esign": 10,
  //   "/congratulations": 11,
  // };

  // const completed = {
  //   pan_details: 0,
  //   fetch_details: 1,
  //   segment_details: 2,
  //   profile_page: 3,
  //   bank_account: 4,
  //   web_image: 5,
  //   document_upload: 6,
  //   nominee_details: 7,
  //   t_and_c: 8,
  //   e_sign: 9,
  //   application_completed: 10,
  // };

  // useEffect(() => {
  //   Object.keys(pathToStepMap).forEach((path) => {
  //     if (pathName.includes(path)) {
  //       //  setStep(pathToStepMap[path]);
  //       setStageDetailsApi(pathToStepMap[path]);
  //       console.log(pathToStepMap[path]);
  //     }
  //   });
  // }, [pathName]);

  // useEffect(() => {
  //   if (stageDetailsApi > 0) {
  //     let obj: any = {
  //       stage: "document_upload",
  //     };

  //     stageDetailsAPICall(obj)
  //       .then((response: any) => {
  //         const responseData = response.data;
  //         console.log("responseData", responseData.completed_stages);
  //         const matchedRoutes: any = [];

  //         responseData.completed_stages.forEach((route) => {
  //           if (completed.hasOwnProperty(route)) {
  //             matchedRoutes.push(completed[route]);
  //           }
  //           console.log(
  //             "matchedRoutes",
  //             matchedRoutes[matchedRoutes.length - 1] + 2
  //           );
  //           setStep(matchedRoutes[matchedRoutes.length - 1] + 1);
  //         });
  //       })
  //       .catch((error: any) => {
  //         console.log("error", error);
  //       });
  //   }
  // }, [stageDetailsApi]);

  // useEffect(() => {
  //   if (stageDetailsApi > -1) {
  //     setshowSteps(true);
  //   }
  // }, [step]);

  // const handleClick = (index) => {
  //   // console.log("path", index);

  //   if (index <= step) {
  //     const path = Object.keys(pathToStepMap).find(
  //       (key) => pathToStepMap[key] === index + 1
  //     );
  //     if (path) {
  //       window.location.href = path;
  //     }
  //   }
  // };
  // const [skipped, setSkipped] = React.useState(new Set<number>());
  // const isStepOptional = (step: number) => {
  //   return step === 1;
  // };

  // const isStepSkipped = (step: number) => {
  //   return skipped.has(step);
  // };

  // const handleNext = (index) => {
  //   const path = Object.keys(pathToStepMap).find(
  //     (key) => pathToStepMap[key] === index
  //   );
  //   if (path) {
  //     window.location.href = path;
  //   }
  //   let newSkipped = skipped;
  //   if (isStepSkipped(step)) {
  //     newSkipped = new Set(newSkipped.values());
  //     newSkipped.delete(step);
  //   }

  //   setStep(index);
  //   setSkipped(newSkipped);
  // };

  const steps = [
    "Pan",
    "Aadhaar",
    "Trading",
    "Profile",
    "Bank",
    "Cam",
    "Documents",
    "Nominee",
    "T&C",
    "Esign",
  ];

  const stepMobile = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  return (
    <ThemeProvider theme={theme}>
      {/* <Stack alignItems={"center"} style={{ overflowX: "hidden" }}>
        {showSteps && (
          <>
            {step >= 0 && step <= 9 && (
              <Box
                sx={{
                  width: "90%",

                  position: "absolute",
                  top: "80px",
                  paddingTop: "5px",
                }}
              >
                <div className={AppStyles.wrapper}>
                  <Stepper
                    activeStep={step}
                    alternativeLabel
                    connector={<QontoConnector />}
                    
                  >
                    {steps.map((label: any, index: any) => (
                      <Step
                        key={label}
                        sx={{ padding: "0 !important", cursor: "pointer" }}
                       
                      >
                    
                        <StepLabel
                          StepIconComponent={QontoStepIcon}
                          onClick={() => {
                            handleClick(index);
                          }}
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
                <div className={AppStyles.mobilewrapper}>
                  <Stepper
                    activeStep={step}
                    alternativeLabel
                    
                  >
                    {stepMobile.map((label: any, index: any) => (
                      <Step
                        key={label}
                        sx={{ padding: "0 !important", cursor: "pointer" }}
                        
                      >
                        <StepButton
                          onClick={() => {
                            handleClick(index);
                          }}
                        ></StepButton>
                    
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </Box>
            )}
          </>
        )}
      </Stack> */}

      <Header />

      <BrowserRouter>
        <Router />
      </BrowserRouter>

      <Footer />
      <BackToTopButton />
    </ThemeProvider>
  );
}

export default App;
