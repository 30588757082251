import React, { useEffect, useState, useRef } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  FormHelperText,
  Snackbar,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import {
  validateEmailOTPAPICall,
  reSendEmailOTPAPICall,
} from "../shared/APICalls";
import { IEmailOTPValidation, IReSendEmailOTP } from "../shared/dtos";
import routesData from "../shared/routes.json";
import ConfitmEmailOtpStyles from "../pages/Styles/ConfirmEmailOtp.module.scss";
import emailOtpImage from "../Components/assets/Images/newLeftImage.svg";
import SnackBar from "../Components/SnackBars/SnackBar";
import Slide, { SlideProps } from "@mui/material/Slide";
import { MuiOtpInput } from "mui-one-time-password-input";
import mobileviewimage from "../Components/assets/Images/mobileviewgiftbox.svg";
type TransitionProps = Omit<SlideProps, "direction">;

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

export default function ConfirmEmailOtpPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { emailID } = state;
  const { validateID } = state;
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState<any>(30);
  const [timerId, setTimerId] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [wrongOTP, setWrongOTP] = useState(false);
  const [loading, setloading] = useState(false);
  const otpInputs = useRef<any>([]);
  const [message, setMessage] = useState("");
  const navigationData = routesData;
  const [transition, setTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined);

  console.log("validateIDEmailOTP", validateID);

  useEffect(() => {
    if (countdown <= 0 && timerId !== null) {
      setIsButtonDisabled(false);

      clearInterval(timerId);
      setTimerId(null);
    }
  }, [countdown]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsButtonDisabled(true);
    setCountdown(30);

    if (timerId === null) {
      let interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      setTimerId(interval);
    }
  }, []);

  function emailOTPValidation(emailOTP: any) {
    // console.log("emailOTP", emailOTP);
    let obj: IEmailOTPValidation = {
      email: emailID,
      email_otp: emailOTP,
    };
    validateEmailOTPAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        setWrongOTP(false);
        setloading(false);
        if (responseData.data.access_token) {
          // console.log("token", responseData.data.access_token);
          sessionStorage.setItem(
            "access-token",
            responseData.data.access_token
          );
        }
        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage]);
        }
        //navigate("/panverification");
      })
      .catch((error: any) => {
        setloading(false);
        setWrongOTP(true);

        setMessage(
          error.message.includes("Network")
            ? "Error occured, Please try again"
            : error.response.data.error.message
        );
        setTimeout(() => {
          setMessage("");
        }, 5000);
      });
  }

  function reSendemailOTP() {
    let obj: IReSendEmailOTP = {
      validation_id: validateID,
      email: emailID,
    };
    reSendEmailOTPAPICall(obj)
      .then((response: any) => {
        const responseData = response.data.data;
        setloading(false);
      })
      .catch((error: any) => {});
    setTransition(() => TransitionUp);
    setOpen(true);
    setIsButtonDisabled(true);
    setCountdown(30);

    if (timerId === null) {
      let interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      setTimerId(interval);
    }
  }

  const handleBackClick = () => {
    navigate("/yourdetails", {
      state: { validationID: validateID },
    });
  };

  const handleResendOTP = () => {
    setloading(true);
    reSendemailOTP();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: any) => {
    //setemail(event.target.value);
  };
  const handleClick = () => {
    navigate("/panverification");
  };
  function validateEmailOTP(value: any) {
    let error: any;
    if (!value) {
      error = "Email OTP Required";
    } else if (!/^[0-9]{6}$/.test(value)) {
      error = "Must be a valid 6 digit OTP";
    }
    return error;
  }

  const handleChangeField = (
    name: any,
    value: any,
    setFieldValue: any,
    values: any
  ) => {
    setFieldValue(name, value);
    //console.log( value);
    if (value.length === 6) {
      emailOTPValidation(value);
    }
  };
  const handleKeyPress = (event: any) => {
    if (!/^[0-9]$/.test(event.key)) {
      event.preventDefault();
    }
  };
  return (
    <>
      <div className={ConfitmEmailOtpStyles.mobileHeader}>
        <p className={ConfitmEmailOtpStyles.LeftContainerHeadermobileHeader}>
          Welcome to Firstock SignUp
        </p>
        <p className={ConfitmEmailOtpStyles.LeftContainerSubTextmobileHeader}>
          Just a couple of clicks and we start.
        </p>
      </div>
      <div className={ConfitmEmailOtpStyles.wrapper}>
        <div className={ConfitmEmailOtpStyles.LeftContainer}>
        <p className={ConfitmEmailOtpStyles.LeftContainerHeader}>
            Invest with Confidence, Anytime, Anywhere With Firstock
          </p>
          <p className={ConfitmEmailOtpStyles.LeftContainerSubText}>
            "Seamless. Secure. Smart Investing for Everyone."
          </p>
          <img
            src={emailOtpImage}
            alt="Image"
            className={ConfitmEmailOtpStyles.image}
          />
        </div>
        <div className={ConfitmEmailOtpStyles.RightContainer}>
          <div className={ConfitmEmailOtpStyles.headerSection}>
            <p className={ConfitmEmailOtpStyles.signup}>Verify Your OTP</p>
            <p className={ConfitmEmailOtpStyles.subText}>
              Sent to <span>{emailID}</span>{" "}
              <a
                onClick={handleBackClick}
                className={ConfitmEmailOtpStyles.change}
              >
                (Change)
              </a>
            </p>
          </div>

          <div>
            <Formik
              initialValues={{
                emailotp: "",
              }}
              onSubmit={(values) => {
                //   console.log(values);
                setloading(true);
                emailOTPValidation(values.emailotp);
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                values,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form
                  onKeyDown={(event) => {
                    // event.code === "Enter" ||
                    //   (event.code === "NumpadEnter" && handleSubmit());

                    event.code.includes("Enter") && handleSubmit();
                  }}
                >
                  <div className={ConfitmEmailOtpStyles.inputBlock}>
                    {/* <Field
                    name="emailotp"
                    validate={validateEmailOTP}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Email OTP"
                        className={ConfitmEmailOtpStyles.textfield}
                        variant="outlined"
                        value={values.emailotp}
                        type="numeric"
                        onChange={(event) => {
                          const updatedValue = event.target.value;
                          handleChangeField("emailotp", updatedValue, setFieldValue, values);
                        }}
            
                        inputProps={{
                          maxLength: 6,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        onKeyPress={(event) => {
                          const keyCode = event.keyCode || event.which;
                          const keyValue = String.fromCharCode(keyCode);
                          const isValidKey = /^\d+$/.test(keyValue);

                          if (!isValidKey) {
                            event.preventDefault();
                          }
                        }}
                      />
                    )}
                  /> */}
                    <MuiOtpInput
                      length={6}
                      value={values.emailotp}
                      //   name="partnercode"
                      autoFocus
                      inputMode="numeric"
                      onChange={(event: any) => {
                        const updatedValue = event;
                        handleChangeField(
                          "emailotp",
                          updatedValue,
                          setFieldValue,
                          values
                        );
                      }}
                      onKeyPress={handleKeyPress}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderRadius: "10px",
                          border: "2px solid #006779",
                        },
                        ".MuiOutlinedInput-input": {
                          padding: "0px !important",
                          height: "60px",
                        },
                      }}
                    />
                    <FormHelperText style={{ color: "#d32f2f" }}>
                      {touched.emailotp && errors.emailotp?.toString()}
                    </FormHelperText>

                    <SnackBar message={message} />

                    {isButtonDisabled ? (
                      <div className={ConfitmEmailOtpStyles.resendSection}>
                        <p className={ConfitmEmailOtpStyles.clickhere}>
                          Resend OTP in {countdown}s
                        </p>
                      </div>
                    ) : (
                      <div className={ConfitmEmailOtpStyles.resendSection}>
                        <p className={ConfitmEmailOtpStyles.clickhere}>
                          Click here to{" "}
                        </p>
                        <LoadingButton
                          onClick={handleResendOTP}
                          // loading={loading}
                          variant="text"
                          id="confirmEmailOtpResendOTPButton"
                          sx={{ color:"#006779" }}
                        >
                          Resend OTP
                        </LoadingButton>
                      </div>
                    )}

                    <div className={ConfitmEmailOtpStyles.mobileImage}>
                      <img
                        src={mobileviewimage}
                        alt="Image"
                        className={ConfitmEmailOtpStyles.imageMobileView}
                      />
                    </div>

                    <div className={ConfitmEmailOtpStyles.buttonSection}>
                      {values.emailotp.length === 6 ? (
                        <LoadingButton
                          variant="contained"
                          type="submit"
                          loading={loading}
                          id="confirmEmailOtpContinueButton"
                          className={ConfitmEmailOtpStyles.button}
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          Continue
                        </LoadingButton>
                      ) : (
                        <Button
                          variant="contained"
                          disabled
                          id="confirmEmailOtpContinueDisabledButton"
                          className={ConfitmEmailOtpStyles.button}
                        >
                          Continue
                        </Button>
                      )}
                    </div>
                    <Snackbar
                      open={open}
                      autoHideDuration={3000}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      TransitionComponent={transition}
                      sx={{ marginTop: "50px" }}
                    >
                      <div
                        style={{ background: "#54BF00", borderRadius: "10px " }}
                      >
                        <div style={{ marginLeft: "30px" }}>
                          <Alert
                            icon={false}
                            severity="success"
                            sx={{
                              width: "max-content",
                              height: "50px",
                              fontSize: "16px",
                              borderRadius: "0",
                            }}
                          >
                            Successfully sent the OTP to your Email.
                          </Alert>
                        </div>
                      </div>
                    </Snackbar>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
