import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Avatar,
  Divider,
  Modal,
  Fade,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { checkUserLoggedIn } from "../shared/commonfunctions";
import PdfModal from "../Components/PdfModal/PdfModal";
import { startOverAPICall, KraesignAPICall } from "../shared/APICalls";
import routesData from "../shared/routes.json";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vW",
  height: "90vH",
  bgcolor: "background.paper",

  boxShadow: 24,
  //padding: "10px 5px 10px 5px"
};

export default function KraEsignPage() {
  const navigate = useNavigate();
  const [showModal, setshowModal] = useState(false);
  const [open, setOpen] = useState(true);
  const [loading, setloading] = useState(false);
  const [startOverloading, setstartOverloading] = useState(false);
  const navigationData = routesData;

  useEffect(() => {
    checkUserLoggedIn(navigate);
  }, []);

  const handleClick = () => {
    // navigate("/congratulations");
    setshowModal(true);
  };

  function KraeSign() {
    setloading(true);
    KraesignAPICall()
      .then((response: any) => {
        const responseData = response.data;
        console.log("responseData", response);
        setloading(false);

        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage]);
        }
      })
      .catch((error: any) => {
        console.log("error", error);
        setloading(false);
      });
  }

  const handleClose = () => {
    setOpen(false);

    KraeSign();
  };

  const handleStartOver = () => {
    if(window.confirm("Are you sure you want to restart, on pressing Yes all the Data will be deleted")){
    setstartOverloading(true);
    startOverAPICall()
      .then((response: any) => {
        const responseData = response.data;
        console.log("responseData", response);
        setstartOverloading(false);
        navigate("/");
      })
      .catch((error: any) => {
        setstartOverloading(false);
      });
    }
  };

  return (
    <>
      <Stack alignItems={"center"} height={'60vh'} marginTop={"100px"} padding={"0px 5px"}>
        <Stack marginTop={"20px"}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            marginTop={"20px"}
          >
            <Typography variant="h5"> Kra Esign </Typography>
            <Button variant="contained" onClick={handleClick}  id="KraEsignButton">
              KraEsign
            </Button>
            <LoadingButton loading={startOverloading} onClick={handleStartOver}  id="KraEsignStartOverButton"
            sx={{color:'rgba(18, 183, 106, 1)'}}
            >
              StartOver
            </LoadingButton>
          </Stack>
          <Typography style={{ fontSize: "14px", marginTop: "10px" }}>
            This will be your account to buy and sell shares, mutual funds, and
            derivatives on NSE and BSE
          </Typography>
        </Stack>
      </Stack>
      {showModal && (
        <Stack>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <PdfModal url={"https://arxiv.org/pdf/quant-ph/0410100.pdf"} />
                <Stack alignItems={"center"} marginTop={"5px"}>
                  <LoadingButton loading ={loading} variant="contained" onClick={handleClose}
                  id="KraEsignApproveButton"
                  >
                    Approve
                  </LoadingButton>
                </Stack>
              </Box>
            </Fade>
          </Modal>
        </Stack>
      )}
    </>
  );
}
