import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Typography,
  Button,
  Modal,
  Fade,
  Box,
  Autocomplete,
  Tooltip,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import {
  deleteDocumentAPICall,
  stageDetailsAPICall,
  uploadDocumentsAPICall,
  uploadDpdetailsAPICall,
} from "./APICallsAccountClosure";

import StageLayout from "../Finupdate/StageLayout";
import uploadStyles from "./AccountClosureCMRUpload.module.scss";
import SnackBar from "../../Components/SnackBars/SnackBar";
import SucessSnackBar from "../../Components/SnackBars/SucesssnackBar";
import csvimage from "../../Components/assets/Images/csvimage.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDropzone } from "react-dropzone";
import Delete from "@mui/icons-material/Delete";
import ReactCropper from "../Finupdate/ReactCropper/ReactCropper";
import PreviewModalPopup from "../Finupdate/PreviewModalPopup";
import PdfModal from "../Finupdate/PdfModal/PdfModal";
import ClearIcon from "@mui/icons-material/Clear";
import viewImage from "../../Components/assets/Images/view-alt-svgrepo-com 1.svg";
import featherUpload from "../../Components/assets/Images/feather_upload-cloud.svg";
import { dataURItoFile } from "../../shared/commonfunctions";
import { getCookie } from "../Finupdate/cookies";
import { confirmAlert } from "react-confirm-alert";
import ErrorSnackBar from "../../Components/SnackBars/ErrorSnackBar";
import BackDrop from "../../Components/BackDrop";
import routesData from "../../shared/routes.json";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vW",
  height: "90vH",
  bgcolor: "background.paper",
  p: 1,
  boxShadow: 24,
  borderRadius: "20px",
};

const successModalStyles = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  padding: "40px 30px",
  outline: "none",
};

const optionsdata = ["NSDL", "CDSL"];

export default function AccountClosureCMRUpload() {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [submitloading, setsubmitloading] = useState(false);
  const [completedStages, setcompletedStages] = useState(50);
  const [uploadloading, setuploadloading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [disableUpload, setdisableUpload] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [preview, setPreview] = useState<string | null>(null);
  const [doneImagePreview, setDoneImagePreview] = useState(false);
  const [image, setImage] = useState<any>();
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [checkedOptions1, setCheckedOptions1] = useState(false);
  const [checkedOptions2, setCheckedOptions2] = useState(false);
  const [openSuccessModal, setopenSuccessModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [displayFile, setDisplayFile] = useState({
    status: false,
    type: "",
  });
  const [deleteloading, setDeleteLoading] = useState(false);

  const [fileUploadedStatus, setFileUploadedStatus] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [clientdpid, setclientdpid] = useState("");
  const [targetdpid, settargetdpid] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [autofocus, setautofocus] = useState(false);
  const maxSize = 10 * 1024 * 1024;

  const formats: any = ["pdf"];

  useEffect(() => {
    window.scrollTo(0, 0);
    StageDetails();
  }, []);

  useEffect(() => {
    if (targetdpid === "" && selectedOption === "NSDL") {
      settargetdpid("IN");
    }
  }, [targetdpid]);

  // const StageDetails = () => {
  //   let obj: any = {
  //     stage: "cmr_upload",
  //   };
  //   stageDetailsAPICall(obj)
  //     .then((response: any) => {
  //       console.log("response", response.data.data);

  //     })
  //     .catch((error: any) => {
  //       console.log("error", error);
  //     });
  // };

  const SubmitDetails = () => {
    let obj: any = {
      dp_id: targetdpid.toUpperCase(),
      client_code: clientdpid.toUpperCase(),
      account_type: selectedOption,
    };
    setsubmitloading(true);
    uploadDpdetailsAPICall(obj)
      .then((response: any) => {
        //console.log("response", response);
        setsubmitloading(false);
        navigate("/account_closure/esign");
      })
      .catch((error: any) => {
        console.log("error", error);
        setsubmitloading(false);
        seterrorMessage("Error Updating DP Details");
        setTimeout(() => {
          seterrorMessage("");
        }, 3000);
      });
  };

  const StageDetails = () => {
    let obj: any = {
      stage: "cmr_upload",
    };

    setloading(true);
    stageDetailsAPICall(obj)
      .then((response: any) => {
        // console.log("response.data.data.url", response.data.data);
        setloading(false);
        if (response.data.data.url !== "") {
          setSelectedFile(response.data.data.url);
          setImage(response.data.data.url);
          setFileUploadedStatus(true);

          setDisplayFile({
            status: true,
            type: response.data.data.doc_type_ref,
          });
        } else {
          setFileUploadedStatus(false);
          setDisplayFile({
            status: false,
            type: "",
          });
        }
        if (response.data.data.target_dp_id) {
          settargetdpid(response.data.data.target_dp_id);
        }
        if (response.data.data.client_dp_id) {
          setclientdpid(response.data.data.client_dp_id);
        }
        if (response.data.data.selected_option) {
          setSelectedOption(response.data.data.selected_option);
        }

        if (response.data.data.rejectionMessage) {
          setRejectMessage(response.data.data.rejectionMessage);
        }
      })
      .catch((error: any) => {
        setloading(false);
      });
  };

  const onDrop = (acceptedFilestype: any) => {
    if (
      formats.some((ext) => acceptedFilestype[0].path.endsWith(`.${ext}`)) &&
      acceptedFilestype[0].size < maxSize
    ) {
      setSelectedFile(acceptedFilestype[0]);
      console.log("acceptedFilestype[0]", acceptedFilestype[0].size);
      if (
        acceptedFilestype[0].path.includes("png") ||
        acceptedFilestype[0].path.includes("jpg") ||
        acceptedFilestype[0].path.includes("jpeg")
      ) {
        const fileUrl = URL.createObjectURL(acceptedFilestype[0]); // Create object URL for the file
        setPreview(fileUrl);
        setshowModal(true);
      }
      if (acceptedFilestype[0].path.includes("pdf")) {
        setshowModal(true);
        setSelectedFile(acceptedFilestype[0]);
      }

      setdisableUpload(true);
    } else {
      setdisableUpload(false);
      setSelectedFile(null);
      setTimeout(() => {
        setdisableUpload(true);
      }, 3000);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: formats,
    disabled: selectedFile,
  });

  const files = acceptedFiles.map((file: any) =>
    disableUpload ? (
      selectedFile &&
      doneImagePreview && (
        <p
          key={file.path}
          style={{
            margin: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span> {file.path} </span>
          <span style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
                fontSize: "12px",
                fontWeight: "500",
                color: "#0F91D2",
                cursor: "pointer",
              }}
              onClick={() => {
                setshowPreviewModal(true);
              }}
            >
              Preview <img src={viewImage} style={{ marginLeft: "5px" }} />
            </div>

            <Tooltip title="Delete File">
              <IconButton
                onClick={() => {
                  handleDeleteDocument();
                }}
              >
                {deleteloading ? (
                  <CircularProgress style={{ width: "25px", height: "25px" }} />
                ) : (
                  <Delete sx={{ color: "red" }} />
                )}
              </IconButton>
            </Tooltip>
          </span>
        </p>
      )
    ) : (
      <li key={file.path} style={{ color: "red" }}>
        Only pdf Files are accepected with max Limit 10MB
      </li>
    )
  );
  // console.log("document.location.href",document.location.href)
  const handleClosePdf = () => {
    setshowModal(false);

    setSelectedFile(null);
  };

  const handlePdfPreviewDone = () => {
    const formData = new FormData();
    const obj: any = {
      file: selectedFile,
      doc_type_ref: "cmr_copy",
      doc_type_purpose: "cmr_proof",
    };

    formData.append("file", obj.file);
    formData.append("doc_type_ref", obj.doc_type_ref);
    formData.append("doc_type_purpose", obj.doc_type_purpose);
    setUploadLoading(true);
    uploadDocumentsAPICall(formData)
      .then((response: any) => {
        const responseData = response.data;
        setUploadLoading(false);
        setFileUploadedStatus(true);
        setSuccessMessage("Document Uploaded Successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
        setshowModal(false);
        setDoneImagePreview(true);
      })
      .catch((error: any) => {
        setUploadLoading(false);
        seterrorMessage(
          error?.response?.data?.error?.message
            ? error.response.data.error.message
            : "Error Uploading Document"
        );
        setTimeout(() => {
          seterrorMessage("");
        }, 5000);
      });
  };

  const handleCallBack = (data: any) => {
    if (data === "close") {
      setImage("close");
      setshowModal(false);
      setDoneImagePreview(false);
      setSelectedFile(null);
      setPreview(null);
    } else {
      setImage(data.getCroppedCanvas().toDataURL());
      const ImageData = data;

      if (ImageData !== "undefined") {
        const canvas = ImageData.getCroppedCanvas();
        const dataURL = canvas.toDataURL();
        const file = dataURItoFile(dataURL, "cropped_image.png");
        const formData = new FormData();
        const obj: any = {
          file: file,
          doc_type_ref: "cmr_copy",
          doc_type_purpose: "cmr_proof",
        };
        // formData.append("client_code", userId);
        formData.append("file", obj.file);
        formData.append("doc_type_ref", obj.doc_type_ref);
        formData.append("doc_type_purpose", obj.doc_type_purpose);
        setUploadLoading(true);
        uploadDocumentsAPICall(formData)
          .then((response: any) => {
            const responseData = response.data;
            setUploadLoading(false);
            setFileUploadedStatus(true);
            setSuccessMessage("Document Uploaded Successfully");
            setTimeout(() => {
              setSuccessMessage("");
            }, 5000);
            setshowModal(false);
            setDoneImagePreview(true);
          })
          .catch((error: any) => {
            setUploadLoading(false);
            seterrorMessage(
              error?.response?.data?.error?.message
                ? error.response.data.error.message
                : "Error Uploading Document"
            );
            setTimeout(() => {
              seterrorMessage("");
            }, 5000);
          });
      }
    }
  };

  const handleDeleteDocument = () => {
    let obj: any = {
      document_purpose: "cmr_proof",
    };
    confirmAlert({
      title: "",
      message: "Are you sure you want to Delete?",
      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => {
            setDeleteLoading(true);
            deleteDocumentAPICall(obj)
              .then((response: any) => {
                // console.log("response", response.data);
                setDeleteLoading(false);
                StageDetails();
                setSuccessMessage("Successfully Deleted the File");
                setTimeout(() => {
                  setSuccessMessage("");
                }, 5000);
                setSelectedFile(null);
              })
              .catch((error: any) => {
                setDeleteLoading(false);
                seterrorMessage(
                  error?.response?.data?.error?.message
                    ? error?.response?.data?.error?.message
                    : "Error Deleting the File"
                );
                setTimeout(() => {
                  seterrorMessage("");
                }, 5000);
              });
          },
        },
      ],
    });
  };

  const handleClosePreview = () => {
    setshowPreviewModal(false);
  };

  const handleCloseSuccessModal = () => {
    setopenSuccessModal(false);
  };

  // console.log("setIncomeslabValue", incomeslabValue);
  return (
    <div className={uploadStyles.mainWrapperTop}>
      <SnackBar message={errorMessage} />
      <ErrorSnackBar message={rejectMessage} />
      <StageLayout
        completedStages={completedStages}
        stageName={"CMR Upload"}
        navigatePath={""}
      />

      {loading && <BackDrop />}

      <div className={uploadStyles.wrapper}>
        <div className={uploadStyles.headerSection}>
          <p className={uploadStyles.signup}>Upload CMR Details</p>
          <p className={uploadStyles.messageSection}>
            Enter the target DP ID and DP client ID
          </p>
          <p
            className={uploadStyles.messageSection}
            style={{ margin: "5px 0px" }}
          >
            For transfer of holdings present in your Demat account
          </p>
        </div>

        <>
          <div className={uploadStyles.messageSection}>
            Please Upload your file
          </div>
          <div className={uploadStyles.messageSectionDesc}>
            {/* Enter Client Code and DP ID */}
          </div>
          <div className={uploadStyles.uploaddropdownWrapper}>
            <div className={uploadStyles.thList}>
              <span style={{ paddingLeft: "5px" }}>
                Select NSDL or CDSL option
              </span>
              <Autocomplete
                size="small"
                onKeyPress={(e) => {
                  e.preventDefault();
                }}
                autoFocus
                options={optionsdata}
                sx={{
                  marginTop: "8px",
                  borderRadius: "5px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  outline: "none",
                  fontSize: "16px",
                  fontWeight: 500,
                  padding: "0 0px",
                  color: "#000000",
                  boxShadow: "none",
                  "& .MuiOutlinedInput-input": {
                    // padding: "4.5px 4px 2.5px 8px",
                    paddingTop: "2px !important",
                    cursor: "pointer",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                id="disable-clearable"
                popupIcon={
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                  />
                }
                value={selectedOption}
                onChange={(event, newValue) => {
                  setSelectedOption(newValue);
                  if (newValue === "NSDL") {
                    settargetdpid("IN");
                    setautofocus(true);
                  } else {
                    settargetdpid("");
                    setautofocus(true);
                  }
                }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Option"
                    inputProps={{ ...params.inputProps, readOnly: true }}
                  />
                )}
              />
            </div>
            <div className={uploadStyles.thList}>
              <span style={{ paddingLeft: "5px" }}>Target DP ID </span>
              <TextField
                placeholder="Enter DP ID"
                id="outlined-size-small"
                size="small"
                variant="standard"
                autoFocus={autofocus}
                value={targetdpid}
                fullWidth
                //   onChange={handleEmailName}
                disabled={selectedOption === ""}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                inputProps={{
                  maxLength: 8,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                sx={{
                  marginTop: "8px",
                  // width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
                onKeyPress={(event) => {
                  const keyCode = event.keyCode || event.which;
                  const keyValue = String.fromCharCode(keyCode);
                  const isValidKey = /^\d+$/.test(keyValue);

                  if (!isValidKey) {
                    event.preventDefault();
                  }
                }}
                onChange={(event: any) =>
                  settargetdpid(event.target.value.toUpperCase())
                }
              />
              <FormHelperText sx={{ color: "red" }}>
                {targetdpid.length > 2 &&
                  targetdpid.length < 8 &&
                  "Min and Max Limit 8 digits"}
              </FormHelperText>
            </div>
            <div className={uploadStyles.thList}>
              <span style={{ paddingLeft: "5px" }}>Target DP client ID</span>
              <TextField
                placeholder="Enter DP Client ID"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={clientdpid}
                fullWidth
                disabled={selectedOption === ""}
                onChange={(event: any) =>
                  setclientdpid(event.target.value.toUpperCase())
                }
                inputProps={{
                  maxLength: 8,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                onKeyPress={(event) => {
                  const keyCode = event.keyCode || event.which;
                  const keyValue = String.fromCharCode(keyCode);
                  const isValidKey = /^\d+$/.test(keyValue);

                  if (!isValidKey) {
                    event.preventDefault();
                  }
                }}
                sx={{
                  marginTop: "8px",
                  // width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
              <FormHelperText sx={{ color: "red" }}>
                {clientdpid.length > 1 &&
                  clientdpid.length < 8 &&
                  "Min and Max Limit 8 digits"}
              </FormHelperText>
            </div>
          </div>

          <div className={uploadStyles.uploadAreaOuter}>
            <section className="container" style={{ width: "100%" }}>
              <div
                {...getRootProps()}
                className={uploadStyles.uploadArea}
                style={{ border: 0 }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={featherUpload} />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <input {...getInputProps()} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p className={uploadStyles.uploadtext1}>
                      Select a file or drag and drop here
                    </p>
                    <p className={uploadStyles.uploadtext2}>
                      File size no more than 10MB
                    </p>
                  </div>
                </div>
                <Button
                  variant="outlined"
                  sx={{
                    width: "max-content",
                    margin: "auto",
                    color: "#0F91D2",
                    textTransform: "none",
                    padding: "0px 30px",
                    borderColor: "#0F91D2",
                    "&:hover": {
                      borderColor: "#0F91D2",
                    },
                  }}
                >
                  Select File
                </Button>
              </div>
            </section>
          </div>

          <div className={uploadStyles.displayFileNameSection}>
            {selectedFile && <img src={csvimage} alt="" />}
            <div style={{ width: "90%" }}>
              <div
                style={{
                  marginLeft: "10px",
                  color: "grey",
                  fontSize: "16px",
                  width: "100%",
                }}
              >
                {!displayFile.status && <> {files}</>}
                {displayFile.status && (
                  <p
                    style={{
                      margin: "5px 0px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span> {displayFile.type} </span>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "10px",
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#0F91D2",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setshowPreviewModal(true);
                        }}
                      >
                        Preview{" "}
                        <img src={viewImage} style={{ marginLeft: "5px" }} />
                      </div>

                      <Tooltip title="Delete File">
                        <IconButton
                          onClick={() => {
                            handleDeleteDocument();
                          }}
                        >
                          {deleteloading ? (
                            <CircularProgress
                              style={{ width: "25px", height: "25px" }}
                            />
                          ) : (
                            <Delete sx={{ color: "red" }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </span>
                  </p>
                )}
              </div>
              <div style={{ width: "100%", marginLeft: "10px" }}>
                {uploadloading && <span className={uploadStyles.loader}></span>}
              </div>
            </div>
          </div>
          <p style={{ fontSize: "14px" }}>
            Please upload a CMR copy of the target DP ID.
          </p>

          <SucessSnackBar message={successMessage} />

          <SnackBar message={errorMessage} />

          <div className={uploadStyles.buttonStack}>
            <div>
              <LoadingButton
                variant="contained"
                onClick={SubmitDetails}
                loading={submitloading}
                id="upLoadDocumentsContinueButton"
                className={uploadStyles.approveButton}
                disabled={
                  !selectedFile ||
                  clientdpid === "" ||
                  targetdpid === "" ||
                  targetdpid.length !== 8 ||
                  selectedOption === ""
                }
              >
                {" "}
                Submit
              </LoadingButton>
            </div>
          </div>
        </>
      </div>

      {showModal && (
        <Stack alignItems={"center"}>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showModal}
            closeAfterTransition
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={showModal}>
              <Box sx={style}>
                {selectedFile && selectedFile.type === "application/pdf" && (
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    margin={"0 30px"}
                  >
                    <Typography variant="h6">Preview</Typography>
                    <IconButton onClick={handleClosePdf}>
                      <ClearIcon />
                    </IconButton>
                  </Stack>
                )}

                <Stack
                  alignItems={"center"}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "90%",
                    objectFit: "contain",
                  }}
                >
                  {selectedFile && selectedFile.type === "application/pdf" ? (
                    <>
                      <div className={uploadStyles.pdfDesktopPreview}>
                        <embed
                          src={URL.createObjectURL(selectedFile)}
                          width="950"
                          height="100%"
                          type="application/pdf"
                        />
                      </div>
                      <div className={uploadStyles.pdfMobilePreview}>
                        <PdfModal url={URL.createObjectURL(selectedFile)} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "5px",
                        }}
                      >
                        <LoadingButton
                          sx={{ width: "150px" }}
                          variant="contained"
                          onClick={handlePdfPreviewDone}
                          id="documentsDoneButton"
                          loading={uploadLoading}
                          type="submit"
                          // disabled = {showInput === false && pdfPassword === ""}
                        >
                          Upload
                        </LoadingButton>
                      </div>
                    </>
                  ) : (
                    <ReactCropper
                      imageSource={preview}
                      callBackImage={handleCallBack}
                      uploadLoading={uploadLoading}
                    />
                  )}
                </Stack>
              </Box>
            </Fade>
          </Modal>
        </Stack>
      )}

      {showPreviewModal && (
        <Stack alignItems={"center"}>
          <PreviewModalPopup
            selectedFile={selectedFile}
            displayFile={displayFile}
            image={image}
            handleClosePreview={handleClosePreview}
            uploadLoading={uploadLoading}
          />
        </Stack>
      )}

      <Modal
        open={openSuccessModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backdropFilter: "blur(10px)" }}
      >
        <Box sx={successModalStyles}>
          <div className={uploadStyles.successModalSection}>
            <p className={uploadStyles.successModalHeader}>Success!</p>
            <p className={uploadStyles.successModalDesc}>
              Documents have been uploaded successfully.
            </p>
            {/* <div
              className={uploadStyles.successModalClose}
              onClick={handleCloseSuccessModal}
            >
              Close
            </div> */}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
