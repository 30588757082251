import React, { useEffect, useRef, useState } from "react";
import {
  Stack,
  TextField,
  Typography,
  Button,
  Modal,
  Fade,
  Box,
  Autocomplete,
  Tooltip,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Radio,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import {
  GetIncomeProofAPICall,
  GetIncomeSlabAPICall,
  SubmitDetailsAPICall,
  deleteDocumentAPICall,
  stageDetailsAPICall,
  uploadDocumentsAPICall,
} from "./APICalls";

import StageLayout from "./StageLayout";
import uploadStyles from "./Uploads.module.scss";
import SnackBar from "../../Components/SnackBars/SnackBar";
import SucessSnackBar from "../../Components/SnackBars/SucesssnackBar";
import csvimage from "../../Components/assets/Images/csvimage.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDropzone } from "react-dropzone";
import Delete from "@mui/icons-material/Delete";
import ReactCropper from "./ReactCropper/ReactCropper";
import PreviewModalPopup from "./PreviewModalPopup";
import PdfModal from "./PdfModal/PdfModal";
import ClearIcon from "@mui/icons-material/Clear";
import viewImage from "../../Components/assets/Images/view-alt-svgrepo-com 1.svg";
import featherUpload from "../../Components/assets/Images/feather_upload-cloud.svg";
import { dataURItoFile } from "../../shared/commonfunctions";
import { getCookie } from "./cookies";
import { confirmAlert } from "react-confirm-alert";
import ErrorSnackBar from "../../Components/SnackBars/ErrorSnackBar";
import BackDrop from "../../Components/BackDrop";
import ChecklistDone from "../../Components/assets/Images/ChecklistDone.svg";
import ChecklistEmpty from "../../Components/assets/Images/ChecklistEmpty.svg";
import FormatterChecker from "../FormatChecker";
import FormatterCheckerFinUpdate from "../FormatCheckerFinupdate";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vW",
  height: "90vH",
  bgcolor: "background.paper",
  p: 1,
  boxShadow: 24,
  borderRadius: "20px",
};

const successModalStyles = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  padding: "40px 30px",
  outline: "none",
};

export default function UploadDocumentsPageFinUpdate() {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [completedStages, setcompletedStages] = useState(50);

  const [incomeProofUrl, setincomeProofUrl] = useState<any>();

  const [checkedOptions1, setCheckedOptions1] = useState(false);
  const [checkedOptions2, setCheckedOptions2] = useState(false);
  const [openSuccessModal, setopenSuccessModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [displayFile, setDisplayFile] = useState({
    status: false,
    type: "",
  });

  const [incomeSlabDropdown, setIncomeSlabDropdown] = useState([]);
  const [incomeProofDropdown, setIncomeProofDropdown] = useState([]);
  const [incomeslabValue, setIncomeslabValue] = useState({
    label: "",
    value: "",
    sequence: 0,
  });
  const [incomesproofValue, setIncomesproofValue] = useState({
    label: "",
    value: "",
    sequence: 0,
  });
  const [incomeSlabAPIValue, setIncomeSlabAPIValue] = useState("");
  const [fileUploadedStatus, setFileUploadedStatus] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const userId = getCookie("userId");
  // const userId = "NP2997";
  const latitude = sessionStorage.getItem("latitude");
  const longitude = sessionStorage.getItem("longitude");
  const [bgColor1, setbgcolor1] = useState("#006779");
  const [bgColor2, setbgcolor2] = useState("#E1E2E3");
  const [selectedValue, setSelectedValue] = useState("a");
  const [disableContinueOption1, setdisableContinueOption1] = useState("");
  const [uploadmessage, setUploadmessage] = useState("");
  const [fetchLodaing, setFetchLoading] = useState(false);
  const [childWindowRef, setchildWindowRef] = useState<any>(null);
  const timerRef = useRef<any>(null);

  const formats: any = ["pdf", "png", "jpg", "jpeg"];

  useEffect(() => {
    window.scrollTo(0, 0);
    handleStageAPI();
    GetIncomeSlabDropDown();
    GetIncomeProofDropDown();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const GetIncomeSlabDropDown = () => {
    let obj: any = {
      purpose: "income_slab",
    };
    GetIncomeSlabAPICall(obj)
      .then((response: any) => {
        // console.log("response", response.data.data);
        if (response.data.data) {
          const FilteredIncomeSlab = response.data.data.sort(
            (a: any, b: any) => a.sequence - b.sequence
          );
          setIncomeSlabDropdown(FilteredIncomeSlab);
          setIncomeslabValue(FilteredIncomeSlab[0]);
        }
      })
      .catch((error: any) => {
        // console.log("error", error);
      });
  };
  const GetIncomeProofDropDown = () => {
    let obj: any = {
      purpose: "income_proof",
    };
    GetIncomeProofAPICall(obj)
      .then((response: any) => {
        if (response.data.data) {
          const FilteredIncomeProof = response.data.data.sort(
            (a: any, b: any) => a.sequence - b.sequence
          );
          setIncomeProofDropdown(FilteredIncomeProof);
          setIncomesproofValue(FilteredIncomeProof[0]);
        }
      })
      .catch((error: any) => {
        // console.log("error", error);
      });
  };

  const SubmitDetails = () => {
    if (selectedValue === "a") {
      setFetchLoading(true);
      let digilink: string = "";

      // IncomeProofRequestAPICall()
      //   .then((response: any) => {
      //     const responseData = response.data;

      //     console.log("responseData", responseData.data.link);
      //     digilink = responseData.data.link;
      //     const childWindow: any = window.open(
      //       digilink,
      //       "_blank",
      //       "width=500,height=700"
      //     );
      //     setchildWindowRef(childWindow);
      //   })
      //   .catch((error: any) => {
      //     setloading(false);
      //   });
    } else {
      SubmitAllDetails();
    }
  };

  useEffect(() => {
    if (childWindowRef) {
      //  console.log("timer trigger");
      timerRef.current = setInterval(checkChild, 500);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [childWindowRef]);

  function checkChild() {
    // console.log("timer");

    if (childWindowRef && childWindowRef.closed) {
      clearInterval(timerRef.current);

      setFetchLoading(false);
    }
  }

  const SubmitAllDetails = () => {
    let obj: any = {
      // client_code: userId,
      income_slab_value: incomeslabValue.value,
      latitude: latitude,
      longitude: longitude,
    };

    SubmitDetailsAPICall(obj)
      .then((response: any) => {
        setopenSuccessModal(true);
        setRejectMessage("");
        setcompletedStages(100);
      })
      .catch((error: any) => {
        // console.log("error", error);
        seterrorMessage("Error Submiting the Details");
        setTimeout(() => {
          seterrorMessage("");
        }, 5000);
      });
  };

  const handleStageAPI = () => {
    let obj: any = {
      client_code: userId,
    };
    setloading(true);
    stageDetailsAPICall()
      .then((response: any) => {
        // console.log("incomeProofDropdown", incomeProofDropdown);
        setloading(false);
        if (response.data.data.url !== "") {
          setincomeProofUrl(response.data.data.url);
          setdisableContinueOption1("Document Successfully Uploaded");
          setFileUploadedStatus(true);
          setDisplayFile({
            status: true,
            type: response.data.data.doc_type_ref,
          });

          if (response.data.data.doc_type_ref === "bank_statement") {
            setSelectedValue("b");
          } else {
            setSelectedValue("b");
          }
        } else {
          setFileUploadedStatus(false);
          setDisplayFile({
            status: false,
            type: "",
          });
        }
        if (response.data.data.isCompleted) {
          setopenSuccessModal(true);
          setcompletedStages(100);
        }
        if (response.data.data.rejectionMessage) {
          setRejectMessage(response.data.data.rejectionMessage);
        }
        if (
          response.data.data.annual_income_value &&
          response.data.data.annual_income_value !== ""
        ) {
          setIncomeSlabAPIValue(response.data.data.annual_income_value);
        }
      })
      .catch((error: any) => {
        setloading(false);
        // console.log("error", error);
      });
  };

  useEffect(() => {
    if (displayFile.type !== "") {
      const filteredIncomeProof: any = incomeProofDropdown.filter(
        (a: any) => a.value === displayFile.type
      );

      setIncomesproofValue(filteredIncomeProof[0]);
    }
    if (incomeSlabAPIValue !== "") {
      const filteredIncomeSlab: any = incomeSlabDropdown.filter(
        (a: any) => a.value === incomeSlabAPIValue
      );

      setIncomeslabValue(filteredIncomeSlab[0]);
    }
  }, [displayFile, incomeSlabAPIValue]);

  const handlePreview = (data: any) => {
    //console.log(data);
  };

  const handleDocumentID = (data: any) => {
    console.log("documentID", data);
    setdisableContinueOption1(data);

    setUploadmessage(data);

    setTimeout(() => {
      setUploadmessage("");
    }, 5000);
  };

  const displayButton =
    selectedValue === "a"
      ? true
      : disableContinueOption1 === "Document Successfully Uploaded"
      ? true
      : false;

  // console.log("setIncomeslabValue", incomeslabValue);
  return (
    <div className={uploadStyles.mainWrapperTop}>
      <SucessSnackBar message={uploadmessage} />
      <SnackBar message={errorMessage} />
      <ErrorSnackBar message={rejectMessage} />
      <StageLayout
        completedStages={completedStages}
        stageName={"Upload Documents"}
        navigatePath={"/nomineepage"}
      />

      {loading && <BackDrop />}

      <div className={uploadStyles.wrapper}>
        <div className={uploadStyles.headerSection}>
          <p className={uploadStyles.signup}>Upload Income Details</p>
          <p className={uploadStyles.subTextTop}>
            Provide your latest income details for better services.
          </p>
        </div>

        <>
          {/* <div className={uploadStyles.messageSection}>
            Please Upload your file
          </div>
          <div className={uploadStyles.messageSectionDesc}>
            Select the type of Income Source
          </div> */}
          <div className={uploadStyles.uploaddropdownWrapper}>
            <div className={uploadStyles.thList}>
              <p className={uploadStyles.incomeSlab}>Income Slab</p>
              {/* <Autocomplete
                size="small"
                onKeyPress={(e) => {
                  e.preventDefault();
                }}
                options={incomeSlabDropdown.map((a: any) => a)}
                getOptionLabel={(option) => option.label}
                sx={{
                  marginTop: "10px",
                  borderRadius: "10px",
                  width: "100%",
                  background: "none",
                  outline: "none",
                  fontSize: "16px",
                  fontWeight: 500,
                  padding: "0 0px",
                  color: "#000000",
                  boxShadow: "none",
                  "& .MuiOutlinedInput-input": {
                    // padding: "4.5px 4px 2.5px 8px",
                    paddingTop: "2px !important",
                    cursor: "pointer",
                    fontSize: "14px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E1E2E3",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E1E2E3",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E1E2E3",
                  },
                }}
                id="disable-clearable"
                popupIcon={
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                  />
                }
                value={incomeslabValue}
                onChange={(event, newValue) => {
                  setIncomeslabValue(newValue);
                }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Income Slab"
                    onKeyPress={(e) => {
                      e.preventDefault();
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    className={
                      selected
                        ? uploadStyles.option
                        : uploadStyles.optionnotselected
                    }
                  >
                    {option.label}
                  </li>
                )}
              /> */}
              <div className={uploadStyles.chipsWrapper}>
                {incomeSlabDropdown &&
                  incomeSlabDropdown.map((a: any) => (
                    <div
                      className={uploadStyles.chip}
                      onClick={(event) => {
                        setIncomeslabValue(a);
                      }}
                      style={{
                        background: incomeslabValue === a ? "" : "none",
                        border:
                          incomeslabValue === a ? "" : "1px solid #EBECEF",
                      }}
                    >
                      <img
                        src={
                          incomeslabValue === a ? ChecklistDone : ChecklistEmpty
                        }
                        className={uploadStyles.checkicon}
                        alt=""
                      />
                      <p
                        className={uploadStyles.checkLabel}
                        style={{
                          color: incomeslabValue === a ? "" : "#6B7280",
                        }}
                      >
                        {a.label}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
            <div className={uploadStyles.centerWrapper}>
              <div className={uploadStyles.centerLeft}>
                <div className={uploadStyles.thList}>
                  <span
                    style={{
                      paddingLeft: "5px",
                      fontWeight: 500,
                      fontSize: "13px",
                    }}
                  >
                    Income Proof
                  </span>
                  <Autocomplete
                    size="small"
                    onKeyPress={(e) => {
                      e.preventDefault();
                    }}
                    options={incomeProofDropdown.map((a: any) => a)}
                    getOptionLabel={(option) => option.label}
                    disabled={disableContinueOption1 !== ""}
                    sx={{
                      marginTop: "10px",
                      borderRadius: "10px",
                      width: "100%",
                      background: "none",
                      height: "30px",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: 500,
                      padding: "0 0px",
                      color: "#000000",
                      boxShadow: "none",
                      "& .MuiOutlinedInput-input": {
                        // padding: "4.5px 4px 2.5px 8px",
                        paddingTop: "2px !important",
                        cursor: "pointer",
                        fontSize: "14px",
                        height: "30px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #E1E2E3",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #E1E2E3",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #E1E2E3",
                      },
                    }}
                    id="disable-clearable"
                    popupIcon={
                      <KeyboardArrowDownIcon
                        style={{ cursor: "pointer" }}
                        fontSize="small"
                      />
                    }
                    value={incomesproofValue}
                    onChange={(event, newValue) => {
                      setIncomesproofValue(newValue);
                      if (newValue.value === "bank_statement") {
                        setSelectedValue("a");
                      } else {
                        setSelectedValue("b");
                      }
                    }}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Template"
                        onKeyPress={(e) => {
                          e.preventDefault();
                        }}
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <li
                        {...props}
                        className={
                          selected
                            ? uploadStyles.option
                            : uploadStyles.optionnotselected
                        }
                      >
                        {option.label}
                      </li>
                    )}
                  />
                </div>
              </div>
              <div className={uploadStyles.centerRight}>
                {incomesproofValue.value === "bank_statement" && (
                  <div
                    className={uploadStyles.subContainer}
                    onClick={() => {
                      const radio = document.getElementById("radio-a");
                      if (radio) {
                        radio.click();
                        setbgcolor1("#006779");
                        setbgcolor2("#E1E2E3");
                      }
                    }}
                    style={{ border: `1px solid ${bgColor1}` }}
                  >
                    <div className={uploadStyles.contentConatiner}>
                      <div
                        className={uploadStyles.subContainer}
                        onClick={() => {
                          const radio = document.getElementById("radio-a");
                          if (radio) {
                            radio.click();
                            setbgcolor2("#006779");
                            setbgcolor1("#E1E2E3");
                          }
                        }}
                      >
                        <div className={uploadStyles.contentConatiner}>
                          <div className={uploadStyles.subContentContainer}>
                            <div className={uploadStyles.aligndiv}>
                              <div>
                                <p className={uploadStyles.text}>
                                  {" "}
                                  Bank Statement Via OTP
                                </p>
                                <p className={uploadStyles.para}>
                                  Add your account number, IFSC manually
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className={uploadStyles.radioSection}>
                            <div>
                              <Radio
                                id="radio-a"
                                checked={selectedValue === "a"}
                                onChange={(event) => {
                                  handleChange(event);
                                  //  console.log(event.target.value);
                                }}
                                value="a"
                                name="radio-buttons"
                                inputProps={{ "aria-label": "B" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {incomesproofValue.value === "bank_statement" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#B0B5C9",
                      fontSize: "14px",
                      margin: "10px 0px",
                    }}
                  >
                    or
                  </div>
                )}
                <div
                  className={uploadStyles.subContainer}
                  onClick={() => {
                    const radio = document.getElementById("radio-b");
                    if (radio) {
                      radio.click();
                      setbgcolor2("#006779");
                      setbgcolor1("#E1E2E3");
                    }
                  }}
                  style={{ border: `1px solid ${bgColor2}` }}
                >
                  <div className={uploadStyles.contentConatiner}>
                    <div
                      className={uploadStyles.subContainer}
                      onClick={() => {
                        const radio = document.getElementById("radio-b");
                        if (radio) {
                          radio.click();
                          setbgcolor2("#006779");
                          setbgcolor1("#E1E2E3");
                        }
                      }}
                    >
                      <div className={uploadStyles.contentConatiner}>
                        <div className={uploadStyles.subContentContainer}>
                          <div className={uploadStyles.aligndiv}>
                            <div>
                              <p className={uploadStyles.text}>
                                {" "}
                                Upload manually
                              </p>
                              {/* <p className={uploadStyles.para}>
                                Add your account number, IFSC manually
                              </p> */}
                            </div>
                          </div>
                        </div>
                        <FormatterCheckerFinUpdate
                          previewImage={handlePreview}
                          acceptedFormats={formats}
                          documentRef={incomesproofValue.value}
                          documentID={handleDocumentID}
                          displayOptions={() => {}}
                          stageDetailsData={incomeProofUrl}
                          name="incomeproof"
                          purpose={incomesproofValue.value}
                          pdfSize={8.5}
                          imageSize={8}
                        />
                        <div className={uploadStyles.radioSection}>
                          <div>
                            <Radio
                              id="radio-b"
                              checked={selectedValue === "b"}
                              onChange={(event) => {
                                handleChange(event);
                                //  console.log(event.target.value);
                              }}
                              value="b"
                              name="radio-buttons"
                              inputProps={{ "aria-label": "B" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={uploadStyles.checkBoxSection}>
            <div className={uploadStyles.checkBoxDiv}>
              <FormGroup>
                <FormControlLabel
                  sx={{ alignItems: "flex-start" }}
                  control={
                    <Checkbox
                      name="condition3"
                      sx={{
                        padding: "0px",
                        "&.Mui-checked": {
                          color: "#4AACDD",
                        },
                      }}
                      checked={checkedOptions1}
                      onChange={(event) => {
                        const { checked } = event.target;
                        setCheckedOptions1(checked);
                      }}
                    />
                  }
                  label={
                    <span className={uploadStyles.label}>
                      I verify that the information supplied above is accurate,
                      truthful, and comprehensive to the best of my
                      understanding. Should any of the specified details be
                      discovered to be inaccurate or false, I acknowledge that I
                      may be held responsible.
                    </span>
                  }
                />
              </FormGroup>
            </div>
            <div className={uploadStyles.checkBoxDiv}>
              <FormGroup>
                <FormControlLabel
                  sx={{ alignItems: "flex-start" }}
                  control={
                    <Checkbox
                      name="condition3"
                      sx={{
                        padding: "0px",
                        "&.Mui-checked": {
                          color: "#4AACDD",
                        },
                      }}
                      checked={checkedOptions2}
                      onChange={(event) => {
                        const { checked } = event.target;
                        setCheckedOptions2(checked);
                      }}
                    />
                  }
                  label={
                    <span className={uploadStyles.label}>
                      If there are any alterations to the provided information
                      in the future, I commit to notifying Firstock within a
                      period of 30 days from the date of such changes.
                    </span>
                  }
                />
              </FormGroup>
            </div>
          </div>

          <SucessSnackBar message={successMessage} />

          <SnackBar message={errorMessage} />

          <div className={uploadStyles.buttonStack}>
            <div>
              <LoadingButton
                variant="contained"
                onClick={SubmitDetails}
                loading={loading}
                id="upLoadDocumentsContinueButton"
                className={uploadStyles.approveButton}
                disabled={
                  !checkedOptions1 || !checkedOptions2 || !displayButton
                }
              >
                {" "}
                {selectedValue === "a" ? "Upload" : "Submit"}
              </LoadingButton>
            </div>
          </div>
        </>
      </div>

      <Modal
        open={openSuccessModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backdropFilter: "blur(10px)" }}
      >
        <Box sx={successModalStyles}>
          <div className={uploadStyles.successModalSection}>
            <p className={uploadStyles.successModalHeader}>Success!</p>
            <p className={uploadStyles.successModalDesc}>
              Documents have been uploaded successfully.
            </p>
            {/* <div
              className={uploadStyles.successModalClose}
              onClick={handleCloseSuccessModal}
            >
              Close
            </div> */}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
