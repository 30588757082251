import React from "react";
import FooterStyles from "./Footer.module.scss";

import TwitterIcon from "../../Components/assets/Images/twitterblack.svg";
import GitHubIcon from "../../Components/assets/Images/githubblack.svg";
import linkedinicon from "../../Components/assets/Images/linkedblack.svg";
import firstocklogo from "../../Components/assets/Images/CompanyLogo.svg";

const Footer = () => {
  return (
    <>
      <div className={FooterStyles.wrapper}>
        <div className={FooterStyles.wrapperTop}>
          <div className={FooterStyles.logoSection}>
            <img src={firstocklogo} className={FooterStyles.companylogo} />
          </div>
          <div className={FooterStyles.siteMaping}>
            <div className={FooterStyles.siteMapingAlign}>
              <div className={FooterStyles.linksandsublinks}>
                <a target="_blank" className={FooterStyles.siteMapingLinks}>
                  Firstock
                </a>
                <a
                  href="https://thefirstock.com/about.html"
                  target="_blank"
                  className={FooterStyles.sublinks}
                >
                  <span className={FooterStyles.toUnderline}>About</span>
                </a>
                <a
                  href="https://login.thefirstock.com/"
                  target="_blank"
                  className={FooterStyles.sublinks}
                >
                  <span className={FooterStyles.toUnderline}>Features</span>
                </a>
                <a
                  href="https://thefirstock.com/contact.html"
                  target="_blank"
                  className={FooterStyles.sublinks}
                >
                  <span className={FooterStyles.toUnderline}>
                    Customer Support
                  </span>
                </a>
                <a
                  href="https://thefirstock.com/company_profile.html"
                  target="_blank"
                  className={FooterStyles.sublinks}
                >
                  <span className={FooterStyles.toUnderline}>
                    Company Profile
                  </span>{" "}
                </a>
              </div>

              <div className={FooterStyles.linksandsublinks}>
                <a target="_blank" className={FooterStyles.siteMapingLinks}>
                  Resources
                </a>
                <a
                  href="https://thefirstock.com/brokerage_calculator.html"
                  target="_blank"
                  className={FooterStyles.sublinks}
                >
                  <span className={FooterStyles.toUnderline}>
                    Brokerage Calculator
                  </span>
                </a>
                <a
                  href="https://thefirstock.com/margin-calculator.html"
                  target="_blank"
                  className={FooterStyles.sublinks}
                >
                  <span className={FooterStyles.toUnderline}>
                    Margin Calculator
                  </span>
                </a>

                <a
                  href="https://connect.thefirstock.com/docsv3"
                  target="_blank"
                  className={FooterStyles.sublinks}
                >
                  <span className={FooterStyles.toUnderline}>API Docs</span>
                </a>
                <a
                  href="https://connect.thefirstock.com/login"
                  target="_blank"
                  className={FooterStyles.sublinks}
                >
                  <span className={FooterStyles.toUnderline}>
                    Key Generation
                  </span>
                </a>
              </div>
            </div>
            <div
              className={FooterStyles.siteMapingAlign}
              style={{ marginTop: "50px" }}
            >
              <div className={FooterStyles.linksandsublinks}>
                <a target="_blank" className={FooterStyles.siteMapingLinks}>
                  Legal
                </a>
                {/* <a href="" target="_blank" className={FooterStyles.sublinks}>
              <span className={FooterStyles.toUnderline}>Legal Aggrement</span>
            </a> */}
                <a
                  href="https://thefirstock.com/terms_and_conditions.html"
                  target="_blank"
                  className={FooterStyles.sublinks}
                >
                  <span className={FooterStyles.toUnderline}>
                    Terms and Conditions
                  </span>
                </a>
                <a
                  href="https://thefirstock.com/privacy_policy.html"
                  target="_blank"
                  className={FooterStyles.sublinks}
                >
                  <span className={FooterStyles.toUnderline}>
                    Privacy Policy
                  </span>
                </a>
                {/* <a href="" target="_blank" className={FooterStyles.sublinks}>
              <span className={FooterStyles.toUnderline}>Security</span>
            </a> */}
              </div>
            </div>
            <div
              className={FooterStyles.siteMapingAlign}
              style={{ marginTop: "50px" }}
            >
              <div className={FooterStyles.linksandsublinks}>
                <a target="_blank" className={FooterStyles.siteMapingLinks}>
                  OFFICE:
                </a>
                <a
                  target="_blank"
                  className={FooterStyles.sublinksnot}
                  style={{ width: "350px", fontSize: "15px" }}
                >
                  <span className={FooterStyles.toUnderline}>
                    NCO Securities and Share Broking Pvt Ltd.
                  </span>
                </a>
                <a
                  target="_blank"
                  className={FooterStyles.sublinksnot}
                  style={{ width: "350px", padding: "3px 0px" }}
                >
                  <span className={FooterStyles.toUnderline}>
                    No 350,1st Floor, 36th A Cross 7th Main Rd 5th Block
                    Jayanagar, Bengaluru, KA 560041.
                  </span>
                </a>
                <a
                  target="_blank"
                  className={FooterStyles.sublinksnot}
                  style={{ width: "350px", padding: "3px 0px" }}
                >
                  <span className={FooterStyles.toUnderline}>
                    NSE​ &​ BSE – SEBI Registration No.: INZ000260334
                  </span>
                </a>
                <a
                  target="_blank"
                  className={FooterStyles.sublinksnot}
                  style={{ width: "350px", padding: "3px 0px" }}
                >
                  <span className={FooterStyles.toUnderline}>
                    CDSL: Depository services – SEBI Registration No.:
                    IN-DP-67-2015 Mutual Fund ARN: 132812
                  </span>
                </a>
              </div>
            </div>
            <div className={FooterStyles.siteMapingAlignMobile}>
              <div className={FooterStyles.linksandsublinkstoalignmobile}>
                <div className={FooterStyles.linksandsublinks}>
                  <a target="_blank" className={FooterStyles.siteMapingLinks}>
                    Firstock
                  </a>
                  <a
                    href="https://thefirstock.com/about.html"
                    target="_blank"
                    className={FooterStyles.sublinks}
                  >
                    <span className={FooterStyles.toUnderline}>About</span>
                  </a>
                  <a
                    href="https://login.thefirstock.com/"
                    target="_blank"
                    className={FooterStyles.sublinks}
                  >
                    <span className={FooterStyles.toUnderline}>Features</span>
                  </a>
                  <a
                    href="https://thefirstock.com/contact.html"
                    target="_blank"
                    className={FooterStyles.sublinks}
                  >
                    <span className={FooterStyles.toUnderline}>
                      Customer Support
                    </span>
                  </a>
                  <a
                    href="https://thefirstock.com/company_profile.html"
                    target="_blank"
                    className={FooterStyles.sublinks}
                  >
                    <span className={FooterStyles.toUnderline}>
                      Company Profile
                    </span>{" "}
                  </a>
                </div>

                <div className={FooterStyles.linksandsublinks}>
                  <a target="_blank" className={FooterStyles.siteMapingLinks}>
                    Resources
                  </a>
                  <a
                    href="https://thefirstock.com/brokerage_calculator.html"
                    target="_blank"
                    className={FooterStyles.sublinks}
                  >
                    <span className={FooterStyles.toUnderline}>
                      Brokerage Calculator
                    </span>
                  </a>
                  <a
                    href="https://thefirstock.com/margin-calculator.html"
                    target="_blank"
                    className={FooterStyles.sublinks}
                  >
                    <span className={FooterStyles.toUnderline}>
                      Margin Calcuator
                    </span>
                  </a>

                  <a
                    href="https://connect.thefirstock.com/docsv3"
                    target="_blank"
                    className={FooterStyles.sublinks}
                  >
                    <span className={FooterStyles.toUnderline}>API Docs</span>
                  </a>
                  <a
                    href="https://connect.thefirstock.com/login"
                    target="_blank"
                    className={FooterStyles.sublinks}
                  >
                    <span className={FooterStyles.toUnderline}>
                      Key Generation
                    </span>
                  </a>
                </div>
              </div>

              <div
                className={FooterStyles.linksandsublinks}
                style={{ marginBottom: "20px" }}
              >
                <a target="_blank" className={FooterStyles.siteMapingLinks}>
                  Legal
                </a>
                {/* <a href="" target="_blank" className={FooterStyles.sublinks}>
              <span className={FooterStyles.toUnderline}>Legal Aggrement</span>
            </a> */}
                <a
                  href="https://thefirstock.com/terms_and_conditions.html"
                  target="_blank"
                  className={FooterStyles.sublinks}
                >
                  <span className={FooterStyles.toUnderline}>
                    Terms and Conditions
                  </span>
                </a>
                <a
                  href="https://thefirstock.com/privacy_policy.html"
                  target="_blank"
                  className={FooterStyles.sublinks}
                >
                  <span className={FooterStyles.toUnderline}>
                    Privacy Policy
                  </span>
                </a>
                {/* <a href="" target="_blank" className={FooterStyles.sublinks}>
              <span className={FooterStyles.toUnderline}>Security</span>
            </a> */}
              </div>
              <div className={FooterStyles.linksandsublinks}>
                <a target="_blank" className={FooterStyles.siteMapingLinks}>
                  OFFICE:
                </a>
                <a
                  target="_blank"
                  className={FooterStyles.sublinksnot}
                  style={{ fontSize: "15px" }}
                >
                  <span className={FooterStyles.toUnderline}>
                    NCO Securities and Share Broking Pvt Ltd.
                  </span>
                </a>
                <a
                  target="_blank"
                  className={FooterStyles.sublinksnot}
                  style={{ padding: "3px 0px" }}
                >
                  <span className={FooterStyles.toUnderline}>
                    No 350,1st Floor, 36th A Cross 7th Main Rd 5th Block
                    Jayanagar, Bengaluru, KA 560041.
                  </span>
                </a>
                <a
                  target="_blank"
                  className={FooterStyles.sublinksnot}
                  style={{ padding: "3px 0px" }}
                >
                  <span className={FooterStyles.toUnderline}>
                    NSE​ &​ BSE – SEBI Registration No.: INZ000260334
                  </span>
                </a>
                <a
                  target="_blank"
                  className={FooterStyles.sublinksnot}
                  style={{ padding: "3px 0px" }}
                >
                  <span className={FooterStyles.toUnderline}>
                    CDSL: Depository services – SEBI Registration No.:
                    IN-DP-67-2015 Mutual Fund ARN: 132812
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={FooterStyles.bottomExtraContent}>
          <div className={FooterStyles.imageSection}>
            <p>Follow Us On </p>
            <a href="https://twitter.com/thefirstock" target="_blank">
              <img
                src={TwitterIcon}
                alt="image"
                className={FooterStyles.icons}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/thefirstock/mycompany/"
              target="_blank"
            >
              <img
                src={linkedinicon}
                alt="image"
                className={FooterStyles.icons}
              />
            </a>
            {/* <a href="https://github.com/the-firstock" target="_blank">
          <img src={TwitterIcon} alt="image" className={FooterStyles.icons} />
        </a> */}
            <a href="https://github.com/the-firstock" target="_blank">
              <img
                src={GitHubIcon}
                alt="image"
                className={FooterStyles.icons}
              />
            </a>
          </div>
          <div className={FooterStyles.contentsectionnew}>
            <div className="">
              <p
                className={FooterStyles.copyRightPara}
                style={{ margin: "5px 0" }}
              >
                Copyright 2021 FIRSTOCK | All Rights Reserved
              </p>
            </div>

            <div className="">
              <div className="" style={{ textAlign: "left" }}>
                <div>
                  <div className="">
                    <p style={{ fontSize: "14px", margin: "5px 0" }}>
                      For any complaints pertaining to securities broking please
                      write to 
                      <a
                        href="mailto:complaints@ncosecurities.com"
                        target="_blank"
                        className={FooterStyles.alink}
                        style={{
                          textDecoration: "underline",
                          fontSize: "14px",
                        }}
                      >
                        complaints@ncosecurities.com
                      </a>
                      , for DP related to 
                      <a
                        href="mailto:ncodp@ncosecurities.com"
                        target="_blank"
                        className={FooterStyles.alink}
                        style={{
                          textDecoration: "underline",
                          fontSize: "14px",
                        }}
                      >
                        ncodp@ncosecurities.com
                      </a>
                      . Please ensure you carefully read the Risk Disclosure
                      Document as prescribed by SEBI.
                    </p>
                  </div>

                  <p className="" style={{ fontSize: "14px", margin: "5px 0" }}>
                    Attention Investors:
                  </p>
                  <p
                    className=""
                    style={{ fontSize: "14px", margin: "10px 0" }}
                  >
                    Investments in securities market are subject to market
                    risks; read all the related documents carefully before
                    investing.
                  </p>
                  <p
                    className=""
                    style={{ fontSize: "14px", margin: "10px 0" }}
                  >
                    Prevent Unauthorized Transactions in your trading/demat
                    account Update your Mobile Number/Email IDs with your Stock
                    brokers/Depository Participant. Receive alerts/information
                    of your transactions on your Registered Mobile/Email for all
                    debit and other important transactions in your trading/demat
                    account directly from Exchange/CDSL on the same day.
                  </p>
                  <p
                    className=""
                    style={{ fontSize: "14px", margin: "10px 0" }}
                  >
                    KYC is one time exercise while dealing in securities markets
                    - once KYC is done through a SEBI registered intermediary
                    (broker, DP, Mutual Fund etc.), you need not undergo the
                    same process again when you approach another intermediary.
                  </p>
                  <p
                    className=""
                    style={{ fontSize: "14px", margin: "10px 0" }}
                  >
                    No need to issue cheques by investors while subscribing to
                    IPO.Just write the bank account number and sign in the
                    application form to authorise your bank to make payment in
                    case of allotment. No worry for refund as the money remains
                    in investor's account.
                  </p>
                  <p className="" style={{ fontSize: "14px", margin: "5px 0" }}>
                    Procedure to file a complaint on{" "}
                    <a
                      href="https://scores.gov.in/scores/Welcome.html"
                      target="_blank"
                      className={FooterStyles.alink}
                      style={{ textDecoration: "underline", fontSize: "14px" }}
                    >
                      SCORES
                    </a>{" "}
                    (Easy & quick). Register on{" "}
                    <a
                      href="https://scores.gov.in/scores/Welcome.html"
                      target="_blank"
                      className={FooterStyles.alink}
                      style={{ textDecoration: "underline", fontSize: "14px" }}
                    >
                      SCORES
                    </a>{" "}
                    portal and have the mandatory details for filing complaints
                    on SCORES (Name, PAN, Address, Mobile Number and E-mail ID).
                    Benefits: Effective Communication and Speedy redressal of
                    the grievances.
                  </p>
                  <p
                    className=""
                    style={{ fontSize: "14px", margin: "10px 0" }}
                  >
                    Dear Investor, if you are subscribing to an IPO, there is no
                    need to issue a cheque. Please write the Bank account number
                    and sign the IPO application form to authorize your bank to
                    make payment in case of allotment. In case of non-allotment,
                    the funds will remain in your bank account. As a business we
                    don't give stock tips, and have not authorized anyone to
                    trade on behalf of others. 
                  </p>
                  <p
                    className=""
                    style={{ fontSize: "14px", margin: "10px 0" }}
                  >
                    Stock Brokers can accept securities as margin from clients
                    only by way of pledge in the depository system w.e.f.
                    September 01, 2020. Update your email id and mobile number
                    with your stock broker / depository participant and receive
                    OTP directly from depository on your email id and/or mobile
                    number to create pledge Check your securities/MF/bonds in
                    the consolidated account statement issued by NSDL/CDSL every
                    month.
                  </p>
                  <p
                    className=""
                    style={{ fontSize: "14px", margin: "10px 0" }}
                  >
                    The Stock Exchange, Mumbai is not in any manner answerable,
                    responsible or liable to any person or persons for any acts
                    of omission or commission, errors, mistakes and/or
                    violation, actual or perceived, by us or our partners,
                    agents, associates etc., of any of the Rules, Regulations,
                    Bye-laws of the Stock Exchange, Mumbai, SEBI Act or any
                    other laws in force from time to time. The Stock Exchange,
                    Mumbai is not answerable, responsible or liable for any
                    information on this Website or for any services rendered by
                    our employees, our servants, and us. Please refer to{" "}
                    <a
                      href="https://bsecrs.bseindia.com/ecomplaint/frmInvestorHome.aspx"
                      target="_blank"
                      className={FooterStyles.alink}
                      style={{ textDecoration: "underline", fontSize: "14px" }}
                    >
                      BSE compliance
                    </a>{" "}
                    for more details.
                  </p>
                  <p
                    className=""
                    style={{ fontSize: "14px", margin: "10px 0" }}
                  >
                    Investors are requested to note that Stock broker NCO
                    Securities and Share Broking Pvt Ltd (Firstock) permitted to
                    receive/pay money from/to investor through designated banks
                    accounts only named as client bank accounts. Stock broker
                    NCO Securities and Share Broking Pvt Ltd (Firstock) is also
                    required to disclose these client bank accounts to Stock
                    Exchange. Hence, you are requested to use following client
                    bank accounts only for the purpose of dealings in your
                    trading account with us. The details of these client bank
                    accounts are also displayed by Stock Exchanges on their
                    website under “Know/ Locate your Stock Broker".
                  </p>
                </div>
              </div>
            </div>
            <div>
              <ul
                className={FooterStyles.bottomLinks}
                style={{ listStyle: "none" }}
              >
                <li style={{ padding: "3px 0" }}>
                  <a
                    href="https://www.thefirstock.com/policies_procedures.html"
                    target="_blank"
                    className={FooterStyles.alink}
                    style={{ color: "#000000" }}
                  >
                    Policies &amp; Procedures
                  </a>
                </li>
                <li style={{ padding: "3px 0" }}>
                  <a
                    href="https://www.thefirstock.com/privacy_policy.html"
                    target="_blank"
                    className={FooterStyles.alink}
                    style={{ color: "#000000" }}
                  >
                    Privacy Policy
                  </a>
                </li>

                <li style={{ padding: "3px 0" }}>
                  <a
                    href="https://www.thefirstock.com/for-investor-attention.html"
                    target="_blank"
                    className={FooterStyles.alink}
                    style={{ color: "#000000" }}
                  >
                    For Investor's Attention
                  </a>
                </li>
                <li style={{ padding: "3px 0" }}>
                  <a
                    href="https://www.thefirstock.com/investor-charter.html"
                    target="_blank"
                    className={FooterStyles.alink}
                    style={{ color: "#000000" }}
                  >
                    Investor Charter
                  </a>
                </li>
                <li style={{ padding: "3px 0" }}>
                  <a
                    href="https://docs.google.com/spreadsheets/d/1B5jrqYw1J4O7jbmmV5nBVEhxMDS6d4jTvnqecidM9ns/edit#gid=0"
                    target="_blank"
                    className={FooterStyles.alink}
                    style={{ color: "#000000" }}
                  >
                    Investor Grievance
                  </a>
                </li>
                <li style={{ padding: "3px 0" }}>
                  <a
                    href="https://firstock-common.s3.ap-south-1.amazonaws.com/forms/NCO+PMLA+%26+AML+POLICY+(1).pdf"
                    target="_blank"
                    className={FooterStyles.alink}
                    style={{ color: "#000000" }}
                  >
                    NCO PMLA Policy
                  </a>
                </li>
              </ul>
            </div>

            <div className={FooterStyles.bottomDiv}>
              <ul
                className={FooterStyles.bottomLinks2}
                style={{ listStyle: "none" }}
              >
                <li style={{ padding: "3px 0" }}>
                  <a
                    href="https://www.thefirstock.com/disclaimer.html"
                    target="_blank"
                    className={FooterStyles.alink}
                    style={{ color: "#000000" }}
                  >
                    Disclaimer
                  </a>
                </li>
                <li style={{ padding: "3px 0" }}>
                  <a
                    href="https://www.thefirstock.com/terms_and_conditions.html"
                    target="_blank"
                    className={FooterStyles.alink}
                    style={{ color: "#000000" }}
                  >
                    Terms &amp; conditions
                  </a>
                </li>
                <li style={{ padding: "3px 0" }}>
                  <a
                    href="https://www.thefirstock.com/risk_disclosure.html"
                    className={FooterStyles.alink}
                    style={{ color: "#000000" }}
                  >
                    Risk Disclosure for F&O
                  </a>
                </li>
                <li style={{ padding: "3px 0" }}>
                  <a
                    href="https://evoting.cdslindia.com/Evoting/EVotingLogin"
                    target="_blank"
                    className={FooterStyles.alink}
                    style={{ color: "#000000" }}
                  >
                    e-Voting facility for Shareholders
                  </a>
                </li>
                <li style={{ padding: "3px 0" }}>
                  <a
                    href="https://nseindia.com"
                    target="_blank"
                    className={FooterStyles.alink}
                    style={{ color: "#000000" }}
                  >
                    NSE
                  </a>
                </li>
                <li style={{ padding: "3px 0" }}>
                  <a
                    href="https://www.bseindia.com/"
                    target="_blank"
                    className={FooterStyles.alink}
                    style={{ color: "#000000" }}
                  >
                    BSE
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  
    </>
  );
};

export default Footer;