import { useEffect, useState } from "react";

import jwt_decode from "jwt-decode";
import { sendEmialAPICall } from "../../shared/APICalls";
import routesData from "../../shared/routes.json";
import { useNavigate, useLocation } from "react-router-dom";
// import GoogleLoginButton from "./components/GoogleLoginButton";

function ContinueWithGoogle({ validationID, handleCallBack }) {
  const [user, setUser] = useState({});
  
  const navigationData = routesData;
  const navigate = useNavigate();

  const handleCallBackResponse = (response) => {
   // console.log("JWT ID: " + response.credential);

    const userObject = jwt_decode(response.credential);

   // console.log(userObject);

    setUser(userObject);
    let obj = {
      //validation_id: validationID,
      email: userObject.email,
      client_name: userObject.given_name,
      is_gmail_verified: userObject.email_verified,
      google_client_id: userObject.aud,
    };
    sendEmialAPICall(obj)
      .then((response) => {
        const responseData = response.data;
      //  console.log(response, "response");

        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage], {
            state: { emailID: userObject.email, validateID: validationID },
          });
        }
      })
      .catch((error) => {
       // console.log("error", error);
        if (error.response.data.error.message) {
          handleCallBack(error.response.data.error.message);

          setTimeout(() => {
            handleCallBack("");
          }, 5000);
        }
      });
  };

  useEffect(() => {
    // global google

    window.google.accounts.id.initialize({
      client_id:
        "1085784227894-t9v8aa1dok1ft3i90r8r4rfokr4nslvr.apps.googleusercontent.com",

      callback: handleCallBackResponse,
    });

    window.google.accounts.id.renderButton(document.getElementById("signup"), {
      theme: "filled_black",

      size: "large",
    });
  }, []);

  return (
    <>
      {/* <GoogleLoginButton /> */}

      <div id="signup" >
        {user && (
          <div >
            <img src={user.picture} alt="User" />

            <h3>{user.name}</h3>
          </div>
        )}
      </div>
    </>
  );
}

export default ContinueWithGoogle;
