import React, { useState, createRef, useEffect } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import { Stack, Button, Typography, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import CropIcon from "@mui/icons-material/Crop";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "cropperjs/dist/cropper.css";
import "../../../App.css";
import CachedIcon from "@mui/icons-material/Cached";

import { LoadingButton } from "@mui/lab";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CropperStyles from "./ReactCropper.module.scss";

interface Props {
  imageSource: any;
  callBackImage: any;
  uploadLoading: boolean;
  // documentRef:string
  // callBackDocumentId:any;
}

export const ReactCropper: React.FC<Props> = ({
  imageSource,
  callBackImage,
  uploadLoading,
  // documentRef,
  // callBackDocumentId
}) => {
  const [image, setImage] = useState(imageSource);
  const [cropData, setCropData] = useState("#");
  const cropperRef = createRef<ReactCropperElement>();
  const [initialImage, setInitialImage] = useState(imageSource);
  const [loading, setloading] = useState(false);

  const handleCloseCropper = () => {
    callBackImage("close");
  };

  const getCropData = () => {
    setloading(true);
    if (typeof cropperRef.current?.cropper !== "undefined") {
      callBackImage(cropperRef.current?.cropper);
      // setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    }
    setTimeout(() => {
      setloading(false);
    }, 4000);
  };

  const rotateImage = () => {
    const imageElement = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    cropper.rotate(90);
  };
  const rotateImageLeft = () => {
    cropperRef.current?.cropper.rotate(-90);
  };
  const zoomIn = () => {
    cropperRef.current?.cropper.zoom(0.1);
  };

  const zoomOut = () => {
    cropperRef.current?.cropper.zoom(-0.3);
  };

  const downloadImage = () => {
    const a = document.createElement("a");
    a.href = cropData;
    a.download = "cropped_image.png";
    a.click();
  };

  const resetCropper = () => {
    setImage(initialImage);
    setCropData("#");
    cropperRef.current?.cropper.reset();
  };

  return (
    <>
      <Stack
        style={{ width: "100%" }}
        alignItems={"center"}
        paddingLeft={"5px"}
      >
        <Stack width={"100%"} alignItems={"flex-start"}>
          <IconButton style={{ zIndex: "100" }} onClick={handleCloseCropper}>
            <KeyboardBackspaceIcon />
          </IconButton>
        </Stack>

        <Typography
          style={{
            position: "absolute",
            right: "13%",
            top: "9%",
            zIndex: "100",
            fontWeight: 500,
          }}
          variant="h5"
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          Preview
        </Typography>

        <Stack width={"100%"} direction={"row"}>
          <Cropper
            ref={cropperRef}
            className={CropperStyles.cropperSection}
            // style={{ height: 400, width: "80%" }}
            zoomTo={-1}
            initialAspectRatio={16 / 9}
            preview=".img-preview"
            src={image}
            viewMode={1}
            minCropBoxHeight={100}
            minCropBoxWidth={100}
            background={false}
            responsive={true}
            autoCropArea={1.8}
            checkOrientation={false}
            guides={true}
          />

          <div
            className="img-preview"
            // style={{
            //   width: "45%",
            //   float: "left",
            //   height: "400px",
            //   //overflowX: "scroll",
            //   margin: "15px",
            //   border: "1px solid grey",
            //   marginTop: "50px",
            //   borderRadius: "10px",
            //   objectFit: "contain",
            // }}
            id={CropperStyles.previewSection}
          />
        </Stack>
      </Stack>

      <Stack
        paddingTop={"10px"}
        direction={"row"}
        width={"100%"}
        sx={{ position: "absolute", bottom: "7%" }}
      >
        <Grid
          container
          justifyContent={{ xs: "center", md: "flex-start" }}
          marginLeft={{ xs: "10px", md: "80px" }}
        >
          <Button
            variant="outlined"
            onClick={zoomIn}
            style={{
              marginRight: "15px",
              marginTop: "10px",
              color: "black",
              border: "1px solid black",
            }}
          >
            <AddIcon />
          </Button>
          <Button
            variant="outlined"
            onClick={zoomOut}
            style={{
              marginRight: "15px",
              marginTop: "10px",
              color: "black",
              border: "1px solid black",
            }}
          >
            <RemoveIcon />
          </Button>
          <Button
            variant="outlined"
            onClick={rotateImageLeft}
            style={{
              marginRight: "15px",
              marginTop: "10px",
              color: "black",
              border: "1px solid black",
            }}
          >
            <RotateLeftIcon />
          </Button>

          <Button
            variant="outlined"
            onClick={rotateImage}
            style={{
              marginRight: "15px",
              marginTop: "10px",
              color: "black",
              border: "1px solid black",
            }}
          >
            <RotateRightIcon />
          </Button>
          <Button
            variant="outlined"
            onClick={resetCropper}
            style={{
              marginRight: "15px",
              marginTop: "10px",
              color: "black",
              border: "1px solid black",
            }}
          >
            <CachedIcon /> Reset
          </Button>
          {/* <Button variant="outlined" onClick={getCropData}>
          <CropIcon />
        </Button> */}
          <LoadingButton
            style={{
              marginRight: "15px",
              marginTop: "10px",
              boxShadow: "none",
            }}
            variant="contained"
            onClick={getCropData}
            loading={uploadLoading}
            type="submit"
          >
            Done
          </LoadingButton>
        </Grid>
      </Stack>
    </>
  );
};

export default ReactCropper;
