import React, { useEffect, useRef, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  IconButton,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Radio,
  Chip,
  Grid,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";
import { checkUserLoggedIn } from "../shared/commonfunctions";
import SelectDropdown from "../Components/SelectDropdown/SelectDropdown";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  startOverAPICall,
  bankDetailsAPICall,
  stageDetailsAPICall,
  NewReversePennyDropRequestAPICall,
  GetReversePennyDropStatusAPICall,
  SubmitReversePennyDropAPICall,
} from "../shared/APICalls";
import {
  IBankDetails,
  IBankPrefillData,
  IRevesePennyDropBankDetails,
} from "../shared/dtos";
import routesData from "../shared/routes.json";
import BankStyles from "../pages/Styles/BankDetails.module.scss";
import StageLayout from "../Layouts/StageLayout";
import Dropdown from "../Components/SelectDropdown/DropDown";
import icon from "../Components/assets/Images/iconnew.svg";
import eyeIcon1 from "../Components/assets/Images/eye-slash.svg";
import eyeIcon2 from "../Components/assets/Images/eyeClose.svg";
import upiImage from "../Components/assets/Images/upi-ar21 1.svg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import manualImage from "../Components/assets/Images/wallet-check-svgrepo-com (1) 1.svg";
import Box from "@mui/material/Box";
import SnackBar from "../Components/SnackBars/SnackBar";
import BackDrop from "../Components/BackDrop";
import BankSnackBar from "../Components/SnackBars/BankSnackBar";
import StartOverBackDrop from "../Components/StartOverBackDrop";
import ErrorSnackBar from "../Components/SnackBars/ErrorSnackBar";
import JsonStyles from "../Components/JsonDataToAadhaarFormat/JsonData.module.scss";
import SucessSnackBar from "../Components/SnackBars/SucesssnackBar";
import StageLayoutEkyc from "../Layouts/StageLayoutEkyc";
import GpayIcon from "../Components/assets/Images/gpayicon.svg";
import PhonepeIcon from "../Components/assets/Images/phonepayicon.svg";
import PaytmIcon from "../Components/assets/Images/paytmicon.svg";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const schema = Yup.object().shape({
  bankAccountNumber: Yup.string()
    .required("Account Number is Required")
    .min(6)
    .max(18)
    .matches(/^[a-zA-Z0-9 ]*$/, "Enter Valid Account Number")
    .transform((value: any) => value && value.toUpperCase()),

  ConfirmBankAccountNumber: Yup.string()
    .required("Confirm Account Number is Required")
    .min(6)
    .max(18)
    .matches(/^[a-zA-Z0-9 ]*$/, "Enter Valid Account Number")
    .transform((value: any) => value && value.toUpperCase())
    .test(
      "match",
      "Account Number and Confirm Account Number should match",
      function (value) {
        return value === this.parent.bankAccountNumber;
      }
    ),

  ifscNumber: Yup.string()
    .required("IFSC is Required")
    .max(11)
    .matches(/^[a-zA-Z0-9 ]*$/, "Enter Valid IFSC")
    .transform((value: any) => value && value.toUpperCase()),

  // micrNumber: Yup.string()
  //   .required("MICR is Required")
  //   .max(11)
  //   .matches(/^[a-zA-Z0-9 ]*$/, "Enter Valid MICR")
  //   .transform((value: any) => value && value.toUpperCase()),

  customerNameAsperBank: Yup.string()
    .required("Name is Required")
    .max(50)
    // .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
    .transform((value: any) => value && value.toUpperCase()),

  // accountType: Yup.object().required("Please select an option"),
});
const accountItems = [
  {
    id: "Current",
    sequence: 1,
    value: "Current",
  },
  {
    id: "Savings",
    sequence: 2,
    value: "Savings",
  },
];

export default function LinkUsingIFSC() {
  // const [accountType, setaccountType] = React.useState("");
  const pathName = useLocation().pathname;
  const navigate = useNavigate();
  const [showAccountNumber, setShowAccountNumber] = React.useState(false);
  const [loading, setloading] = useState(false);
  const [prefilloading, setprefilloading] = useState(true);
  const [startOverloading, setstartOverloading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [editUpdates, setEditUpdates] = useState<any>(false);
  const [value, setValue] = React.useState(0);
  const [completedStages, setCompletedStages] = useState<any>();
  const [upiID, setUpiId] = useState("");
  const [ifscData, setIFSCData] = useState<any>({
    Bank: "",
    Address: "",
  });

  const [prefillData, setprefillData] = useState<IBankPrefillData>({
    bankAccountNumber: "",
    ConfirmBankAccountNumber: "",
    ifscNumber: "",
    customerNameAsperBank: "",
    // micrNumber: "",
  });
  const [rejectMessage, setRejectMessage] = useState("");

  const [editcustomerName, setEditCustomer] = useState(true);
  const [bgColor1, setbgcolor1] = useState("#CCE1E4");
  const [bgColor2, setbgcolor2] = useState("white");
  const [selectedValue, setSelectedValue] = useState("a");
  const [displayManualEnter, setDisplayManualEnter] = useState(false);
  const [upiMode, setUPIMode] = useState(false);
  const [initialOption, setInitialOption] = useState(true);
  const [backdrop, setBackDrop] = useState(false);
  const [circularloader, setcircularLoader] = useState(false);
  const [reversePennyDropStauts, setreversePennyDropStauts] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [getStatus, setGetStatus] = useState(false);
  const [agentPlatform, setAgentPlatform] = useState("");
  const [micrNumber, setMicrNumber] = useState("");

  const [setuDetails, setSetuDetails] = useState({
    id: "",
    shortUrl: "",
    upiLink: "",
  });

  const [bankDetailsFetched, setBankDetailsFetched] = useState<any>({
    accountType: "",
    bankAccountIfsc: "",
    bankAccountName: "",
    bankAccountNumber: "",
    payerVpa: "",
    ifscDetails: { address: "", branch: "", micr: "", name: "" },
  });

  const [fetchedbankDetails, setfetchedbankDetails] = useState({
    bankAddress: "",
    bankName: "",
    branchName: "",
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    // console.log(newValue)
  };

  const navigationData = routesData;
  const handleClickShowAccountNumber = () =>
    setShowAccountNumber((show) => !show);

  const handleMouseDownAccountNumber = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const beforeUnloadRef: any = useRef();
  useEffect(() => {
    if (value === 1) {
      beforeUnloadRef.current = (event) => {
        const confirmationMessage = "Saved Data Will be Lost?";
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      };
      window.addEventListener("beforeunload", beforeUnloadRef.current);
    }

    return () => {
      if (value === 1) {
        window.removeEventListener("beforeunload", beforeUnloadRef.current);
      }
    };
  }, [value]);

  const handleMobilePaymentLink = () => {
    setTimeout(() => {
      if (value === 1) {
        window.removeEventListener("beforeunload", beforeUnloadRef.current);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    checkUserLoggedIn(navigate);
    setprefilloading(true);
    let obj: any = {
      stage: "bank_account",
    };

    stageDetailsAPICall(obj)
      .then((response: any) => {
        setprefilloading(false);
        const responseData = response.data;
        setCompletedStages(responseData.completed_stages);

        if (responseData.data.StageDetails.BankAccountNumber) {
          setInitialOption(false);
          setDisplayManualEnter(true);
          setUPIMode(false);
        }
        if (responseData.data.StageDetails.BeneficiaryName) {
          setEditCustomer(false);
        }
        setfetchedbankDetails({
          bankAddress: responseData.data.StageDetails.BankAddress,
          bankName: responseData.data.StageDetails.BankName,
          branchName: responseData.data.StageDetails.BranchName,
        });
        setIFSCData({
          Bank: responseData.data.StageDetails.BankName,
          Address: responseData.data.StageDetails.BranchName,
        });
        setRejectMessage(response.data.data.StageDetails.ErrorMessage);
        setprefillData((prev) => {
          return {
            ...prev,
            bankAccountNumber: responseData.data.StageDetails.BankAccountNumber,
            ConfirmBankAccountNumber:
              responseData.data.StageDetails.BankAccountNumber,
            ifscNumber: responseData.data.StageDetails.IFSC,
            customerNameAsperBank:
              responseData.data.StageDetails.CustomerName !== ""
                ? responseData.data.StageDetails.CustomerName
                : responseData.data.StageDetails.BeneficiaryName,

            // micrNumber: responseData.data.StageDetails.Micr,
          };
        });
        if (responseData.data.StageDetails.Micr) {
          setMicrNumber(responseData.data.StageDetails.Micr);
        }

        setBankDetailsFetched((prev) => {
          return {
            ...prev,
            bankAccountNumber: responseData.data.StageDetails.BankAccountNumber,
            ConfirmBankAccountNumber:
              responseData.data.StageDetails.BankAccountNumber,
            bankAccountIfsc: responseData.data.StageDetails.IFSC,
            bankAccountName: responseData.data.StageDetails.BeneficiaryName,
            accountType: "Savings",
            payerVpa: responseData.data.StageDetails.UpiId,
            ifscDetails: {
              name: responseData.data.StageDetails.BankName,
              address: responseData.data.StageDetails.BankAddress,
              micr: responseData.data.StageDetails.Micr,
              branch: responseData.data.StageDetails.BranchName,
            },
          };
        });
        if (responseData.data.StageDetails.UpiId !== "") {
          setValue(1);
          setInitialOption(false);
          setDisplayManualEnter(false);
          setUPIMode(true);
          setUpiId(responseData.data.StageDetails.UpiId);
        }

        setEditUpdates(true);
      })
      .catch((error: any) => {
        // console.log("error", error);
        setprefilloading(false);
      });

    NewReversePennyDropRequestAPICall({})
      .then((response: any) => {
        const responseData = response.data;
        //console.log("responseData", responseData);
        setSetuDetails({
          id: responseData.data.id,
          shortUrl: responseData.data.shortUrl,
          upiLink: responseData.data.upiLink,
        });
      })
      .catch((error: any) => {
        //  console.log("error", error);
      });

    const userAgent = navigator.userAgent.toLowerCase();

    if (/iphone|ipad|ipod/.test(userAgent)) {
      setAgentPlatform("iOS");
    } else if (/android/.test(userAgent)) {
      setAgentPlatform("Android");
    } else {
      setAgentPlatform("Other");
    }
  }, []);

  const handleUpdate = () => {
    NewReversePennyDropRequestAPICall({})
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);

        setUpiId("");
        setSetuDetails({
          id: responseData.data.id,
          shortUrl: responseData.data.shortUrl,
          upiLink: responseData.data.upiLink,
        });
        setBankDetailsFetched({
          accountType: "",
          bankAccountIfsc: "",
          bankAccountName: "",
          bankAccountNumber: "",
          ifscDetails: { address: "", branch: "", name: "", micr: "" },
        });
        setreversePennyDropStauts(true);
        setGetStatus(true);
        setInitialOption(false);
        setDisplayManualEnter(false);
        setUPIMode(true);
        // getReversePennyDropStatus();
        if (selectedValue === "a") {
          setTimeout(() => {
            setcircularLoader(true);
          }, 2000);
        } else {
          setcircularLoader(false);
        }
      })
      .catch((error: any) => {
        // console.log("error", error);
        setreversePennyDropStauts(false);
        setGetStatus(false);
      });
  };

  useEffect(() => {
    if (getStatus === true) {
      getReversePennyDropStatus();
    }
  }, [getStatus]);

  useEffect(() => {
    if (value === 1 && setuDetails.id) {
      getReversePennyDropStatus();
    }
  }, [value, setuDetails.id]);

  //  console.log(pathName);

  const getReversePennyDropStatus = () => {
    if (
      pathName === "/linkusingifsc" &&
      bankDetailsFetched.bankAccountNumber === ""
    ) {
      GetReversePennyDropStatusAPICall({ id: setuDetails.id })
        .then((response: any) => {
          const responseData = response.data;
          // console.log("responseData", response);
          if (responseData.data.data === null) {
            setTimeout(() => {
              getReversePennyDropStatus();
            }, 2000);
          } else {
            // console.log("BankDetails", responseData.data.data);
            if (responseData.data.data.ifscCrossCheck) {
              setBankDetailsFetched(responseData.data.data);
              setUpiId(responseData.data.data.payerVpa);
              setSuccessMessage("Bank Details Fetched Successfully");
              setGetStatus(false);
            } else if (!responseData.data.data.ifscCrossCheck) {
              setDisplayManualEnter(true);
              setUPIMode(false);
              setGetStatus(false);
              setErrorMessage(
                "Error Fetching Bank Details, Enter Details Manually"
              );
              setTimeout(() => {
                setErrorMessage("");
              }, 5000);
            }

            setreversePennyDropStauts(false);
            setTimeout(() => {
              setSuccessMessage("");
            }, 5000);
          }
        })
        .catch((error: any) => {});
    }
  };

  const handleContinueNext = () => {
    setloading(true);
    let obj: IRevesePennyDropBankDetails = {
      upi_id: bankDetailsFetched.payerVpa,
      account_type: "savings",
      ifsc: bankDetailsFetched.bankAccountIfsc.toUpperCase(),
      micr: bankDetailsFetched.ifscDetails.micr,
      bank_account_number: bankDetailsFetched.bankAccountNumber,
      customer_name_in_bank: bankDetailsFetched.bankAccountName.toUpperCase(),
      bank_address: bankDetailsFetched.ifscDetails.address.toUpperCase(),
      bank_name: bankDetailsFetched.ifscDetails.name.toUpperCase(),
      branch_name: bankDetailsFetched.ifscDetails.branch.toUpperCase(),
    };
    // console.log("bankDetailsFetched", bankDetailsFetched);
    SubmitReversePennyDropAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);

        // window.alert(responseData.pennyDropUpdate);
        setloading(false);
        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage]);
        }
      })
      .catch((error: any) => {
        setloading(false);

        setMessage(
          error.message.includes("Network")
            ? "Error occured, Please try again"
            : error.response.data.error.message
        );
        setTimeout(() => {
          setMessage("");
        }, 8000);
      });
  };

  const handlePaste = (event) => {
    event.preventDefault();
  };

  const handleStartOver = () => {
    if (
      window.confirm(
        "Are you sure you want to restart, on pressing Yes All the Data will be deleted"
      )
    ) {
      setBackDrop(true);
      setstartOverloading(true);
      startOverAPICall()
        .then((response: any) => {
          const responseData = response.data;
          console.log("responseData", response);
          setstartOverloading(false);
          setBackDrop(false);
          navigate("/");
          window.location.reload();
        })
        .catch((error: any) => {
          setBackDrop(false);
          setstartOverloading(false);
        });
    }
  };

  const handleChangeField = (
    name: string,
    value: any,
    callBack: any,

    values: any
  ) => {
    const ifscCode = value.toUpperCase();
    console.log(ifscCode);
    callBack(name, ifscCode);
    if (ifscCode.length === 11) {
      fetch(`https://ifsc.razorpay.com/${ifscCode}`)
        .then((response) => response.json())
        .then((data) => {
          // callBack("micrNumber", data.MICR === null ? "Not Found" : data.MICR);
          setMicrNumber(data.MICR === null ? "" : data.MICR);
          console.log("data", data);
          if (data === "Not Found") {
            setErrorMessage("Please enter Valid IFSC");
            setIFSCData({
              Bank: "",
              Address: "",
            });
          }
          if (data !== "Not Found") {
            setfetchedbankDetails({
              bankAddress: data.ADDRESS,
              bankName: data.BANK,
              branchName: data.BRANCH,
            });
            setIFSCData({
              Bank: data.BANK,
              Address: data.BRANCH,
            });
          }
          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (ifscCode.length < 11) {
      setIFSCData({
        Bank: "",
        Address: "",
      });
    }
  };

  function bankDetails(data: any) {
    setloading(true);
    let obj: IBankDetails = {
      // account_type: data.accountType.value,
      ifsc: data.ifscNumber.toUpperCase(),
      micr: micrNumber,
      bank_account_number: data.bankAccountNumber,
      customer_name_in_bank: data.customerNameAsperBank.toUpperCase(),
      bank_address: fetchedbankDetails.bankAddress.toUpperCase(),
      bank_name: fetchedbankDetails.bankName.toUpperCase(),
      branch_name: fetchedbankDetails.branchName.toUpperCase(),
    };
    bankDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;

        setloading(false);
        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          // navigate(navigationData[responseData.data.stage]);
          window.location.href = navigationData[responseData.data.stage];
        }
      })
      .catch((error: any) => {
        setloading(false);

        setMessage(error.response.data.error.message);
        setTimeout(() => {
          setMessage("");
        }, 8000);
      });
  }

  const InitialValues: IBankPrefillData = {
    bankAccountNumber: prefillData.bankAccountNumber,
    ConfirmBankAccountNumber: prefillData.ConfirmBankAccountNumber,
    ifscNumber: prefillData.ifscNumber,
    customerNameAsperBank: prefillData.customerNameAsperBank,
    // accountType: prefillData.accountType,
    // micrNumber: prefillData.micrNumber,
  };

  const handleContinuewithUPI = () => {
    setInitialOption(false);
    setDisplayManualEnter(false);
    setUPIMode(true);
    if (selectedValue === "a") {
      setTimeout(() => {
        setcircularLoader(true);
      }, 2000);
    } else {
      setcircularLoader(false);
    }
    if (setuDetails.id) {
      getReversePennyDropStatus();
    }
  };
  const handleContinueManually = () => {
    setInitialOption(false);
    setDisplayManualEnter(true);
    setUPIMode(false);
  };
  // console.log("bank", bankDetailsFetched);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleBackClick = () => {
    setDisplayManualEnter(false);
    setUPIMode(false);
  };
  const handleDoManuallyClick = () => {
    setDisplayManualEnter(false);
    setUPIMode(false);
    NewReversePennyDropRequestAPICall({})
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);

        setUpiId("");
        setSetuDetails({
          id: responseData.data.id,
          shortUrl: responseData.data.shortUrl,
          upiLink: responseData.data.upiLink,
        });
        setBankDetailsFetched({
          accountType: "",
          bankAccountIfsc: "",
          bankAccountName: "",
          bankAccountNumber: "",
          ifscDetails: { address: "", branch: "", name: "", micr: "" },
        });
        setreversePennyDropStauts(true);
      })
      .catch((error: any) => {
        // console.log("error", error);
        setreversePennyDropStauts(false);
      });
  };

  return (
    <div className={BankStyles.mainWrapperTop}>
      <StageLayoutEkyc
        completedStages={completedStages}
        stageName={"Bank Details"}
        navigatePath={"/tradingPreference"}
      />
      <ErrorSnackBar message={rejectMessage} />

      {displayManualEnter && (
        <div className={BankStyles.wrapper}>
          <div>
            <p className={BankStyles.Header}> Enter Your Bank Details </p>
          </div>

          <p className={BankStyles.subText}>
            we need these details only to verify that this bank account belongs
            to you.
          </p>
          {!prefilloading ? (
            <Formik
              validationSchema={schema}
              initialValues={InitialValues}
              onSubmit={(values: any) => {
                // console.log("values", values);
                bankDetails(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,

                setFieldValue,
              }) => {
                // console.log("errors", errors);
                return (
                  <form
                    noValidate
                    onSubmit={handleSubmit}
                    onKeyDown={(event) => {
                      event.code === "Enter" ||
                        (event.code === "NumpadEnter" && handleSubmit());
                      // event.code.includes("Enter") && handleSubmit()
                    }}
                    className={BankStyles.form}
                  >
                    <div className={BankStyles.container}>
                      <div className={BankStyles.feildSection}>
                        <TextField
                          className={BankStyles.textfeild}
                          id="outlined-basic"
                          label="Full Name of Bank Account Name"
                          variant="outlined"
                          name="customerNameAsperBank"
                          // disabled={!editcustomerName}
                          value={values.customerNameAsperBank.toUpperCase()}
                          error={
                            touched.customerNameAsperBank &&
                            Boolean(errors.customerNameAsperBank)
                          }
                          helperText={
                            touched.customerNameAsperBank &&
                            errors.customerNameAsperBank?.toString()
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputProps={{ maxLength: 50 }}
                        />
                      </div>
                      <div className={BankStyles.feildSection}>
                        <TextField
                          className={BankStyles.textfeild}
                          id="outlined-basic"
                          label="Branch's IFSC"
                          variant="outlined"
                          name="ifscNumber"
                          value={values.ifscNumber.toUpperCase()}
                          error={
                            touched.ifscNumber && Boolean(errors.ifscNumber)
                          }
                          helperText={
                            touched.ifscNumber && errors.ifscNumber?.toString()
                          }
                          onChange={(event) => {
                            handleChangeField(
                              "ifscNumber",
                              event.target.value,
                              setFieldValue,

                              values
                            );
                          }}
                          onBlur={handleBlur}
                          inputProps={{ maxLength: 11 }}
                        />

                        <SnackBar message={errorMessage} />
                        <SnackBar message={message} />
                        <BankSnackBar message={ifscData} />
                      </div>
                    </div>

                    <div className={BankStyles.container}>
                      <div className={BankStyles.feildSection}>
                        <TextField
                          className={BankStyles.textfeild}
                          id="outlined-basic"
                          label="Bank Account Number"
                          variant="outlined"
                          name="bankAccountNumber"
                          type="password"
                          value={values.bankAccountNumber.toUpperCase()}
                          error={
                            touched.bankAccountNumber &&
                            Boolean(errors.bankAccountNumber)
                          }
                          helperText={
                            touched.bankAccountNumber &&
                            errors.bankAccountNumber?.toString()
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputProps={{
                            maxLength: 18,
                          }}
                          onCopy={(e) => e.preventDefault()}
                          onPaste={(e) => e.preventDefault()}
                        />
                      </div>
                      <div className={BankStyles.feildSection}>
                        <TextField
                          className={BankStyles.textfeild}
                          id="outlined-basic"
                          label="Confirm Bank Account Number"
                          variant="outlined"
                          name="ConfirmBankAccountNumber"
                          value={values.ConfirmBankAccountNumber.toUpperCase()}
                          error={
                            touched.ConfirmBankAccountNumber &&
                            Boolean(errors.ConfirmBankAccountNumber)
                          }
                          helperText={
                            touched.ConfirmBankAccountNumber &&
                            errors.ConfirmBankAccountNumber?.toString()
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // onPaste={handlePaste}
                          inputProps={{ maxLength: 18 }}
                          type={showAccountNumber ? "password" : "text"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle account number visibility"
                                  onClick={handleClickShowAccountNumber}
                                  onMouseDown={handleMouseDownAccountNumber}
                                  edge="end"
                                >
                                  {showAccountNumber ? (
                                    <img src={eyeIcon1} width={"20px"} />
                                  ) : (
                                    <img src={eyeIcon2} width={"20px"} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onCopy={(e) => e.preventDefault()}
                          onPaste={(e) => e.preventDefault()}
                        />

                        <SnackBar message={message} />
                      </div>
                    </div>

                    <div className={BankStyles.buttonStack}>
                      <div className={BankStyles.buttonBackandContinueSection}>
                        <div>
                          <Button
                            className={BankStyles.buttonBack}
                            variant="outlined"
                            sx={{ color: "#006779" }}
                            onClick={handleBackClick}
                          >
                            Back
                          </Button>
                        </div>
                        <div>
                          {values.bankAccountNumber &&
                          values.ConfirmBankAccountNumber &&
                          values.customerNameAsperBank &&
                          values.ifscNumber &&
                          ifscData.Bank !== "" &&
                          !errors.ConfirmBankAccountNumber &&
                          !errors.ifscNumber &&
                          !errors.bankAccountNumber ? (
                            // values.micrNumber
                            <LoadingButton
                              loading={loading}
                              variant="contained"
                              type="submit"
                              id="linkUsingIFSCContinueButton"
                              className={BankStyles.approveButton}
                            >
                              Continue
                            </LoadingButton>
                          ) : (
                            <Button
                              variant="contained"
                              disabled
                              className={BankStyles.approveButton}
                            >
                              Continue
                            </Button>
                          )}
                        </div>
                      </div>
                      {backdrop && <StartOverBackDrop />}
                      <div className={BankStyles.startOverSection}>
                        <img src={icon} className={BankStyles.iconImage} />
                        <p
                          className={BankStyles.paratext}
                          style={{ fontSize: "13px" }}
                        >
                          {" "}
                          if the above mentioned info not yours
                          <span
                            className={BankStyles.startoverbutton}
                            onClick={handleStartOver}
                          >
                            Start Over
                          </span>
                        </p>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          ) : (
            <div className={BankStyles.backdropStyles}>
              <BackDrop />
            </div>
          )}
        </div>
      )}

      {upiMode && (
        <div className={BankStyles.wrapper} style={{ marginBottom: "30px" }}>
          <p className={BankStyles.subText}>
            Debited Amount Rs.1 will be credited back in 2 working days
          </p>
          <SucessSnackBar message={successMessage} />
          {bankDetailsFetched.bankAccountNumber === "" && upiID === "" ? (
            <>
              {!circularloader && (
                <Stack
                  height={"60%"}
                  justifyContent={"center"}
                  display={{ xs: "none", sm: "flex" }}
                >
                  <CircularProgress />
                </Stack>
              )}

              <iframe
                src={setuDetails.shortUrl}
                width={"65%"}
                height={"80%"}
                style={{
                  border: "none",
                  visibility: !circularloader ? "hidden" : "visible",
                }}
                className={BankStyles.desktopIframe}
              />
              <div className={BankStyles.buttonBackStyles}>
                <Button
                  className={BankStyles.buttonBackStyles}
                  variant="contained"
                  onClick={handleBackClick}
                >
                  Back
                </Button>
              </div>
              <div className={BankStyles.mobileUpiLink}>
                {agentPlatform === "iOS" ? (
                  <>
                    <div className={BankStyles.iosPaymentUI}>
                      <p className={BankStyles.selectText}>Select an app to</p>
                      <p className={BankStyles.amountText}>
                        Pay <span style={{ fontWeight: 600 }}>Rs.1</span>
                      </p>
                      <div className={BankStyles.paymentsOptionsSction}>
                        <a
                          href={setuDetails.upiLink?.replace(
                            "upi://pay",
                            "phonepe://pay"
                          )}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <div className={BankStyles.paymentOptions}>
                            <img src={PhonepeIcon} />
                            <p>PhonePe</p>
                          </div>
                        </a>
                        <a
                          href={setuDetails.upiLink?.replace(
                            "upi://pay",
                            "gpay://upi/pay"
                          )}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <div className={BankStyles.paymentOptions}>
                            <img src={GpayIcon} />
                            <p>Google Pay</p>
                          </div>
                        </a>
                        <a
                          href={setuDetails.upiLink?.replace(
                            "upi://pay",
                            "paytmmp://pay"
                          )}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <div className={BankStyles.paymentOptions}>
                            <img src={PaytmIcon} />
                            <p>PayTM</p>
                          </div>
                        </a>
                      </div>
                    </div>

                    {/* <a
                      href={setuDetails.shortUrl}
                      className={BankStyles.upiLink}
                    >
                      {" "}
                      <Button variant="contained" sx={{ width: "250px" }}>
                        Pay Rs.1
                      </Button>
                    </a> */}
                  </>
                ) : (
                  <a href={setuDetails.upiLink} className={BankStyles.upiLink}>
                    <Button
                      variant="contained"
                      sx={{ width: "250px" }}
                      onClick={handleMobilePaymentLink}
                    >
                      Pay Rs.1
                    </Button>
                  </a>
                )}

                <Button
                  className={BankStyles.buttonBackStyles}
                  variant="outlined"
                  sx={{ color: "#006779" }}
                  onClick={handleBackClick}
                >
                  Back
                </Button>
              </div>
            </>
          ) : (
            <Stack
              alignItems={"center"}
              height={"max-content"}
              className={BankStyles.displayFetchedData}
            >
              <p className={JsonStyles.header} style={{ marginBottom: "20px" }}>
                Fetched bank Details
              </p>

              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 5 }}
              >
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    // focused
                    id="outlined-basic"
                    label="Name as Per Bank"
                    variant="outlined"
                    value={bankDetailsFetched.bankAccountName}
                    sx={{
                      input: {
                        color: "#344054",
                        fontSize: "14px",
                        "& .MuiOutlinedInput-input:focused": {
                          borderColor: "grey",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    // focused
                    id="outlined-basic"
                    label="IFSC Code"
                    variant="outlined"
                    value={bankDetailsFetched.bankAccountIfsc}
                    sx={{ input: { color: "#344054", fontSize: "14px" } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    // focused
                    id="outlined-basic"
                    label="Bank Account Number"
                    variant="outlined"
                    value={bankDetailsFetched.bankAccountNumber}
                    sx={{ input: { color: "#344054", fontSize: "14px" } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    // focused
                    id="outlined-basic"
                    label="Bank Name"
                    variant="outlined"
                    value={bankDetailsFetched.ifscDetails.name}
                    sx={{ input: { color: "#344054", fontSize: "14px" } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    // focused
                    id="outlined-basic"
                    label="UPI ID"
                    variant="outlined"
                    value={bankDetailsFetched.payerVpa}
                    sx={{ input: { color: "#344054", fontSize: "12px" } }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    // focused
                    id="outlined-basic"
                    label="Bank Address"
                    variant="outlined"
                    value={bankDetailsFetched.ifscDetails.address}
                    sx={{ input: { color: "#344054", fontSize: "12px" } }}
                  />
                </Grid>
              </Grid>
              <div className={BankStyles.displayFetchedButton}>
                <div>
                  <LoadingButton
                    variant="outlined"
                    sx={{ color: "#006779" }}
                    className={BankStyles.displayFetchedButtonRedo}
                    type="submit"
                    id="linkUsingIFSCContinueButton"
                    onClick={handleUpdate}
                  >
                    Redo
                  </LoadingButton>
                </div>
                <div className={BankStyles.displayFetchedButtonContinue}>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    type="submit"
                    id="linkUsingIFSCContinueButton"
                    className={BankStyles.displayFetchedButtonContinue}
                    onClick={handleContinueNext}
                  >
                    Continue
                  </LoadingButton>
                </div>
              </div>
              <div style={{ marginTop: "30px" }}>
                <Button
                  className={BankStyles.buttonBackStyles}
                  variant="outlined"
                  sx={{ color: "#006779" }}
                  onClick={handleDoManuallyClick}
                >
                  Change Mode
                </Button>
              </div>
              {backdrop && <StartOverBackDrop />}
              <div className={BankStyles.startOverSection}>
                <img src={icon} className={BankStyles.iconImage} />
                <p className={BankStyles.paratext} style={{ fontSize: "13px" }}>
                  {" "}
                  if the above mentioned info not yours
                  <span
                    className={BankStyles.startoverbutton}
                    onClick={handleStartOver}
                  >
                    Start Over
                  </span>
                </p>
              </div>
            </Stack>
          )}
        </div>
      )}

      {!displayManualEnter && !upiMode && (
        <div className={BankStyles.mainWrapper}>
          <p className={BankStyles.Header}> Link Your Bank Account </p>
          <p className={BankStyles.subText}>
            Add Savings/Current account to safely transfer funds for our
            partner-referred clients by incorporating
          </p>
          <div className={BankStyles.mainConatiner}>
            <p className={BankStyles.textcontent}>
              Verify with UPI or add manually
            </p>
            <div
              className={BankStyles.subContainer}
              onClick={() => {
                const radio = document.getElementById("radio-a");
                if (radio) {
                  radio.click();
                  setbgcolor1("#CCE1E4");
                  setbgcolor2("white");
                }
              }}
              style={{ background: bgColor1 }}
            >
              <div className={BankStyles.contentConatiner}>
                <div className={BankStyles.subContentContainer}>
                  <div className={BankStyles.aligndiv}>
                    <div className={BankStyles.imagestyles}>
                      <img src={upiImage} />
                    </div>
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p className={BankStyles.text}> Verify with UPI</p>
                        <Chip
                          label="Faster"
                          variant="outlined"
                          sx={{
                            height: "25px",
                            border: "2px solid #006779",
                            fontSize: "12px",
                            fontWeight: 400,
                            width: "65px",
                            // color: "white",
                          }}
                        />
                      </div>
                      <p className={BankStyles.para}>
                        We'll debit Rs.1 from your account to verify the
                        details,we'll refund this once the verification is
                        completed
                      </p>
                    </div>

                    {/* <img src={upiImage} className={BankStyles.image} /> */}
                  </div>
                </div>
                <div className={BankStyles.radioSection}>
                  <div>
                    <Radio
                      id="radio-a"
                      checked={selectedValue === "a"}
                      onChange={(event) => {
                        handleChange(event);
                        // console.log(event.target.value);
                      }}
                      value="a"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={BankStyles.subContainer}
              onClick={() => {
                const radio = document.getElementById("radio-b");
                if (radio) {
                  radio.click();
                  setbgcolor2("#CCE1E4");
                  setbgcolor1("white");
                }
              }}
              style={{ marginTop: "20px", background: bgColor2 }}
            >
              <div className={BankStyles.contentConatiner}>
                <div className={BankStyles.subContentContainer}>
                  <div className={BankStyles.aligndiv}>
                    <div className={BankStyles.imagestyles}>
                      <img src={manualImage} />
                    </div>
                    <div>
                      <p className={BankStyles.text}> Add details manually</p>
                      <p className={BankStyles.para}>
                        Add your account number, IFSC manually
                      </p>
                    </div>
                  </div>
                </div>
                <div className={BankStyles.radioSection}>
                  <div>
                    <Radio
                      id="radio-b"
                      checked={selectedValue === "b"}
                      onChange={(event) => {
                        handleChange(event);
                        //  console.log(event.target.value);
                      }}
                      value="b"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "B" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={BankStyles.buttonStack}>
            <div>
              {selectedValue === "a" ? (
                <LoadingButton
                  variant="contained"
                  type="submit"
                  id="linkUsingIFSCContinueButton"
                  className={BankStyles.approveButton}
                  onClick={handleContinuewithUPI}
                >
                  Continue securely with UPI
                </LoadingButton>
              ) : (
                <LoadingButton
                  variant="contained"
                  type="submit"
                  id="linkUsingIFSCContinueButton"
                  className={BankStyles.approveButton}
                  onClick={handleContinueManually}
                >
                  Add details manually
                </LoadingButton>
              )}
            </div>
            <div className={BankStyles.startOverSection}>
              <img src={icon} className={BankStyles.iconImage} />
              <p className={BankStyles.paratext} style={{ fontSize: "13px" }}>
                {" "}
                if the above mentioned info not yours
                <span
                  className={BankStyles.startoverbutton}
                  onClick={handleStartOver}
                >
                  Start Over
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
