import React from "react";

import KRAStyles from "../../pages/Styles/KraFetched.module.scss";
import profile from "../../Components/assets/Images/cnameimage.svg";
import profile2 from "../../Components/assets/Images/cgenderimage.svg";
import calender from "../../Components/assets/Images/cdobimage.svg";

import { Backdrop, CircularProgress } from "@mui/material";

interface Props {
  data: any;
}

export default function FetchedDetails({ data }: Props) {
  //   console.log("data", data);
  return (
    <>
      {data.FullName ? (
        <div className={KRAStyles.wrapper}>
          <p
            style={{
              fontSize: "16px",
              margin: "5px 0",
              fontWeight: 600,
              textAlign: "center",
              color: "#16192C",
            }}
          >
            Details
          </p>

          <>
            <div>
              <p className={KRAStyles.nameDetails}>
                <img src={profile2} style={{ marginRight: "5px" }} />

                <span className={KRAStyles.nameDetailsText}>
                  {data.FullName}
                </span>
              </p>

              <p className={KRAStyles.nameDetails}>
                <img src={profile} style={{ marginRight: "5px" }} />

                <span className={KRAStyles.nameDetailsText}>{data.Email}</span>
              </p>
            </div>
            <div>
              <p className={KRAStyles.nameDetails}>
                <img src={profile} style={{ marginRight: "5px" }} />

                <span className={KRAStyles.nameDetailsText}>{data.PanNo}</span>
              </p>
            </div>
            <div>
              <p className={KRAStyles.nameDetails}>
                <img src={calender} style={{ marginRight: "5px" }} />

                <span className={KRAStyles.nameDetailsText}>{data.Mobile}</span>
              </p>
            </div>

            {/* <p className={KRAStyles.Address}>
            <img src={location} style={{ marginRight: "5px" }} />
            <span className={KRAStyles.nameDetailsText}>
              {" "}
              {data.Address.length > 40
                ? data.Address.slice(0, 50)
                : data.Address}
              <br />
              {data.Address.slice(50, 80)}
              <br />
              {data.Address.slice(80, 110)}
              <br />
              {data.Address.slice(110, 150)}
            </span>
          </p> */}
          </>
        </div>
      ) : (
        <div className={KRAStyles.wrapper} style={{ height: "350px" }}>
          <p
            style={{
              fontSize: "16px",
              margin: "5px 0",
              fontWeight: 600,
              textAlign: "center",
              color: "#16192C",
            }}
          >
            Details
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "250px",
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        </div>
      )}
    </>
  );
}
