import { DOMIAN } from "../../shared/constants";

// Set a cookie
export function setCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}
// Get a cookie
export function getCookie(name) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return "";
}
export const removeAllCookies = () => {
  const cookies = document.cookie;
  if (cookies.length) {
    const cookiePairs = cookies.split(";");

    // Initialize variables for storing the extracted values

    // Loop through the cookie pairs and extract values
    for (const pair of cookiePairs) {
      const splittedData = pair.trim().split("=");
      let key = "";

      if (splittedData.length === 2) {
        key = splittedData[0];
      } else if (splittedData.length > 2) {
        key = splittedData[0];
        let temp: any = splittedData.slice(1);
        temp = temp.join("=");
      }
      if (key !== "deviceId") {
        // Retaining DeviceId even after Logout
        document.cookie = `${key}=; path=/; domain=.${DOMIAN}; max-age=0`;
      }
    }
  }
};
