import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import {
  Chip,
  Fab,
  FormControl,
  InputLabel,
  Select,
  Stack,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import EmailIcon from "@mui/icons-material/Email";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SucessSnackBar from "../Components/SnackBars/SucesssnackBar";
import SnackBar from "../Components/SnackBars/SnackBar";
import logo from "../Components/assets/Images/CompanyLogo.svg";
import FirtsockLogo from "../Components/assets/Images/Firstocklogo.jpg";
import logouticon from "../Components/assets/Images/logouticon.svg";
import ChatIcon from "@mui/icons-material/Chat";
import { TOKENNAME } from "../shared/constants";
import { getCookie } from "../shared/cookies";
import { removeAllCookies } from "./Finupdate/cookies";

function Header() {
  const cookieData = getCookie(TOKENNAME);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [selectedoption, setSelectedOption] = React.useState<any>();
  const [displaySelectedOPtion, setDisplaySelectedoption] = useState<any>();
  const [styles, setStyles] = useState({
    borderRadius: "10px",
    background: "#F5F9FF",
    color: "",
    height: "35px",
  });
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const pathName = useSelector((state: any) => state.PATHNAME.pathname);
  const [step, setStep] = useState<any>(0);
  const [stageDetailsApi, setStageDetailsApi] = useState<any>(0);
  const [showSteps, setshowSteps] = useState(false);
  const pathToStepMap = {
    "/": -4,
    "/confirm_otp": -3,
    "/yourdetails": -2,
    "/confirmemailotp": -1,
    "/panverification": 1,
    "/aadhaarkyc": 2,
    "/tradingPreference": 3,
    "/yourprofile": 4,
    "/linkusingifsc": 5,
    "/cameramodule": 6,
    "/uploaddocuments": 7,
    "/nomineepage": 8,
    "/termsandconditions": 9,
    "/esign": 10,
    "/congratulations": 11,
  };
  useEffect(() => {
    Object.keys(pathToStepMap).forEach((path) => {
      if (pathName.includes(path)) {
        //  setStep(pathToStepMap[path]);
        setStageDetailsApi(pathToStepMap[path]);
        //console.log(pathToStepMap[path]);
      }
    });
  }, [pathName]);

  useEffect(() => {
    if (stageDetailsApi > -1) {
      setshowSteps(true);
    }
  }, [step]);

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
    // console.log(event.target.value);

    return <SnackBar message={"hello"} />;
  };

  const handleLogout = () => {
    if (window.confirm("You will be logged out")) {
      sessionStorage.clear();
      if (cookieData) {
        removeAllCookies();
        window.location.href = "/account_closure";
      } else {
        window.location.href = "/";
      }
    }
  };

  const handleMouseEnter = () => {
    setIsMenuOpen(true);
    setStyles({
      borderRadius: "10px",
      background: "black",
      color: "white",
      height: "35px",
    });
  };

  const handleMouseLeave = () => {
    setIsMenuOpen(false);
    setStyles({
      borderRadius: "10px",
      background: "#F5F9FF",
      color: "black",
      height: "35px",
    });
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
    setStyles({
      borderRadius: "10px",
      background: "#F5F9FF",
      color: "black",
      height: "35px",
    });
  };

  const handleCopy = () => {
    setIsMenuOpen(false);
    setStyles({
      borderRadius: "10px",
      background: "#F5F9FF",
      color: "black",
      height: "35px",
    });
    setShowSnackBar(true);
    setSnackBarMessage("Details Copied");
    setTimeout(() => {
      setSnackBarMessage("");
    }, 5000);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const phoneNumber =
    pathName.includes("/completed") || pathName.includes("account_closure")
      ? " 080 4670 2050"
      : "080 4670 2020";
  const handleWhatsappClick = () => {
    setAnchorEl(null);
    const phoneNumberNew = pathName.includes("/completed")
      ? " +91-9900199801"
      : "+91-9900199801";
    const message = "Hello, I wanted to connect with you!";
    const url = `https://api.whatsapp.com/send?phone=${encodeURIComponent(
      phoneNumberNew
    )}&text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  const handleDialpadClick = () => {
    setAnchorEl(null);
    const dialpadUrl = `tel:${phoneNumber}`;
    window.location.href = dialpadUrl;
  };
  const handleEmailClick = () => {
    const emailAddress =
      pathName.includes("/completed") || pathName.includes("account_closure")
        ? "support@thefirstock.com"
        : "sales@thefirstock.com";
    const subject = "hello";

    const emailUrl = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}`;
    window.location.href = emailUrl;
    setAnchorEl(null);
  };
  return (
    <AppBar
      position={pathName === "/home" ? "absolute" : "relative"}
      sx={{
        backgroundColor: pathName === "/home" ? "white" : "white",
        boxShadow: "none",
        opacity: pathName === "/home" ? "70%" : "100%",
        borderRadius: { xs: "0px 0px 20px 20px", md: "0px" },
        borderBottom: "1px solid #E5EFFF",
      }}
    >
      {showSnackBar && <SucessSnackBar message={snackBarMessage} />}
      <Container
        maxWidth="xl"
        sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Stack sx={{ display: { xs: "none", md: "flex" } }}>
            <div
              style={{ display: "flex", alignItems: "baseline", gap: "5px" }}
            >
              {" "}
              <img
                src={FirtsockLogo}
                style={{ zIndex: 100, cursor: "pointer", width: "50px" }}
                onClick={() => {}}
              />{" "}
              <img
                src={logo}
                style={{ zIndex: 100, cursor: "pointer" }}
                onClick={() => {}}
              />{" "}
            </div>
          </Stack>

          <Typography
            variant="h5"
            noWrap
            component="a"
            // href=""
            sx={{
              mr: 0,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "black",
              textDecoration: "none",
            }}
          >
            <img
              src={FirtsockLogo}
              style={{ zIndex: 100, cursor: "pointer", width: "30px" }}
              onClick={() => {}}
            />{" "}
            <img
              src={logo}
              style={{ width: "100px", cursor: "pointer" }}
              onClick={() => {}}
            />
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: 130, display: { xs: "none", md: "flex" } }}>
              <FormControl
                fullWidth
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={setSelectedOption}
                  size="small"
                  open={isMenuOpen}
                  sx={styles}
                  onClose={handleMenuClose}
                  onChange={handleChange}
                  IconComponent={() => null}
                  renderValue={(value) => (
                    <div
                      style={{
                        textAlign: "center",
                        marginLeft: "2px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {" "}
                      Need any Help?
                    </div>
                  )}
                >
                  <CopyToClipboard text={"+91-9900199801"} onCopy={handleCopy}>
                    <MenuItem
                      value={"+91-9900199801"}
                      sx={{ background: "#ECFDF3", fontSize: "14px" }}
                    >
                      <WhatsAppIcon sx={{ marginRight: "3px" }} />
                      +91-9900199801
                    </MenuItem>
                  </CopyToClipboard>
                  <CopyToClipboard
                    text={
                      pathName.includes("/completed")
                        ? " 080 4670 2050"
                        : "080 4670 2020"
                    }
                    onCopy={handleCopy}
                  >
                    <MenuItem
                      value={
                        pathName.includes("/completed")
                          ? " 080 4670 2050"
                          : "080 4670 2020"
                      }
                      sx={{ background: "#ECFDF3", fontSize: "14px" }}
                    >
                      <PhoneInTalkIcon sx={{ marginRight: "3px" }} />
                      {pathName.includes("/completed")
                        ? " 080 4670 2050"
                        : "080 4670 2020"}
                    </MenuItem>
                  </CopyToClipboard>
                  <CopyToClipboard
                    text={
                      pathName.includes("/completed")
                        ? " support@thefirstock.com"
                        : "sales@thefirstock.com"
                    }
                    onCopy={handleCopy}
                  >
                    <MenuItem
                      value={
                        pathName.includes("/completed")
                          ? " support@thefirstock.com"
                          : "sales@thefirstock.com"
                      }
                      sx={{ background: "#ECFDF3", fontSize: "14px" }}
                    >
                      <MailOutlineIcon sx={{ marginRight: "3px" }} />
                      {pathName.includes("/completed")
                        ? " support@thefirstock.com"
                        : "sales@thefirstock.com"}
                    </MenuItem>
                  </CopyToClipboard>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Fab
                  color="primary"
                  aria-label="add"
                  onClick={handleActionClick}
                  // size="small"
                  sx={{ height: "35px", width: "35px" }}
                >
                  {anchorEl ? (
                    <ClearOutlinedIcon sx={{ color: "white" }} />
                  ) : (
                    <ChatIcon sx={{ color: "white" }} />
                  )}
                </Fab>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    style: {
                      backgroundColor: "transparent", // Set the background color to transparent
                      boxShadow: "none", // Remove the box shadow
                    },
                  }}
                  sx={{ width: "100px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      // bottom: "12%",
                      // right: "4%",
                    }}
                  >
                    <Fab
                      size="small"
                      onClick={handleWhatsappClick}
                      sx={{
                        height: "35px",
                        width: "35px",
                        marginTop: "10px",
                        boxShadow: "none",
                        background: "#054F31",
                      }}
                    >
                      <WhatsAppIcon style={{ color: "white" }} />
                      {/* </WhatsappShareButton> */}
                    </Fab>
                    <Fab
                      sx={{
                        height: "35px",
                        width: "35px",
                        marginTop: "10px",
                        boxShadow: "none",
                        background: "#054F31",
                      }}
                      size="small"
                      onClick={handleDialpadClick}
                    >
                      {/* <img src={twitterImage} /> */}
                      <PhoneInTalkOutlinedIcon
                        style={{ color: "white" }}
                        //style={{ marginRight: "10px" }}
                      />
                    </Fab>
                    <Fab
                      size="small"
                      onClick={handleEmailClick}
                      sx={{
                        height: "35px",
                        width: "35px",
                        marginTop: "10px",
                        boxShadow: "none",
                        background: "#054F31",
                      }}
                    >
                      <EmailIcon style={{ color: "white" }} />
                    </Fab>
                  </div>
                </Menu>
              </div>
            </Box>

            {(showSteps && pathName === "/account_closure/cmr_upload") ||
              (pathName === "/account_closure/esign" &&
                pathName !== "/callback/accountclosure/esign/*" && (
                  <>
                    {(stageDetailsApi > 0 &&
                      stageDetailsApi <= 10 &&
                      pathName === "/account_closure/cmr_upload") ||
                      (pathName === "/account_closure/esign" &&
                        pathName !== "/callback/accountclosure/esign/*" && (
                          <Button
                            onClick={handleLogout}
                            variant="outlined"
                            endIcon={<img src={logouticon} />}
                            sx={{
                              height: "33px",
                              marginLeft: "15px",
                              color: "#00616A",
                              borderRadius: "100px",
                            }}
                          >
                            Logout
                          </Button>
                        ))}
                  </>
                ))}
            {pathName === "/account_closure/cmr_upload" && (
              <>
                <Button
                  onClick={handleLogout}
                  variant="outlined"
                  endIcon={<img src={logouticon} />}
                  sx={{
                    height: "33px",
                    marginLeft: "15px",
                    color: "#00616A",
                    borderRadius: "100px",
                  }}
                >
                  Logout
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
