import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  Divider,
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  FormHelperText,
  Grid,
  Chip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { checkUserLoggedIn } from "../shared/commonfunctions";
import SelectDropdown from "../Components/SelectDropdown/SelectDropdown";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./YourProfile.css";
import {
  fetchGetDropdownValuesAPICall,
  yourProfileDetailsAPICall,
  startOverAPICall,
  stageDetailsAPICall,
} from "../shared/APICalls";
import { IYourProfileDetails, IYourProfilePrefillData } from "../shared/dtos";
import routesData from "../shared/routes.json";
import ProfileStyles from "../pages/Styles/YourProfile.module.scss";
import KRAFetchedDetails from "./KRAFetchedDetails";
import StageLayout from "../Layouts/StageLayout";
import icon from "../Components/assets/Images/iconnew.svg";
import jwt_decode from "jwt-decode";
import BackDrop from "../Components/BackDrop";
import Dropdown from "../Components/SelectDropdown/DropDown";
import StartOverBackDrop from "../Components/StartOverBackDrop";
import ErrorSnackBar from "../Components/SnackBars/ErrorSnackBar";
import StageLayoutEkyc from "../Layouts/StageLayoutEkyc";

const schema = Yup.object().shape({
  fatherFullName: Yup.string()
    .required("Father First Name is Required")
    .max(50)
    .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
    .transform((value: any) => value && value.toUpperCase()),

  // fatherLastName: Yup.string()
  //   .required("Father LastName is Required")
  //   .max(50)
  //   .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
  //   .transform((value: any) => value && value.toUpperCase()),
  motherFullName: Yup.string()
    .required("Mother First Name is Required")
    .max(50)
    .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
    .transform((value: any) => value && value.toUpperCase()),
  // motherLastName: Yup.string()
  //   .required("Mother LastName is Required")
  //   .max(50)
  //   .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
  //   .transform((value: any) => value && value.toUpperCase()),
  annualIncome: Yup.object().required("Please select an option"),
  tradingExperience: Yup.object().required("Please select an option"),
  occupation: Yup.object().required("Please select an option"),
  occupationIfOthers: Yup.string().when("occupation", {
    is: "Others",

    then(schema) {
      return schema.required("Please Enter Occupation Others");
    },
  }),
  // occupationIfOthers: Yup.string().when(
  //   "occupation",
  //   (occupation: any, schema: any) => {
  //     // console.log("occupation", occupation);
  //     if (occupation.length && occupation.value === "Others") {
  //       return schema
  //         .required("Occupation is Required")

  //         .transform((value: any) => value && value.toUpperCase());
  //     }

  //     return schema;
  //   }
  // ),

  areYouPoliticallyExposed: Yup.string().required("Please select an option"),
  maritalStatus: Yup.string().required("Marital Status is required"),
  // settlements: Yup.object().required("Settlement Preference is required"),
});

const Settlements = [
  {
    id: "quarter",
    sequence: 1,
    value: "Once every calender quarter",
  },
  {
    id: "month",
    sequence: 2,
    value: "Once in a calender month",
  },
];

export default function YourProfilePage() {
  const navigate = useNavigate();

  //Loaders
  const [loading, setloading] = useState(false);
  const [prefilloading, setprefilloading] = useState(true);

  const [occupationOthers, setOccupationOthers] = useState<any>();
  const [occupationOther, setOthersDataFeild] = useState<any>();
  const [dropDownData, setDropDownData] = useState<any>({});
  const [annualDropDownData, setAnnualDropDownData] = useState<any>([]);
  const [tradingExperienceDropdownData, settradingExperienceDropdownData] =
    useState<any>([]);
  const [occupationDropdownData, setoccupationDropdownData] = useState<any>([]);
  const [displayFatherNameField, setdisplayFatherNameField] =
    useState<any>(true);

  const [editUpdates, setEditUpdates] = useState<any>(true);
  const [startOverloading, setstartOverloading] = useState(false);
  const [completedStages, setCompletedStages] = useState<any>();
  const [prefillData, setprefillData] = useState<IYourProfilePrefillData>({
    fatherFullName: "",
    motherFullName: "",
    annualIncome: "",
    tradingExperience: "",
    occupation: "",
    areYouPoliticallyExposed: "",
    maritalStatus: "",
    occupationIfOthers: "",
    // settlements: {
    //   id: "quarter",
    //   sequence: 1,
    //   value: "Once every calender quarter",
    // },
  });
  const [noeditableData, setnoEditableData] = useState<any>([]);
  const [backdrop, setBackDrop] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");

  const navigationData = routesData;
  const [user, setUser] = useState({});

  function setOthersData(data: any) {
    // console.log(data.target.value.value);

    setOthersDataFeild(data.target.value.value);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    checkUserLoggedIn(navigate);
    let annualFetchedData;
    let tradingFetchedData;
    let OccupationFetchedData;
    setprefilloading(true);
    fetchGetDropdownValuesAPICall()
      .then((response: any) => {
        const responseData = response.data;

        // setAnnualDropDownData(
        //   responseData.annual_income.map((a: any) => {
        //     return a;
        //   })
        // );
        setAnnualDropDownData(
          responseData.annual_income
            .map((a: any) => {
              return a;
            })
            .sort((a: any, b: any) => a.sequence - b.sequence)
        );
        annualFetchedData = responseData.annual_income.map((a: any) => {
          return a;
        });

        settradingExperienceDropdownData(
          responseData.trading_exp
            .map((a: any) => {
              return a;
            })
            .sort((a: any, b: any) => a.sequence - b.sequence)
        );

        tradingFetchedData = responseData.trading_exp.map((a: any) => {
          return a;
        });
        setoccupationDropdownData(
          responseData.occupation.map((a: any) => {
            return a;
          })
        );

        OccupationFetchedData = responseData.occupation.map((a: any) => {
          return a;
        });

        // API Call
        let obj: any = {
          stage: "profile_page",
        };

        stageDetailsAPICall(obj)
          .then((response: any) => {
            setprefilloading(false);
            setEditUpdates(false);
            const responseData = response.data;

            // if (responseData.data.StageDetails.noneditable.FatherName){
            //   setdisplayFatherNameField(false)
            // }

            // setnoEditableData(responseData.data.StageDetails.noneditable);

            setCompletedStages(responseData.completed_stages);
            const annualIncomeReturned = annualFetchedData.filter(
              (data: any) =>
                data.value ===
                response.data.data.StageDetails.editable.AnnualIncome
            )[0];
            const tradingExperienceReturned = tradingFetchedData.filter(
              (data: any) =>
                data.value ===
                response.data.data.StageDetails.editable.TradingExp
            )[0];
            const occupationReturned = OccupationFetchedData.filter(
              (data: any) =>
                data.value ===
                response.data.data.StageDetails.editable.Occupation
            )[0];
            // console.log("hello");

            // const preferenceType = Settlements.filter((data) =>
            //   data.value.includes(
            //     response.data.data.StageDetails.editable.SettlementPreference
            //   )
            // )[0];
            // console.log("response.data.data.StageDetails.editable.SettlementPreference",preferenceType)
            setRejectMessage(
              response.data.data.StageDetails.editable.ErrorMessage
            );
            setOthersDataFeild(
              responseData.data.StageDetails.editable.Occupation
            );
            setprefillData((prev) => {
              return {
                ...prev,
                fatherFullName:
                  responseData.data.StageDetails.noneditable.FatherName,
                motherFullName:
                  responseData.data.StageDetails.editable.MotherName,
                annualIncome: annualIncomeReturned,
                maritalStatus:
                  responseData.data.StageDetails.editable.MaritalStatus === true
                    ? "married"
                    : "" ||
                      responseData.data.StageDetails.editable.MaritalStatus ===
                        false
                    ? "single"
                    : "",
                areYouPoliticallyExposed:
                  responseData.data.StageDetails.editable.PoliticallyExposed ===
                  true
                    ? "Yes"
                    : "" ||
                      responseData.data.StageDetails.editable
                        .PoliticallyExposed === false
                    ? "No"
                    : "",
                tradingExperience: tradingExperienceReturned,
                occupation: occupationReturned,
                occupationIfOthers:
                  responseData.data.StageDetails.editable.OccupationOthers,

                // settlements: preferenceType ? preferenceType : Settlements[0],
              };
            });
          })
          .catch((error: any) => {
            setprefilloading(false);
            setdisplayFatherNameField(true);
            //  console.log("error", error);
          });
      })
      .catch((error: any) => {
        setprefilloading(false);
        // console.log("error", error);
      });
  }, []);

  // console.log("annualDropDownData",annualDropDownData)

  const annualIncomeOptions = annualDropDownData;
  const tradingExperienceOptions = tradingExperienceDropdownData;
  const occupationOptions = occupationDropdownData;

  function yourProfile(data: any) {
    setloading(true);
    let obj: IYourProfileDetails = {
      marital_status: data.maritalStatus === "married" ? true : false,
      father_name: data.fatherFullName.toUpperCase(),
      mother_name: data.motherFullName.toUpperCase(),
      annual_income_id: data.annualIncome.id,
      trading_exp_id: data.tradingExperience.id,
      occupation_id: data.occupation.id,
      occupation_others:
        occupationOther === "Others"
          ? data.occupationIfOthers.toUpperCase()
          : "",
      politically_exposed:
        data.areYouPoliticallyExposed === "Yes" ? true : false,
      // preference: data.settlements.value.includes("month")
      //   ? "month"
      //   : "quarter",
    };
    yourProfileDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log(responseData);
        setloading(false);
        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage]);
        }
      })
      .catch((error: any) => {
        setloading(false);
      });
  }

  const handleStartOver = () => {
    if (
      window.confirm(
        "Are you sure you want to restart, on pressing Yes all the Data will be deleted"
      )
    ) {
      setBackDrop(true);
      setstartOverloading(true);
      startOverAPICall()
        .then((response: any) => {
          const responseData = response.data;
          // console.log("responseData", response);
          setstartOverloading(false);
          setBackDrop(false);
          navigate("/");
          window.location.reload();
        })
        .catch((error: any) => {
          setBackDrop(false);
          setstartOverloading(false);
        });
    }
  };
  const initialValues: IYourProfilePrefillData = {
    fatherFullName: prefillData.fatherFullName,
    motherFullName: prefillData.motherFullName,
    annualIncome: prefillData.annualIncome,
    tradingExperience: prefillData.tradingExperience,
    occupation: prefillData.occupation,
    areYouPoliticallyExposed: prefillData.areYouPoliticallyExposed,
    maritalStatus: prefillData.maritalStatus,
    occupationIfOthers:
      prefillData.occupationIfOthers === null
        ? ""
        : prefillData.occupationIfOthers,
    // settlements: prefillData.settlements,
  };

  //console.log("prefillData" , prefillData)

  return (
    <div className={ProfileStyles.mainWrapper}>
      <StageLayoutEkyc
        completedStages={completedStages}
        stageName={"Personal Details"}
        navigatePath={"/aadhaarkyc"}
      />
      <>
        <div className={ProfileStyles.wrapper}>
          <div className={ProfileStyles.leftContainer}>
            <div className={ProfileStyles.headerSection}>
              <p className={ProfileStyles.signup}>Share A Bit About Yourself</p>
            </div>
            {!prefilloading ? (
              <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={(values: any) => {
                  // console.log("values", values);
                  yourProfile(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => {
                  // console.log("values", values);
                  return (
                    <form
                      noValidate
                      onSubmit={handleSubmit}
                      onKeyDown={(event) => {
                        event.code === "Enter" && handleSubmit();

                        // event.code.includes("Enter") && handleSubmit()
                      }}
                    >
                      <Stack margin={"10px 0"}>
                        <div className={ProfileStyles.topContainer}>
                          <div className={ProfileStyles.subContainer}>
                            {displayFatherNameField && (
                              <TextField
                                className={ProfileStyles.textfield}
                                id="outlined-basic"
                                label="Father's Full Name"
                                variant="outlined"
                                name="fatherFullName"
                                value={
                                  values.fatherFullName &&
                                  values.fatherFullName.toUpperCase()
                                }
                                error={
                                  touched.fatherFullName &&
                                  Boolean(errors.fatherFullName)
                                }
                                helperText={
                                  touched.fatherFullName &&
                                  errors.fatherFullName?.toString()
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{
                                  maxLength: 50,
                                  inputMode: "text",
                                }}
                              />
                            )}
                          </div>

                          <div className={ProfileStyles.subRightContainer}>
                            <TextField
                              className={ProfileStyles.textfield}
                              id="outlined-basic"
                              label="Mother's Full Name"
                              variant="outlined"
                              name="motherFullName"
                              value={
                                values.motherFullName &&
                                values.motherFullName.toUpperCase()
                              }
                              error={
                                touched.motherFullName &&
                                Boolean(errors.motherFullName)
                              }
                              helperText={
                                touched.motherFullName &&
                                errors.motherFullName?.toString()
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inputProps={{ maxLength: 50 }}
                            />
                          </div>
                        </div>
                      </Stack>
                      <div className={ProfileStyles.topContainer}>
                        <div className={ProfileStyles.subContainer}>
                          <div style={{ width: "100%" }}>
                            <Typography
                              fontSize={"18px"}
                              color={"#000000"}
                              sx={{
                                paddingLeft: "7px",
                                paddingBottom: "10px",
                                textAlign: "center",
                              }}
                            >
                              Are you politically exposed
                            </Typography>
                            <FormControl
                              component="fieldset"
                              error={
                                touched.maritalStatus &&
                                Boolean(errors.maritalStatus)
                              }
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  margin: "auto",
                                  width: "100%",
                                }}
                                className={ProfileStyles.chipsstylecontainer}
                              >
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Chip
                                        label="Yes"
                                        onClick={() => {
                                          handleChange(
                                            "areYouPoliticallyExposed"
                                          )("Yes");
                                          handleBlur(
                                            "areYouPoliticallyExposed"
                                          );
                                        }}
                                        color={
                                          values.areYouPoliticallyExposed ===
                                          "Yes"
                                            ? "primary"
                                            : undefined
                                        }
                                        clickable
                                        variant={
                                          values.areYouPoliticallyExposed ===
                                          "Yes"
                                            ? "filled"
                                            : "outlined"
                                        }
                                        style={{
                                          color:
                                            values.areYouPoliticallyExposed ===
                                            "Yes"
                                              ? "white"
                                              : "black",
                                          padding: "0 5px",
                                          borderRadius: "10px",
                                          width: "100px",
                                        }}
                                      />
                                    }
                                    label=""
                                  />
                                </div>
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Chip
                                        label="No"
                                        onClick={() => {
                                          handleChange(
                                            "areYouPoliticallyExposed"
                                          )("No");
                                          handleBlur(
                                            "areYouPoliticallyExposed"
                                          );
                                        }}
                                        color={
                                          values.areYouPoliticallyExposed ===
                                          "No"
                                            ? "primary"
                                            : "default"
                                        }
                                        clickable
                                        variant={
                                          values.areYouPoliticallyExposed ===
                                          "No"
                                            ? "filled"
                                            : "outlined"
                                        }
                                        style={{
                                          color:
                                            values.areYouPoliticallyExposed ===
                                            "No"
                                              ? "white"
                                              : "black",
                                          padding: "0 5px",
                                          borderRadius: "10px",
                                          width: "100px",
                                        }}
                                      />
                                    }
                                    label=""
                                  />
                                </div>
                              </div>
                            </FormControl>
                          </div>
                        </div>
                        <div className={ProfileStyles.subRightContainer}>
                          <div style={{ width: "100%" }}>
                            <Typography
                              fontSize={"18px"}
                              color={"#000000"}
                              sx={{
                                paddingLeft: "7px",
                                paddingBottom: "10px",
                                textAlign: "center",
                              }}
                            >
                              Select Your Marital Status
                            </Typography>
                            <FormControl
                              component="fieldset"
                              error={
                                touched.maritalStatus &&
                                Boolean(errors.maritalStatus)
                              }
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  // margin: "auto",
                                  width: "100%",
                                }}
                                className={ProfileStyles.chipsstylecontainer}
                              >
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Chip
                                        label="Single"
                                        onClick={() => {
                                          handleChange("maritalStatus")(
                                            "single"
                                          );
                                          handleBlur("maritalStatus");
                                        }}
                                        color={
                                          values.maritalStatus === "single"
                                            ? "primary"
                                            : undefined
                                        }
                                        clickable
                                        variant={
                                          values.maritalStatus === "single"
                                            ? "filled"
                                            : "outlined"
                                        }
                                        style={{
                                          color:
                                            values.maritalStatus === "single"
                                              ? "white"
                                              : "black",
                                          padding: "0 5px",
                                          borderRadius: "10px",
                                          width: "100px",
                                        }}
                                      />
                                    }
                                    label=""
                                  />
                                </div>
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Chip
                                        label="Married"
                                        onClick={() => {
                                          handleChange("maritalStatus")(
                                            "married"
                                          );
                                          handleBlur("maritalStatus");
                                        }}
                                        color={
                                          values.maritalStatus === "married"
                                            ? "primary"
                                            : "default"
                                        }
                                        clickable
                                        variant={
                                          values.maritalStatus === "married"
                                            ? "filled"
                                            : "outlined"
                                        }
                                        style={{
                                          color:
                                            values.maritalStatus === "married"
                                              ? "white"
                                              : "black",
                                          padding: "0 5px",
                                          borderRadius: "10px",
                                          width: "100px",
                                        }}
                                      />
                                    }
                                    label=""
                                  />
                                </div>
                              </div>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                      <div className={ProfileStyles.topContainer}>
                        <div className={ProfileStyles.subContainer}>
                          <div style={{ width: "100%" }}>
                            <Typography
                              fontSize={"18px"}
                              color={"#000000"}
                              sx={{
                                paddingLeft: "7px",
                                paddingBottom: "10px",
                                textAlign: "center",
                              }}
                            >
                              Your trading experience
                            </Typography>
                            <div style={{ width: "100%" }}>
                              <SelectDropdown
                                LabelName="Trading Experience"
                                menuItems={tradingExperienceOptions}
                                name="tradingExperience"
                                value={values.tradingExperience}
                                handleChange={handleChange}
                                error={
                                  touched.tradingExperience &&
                                  Boolean(errors.tradingExperience)
                                }
                                helperText={
                                  touched.tradingExperience &&
                                  errors.tradingExperience
                                }
                                setOthers={setOccupationOthers}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={ProfileStyles.subRightContainer}>
                          <Typography
                            fontSize={"18px"}
                            color={"#000000"}
                            sx={{ paddingLeft: "7px", paddingBottom: "10px" }}
                          >
                            Your annual income
                          </Typography>
                          <SelectDropdown
                            LabelName="Annual Income"
                            menuItems={annualIncomeOptions}
                            name="annualIncome"
                            value={values.annualIncome}
                            handleChange={handleChange}
                            error={
                              touched.annualIncome &&
                              Boolean(errors.annualIncome)
                            }
                            helperText={
                              touched.annualIncome && errors.annualIncome
                            }
                            setOthers={setOccupationOthers}
                          />
                        </div>
                      </div>
                      <div className={ProfileStyles.topContainerOccupation}>
                        <div
                          className={ProfileStyles.subRightContainerOccupation}
                        >
                          <div style={{ width: "100%" }}>
                            <Typography
                              fontSize={"18px"}
                              color={"#000000"}
                              sx={{ paddingLeft: "7px", paddingBottom: "10px" }}
                            >
                              Your Occupation
                            </Typography>
                            <div style={{ width: "100%" }}>
                              <SelectDropdown
                                LabelName="Occupation"
                                menuItems={occupationOptions}
                                name="occupation"
                                value={values.occupation}
                                handleChange={handleChange}
                                error={
                                  touched.occupation &&
                                  Boolean(errors.occupation)
                                }
                                helperText={
                                  touched.occupation && errors.occupation
                                }
                                setOthers={setOthersData}
                                padding={"50px"}
                              />
                            </div>
                            {occupationOther === "Others" ? (
                              <Grid item  spacing={2}>
                                <TextField
                                  style={{ marginTop: "10px" }}
                                  id="outlined-basic"
                                  label="Enter Your Occupation"
                                  variant="outlined"
                                  name="occupationIfOthers"
                                  size="small"
                                  value={values.occupationIfOthers.toUpperCase()}
                                  error={
                                    touched.occupationIfOthers &&
                                    Boolean(errors.occupationIfOthers)
                                  }
                                  helperText={
                                    touched.occupationIfOthers &&
                                    errors.occupationIfOthers?.toString()
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  inputProps={{ maxLength: 50 }}
                                />
                              </Grid>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <ErrorSnackBar message={rejectMessage} />
                      {backdrop && <StartOverBackDrop />}
                      <div className={ProfileStyles.buttonStack}>
                        <div>
                          {values.areYouPoliticallyExposed &&
                          values.fatherFullName &&
                          values.motherFullName &&
                          values.annualIncome &&
                          values.occupation &&
                          values.maritalStatus &&
                          values.tradingExperience &&
                          // values.settlements &&
                          (values.occupation.value === "Others"
                            ? Boolean(values.occupationIfOthers)
                            : true) &&
                          !errors.fatherFullName &&
                          !errors.motherFullName ? (
                            <LoadingButton
                              loading={loading}
                              variant="contained"
                              type="submit"
                              id="yourProfileContinueButton"
                              onClick={() => {
                                handleSubmit();
                              }}
                              className={ProfileStyles.approveButton}
                            >
                              Continue
                            </LoadingButton>
                          ) : (
                            <Button
                              variant="contained"
                              disabled
                              sx={{ width: "200px" }}
                              onClick={() => {
                                handleSubmit();
                              }}
                              className={ProfileStyles.approveButton}
                            >
                              Continue
                            </Button>
                          )}
                        </div>
                        <div className={ProfileStyles.startOverSection}>
                          <img src={icon} className={ProfileStyles.iconImage} />
                          <p className={ProfileStyles.paratext}>
                            {" "}
                            if the above mentioned info not yours
                            <span
                              className={ProfileStyles.startoverbutton}
                              onClick={handleStartOver}
                            >
                              Start Over
                            </span>
                          </p>
                        </div>
                      </div>

                      {/* <Stack>
                   <Grid
                     container
                     justifyContent={"space-around"}
                     alignItems={"center"}
                   >
                     <Typography>Marital Status</Typography>
                     <Stack direction="row" spacing={1}>
                       <FormControl
                         component="fieldset"
                         error={
                           touched.maritalStatus &&
                           Boolean(errors.maritalStatus)
                         }
                       >
                         <div
                           style={{ display: "flex", flexDirection: "row" }}
                         >
                           <FormControlLabel
                             control={
                               <Chip
                                 label="Single"
                                 onClick={() => {
                                   handleChange("maritalStatus")("single");
                                   handleBlur("maritalStatus");
                                 }}
                                 color={
                                   values.maritalStatus === "single"
                                     ? "primary"
                                     : undefined
                                 }
                                 clickable
                                 variant={
                                   values.maritalStatus === "single"
                                     ? "filled"
                                     : "outlined"
                                 }
                                 style={{
                                   margin: "5px",
                                   color:
                                     values.maritalStatus === "single"
                                       ? "white"
                                       : "black",
                                   padding: "0 5px",
                                 }}
                               />
                             }
                             label=""
                           />
                           <FormControlLabel
                             control={
                               <Chip
                                 label="Married"
                                 onClick={() => {
                                   handleChange("maritalStatus")("married");
                                   handleBlur("maritalStatus");
                                 }}
                                 color={
                                   values.maritalStatus === "married"
                                     ? "primary"
                                     : "default"
                                 }
                                 clickable
                                 variant={
                                   values.maritalStatus === "married"
                                     ? "filled"
                                     : "outlined"
                                 }
                                 style={{
                                   margin: "5px",
                                   color:
                                     values.maritalStatus === "married"
                                       ? "white"
                                       : "black",
                                   padding: "0 5px",
                                 }}
                               />
                             }
                             label=""
                           />
                         </div>

                       </FormControl>
                     </Stack>
                   </Grid>
                   <Divider />
                   <Stack margin={"10px 0"} alignItems={"center"}>
                   

                     <Grid container justifyContent={"center"}>
                       {displayFatherNameField && (
                         <TextField
                           style={{ marginRight: "10px", marginTop: "8px" }}
                           id="outlined-basic"
                           label="Father's FullName"
                           variant="outlined"
                           name="fatherFullName"
                           size="small"
                           value={values.fatherFullName}
                           error={
                             touched.fatherFullName &&
                             Boolean(errors.fatherFullName)
                           }
                           // helperText={
                           //   touched.fatherFullName && errors.fatherFullName
                           // }
                           onChange={handleChange}
                           onBlur={handleBlur}
                           inputProps={{ maxLength: 50 }}
                         />
                       )}

              
                       <TextField
                         style={{ marginRight: "10px", marginTop: "8px" }}
                         id="outlined-basic"
                         label="Mother's FullName"
                         variant="outlined"
                         name="motherFullName"
                         size="small"
                         value={values.motherFullName}
                         error={
                           touched.motherFullName &&
                           Boolean(errors.motherFullName)
                         }
                        
                         onChange={handleChange}
                         onBlur={handleBlur}
                         inputProps={{ maxLength: 50 }}
                       />
                     </Grid>
                   </Stack>
                   <Divider />
                   <Stack margin={"10px 0"} alignItems={"center"}>
                     

                     <Grid container spacing={2} justifyContent={"center"}>
                       <Grid item width={"90%"} spacing={2}>
                         <Typography
                           fontSize={"18px"}
                           color={"rgba(52, 64, 84, 1)"}
                         >
                           Your Annual Income
                         </Typography>
                         <SelectDropdown
                           LabelName="Annual Income"
                           menuItems={annualIncomeOptions}
                           name="annualIncome"
                           value={values.annualIncome}
                           handleChange={handleChange}
                           error={
                             touched.annualIncome &&
                             Boolean(errors.annualIncome)
                           }
                           helperText={
                             touched.annualIncome && errors.annualIncome
                           }
                           setOthers={setOccupationOthers}
                         />
                         <Divider />
                       </Grid>

                       <Grid item width={"90%"} spacing={2}>
                         <Typography
                           fontSize={"18px"}
                           color={"rgba(52, 64, 84, 1)"}
                         >
                           Your Trading Experience
                         </Typography>
                         <SelectDropdown
                           LabelName="Trading Experience"
                           menuItems={tradingExperienceOptions}
                           name="tradingExperience"
                           value={values.tradingExperience}
                           handleChange={handleChange}
                           error={
                             touched.tradingExperience &&
                             Boolean(errors.tradingExperience)
                           }
                           helperText={
                             touched.tradingExperience &&
                             errors.tradingExperience
                           }
                           setOthers={setOccupationOthers}
                         />
                         <Divider />
                       </Grid>
                       <Grid item width={"90%"} spacing={2}>
                         <Typography
                           fontSize={"18px"}
                           color={"rgba(52, 64, 84, 1)"}
                         >
                           Your Occupation
                         </Typography>
                         <SelectDropdown
                           LabelName="Occupation"
                           menuItems={occupationOptions}
                           name="occupation"
                           value={values.occupation}
                           handleChange={handleChange}
                           error={
                             touched.occupation && Boolean(errors.occupation)
                           }
                           helperText={
                             touched.occupation && errors.occupation
                           }
                           setOthers={setOthersData}
                         />
                       </Grid>
                       {occupationOther === "Others" ? (
                         <Grid item width={220} spacing={2}>
                           <TextField
                             // style={{ marginTop: "10px" }}
                             id="outlined-basic"
                             label="Occupation Others"
                             variant="outlined"
                             name="occupationIfOthers"
                             size="small"
                             value={values.occupationIfOthers.toUpperCase()}
                             error={
                               touched.occupationIfOthers &&
                               Boolean(errors.occupationIfOthers)
                             }
                         
                             onChange={handleChange}
                             onBlur={handleBlur}
                             inputProps={{ maxLength: 50 }}
                           />
                         </Grid>
                       ) : (
                         ""
                       )}
                     </Grid>
                   </Stack>
                   <Divider />
                   
                   <Grid
                     container
                     justifyContent={"space-around"}
                     alignItems={"center"}
                   >
                     <Typography>Are You Politically Exposed</Typography>
                     <Stack direction={"row"}>
                       <FormControl
                         component="fieldset"
                         error={
                           touched.areYouPoliticallyExposed &&
                           Boolean(errors.areYouPoliticallyExposed)
                         }
                       >
                         <RadioGroup
                           aria-label="Are You Politically Exposed"
                           name="areYouPoliticallyExposed"
                           style={{ display: "flex", flexDirection: "row" }}
                           value={values.areYouPoliticallyExposed}
                           onChange={handleChange}
                           onBlur={handleBlur}
                         >
                           <FormControlLabel
                             value="Yes"
                             control={<Radio />}
                             label="Yes"
                           />
                           <FormControlLabel
                             value="No"
                             control={<Radio />}
                             label="No"
                           />
                         </RadioGroup>
                   
                       </FormControl>
                     </Stack>
                   </Grid>

                   <Stack
                     style={{ margin: "10px 0" }}
                     alignItems={"center"}
                     justifyContent={"center"}
                     direction={{ sm: "column", md: "row" }}
                   >
                     {values.areYouPoliticallyExposed &&
                     values.fatherFullName &&
                     values.motherFullName &&
                     values.annualIncome &&
                     values.occupation &&
                     values.maritalStatus &&
                     values.tradingExperience ? (
                       <LoadingButton
                         loading={loading}
                         variant="contained"
                         type="submit"
                         id="yourProfileContinueButton"
                         sx={{ width: "200px" }}
                         onClick={() => {
                           handleSubmit();
                         }}
                       >
                         Continue
                       </LoadingButton>
                     ) : (
                       <Button
                         variant="contained"
                         disabled
                         sx={{ width: "200px" }}
                         onClick={() => {
                           handleSubmit();
                         }}
                       >
                         Continue
                       </Button>
                     )}

                   

                     <LoadingButton
                       onClick={handleStartOver}
                       loading={startOverloading}
                       sx={{ color: "rgba(18, 183, 106, 1)" }}
                       id="yourProfileStartOverButton"
                     >
                       StartOver
                     </LoadingButton>

             
                   </Stack>
                 </Stack> */}
                    </form>
                  );
                }}
              </Formik>
            ) : (
              // <div>
              //   <Stack margin={"10px 0"}>
              //     <div className={ProfileStyles.topContainer}>
              //       <div className={ProfileStyles.subContainer}>
              //         {displayFatherNameField && (
              //           <TextField
              //             className={ProfileStyles.textfield}
              //             id="outlined-basic"
              //             label="Father's FullName"
              //             variant="outlined"
              //             name="fatherFullName"
              //             inputProps={{ maxLength: 50 }}
              //           />
              //         )}
              //       </div>

              //       <div className={ProfileStyles.subRightContainer}>
              //         <TextField
              //           // style={{ marginRight: "10px", marginTop: "8px", width:'200px' }}
              //           className={ProfileStyles.textfield}
              //           id="outlined-basic"
              //           label="Mother's FullName"
              //           variant="outlined"
              //           name="motherFullName"
              //           inputProps={{ maxLength: 50 }}
              //         />
              //       </div>
              //     </div>
              //   </Stack>
              //   <div className={ProfileStyles.topContainer}>
              //     <div className={ProfileStyles.subContainer}>
              //       <Typography
              //         fontSize={"20px"}
              //         color={"#475467"}
              //         sx={{ paddingLeft: "7px", paddingBottom: "10px" }}
              //       >
              //         Your trading experience
              //       </Typography>
              //       <SelectDropdown
              //         LabelName="Trading Experience"
              //         menuItems={tradingExperienceOptions}
              //         name="tradingExperience"
              //         value={""}
              //         handleChange={() => {}}
              //         error={""}
              //         helperText={""}
              //         setOthers={setOccupationOthers}
              //       />
              //       <Divider sx={{ padding: "10px", marginRight: "40px" }} />
              //     </div>

              //     <div className={ProfileStyles.subContainer}>
              //       <Typography
              //         fontSize={"20px"}
              //         color={"#475467"}
              //         sx={{ paddingLeft: "7px", paddingBottom: "10px" }}
              //       >
              //         Your annual income
              //       </Typography>
              //       <SelectDropdown
              //         LabelName="Annual Income"
              //         menuItems={annualIncomeOptions}
              //         name="annualIncome"
              //         value={""}
              //         handleChange={() => {}}
              //         error={""}
              //         helperText={""}
              //         setOthers={setOccupationOthers}
              //       />
              //       <Divider sx={{ padding: "10px", marginRight: "40px" }} />
              //     </div>
              //   </div>
              //   <div className={ProfileStyles.topContainer}>
              //     <div className={ProfileStyles.subContainer}>
              //       <div>
              //         <Typography
              //           fontSize={"20px"}
              //           color={"#475467"}
              //           sx={{ paddingLeft: "7px", paddingBottom: "10px" }}
              //         >
              //           Your Trading Experience
              //         </Typography>
              //         <FormControl component="fieldset">
              //           <div style={{ display: "flex", flexDirection: "row" }}>
              //             <FormControlLabel
              //               control={<Chip label="Single" />}
              //               label=""
              //             />
              //             <FormControlLabel
              //               control={<Chip label="Married" />}
              //               label=""
              //             />
              //           </div>
              //         </FormControl>
              //         <Divider sx={{ padding: "10px", marginRight: "40px" }} />
              //       </div>
              //       <div style={{ marginTop: "20px" }}>
              //         <Typography
              //           fontSize={"20px"}
              //           color={"#475467"}
              //           sx={{ paddingLeft: "7px", paddingBottom: "10px" }}
              //         >
              //           Are you politically exposed
              //         </Typography>
              //         <FormControl component="fieldset">
              //           <div style={{ display: "flex", flexDirection: "row" }}>
              //             <FormControlLabel
              //               control={<Chip label="Yes" />}
              //               label=""
              //             />
              //             <FormControlLabel
              //               control={<Chip label="No" />}
              //               label=""
              //             />
              //           </div>
              //         </FormControl>
              //       </div>
              //     </div>

              //     <div className={ProfileStyles.subContainer}>
              //       <Typography
              //         fontSize={"20px"}
              //         color={"#475467"}
              //         sx={{ paddingLeft: "7px", paddingBottom: "10px" }}
              //       >
              //         Your Occupation
              //       </Typography>
              //       <SelectDropdown
              //         LabelName="Occupation"
              //         menuItems={occupationOptions}
              //         name="occupation"
              //         value={""}
              //         handleChange={() => {}}
              //         error={""}
              //         helperText={""}
              //         setOthers={setOthersData}
              //       />
              //       {occupationOther === "Others" ? (
              //         <Grid item width={220} spacing={2}>
              //           <TextField
              //             // style={{ marginTop: "10px" }}
              //             id="outlined-basic"
              //             label="Occupation Others"
              //             variant="outlined"
              //             name="occupationIfOthers"
              //             size="small"
              //           />
              //         </Grid>
              //       ) : (
              //         ""
              //       )}
              //     </div>
              //   </div>

              //   <div className={ProfileStyles.buttonStack}>
              //     <div>
              //       <LoadingButton
              //         loading={loading}
              //         variant="contained"
              //         type="submit"
              //         id="yourProfileContinueButton"
              //         className={ProfileStyles.approveButton}
              //       >
              //         Continue
              //       </LoadingButton>
              //     </div>
              //     <div className={ProfileStyles.startOverSection}>
              //       <img src={icon} className={ProfileStyles.iconImage} />
              //       <p className={ProfileStyles.paratext}>
              //         {" "}
              //         if the above mentioned info not your
              //         <span
              //           className={ProfileStyles.startoverbutton}
              //           onClick={handleStartOver}
              //         >
              //           Start Over
              //         </span>
              //       </p>
              //     </div>
              //   </div>
              // </div>
              <div style={{ height: "80vh" }}>
                <BackDrop />
              </div>
            )}
          </div>
          {/* <div className={ProfileStyles.rightContainer}>
          <KRAFetchedDetails data={noeditableData} />
        </div> */}
        </div>
      </>
    </div>
  );
}
