import React, { useEffect, useState } from "react";

import { Avatar, Grid, Stack, TextField, Typography } from "@mui/material";

import KRAStyles from "../pages/Styles/KRAScreen.module.scss";

interface Props {
  aadhaarData: any;
  aadaarFetched: any;
}

export default function KRAScreen({ aadhaarData, aadaarFetched }: Props) {
  

  const [fetechedDetails, setfetechedDetails] = useState(true);

//  console.log(aadhaarData);

  return (
    <>
      {fetechedDetails ? (
        <Stack alignItems={"center"} height={"max-content"}>
          <p className={KRAStyles.header}>Fetched KRA Details</p>
          <div className={KRAStyles.container}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 5 }}
            >
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Name As per PAN"
                  variant="outlined"
                  value={aadhaarData.KraName}
                  // value={"hhhhhhhh"}
                  sx={{ input: { color: "#344054", fontSize: "14px" } }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Father Name"
                  variant="outlined"
                  value={aadhaarData.KraFatherName}
                  //value={"hhhhhhhh"}
                  sx={{ input: { color: "#344054", fontSize: "14px" } }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Date of Birth"
                  variant="outlined"
                  value={aadhaarData.KraDob}
                  // value={"hhhhhhhh"}
                  sx={{ input: { color: "#344054", fontSize: "14px" } }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Gender"
                  variant="outlined"
                  value={aadhaarData.KraGender}
                  //value={"hhhhhhhh"}
                  sx={{ input: { color: "#344054", fontSize: "14px" } }}
                />
              </Grid>
              <Grid item xs={6} justifyContent={"center"} margin={"auto"}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  value={aadhaarData.KraEmail.toLowerCase()}
                  //value={"hhhhhhhh"}
                  sx={{ input: { color: "#344054", fontSize: "14px" } }}
                />
              </Grid>
              <Grid item xs={6} justifyContent={"center"} margin={"auto"}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Pincode"
                  variant="outlined"
                  value={aadhaarData.KraPincode.toLowerCase()}
                  //value={"hhhhhhhh"}
                  sx={{ input: { color: "#344054", fontSize: "14px" } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  value={aadhaarData.KraAddress}
                  // value={"hhhhhhhh"}
                  sx={{ input: { color: "#344054", fontSize: "12px" } }}
                />
              </Grid>
            </Grid>
          </div>
        </Stack>
      ) : (
        <Stack>
          <Typography>No Details Fetched</Typography>
        </Stack>
      )}
    </>
  );
}
