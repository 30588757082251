import React, { useEffect, useState } from "react";
import {
  Stack,
  Button,
  TextField,
  Modal,
  Fade,
  Box,
  IconButton,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import uploadIcon from "../../Components/assets/Images/UploadIcon.svg";
import SnackBar from "../SnackBars/SnackBar";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

const styleNew = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vW",
  height: "60vH",
  bgcolor: "background.paper",

  p: 1,
  boxShadow: 24,
  borderRadius: "10px",
};
const isMobileView = window.innerWidth <= 768; // Adjust the breakpoint if needed

// Change the width value to "90vw" for mobile view
if (isMobileView) {
  styleNew.width = "90vw";
}

interface Props {
  showDragandDropModal: any;
  handleCloseModal: any;
  handleFileChangeUpload: any;
  acceptedFormats: any;
  errorMessage: string;
  selectedFile: any;
  showInput: any;
  image: any;
  pdfSize?: any;
  imageSize?: any;
}

export default function DragandDropFile({
  showDragandDropModal,
  handleCloseModal,
  handleFileChangeUpload,
  acceptedFormats,
  errorMessage,
  selectedFile,
  showInput,
  image,
  pdfSize,
  imageSize,
}: Props) {
  const [file, setFile] = useState<any>(null);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const handleChange = (file: any) => {
    setFile(file);
  };

  // console.log("acceptedFormats", acceptedFormats);

  return (
    <Stack alignItems={"center"}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showDragandDropModal}
        // onClose={handleCloseModal}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showDragandDropModal}>
          <Box sx={styleNew}>
            <IconButton
              style={{ position: "absolute", left: "5%" }}
              onClick={handleCloseModal}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
            <Stack
              height={"50vh"}
              justifyContent={"center"}
              alignItems={"center"}
              marginTop={"50px"}
            >
              {/* <h3> Drag & Drop Files Here</h3> */}
              <div>
                <FileUploader
                  multiple={true}
                  handleChange={handleFileChangeUpload}
                  name="file"
                  types={acceptedFormats}
                  onTypeError={(err: any) => {
                    setFileErrorMessage(err);

                    setTimeout(() => {
                      setFileErrorMessage("");
                    }, 5000);
                  }}
                >
                  <div
                    style={{
                      border: "2px dotted #006779",
                      borderRadius: "10px",
                      width: "300px",
                      height: "300px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <img src={uploadIcon} width={"60px"} /> */}
                      <CloudUploadOutlinedIcon
                        style={{ fontSize: "60px", color: "#006779" }}
                      />
                      <p>Drag and drop your file here</p>
                    </div>
                    <p style={{ marginTop: "0px" }}>or</p>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Button variant="contained" style={{ width: "80%" }}>
                        Browse File
                      </Button>
                    </div>
                    <p style={{ fontSize: "12px", marginTop: "15px" }}>
                      <span
                        style={{ marginLeft: "10px", letterSpacing: "1px" }}
                      >
                        Max File Size allowed is {imageSize ? imageSize :"2"} Mb
                      </span>
                    </p>
                    <p style={{ fontSize: "12px", marginTop: "15px" }}>
                      <span
                        style={{ marginLeft: "10px", letterSpacing: "1px" }}
                      >
                        {`${acceptedFormats}`}{" "}
                      </span>
                    </p>
                    {/* {errorMessage && errorMessage} */}
                  </div>
                </FileUploader>
              </div>
              <SnackBar message={fileErrorMessage} />

              {/* {errorMessage && errorMessage} */}
              {/* {selectedFile && image !== "close" && selectedFile.name} */}
              {/* {showInput ? (
                <input
                  style={{ width: "100px", height: "20px" }}
                  placeholder="Enter Password"
                />
              ) : (
                ""
              )} */}
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </Stack>
  );
}
