import Webcam from "react-webcam";
import React, { useRef, useEffect, useState } from "react";
import { useFaceDetection } from "react-use-face-detection";
import FaceDetection from "@mediapipe/face_detection";
import { Camera } from "@mediapipe/camera_utils";
import { useNavigate } from "react-router-dom";
import { Stack, Button, Card, CardMedia, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { browserName } from "react-device-detect";
import "react-loading-skeleton/dist/skeleton.css";
import { IWebImage } from "../../shared/dtos";
import {
  stageDetailsAPICall,
  startOverAPICall,
  webImageBase64APICall,
} from "../../shared/APICalls";
import routesData from "../../shared/routes.json";
import CamStyles from "./WebCam.module.scss";
import icon from "../../Components/assets/Images/iconnew.svg";
import StartOverBackDrop from "../StartOverBackDrop";
import ErrorSnackBar from "../SnackBars/ErrorSnackBar";
import StageLayoutEkyc from "../../Layouts/StageLayoutEkyc";
import * as faceapi from "face-api.js";

const width = 220;
const height = 220;

declare global {
  interface Window {
    blazeface: any;
    tf: any; // Add this to reference TensorFlow globally if needed
  }
}

const WebcamDemo = (): JSX.Element => {
  const { webcamRef, boundingBox, isLoading, detected, facesDetected }: any =
    useFaceDetection({
      faceDetectionOptions: {
        model: "short",
      },
      faceDetection: new FaceDetection.FaceDetection({
        locateFile: (file: any) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
      }),
      camera: ({ mediaSrc, onFrame }: any) =>
        new Camera(mediaSrc, {
          onFrame,
          width,
          height,
        }),
    });
  const [boundingBoxes, setBoundingBoxes] = useState<
    { top: number; left: number; width: number; height: number }[]
  >([]);

  const [previewUrl, setPreviewUrl] = useState("");
  const videoRef: any = useRef<HTMLVideoElement | null>(null);
  const canvasRef: any = useRef<HTMLCanvasElement | null>(null);
  const [setVisible, setsetVisible] = useState<any>("hidden");
  const [allowCapture, setAllowCapture] = useState(true);
  const [showCamera, setShowCamera] = useState(true);
  const [isSafari, setisSafari] = useState(false);
  const [Border, setBorder] = useState("");
  const [loading, setloading] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [completedStages, setCompletedStages] = useState<any>();
  const [backdrop, setBackDrop] = useState(false);
  const [reload, setReload] = useState(true);
  const [rejectMessage, setRejectMessage] = useState("");
  const [take, settake] = useState(true);
  const [facePredictions, setFacePredictions] = useState([]);
  const navigationData = routesData;

  const navigate = useNavigate();

  const [changeStyles, setChangeStyles] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setChangeStyles(true);
    } else {
      setChangeStyles(false);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    settake(true);
    setTimeout(() => {
      settake(false);
    }, 4000);

    let obj: any = {
      stage: "web_image",
    };

    stageDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        setCompletedStages(responseData.completed_stages);
        // console.log(responseData);
        // console.log("response", response.data.data.StageDetails.WebImage);
        if (response.data.data.StageDetails.ErrorMessage) {
          setRejectMessage(response.data.data.StageDetails.ErrorMessage);
        }
        const retakeImage = sessionStorage.getItem("re-take");

        if (retakeImage === "retake") {
          setPreviewUrl("");
        } else {
          setPreviewUrl(response.data.data.StageDetails.WebImage);
        }

        setReload(false);
      })
      .catch((error: any) => {
        //  console.log("error", error);
      });

    let intervalId: any;
    let alertShown = false;

    const startCameraInterval = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        stream.getTracks().forEach((track) => track.stop());

        setsetVisible("visible");
        setShowCamera(true);
        clearInterval(intervalId);
        if (videoRef.current) {
          const streamNew = await navigator.mediaDevices.getUserMedia({
            video: { width: 500, height: 400 },
            audio: false,
          });
          videoRef.current.srcObject = streamNew;
        }
      } catch (error: any) {
        if (browserName.includes("Safari")) {
          setisSafari(true);
        }
        setShowCamera(false);
        if (
          !alertShown &&
          (error.name === "NotAllowedError" ||
            error.name === "PermissionDeniedError")
        ) {
          alertShown = true;
          const shouldAskForPermission = window.confirm(
            "Camera access is blocked. Please unblock it in browser settings."
          );
          if (shouldAskForPermission) {
            intervalId = setInterval(async () => {
              try {
                const stream = await navigator.mediaDevices.getUserMedia({
                  video: true,
                });
                stream.getTracks().forEach((track) => track.stop());
                clearInterval(intervalId);
                // console.log("video is true");
                // setShowCamera(true);
                window.location.reload();
              } catch (error: any) {
                // console.error(error);
              }
            }, 1000);
          } else {
            // console.error(error);
          }
        }
      }
    };
    if (browserName !== "Firefox") {
      intervalId = setInterval(startCameraInterval, 1000);
    }
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const loadScriptsAndModel = async () => {
      const loadScript = (src: string) => {
        return new Promise<void>((resolve, reject) => {
          const script = document.createElement("script");
          script.src = src;
          script.async = true;
          script.onload = () => resolve();
          script.onerror = () =>
            reject(new Error(`Failed to load script ${src}`));
          document.body.appendChild(script);
        });
      };

      try {
        await loadScript("https://cdn.jsdelivr.net/npm/@tensorflow/tfjs");
        await loadScript(
          "https://cdn.jsdelivr.net/npm/@tensorflow-models/blazeface"
        );

        const model = await window.blazeface.load();

        if (videoRef.current) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { width: 220, height: 220 },
            audio: false,
          });
          videoRef.current.srcObject = stream;

          videoRef.current.onloadeddata = () => {
            // const ctx = canvasRef.current.getContext("2d");

            const detectFaces = async () => {
              if (videoRef.current && model) {
                const predictions = await model.estimateFaces(
                  videoRef.current,
                  false
                );
                // console.log("predictions", predictions);
                setFacePredictions(predictions);

                const boxes = predictions.map((prediction: any) => {
                  const top = 220 - prediction.topLeft[1];
                  const left = 220 - prediction.topLeft[0];
                  const width = 40;
                  const height = 40;

                  return { top, left, width, height };
                });

                setBoundingBoxes(boxes);
              }
            };

            setInterval(detectFaces, 100);
          };
        }
      } catch (error) {
        console.log("Error loading scripts or model", error);
      }
    };

    loadScriptsAndModel();
  }, []);

  useEffect(() => {
    if (facesDetected > 1) {
      setAllowCapture(false);

      setBorder("4px solid red");
    } else if (facesDetected === 1) {
      setAllowCapture(true);

      setBorder("4px solid green");
    } else {
      if (isLoading === false) {
        setAllowCapture(false);

        setBorder("4px solid red");
      }
    }
  }, [facesDetected]);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    //console.log(imageSrc, "imageSrc");
    setPreviewUrl(imageSrc);
  }, [webcamRef]);

  const handleStartOver = () => {
    if (
      window.confirm(
        "Are you sure you want to restart, on pressing Yes All the Data will be deleted"
      )
    ) {
      setBackDrop(true);

      startOverAPICall()
        .then((response: any) => {
          const responseData = response.data;
          //console.log("responseData", response);
          setisloading(false);
          setBackDrop(false);
          navigate("/");
        })
        .catch((error: any) => {
          setAllowCapture(false);
        });
    }
  };

  function webImageBase64() {
    setloading(true);
    let obj: IWebImage = {
      web_image_base64: previewUrl,
    };

    webImageBase64APICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //console.log("responseData", response);
        setloading(false);
        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          // navigate(navigationData[responseData.data.stage]);
          window.location.href = navigationData[responseData.data.stage];
        }
      })
      .catch((error: any) => {
        setloading(false);
      });
  }

  const handleClick = () => {
    sessionStorage.setItem("re-take", "");
    webImageBase64();
  };

  const refreshCamera = () => {
    if (webcamRef.current && webcamRef.current.stream) {
      const stream = webcamRef.current.stream;

      // Stop the current stream
      stream.getTracks().forEach((track: any) => track.stop());

      // Start a new stream
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((newStream) => {
          webcamRef.current.stream = newStream;
        })
        .catch((error) => {
          //  console.error("Error refreshing camera:", error);
        });
    }
  };

  return (
    <div className={CamStyles.main}>
      {" "}
      <StageLayoutEkyc
        completedStages={completedStages}
        stageName={"Identification Verification"}
        navigatePath={"/uploaddocuments"}
      />
      <video ref={videoRef} autoPlay style={{ display: "none" }}></video>
      <div className={CamStyles.mainWrapper}>
        <ErrorSnackBar message={rejectMessage} />
        <div className={CamStyles.wrapper}>
          <div>
            <p className={CamStyles.Header}>Capture Your Selfie</p>
            <p className={CamStyles.subText}>
              Help us verify your identity by capturing a clear selfie.
            </p>
          </div>
          <div className={CamStyles.Container}>
            <div style={{ width, height, position: "relative" }}>
              <div>
                {previewUrl === "" ? (
                  <>
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      forceScreenshotSourceSize
                      screenshotFormat="image/png"
                      style={{
                        height,
                        width,
                        borderRadius: "50%",
                        position: "absolute",
                        border: Border,
                        marginTop: "10px",
                        // display: isLoading === true ? "none" : "block",
                      }}
                    />
                  </>
                ) : (
                  <div>
                    <Card
                      sx={{
                        width: 222,
                        marginTop: "10px",
                        borderRadius: "50%",
                        zIndex: 100,
                        background: "white",
                        marginLeft: "1px",
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="222"
                        image={previewUrl}
                        sx={{
                          zIndex: 100,
                          position: "absolute",
                          borderRadius: "50%",
                          objectFit: "contain",
                        }}
                      />
                    </Card>
                    <Stack width={"150px"} style={{ margin: "10px 0" }}></Stack>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {backdrop && <StartOverBackDrop />}

        <Stack style={{ marginTop: "20px" }}>
          <>
            {showCamera ? (
              <Stack alignItems={"center"}>
                {previewUrl === "" ? (
                  <>
                    <Stack
                      alignItems={"center"}
                      marginTop={"0px"}
                      height={"10px"}
                    >
                      {!allowCapture ? (
                        <Typography style={{ color: "red" }}>
                          No Face/ Detected too many faces
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Stack>
                    <div className={CamStyles.textdiv}>
                      <p className={CamStyles.instruction}>Instructions</p>
                      <p className={CamStyles.instructiontext}>
                        Ensure your face is well-lit and centered in the frame.
                      </p>
                      <p className={CamStyles.instructiontext}>
                        Remove any accessories that may obstruct your face
                        (e.g., sunglasses, hats).
                      </p>
                      <p className={CamStyles.instructiontext}>
                        Click "Capture Selfie" when you're ready.
                      </p>
                    </div>
                    {!take ? (
                      <Button
                        style={{
                          marginTop: "10px",
                        }}
                        className={CamStyles.takePhoto}
                        variant="contained"
                        onClick={capture}
                        disabled={!allowCapture}
                      >
                        Take a Photo
                      </Button>
                    ) : (
                      <Button
                        style={{
                          marginTop: "10px",
                        }}
                        className={CamStyles.takePhoto}
                        variant="contained"
                        onClick={capture}
                        disabled
                      >
                        Take a Photo
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <div className={CamStyles.textdiv}>
                      <p className={CamStyles.instruction}>Instructions</p>
                      <p className={CamStyles.instructiontext}>
                        Ensure your face is well-lit and centered in the frame.{" "}
                      </p>
                      <p className={CamStyles.instructiontext}>
                        Remove any accessories that may obstruct your face
                        (e.g., sunglasses, hats).
                      </p>
                      <p className={CamStyles.instructiontext}>
                        Click "Capture Selfie" when you're ready.
                      </p>
                    </div>
                    <div className={CamStyles.buttonSection}>
                      <Button
                        style={{
                          color: "#111111",
                          borderColor: "#111111",
                        }}
                        variant="outlined"
                        onClick={() => {
                          setPreviewUrl("");
                          refreshCamera();
                          sessionStorage.setItem("re-take", "retake");
                          // if (reload === true) {
                          window.location.reload();
                          // }
                        }}
                        className={CamStyles.buttonStyles}
                      >
                        Re-Take Photo
                      </Button>
                      <LoadingButton
                        variant="contained"
                        onClick={handleClick}
                        loading={loading}
                        disabled={previewUrl === ""}
                        className={CamStyles.continueButton}
                      >
                        {" "}
                        Continue
                      </LoadingButton>
                    </div>
                  </>
                )}
                <div className={CamStyles.startOverSection}>
                  <img src={icon} className={CamStyles.iconImage} />
                  <p className={CamStyles.paratext}>
                    {" "}
                    if the above mentioned info not yours
                    <span
                      className={CamStyles.startoverbutton}
                      onClick={handleStartOver}
                    >
                      Start Over
                    </span>
                  </p>
                </div>
              </Stack>
            ) : (
              <Stack alignItems={"center"} justifyContent={"center"}>
                <Typography>
                  Grant the Camera access to proceed further{" "}
                </Typography>
              </Stack>
            )}
          </>
          {/* )} */}
        </Stack>

        {isSafari ? (
          <Stack alignItems={"center"} justifyContent={"center"}>
            <Typography sx={{ textAlign: "center" }}>
              Please Press Reload Button after granting camera access
            </Typography>
            <Button
              variant="outlined"
              onClick={() => {
                window.location.reload();
              }}
              style={{ color: "#006779" }}
            >
              Reload
            </Button>
          </Stack>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default WebcamDemo;
