import { createTheme } from "@mui/material/styles";
import { hover } from "@testing-library/user-event/dist/hover";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#006779",
      dark: "#006779",
      
    },
    secondary: {
      main: "#027A48",
    },
    error: {
      main: "#ef5350",
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "white",
          fontWeight:400,
          height:'45px'
        },
        // contained: {
        //   boxShadow: "0px 10px 30px rgba(18, 183, 106, 0.39)",
        //   '&:hover': {
        //     boxShadow: "0px 10px 30px rgba(18, 183, 106, 0.39)",
        //   },
        // },
     
        text: {
          color: "rgba(18, 183, 106, 1)",
        },
      },
    },
    MuiButtonBase:{
    styleOverrides:{
      root:{
        
      }
    }
    }
  
    
    
    

  },


  // breakpoints: {
  //   values: {
  //     xs: 0,       // Extra small screens (0px and up)
  //     sm: 640,     // Small screens (640px and up)
  //     md: 1024,    // Medium screens (1024px and up)
  //     lg: 1280,    // Large screens (1280px and up)
  //     xl: 1920,
  //   },
  // },
});
