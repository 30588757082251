import React, { useEffect, useRef, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  IconButton,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Radio,
  Chip,
  Grid,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";

import { GetFirstTimeLoginAPICall, stageDetailsAPICall } from "./APICalls";

import BankStyles from "./BankDetails.module.scss";
import StageLayout from "./StageLayout";

import { APPID, ENV, SSOLINK, TOKENNAME } from "../../shared/constants";
import { browserName } from "react-device-detect";
import { getCookie } from "./cookies";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function Profile() {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const params: any = Object.fromEntries(urlParams.entries());
  const pathName = useLocation().pathname;
  const [loading, setloading] = useState(false);
  const [userDeniedLocation, setuserDeniedLocation] = useState(false);
  const [intervalId, setintervalId] = useState<any>(null);

  const [completedStages, setCompletedStages] = useState<any>();
  const [userData, setUserData] = useState({
    message: "",
    clientCode: "",
    fullName: "",
    email: "",
    mobile: "",
    pan: "",
    TradingPreference: "",
    isCompleted: false,
  });
  const [tradingPreference, setTradingPreference] = useState([]);

  const callBackURL = document.location.href;

  const cookieData = getCookie(TOKENNAME);
  // const cookieData =
  //  "finupdate_token=eSRYumznqlmYiKN3qHBCZFY5mzDRBhiZ5Z0/jzqCq1vT3gGBqoNrCH2y+0XedPTtO/0u6MdlOgzkaWfSAbHxM6hbKiF+FJ4RLj8Ry8SMVBVpVkKJyeIulAzpbdoBaN2mIvGJNsVPEZflynaWZiAxBDd1kcP5/03QpsMBpg7WF7w=";
  const userId = getCookie("userId");
  // const userId = "KC1593";

  useEffect(() => {
    // console.log("params", params);
    if (cookieData) {
      // sessionStorage.setItem("access-token", cookieData);
      // navigate("/dashboard");
    } else {
      window.location.href = `${SSOLINK}?appId=${APPID}&callback=${callBackURL}&env=${ENV}`;
    }
  }, [cookieData]);

  // console.log("userId",userId,"cookieData",cookieData)

  useEffect(() => {
    // Admin Login

    getLocation();

    return () => clearInterval(intervalId);
  }, [pathName]);

  useEffect(() => {
    if (
      userDeniedLocation &&
      intervalId === null &&
      browserName !== "Firefox"
    ) {
      let interval = setInterval(() => {
        getLocation();
      }, 1000);
      setintervalId(interval);
    }
  }, [userDeniedLocation]);

  // useEffect(() => {
  //   if (captcha === "Captcha Matched") {
  //     //sendMobileNumber();
  //   } else {
  //     setcaptchSubmit(false);
  //   }
  // }, [captcha]);

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      // console.log("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    setuserDeniedLocation(false);

    const latitudeCoords = position.coords.latitude;
    const longitudeCoords = position.coords.longitude;

    sessionStorage.setItem("latitude", latitudeCoords);
    sessionStorage.setItem("longitude", longitudeCoords);

    if (intervalId) {
      clearInterval(intervalId);
    }
  }

  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        // console.log("User denied the request for Geolocation.");
        setuserDeniedLocation(true);
        if (browserName === "Firefox") {
        }

        break;

      case error.POSITION_UNAVAILABLE:
        // console.log("Location information is unavailable.");

        break;
      case error.TIMEOUT:
        // console.log("The request to get user location timed out.");

        break;
      case error.UNKNOWN_ERROR:
        // console.log("An unknown error occurred.");

        break;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userId) {
      GetFirstTimeLogin();
    }
  }, [userId]);

  const GetFirstTimeLogin = () => {
    GetFirstTimeLoginAPICall(userId)
      .then((response: any) => {
        const TP = response.data.data.TradingPreference;

        const filteredData = Object.keys(TP).filter((key) => TP[key] === true);

        const newData: any = filteredData.map((a) => {
          if (a.includes("bse_equity") || a.includes("nse_equity")) {
            return "Equity";
          } else if (
            a.includes("bse_futures_and_options") ||
            a.includes("nse_futures_and_options")
          ) {
            return "Futures and Options";
          }
        });

        const uniqueArray = newData.filter(
          (value: any, index: any) => newData.indexOf(value) === index
        );
        // console.log("TP", uniqueArray);
        setTradingPreference(uniqueArray);

        if (response.data?.data) {
          setUserData(response.data.data);
        }
      })
      .catch((error: any) => {
        // console.log("error", error);
      });
  };

  const handleNext = () => {
    navigate("/finupdate/documents_upload");
  };

  return (
    <div className={BankStyles.mainWrapper}>
      <StageLayout
        completedStages={completedStages}
        stageName={"Bank Details"}
        navigatePath={"/cameramodule"}
      />

      <div className={BankStyles.wrapper}>
        <div>
          <p className={BankStyles.Header}>Your Profile Details</p>
        </div>
        <p className={BankStyles.subText}>
          View your personal information below. Note: You can't edit this
          information
        </p>

        <div className={BankStyles.textFeildwrappernew}>
          <div className={BankStyles.pDetailsField}>
            Client Code
            <TextField
              placeholder="Client Code"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={userData.clientCode}
              fullWidth
              //   onChange={handleEmailName}
              InputProps={{
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                // width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={BankStyles.pDetailsField}>
            Full Name
            <TextField
              placeholder="Username"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={userData.fullName}
              fullWidth
              //   onChange={handleEmailName}
              InputProps={{
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                // width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
        </div>
        <div className={BankStyles.textFeildwrappernew}>
          <div className={BankStyles.pDetailsField}>
            Email
            <TextField
              placeholder="Client Code"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={userData.email}
              fullWidth
              //   onChange={handleEmailName}
              InputProps={{
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                // width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={BankStyles.pDetailsField}>
            Mobile
            <TextField
              placeholder="Username"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={userData.mobile}
              fullWidth
              //   onChange={handleEmailName}
              InputProps={{
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                // width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
        </div>
        <div className={BankStyles.textFeildwrappernew}>
          <div className={BankStyles.pDetailsField}>
            PAN
            <TextField
              placeholder="Client Code"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={userData.pan}
              fullWidth
              //   onChange={handleEmailName}
              InputProps={{
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                // width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={BankStyles.pDetailsField}>
            Trading Preference
            <TextField
              placeholder="Trading Preference"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={
                tradingPreference?.length > 0
                  ? tradingPreference.join(", ")
                  : "-"
              }
              fullWidth
              //   onChange={handleEmailName}
              InputProps={{
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                // width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
        </div>

        <div className={BankStyles.buttonStack}>
          <div>
            {userDeniedLocation ? (
              <p style={{ textAlign: "center" }}>
                Kindly grant the Location Access to proceed further
              </p>
            ) : (
              <LoadingButton
                loading={loading}
                variant="contained"
                type="submit"
                id="linkUsingIFSCContinueButton"
                className={BankStyles.approveButton}
                sx={{
                  textTransform: "uppercase",
                  fontSize: "12px",
                  marginTop: "20px",
                }}
                onClick={handleNext}
              >
                Continue to Update Income Details
              </LoadingButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
