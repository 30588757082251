import Styles from "../../pages/Styles/NomineeStyles.module.scss";
import {
  Stack,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  RadioGroupProps,
  TextFieldProps,
  OutlinedTextFieldProps,
  ButtonProps,
  FormHelperText,
} from "@mui/material";
import { ISelectProofProps } from "../../shared/dtos";

interface IRadioProps extends RadioGroupProps {}

interface ITextProps extends OutlinedTextFieldProps {}

export default function SelectProof(
  props: IRadioProps & ITextProps & ISelectProofProps
) {
  const {
    textFieldValue,
    textFieldLabel,
    textFieldError,
    onTextFieldChange,
    textFieldHelperText,
    radioGroups,
    index,
    currentIndex,
    textFieldName,
    handleRadioButtonFieldChange,
    helperText,
    radioGroupValue,
    values,
  } = props;

  return (
    <div
      className={Styles.radioContainer}
      style={{
        display: currentIndex !== index ? "none" : "block",
      }}
    >
      <p className={Styles.selectproof}>Select Proof (Optional)</p>
      <div>
        <RadioGroup
          {...props}
          onChange={handleRadioButtonFieldChange}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          {radioGroups.map((item: any, index: number) => (
            <FormControlLabel
              key={index}
              value={item}
              control={<Radio />}
              label={item}
              checked={item === radioGroupValue}
            />
          ))}
        </RadioGroup>
        <FormHelperText style={{ color: "#d32f2f" }}>
          {helperText}
        </FormHelperText>
      </div>
      {radioGroupValue && (
        <div>
          <TextField
            {...props}
            className={Styles.proofTextFeild}
            // fullWidth
            // size="small"
            id="outlined-basic"
            label="Enter ID Proof Name"
            variant="outlined"
            name={textFieldName}
            value={textFieldValue.toUpperCase()}
            error={textFieldError}
            helperText={textFieldHelperText}
            onChange={onTextFieldChange}
            // onBlur={handleBlur}
            inputProps={{ maxLength: 50 }}
          />
        </div>
      )}
    </div>
  );
}

function TextFieldComponent(props: ITextProps) {}
