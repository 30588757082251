import React, { useState, useRef, useEffect } from "react";
import {
  Stack,
  Button,
  TextField,
  Modal,
  Fade,
  Box,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import pdfjsLib from "pdfjs-dist";
import { PDFDocument } from "pdf-lib";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ReactCropper from "../Components/ReactCropper/ReactCropper";
import DragandDropFile from "../Components/DragAndDrop/DragandDrop";
import { FileUploader } from "react-drag-drop-files";
import { dataURItoFile } from "../shared/commonfunctions";
import {
  uploadDocumentsAPICall,
  deleteDocumentAPICall,
  stageDetailsAPICall,
} from "../shared/APICalls";
import PreviewModalPopup from "../Components/Modals/PreviewModalPopup";
import PdfModal from "../Components/PdfModal/PdfModal";
import ClearIcon from "@mui/icons-material/Clear";
import { IDeleteDocument } from "../shared/dtos";
import { LoadingButton } from "@mui/lab";
import FormatStyles from "../pages/Styles/FormatChecker.module.scss";
import UploadImage from "../Components/assets/Images/document-upload.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SucessSnackBar from "../Components/SnackBars/SucesssnackBar";
import ErrorSnackBar from "../Components/SnackBars/ErrorSnackBar";
import SnackBar from "../Components/SnackBars/SnackBar";

interface Props {
  acceptedFormats: any;
  previewImage: any;
  documentRef: string;
  documentID: any;
  displayOptions: any;
  stageDetailsData: any;
  name: string;
  purpose: string;
  pdfSize: number;
  imageSize: number;
  selectedFile:any;
  setSelectedFile:any;
  setpancardUrl:any;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vW",
  height: "90vH",
  bgcolor: "background.paper",
  p: 1,
  boxShadow: 24,
  borderRadius: "20px",
};

const FormatterCheckerPanFetch: React.FC<Props> = ({
  acceptedFormats,
  previewImage,
  documentRef,
  documentID,
  displayOptions,
  stageDetailsData,
  name,
  purpose,
  pdfSize,
  imageSize,
  selectedFile,
  setSelectedFile,
  setpancardUrl
}) => {
  
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showInput, setShowInput] = useState(false);
  const [preview, setPreview] = useState<string | null>(null);
  const [showModal, setshowModal] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [showDragandDropModal, setshowDragandDropModal] = useState(false);
  // const [showDragandDropModal, setshowDragandDropModal] = useState(false);
  const [pdfFile, setPdfFile] = useState<any>();

  const [image, setImage] = useState<any>();
  const [pdfPreviewFile, setPdfPreviewFile] = useState<any>();
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [previewPDFFile, setpreviewPDFFile] = useState(false);
  const [panCardDocumentId, setPanCardDocumentId] = useState("");
  const [imageDocumentId, setImageDocumentId] = useState("");
  const [imagedeleteLoading, setImagedeleteLoading] = useState(false);
  const [pdfdeleteLoading, setpdfdeleteLoading] = useState(false);
  const [uploadLoading, setuploadLoading] = useState(false);
  const [pdfUploadLoading, setpdfUploadLoading] = useState(false);
  const [pdfPassword, setPdfPassword] = useState("");
  const [message, setMessage] = useState("");
  const [previewLoading, setpreviewLoading] = useState(false);
  const [previewImageLoading, setpreviewImageLoading] = useState(false);

  let PasswordTyped: any = "";

  let PagesCount: any;
  //  console.log("stageDetailsData", documentRef);

  useEffect(() => {
    setImage(stageDetailsData);
    setSelectedFile(stageDetailsData);
  }, [stageDetailsData]);

  const handleUpload = () => {
    //console.log("drag");
    // setuploadLoading(true)
    setshowDragandDropModal(true);
  };

  const handleFileChangeUpload = async (event: any) => {
    const file = event[0];
    setImage(""); // Accessing the first file from the array
    // console.log(file);
    if (!file) {
      setSelectedFile(null);
      setErrorMessage("");
      return;
    }

    const fileType = file.type.split("/")[1];

    if (!acceptedFormats.includes(fileType)) {
      setSelectedFile(null);
      setErrorMessage(
        `Invalid file format. Only ${acceptedFormats.join(
          ", "
        )} files are accepted.`
      );
      return;
    }

    // if (file.size > UPLOADSIZELIMIT * 1024 * 1024) {
    // PDF File Size check
    if (fileType === "pdf" && file.size > pdfSize * 1024 * 1024) {
      setSelectedFile(null);
      setErrorMessage(`File size exceeds ${pdfSize}MB limit.`);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }
    // Image File Size check
    if (
      ["png", "jpg", "jpeg"].includes(fileType) &&
      file.size > imageSize * 1024 * 1024
    ) {
      setSelectedFile(null);
      setErrorMessage(`File size exceeds ${imageSize}MB limit.`);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }

    if (fileType !== "pdf") {
      setOpen(true);
      // console.log(fileType);
      //console.log("showModal", showModal);
      // setshowModal(true);
    }

    if (fileType === "pdf") {
      // setOpen(true);
      setpreviewPDFFile(true);
      setshowDragandDropModal(false);

      try {
        const bytes = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(bytes, { updateMetadata: false });
        const pageCount = pdfDoc.getPageCount();

        PagesCount = pageCount;
      } catch (e) {
        // window.alert("File is Password Protected, Please Enter Password");
        setShowInput(true);
      }
    }

    setSelectedFile(file);
    setshowModal(true);
    const fileUrl = URL.createObjectURL(file); // Create object URL for the file
    setPreview(fileUrl); // Set the preview with the file URL

    // console.log("Object URL", fileUrl);
    setErrorMessage("");
  };

  const handlePdfPreviewDone = () => {
    setpdfUploadLoading(true);
    previewImage(selectedFile);
    setshowModal(false);
    // console.log("documentRef", documentRef);
    const formData = new FormData();
    const obj: any = {
      file: selectedFile,
      doc_type_ref: purpose,
      file_password: PasswordTyped,
      doc_type_purpose: documentRef,
    };

    formData.append("file", obj.file);
    formData.append("doc_type_ref", obj.doc_type_ref);
    formData.append("file_password", obj.file_password);
    formData.append("doc_type_purpose", obj.doc_type_purpose);
    setpreviewLoading(true);
    uploadDocumentsAPICall(formData)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("response", response);
        // console.log("responseData", responseData.data);
        setpdfUploadLoading(false);
        setShowInput(false);
        setPdfPreviewFile(responseData.data.url);

        documentID(responseData.data.message);
        setpreviewLoading(false);

        if (displayOptions) displayOptions(false);
      })
      .catch((error: any) => {
        previewImage("");
        setshowModal(false);
        setShowInput(false);
        setSelectedFile(null);
        setpreviewPDFFile(false);
        setPdfPreviewFile("");

        // setErrorMessage("Error Uploading")
        setpdfUploadLoading(false);
        setpreviewLoading(false);

        setErrorMessage(
          error.message.includes("Network")
            ? "Error Uploading, Please try again"
            : error.response.data.error.message
        );

        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      });

    setOpen(false);
  };

  const handleCloseModal = () => setshowDragandDropModal(false);

  const handleCallBack = (data: any) => {
    setshowModal(false);
    if (data === "close") {
      // setTimeout(() => {
      setImage("close");
      // }, 3000);
    } else {
      previewImage(data.getCroppedCanvas().toDataURL());
      setImage(data.getCroppedCanvas().toDataURL());

      setSelectedFile(data.getCroppedCanvas().toDataURL());
      const ImageData = data;
      const formData = new FormData();

      if (ImageData !== "undefined") {
        const canvas = ImageData.getCroppedCanvas();
        const dataURL = canvas.toDataURL();
        const file = dataURItoFile(dataURL, "cropped_image.png");
        // console.log("Cropped Image File:", file);

        const obj: any = {
          file: file,
          doc_type_ref: purpose,
          file_password: "123456",
          doc_type_purpose: documentRef,
        };

        formData.append("file", obj.file);
        formData.append("doc_type_ref", obj.doc_type_ref);
        formData.append("file_password", obj.file_password);
        formData.append("doc_type_purpose", obj.doc_type_purpose);
        // console.log("hello");
        setpreviewImageLoading(true);
        uploadDocumentsAPICall(formData)
          .then((response: any) => {
            const responseData = response.data;
            //  console.log("responseDataImage", response);
            setImage(responseData.data.url);
            setSelectedFile(responseData.data.url);
            setImageDocumentId(responseData.data.document_id);
            documentID(responseData.data.message);

            setpreviewImageLoading(false);
            if (displayOptions) displayOptions(false);

            // setshowModal(false)
          })
          .catch((error: any) => {
            // console.log("error", error)
            setpreviewImageLoading(false);
            setSelectedFile(null);
            setErrorMessage(
              error.message.includes("Network")
                ? "Error Uploading, Please try again"
                : error.response.data.error.message
            );

            setTimeout(() => {
              setErrorMessage("");
            }, 5000);
          });
      }
    }
  };
  const handlePreviewClick = () => {
    setshowPreviewModal(true);
  };
  const handleClosePreview = () => {
    setshowPreviewModal(false);
  };

  useEffect(() => {
    setshowDragandDropModal(false);
    if (image && image) {
      setOpen(false);
      if (image === "close") {
        setshowDragandDropModal(false);
      }
    } else {
      setOpen(true);
    }
  }, [image]);

  const handlePreviewPDFClick = () => {
    setshowPreviewModal(true);
  };

  const handledeleteImageClick = () => {
    setImagedeleteLoading(true);
    if (window.confirm("Are you sure you want to delete")) {
      // documentID(imageDocumentId)
      let obj: IDeleteDocument = {
        document_purpose: documentRef,
      };
      deleteDocumentAPICall(obj)
        .then((response: any) => {
          const responseData = response.data;
          documentID("");
          setpancardUrl(null)
          setSelectedFile(null);
          setImagedeleteLoading(false);
          if (displayOptions) {
            displayOptions(true);
          }
        })
        .catch((error: any) => {
          setImagedeleteLoading(false);
        });
    } else {
      setImagedeleteLoading(false);
    }
  };

  const handledeleteClick = () => {
    // documentID(panCardDocumentId);
    setpdfdeleteLoading(true);
    if (window.confirm("Are you sure you want to delete")) {
      let obj: IDeleteDocument = {
        document_purpose: documentRef,
      };
      deleteDocumentAPICall(obj)
        .then((response: any) => {
          const responseData = response.data;
          //console.log("responseData", response);
          documentID("");
          setpancardUrl(null)
          setSelectedFile(null);
          setpreviewPDFFile(false);
          setpdfdeleteLoading(false);

          if (displayOptions) {
            displayOptions(true);
          }
        })
        .catch((error: any) => {
          setpdfdeleteLoading(false);
        });
    } else {
      setpdfdeleteLoading(false);
    }
  };
  const handleClosePdf = () => {
    setshowModal(false);
    setShowInput(false);
    setSelectedFile(null);
    setpreviewPDFFile(false);
  };

  const selectedfileName =
    selectedFile && image !== "close" && selectedFile.name;

  return (
    <Stack alignItems={"flex-end"}>
      <SnackBar message={errorMessage} />

      <Stack direction={"row"}>
        {!previewPDFFile && (!image || image === "close" || !selectedFile) && (
          <LoadingButton
            variant="contained"
            component="label"
            startIcon={<img src={UploadImage} width={"20px"} />}
            style={{
              width: "120px",
              height: "35px",
              color: "white",
              borderRadius: "20px",
              boxShadow: "none",
            }}
            onClick={handleUpload}
            id="documentsUploadButton"
            loading={uploadLoading}
          >
            Upload
          </LoadingButton>
        )}

        {image && image !== "close" && selectedFile && (
          <>
            {!previewImageLoading ? (
              <>
                <Tooltip title="Preview">
                  <LoadingButton
                    style={{
                      marginLeft: "10px",
                      color: "#006779",
                      height: "30px",
                      borderRadius: "20px",
                    }}
                    variant="outlined"
                    onClick={handlePreviewClick}
                    id="documentsPreviewButton"
                    loading={previewImageLoading}
                  >
                    <VisibilityIcon />
                  </LoadingButton>
                </Tooltip>
                <LoadingButton
                  style={{
                    marginLeft: "10px",
                    color: "red",
                    height: "30px",
                    borderRadius: "20px",
                  }}
                  variant="outlined"
                  onClick={handledeleteImageClick}
                  color="error"
                  type="submit"
                  id="documentsDeleteButton"
                  loading={imagedeleteLoading}
                >
                  <DeleteOutlineIcon />
                </LoadingButton>{" "}
              </>
            ) : (
              <LoadingButton
                variant="contained"
                component="label"
                startIcon={<img src={UploadImage} width={"20px"} />}
                style={{
                  width: "120px",
                  height: "35px",
                  color: "white",
                  borderRadius: "20px",
                  boxShadow: "none",
                }}
                onClick={handleUpload}
                id="documentsUploadButton"
                loading={true}
              >
                Upload
              </LoadingButton>
            )}
          </>
        )}
        {previewPDFFile && selectedFile && (
          <>
            {!previewLoading ? (
              <>
                <Tooltip title="Preview">
                  <LoadingButton
                    style={{
                      marginLeft: "10px",
                      color: "#006779",
                      height: "30px",
                      borderRadius: "20px",
                    }}
                    loading={previewLoading}
                    variant="outlined"
                    onClick={handlePreviewPDFClick}
                    id="documentsPDFPreviewButton"
                  >
                    <VisibilityIcon />
                  </LoadingButton>
                </Tooltip>
                <LoadingButton
                  style={{
                    marginLeft: "10px",
                    color: "red",
                    height: "30px",
                    borderRadius: "20px",
                  }}
                  variant="outlined"
                  onClick={handledeleteClick}
                  color="error"
                  id="documentsPDFDeleteButton"
                  loading={pdfdeleteLoading}
                  type="submit"
                >
                  <DeleteOutlineIcon />
                </LoadingButton>
              </>
            ) : (
              <LoadingButton
                variant="contained"
                component="label"
                startIcon={<img src={UploadImage} width={"20px"} />}
                style={{
                  width: "120px",
                  height: "35px",
                  color: "white",
                  borderRadius: "20px",
                  boxShadow: "none",
                }}
                onClick={handleUpload}
                id="documentsUploadButton"
                loading={true}
              >
                Upload
              </LoadingButton>
            )}
          </>
        )}
      </Stack>

      {showDragandDropModal && (
        <>
    
        <DragandDropFile
          showDragandDropModal={showDragandDropModal}
          handleCloseModal={handleCloseModal}
          handleFileChangeUpload={handleFileChangeUpload}
          acceptedFormats={acceptedFormats}
          errorMessage={errorMessage}
          selectedFile={selectedFile}
          showInput={showInput}
          image={image}
        />
        </>
      )}

      {showModal && (
        <Stack alignItems={"center"}>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showModal}
            closeAfterTransition
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={showModal}>
              <Box sx={style}>
                {selectedFile && selectedFile.type === "application/pdf" && (
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    margin={"0 30px"}
                  >
                    <Typography variant="h6">Preview</Typography>
                    <IconButton onClick={handleClosePdf}>
                      <ClearIcon />
                    </IconButton>
                  </Stack>
                )}

                <Stack
                  alignItems={"center"}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "90%",
                    objectFit: "contain",
                  }}
                >
                  {selectedFile && selectedFile.type === "application/pdf" ? (
                    <>
                      <div className={FormatStyles.pdfDesktopPreview}>
                        <embed
                          src={URL.createObjectURL(selectedFile)}
                          width="950"
                          height="100%"
                          type="application/pdf"
                        />
                      </div>
                      <div className={FormatStyles.pdfMobilePreview}>
                        <PdfModal url={URL.createObjectURL(selectedFile)} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                      
                          
                          paddingTop: "5px",
                          
                        }}
                      >
                        {showInput ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <TextField
                                style={{ marginRight: "10px" }}
                                id="outlined-basic"
                                label="Enter Password"
                                variant="outlined"
                                name="password"
                                size="small"
                                // value={pdfPassword}
                                onChange={(event) => {
                                  //  setPdfPassword(event.target.value);
                                  PasswordTyped = event.target.value;
                                }}
                                inputProps={{ maxLength: 15 }}
                              />
                              <p style={{fontSize:'12px', fontWeight:500, margin:'5px'}}>
                                If the File is Password Protected, Please Enter
                                the Password
                              </p>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <LoadingButton
                          sx={{ width: "150px" }}
                          variant="contained"
                          onClick={handlePdfPreviewDone}
                          id="documentsDoneButton"
                          loading={pdfUploadLoading}
                          type="submit"
                          // disabled = {showInput === false && pdfPassword === ""}
                        >
                          Upload
                        </LoadingButton>
                      </div>
                    </>
                  ) : (
                    <ReactCropper
                      imageSource={preview}
                      callBackImage={handleCallBack}
                      // documentRef={documentRef}
                      // callBackDocumentId={handleCallBackDocument}
                    />
                  )}
                </Stack>
              </Box>
            </Fade>
          </Modal>
        </Stack>
      )}

      {showPreviewModal && (
        <Stack alignItems={"center"}>
          <PreviewModalPopup
            selectedFile={selectedFile}
            image={image}
            documentName={documentRef}
            handleClosePreview={handleClosePreview}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default FormatterCheckerPanFetch;
