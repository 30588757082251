import React, { useEffect, useState, useRef } from "react";
import { Stack, Button, Box, Divider } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";

import { LoadingButton } from "@mui/lab";

import Data from "../Components/Data.json";
import {
  KRAUpdateAPICall,
  digiLockerInitAPICall,
  digiLockerStatusAPICall,
  stageDetailsAPICall,
  startOverAPICall,
} from "../shared/APICalls";
import JsonDataToAadhaarFormat from "../Components/JsonDataToAadhaarFormat/JsonDataToAadhaarFormat";
import routesData from "../shared/routes.json";
import AadhaarStyles from "../pages/Styles/AadharKYC.module.scss";
import AadhaarImage from "../Components/assets/Images/aadhaarpagenewimage.svg";
import StageLayout from "../Layouts/StageLayout";
import icon from "../Components/assets/Images/iconnew.svg";
import SnackBar from "../Components/SnackBars/SnackBar";
import KRAScreen from "./KRAscreen";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import BackDrop from "../Components/BackDrop";
import StartOverBackDrop from "../Components/StartOverBackDrop";
import ErrorSnackBar from "../Components/SnackBars/ErrorSnackBar";
import StageLayoutEkyc from "../Layouts/StageLayoutEkyc";


const label = { inputProps: { "aria-label": "Checkbox demo" } };

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vW",
  height: "80vH",
  bgcolor: "white",

  padding: "10px 5px 10px 5px",
};

export default function AadhaarKYCPage() {
  const navigate = useNavigate();
  const [displayAadhaar, setDisplayAadhaar] = useState<any>(true);
  const [displayAadhaarData, setDisplayAadhaarData] = useState<any>(false);
  const [aadhaarData, setaadhaarData] = useState<any>({
    aadhaarData: {},
    isAadhaarFetched: false,
  });
  const [completedStages, setCompletedStages] = useState<any>();
  const [childWindowRef, setchildWindowRef] = useState<any>(null);
  const [closetTimer, setclosetTimer] = useState(false);
  const [loading, setloading] = useState(false);
  const [startOverloading, setstartOverloading] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [aadhaarDataFetchedKYC, setaadhaarDataFetchedKYC] = useState<any>({
    aadhaarData: {},
    isAadhaarFetched: false,
  });
  const [KraData, setKraData] = useState<any>({
    KraData: {},
    isAadhaarFetched: false,
  });
  const navigationData = routesData;
  const [navigatePath, setNavigate] = useState<any>();
  const [message, setMessage] = useState("");
  const [displayFetchedData, setDisplayFetchedData] = useState(true);
  const [backdrop, setBackDrop] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");

  const timerRef = useRef<any>(null);

  const handleOpenClick = () => {
    setloading(true);
    let digilink: string = "";
    let obj = {
      email: "k.chethan222@gmail.com",
      name: "chethan",
    };
    digiLockerInitAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // setloading(false);
        console.log("responseData", responseData);
        digilink = responseData.data.link;
        //  console.log("digilink", digilink);
        setTimeout(()=>{
          const childWindow: any = window.open(
            digilink,
            "_blank",
            "width=500,height=700"
          );
          setchildWindowRef(childWindow);
        })
   
        // setloading(false);
        // if (
        //   responseData.data.stage &&
        //   navigationData.hasOwnProperty(responseData.data.stage)
        // ) {
        //   navigate(navigationData[responseData.data.stage]);
        // }
      })
      .catch((error: any) => {
        setloading(false);
      });
    // const childWindow: any = window.open(
    //   DIGI_LOCKER_LINK,
    //   "_blank",
    //   "width=500,height=500"
    // );
  };
  const handleClose = () => {
    setloading(false);
    setTimeout(() => {
      // navigate("/tradingPreference");
      navigate(navigatePath);
    }, 500);
  };

  const handleContinuetoNextPage = () => {
    let obj = {
      is_continued: true,
    };
    KRAUpdateAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log("responseData", responseData.data.stage);
        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage]);
        }
      })
      .catch((error: any) => {
        //  console.log("error", error.response.data.message);
      });
    setloading(false);
  };

  const handleContinue = () => {
    digiLockerStatusAPICall()
      .then((response: any) => {
        const responseData = response.data;

        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage]);
        }
        // console.log("responseData", responseData.data.stage);
      })
      .catch((error: any) => {
        // console.log("error", error.response.data.message);
      });
    setloading(false);
  };

  useEffect(() => {
    if (!closetTimer && childWindowRef) {
      //  console.log("timer trigger");
      timerRef.current = setInterval(checkChild, 500);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [childWindowRef, closetTimer]);

  function checkChild() {
    // console.log("timer");
    if (childWindowRef && childWindowRef.closed) {
      clearInterval(timerRef.current);
      // console.log("Child window closed");

      digiLockerStatusAPICall()
        .then((response: any) => {
          const responseData = response.data;
          setloading(false);
          if (
            responseData.data.stage &&
            navigationData.hasOwnProperty(responseData.data.stage)
          ) {
            setNavigate(navigationData[responseData.data.stage]);
          }

          //  console.log("responseData", responseData.data.stage);
          if (response.status === 200) {
            // console.log("true");
            setshowModal(true);
            
            setaadhaarDataFetchedKYC(responseData.data);
            setDisplayFetchedData(false);
            setDisplayFetchedData(false);
           
          } else {
            setshowModal(false);
          }
          setclosetTimer(true);
        })
        .catch((error: any) => {
          // console.log("error", error.response.data.message);
          setloading(false);
          setMessage(error.response.data.message);
          setshowModal(false);
          setDisplayFetchedData(true);
          setTimeout(() => {
            setMessage("");
          }, 8000);
        });
    }
  }

  const handleStartOver = () => {
    if (window.confirm("Are you sure you want to restart, on pressing Yes all the Data will be deleted")) {
      setBackDrop(true);
      setstartOverloading(true);
      startOverAPICall()
        .then((response: any) => {
          const responseData = response.data;
          // console.log("responseData", response);
          setstartOverloading(false);
          setBackDrop(false);
          navigate("/");
          window.location.reload();
        })
        .catch((error: any) => {
          setBackDrop(false);
          setstartOverloading(false);
        });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    let obj: any = {
      stage: "fetch_details",
    };

    stageDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        setCompletedStages(responseData.completed_stages);
      
    
        setRejectMessage(response.data.data.StageDetails.ErrorMessage);
  
        if (response.data.data.StageDetails.KraStatus === true) {
          setKraData(response.data.data.StageDetails);
          setDisplayFetchedData(true);
          setDisplayAadhaarData(true);
          setDisplayAadhaar(false);
        }
      
        if (response.data.data.StageDetails.KraStatus === false) {
          setDisplayAadhaar(true);

          setDisplayFetchedData(true);
          setDisplayAadhaarData(true);
        }
       
        if (response.data.data.StageDetails.AadhaarFetched === true) {
          // setDisplayFetchedData(false);
          // setDisplayAadhaarData(false)
          setDisplayAadhaar(true);

          setaadhaarData(response.data.data.StageDetails);
          setDisplayAadhaarData(false);
          //  console.log(response.data.data.StageDetails);
        }

      })
      .catch((error: any) => {
        setDisplayAadhaarData(true);
        // console.log("error", error);
      });
  }, []);

  const handleUpdateAadhaarClick = () => {
    setDisplayAadhaar(true);
    let obj = {
      is_continued: false,
    };
    KRAUpdateAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData.data.stage);
        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage]);
        }
      })
      .catch((error: any) => {
        // console.log("error", error.response.data.message);
      });
    setloading(false);
  };
  const handleFetcedClick = () => {
    setDisplayAadhaar(false);
  };

  return (
    <div className={AadhaarStyles.mainWrapper}>
      <StageLayoutEkyc
        completedStages={completedStages}
        stageName={"Fetched Details"}
        navigatePath={"/aadhaarkyc"}
      />
      {displayAadhaar ? (
        <div className={AadhaarStyles.wrapper}>
          <div className={AadhaarStyles.titleSection}>
            {displayFetchedData && displayAadhaarData ? (
              <p className={AadhaarStyles.title}> Link Your Aadhaar </p>
            ) : (
              ""
            )}
          </div>

          {displayFetchedData && (
            <div className={AadhaarStyles.container}>
              {displayAadhaarData ? (
                <>
                  <div className={AadhaarStyles.leftcontainer}>
                    <p className={AadhaarStyles.leftcontainerText}>
                      1.Click on the KYC button below.
                    </p>
                    <p className={AadhaarStyles.leftcontainerText}>
                      2.Enter your Aadhaar Number.
                    </p>
                    <p className={AadhaarStyles.leftcontainerText}>
                      3.Enter the OTP received on your mobile number linked.
                    </p>
                  </div>
                  <div className={AadhaarStyles.rightcontainer}>
                    <img src={AadhaarImage} className={AadhaarStyles.image} />
                  </div>
                </>
              ) : (
                <Stack>
                  {aadhaarData && aadhaarData.AadhaarName ? (
                    <Box>
                      <JsonDataToAadhaarFormat
                        aadhaarData={aadhaarData}
                        aadaarFetched={aadhaarData.isAadhaarFetched}
                      />
                    </Box>
                  ) : (
                    <div style={{ height: "100vh" }}>
                      <BackDrop />
                    </div>
                  )}
                </Stack>
              )}
            </div>
          )}
          {!displayFetchedData && (
            <div>
              <Box>
                <JsonDataToAadhaarFormat
                  aadhaarData={aadhaarDataFetchedKYC}
                  aadaarFetched={aadhaarDataFetchedKYC.isAadhaarFetched}
                />
                <div className={AadhaarStyles.buttonStack}>
                  <div className={AadhaarStyles.redoStyles}>
                    {/* <Button
                      id="aadhaarApproveButton"
                      variant="outlined"
                      sx={{
                        color: "green",
                        width: "100px",
                        marginRight: { xs: "20px", sm: "10px" },
                        marginTop:'10px'
                      }}
                      onClick={() => {
                        setDisplayFetchedData(true);
                       
                      }}
                      className={AadhaarStyles.redoButton}
                    >
                      Redo
                    </Button> */}

                    <Button
                      id="aadhaarApproveButton"
                      variant="contained"
                      onClick={handleClose}
                      className={AadhaarStyles.approveButton}
                      sx={{ marginTop:'10px'}}
                    >
                      Continue
                    </Button>
                  </div>
                  <div className={AadhaarStyles.startOverSection}>
                    <img src={icon} className={AadhaarStyles.iconImage} />
                    <p className={AadhaarStyles.paratext}>
                      {" "}
                      if the above mentioned info not yours
                      <span
                        className={AadhaarStyles.startoverbutton}
                        onClick={handleStartOver}
                      >
                        Start Over
                      </span>
                    </p>
                  </div>
                </div>
              </Box>
            </div>
          )}
          <ErrorSnackBar message={rejectMessage} />
          <Stack alignItems={"center"} paddingBottom={"20px"}>
            <div className={AadhaarStyles.displayBack}>
              {!displayAadhaar && (
                <Button
                  variant="outlined"
                  sx={{ color: "rgba(18, 183, 106, 1)", marginTop: "15px" }}
                  onClick={handleFetcedClick}
                >
                  Continue with Fetched
                </Button>
              )}

              {displayFetchedData ? (
                <div>
                  <SnackBar message={message} />
                  <div className={AadhaarStyles.buttonStyles}>
                    <div>
                      {displayAadhaarData === true ? (
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          className={AadhaarStyles.buttonKYC}
                          onClick={handleOpenClick}
                          loading={loading}
                          id="aadharOpenLinkButton"
                        >
                          Link From Digilocker
                        </LoadingButton>
                      ) : (
                        <div className={AadhaarStyles.redoStyles}>
                          <Button
                            id="aadhaarApproveButton"
                            variant="outlined"
                            onClick={() => {
                              setDisplayAadhaarData(true);
                            }}
                            sx={{
                              color: "green",
                              width: "100px",
                             
                              marginRight: { xs: "20px", sm: "10px" },
                            }}
                            className={AadhaarStyles.redoButton}
                          >
                            Redo
                          </Button>
                          <Button
                            id="aadhaarApproveButton"
                            variant="contained"
                            onClick={handleContinue}
                            className={AadhaarStyles.approveButton}
                          >
                            Continue
                          </Button>
                        </div>
                      )}
                    </div>

                    <div className={AadhaarStyles.startOverSection}>
                      <img src={icon} className={AadhaarStyles.iconImage} />
                      <p className={AadhaarStyles.paratext}>
                        {" "}
                        if the above mentioned info not yours
                        <span
                          className={AadhaarStyles.startoverbutton}
                          onClick={handleStartOver}
                        >
                          Start Over
                        </span>
                      </p>
                    </div>
                    <div>{backdrop && <StartOverBackDrop />}</div>
                  </div>
                  <div className={AadhaarStyles.bottomContentContainer}>
                    <Divider />

                    <p className={AadhaarStyles.bottomContentPara}>
                      *By clicking the above button. I agree that I have read
                      the{" "}
                      <a
                        href="https://www.thefirstock.com/terms_and_conditions.html"
                        target="_blank"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        Aadhaar user consent document
                      </a>{" "}
                      and voluntarily consent to share my Aadhaar information
                      (excluding the Aadhaar number) with Firstock for
                      conducting online KYC for opening a trading and Demat
                      account adhering to KRA regulation (2011) and PMLA
                      guidlines (2002).
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </Stack>
        </div>
      ) : (
        <div className={AadhaarStyles.fetchWrapper}>
          <KRAScreen
            aadhaarData={KraData}
            aadaarFetched={KraData.isAadhaarFetched}
          />
          {backdrop && <StartOverBackDrop />}
          <div className={AadhaarStyles.fetchButtons}>
            <Button
              variant="outlined"
              className={AadhaarStyles.fetchUpdate}
              onClick={handleUpdateAadhaarClick}
            >
              Update as on Aadhaar
            </Button>
            <Button
              variant="contained"
              className={AadhaarStyles.fetchContinue}
              onClick={handleContinuetoNextPage}
            >
              Continue
            </Button>
          </div>
          <div className={AadhaarStyles.startOverSection}>
            <img src={icon} className={AadhaarStyles.iconImage} />
            <p className={AadhaarStyles.paratext}>
              {" "}
              if the above mentioned info not yours
              <span
                className={AadhaarStyles.startoverbutton}
                onClick={handleStartOver}
              >
                Start Over
              </span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

function MyComponent({ displayKyc, aadhaarDataDetails }) {
  const [childWindowRef, setchildWindowRef] = useState<any>(null);
  const [closetTimer, setclosetTimer] = useState(false);
  const [loading, setloading] = useState(false);
  const [startOverloading, setstartOverloading] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [showModal, setshowModal] = useState(false);
  const [aadhaarDataFetchedKYC, setaadhaarDataFetchedKYC] = useState<any>({
    aadhaarData: {},
    isAadhaarFetched: false,
  });
  const navigationData = routesData;
  const [navigatePath, setNavigate] = useState<any>();

  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const timerRef = useRef<any>(null);

  const handleOpenClick = () => {
    setloading(true);
    let digilink: string = "";
    let obj = {
      email: "k.chethan222@gmail.com",
      name: "chethan",
    };
    digiLockerInitAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // setloading(false);
        // console.log("responseData", responseData);
        digilink = responseData.data.link;
        //  console.log("digilink", digilink);
        const childWindow: any = window.open(
          digilink,
          "_blank",
          "width=500,height=700"
        );
        setchildWindowRef(childWindow);
      })
      .catch((error: any) => {
        setloading(false);
      });
  };
  const handleClose = () => {
    setOpen(false);
    setloading(false);
    setTimeout(() => {
      navigate(navigatePath);
    }, 500);
  };

  useEffect(() => {
    if (!closetTimer && childWindowRef) {
      //  console.log("timer trigger");
      timerRef.current = setInterval(checkChild, 500);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [childWindowRef, closetTimer]);

  function checkChild() {
    //  console.log("timer");
    if (childWindowRef && childWindowRef.closed) {
      clearInterval(timerRef.current);
      // console.log("Child window closed");

      digiLockerStatusAPICall()
        .then((response: any) => {
          const responseData = response.data;
          setloading(false);
          if (
            responseData.data.stage &&
            navigationData.hasOwnProperty(responseData.data.stage)
          ) {
            setNavigate(navigationData[responseData.data.stage]);
          }
          // console.log("responseData", responseData.data.stage);
          if (response.status === 200) {
            // console.log("true");
            setshowModal(true);
            setaadhaarDataFetchedKYC(responseData.data);
            aadhaarDataDetails(responseData.data);
          } else {
            setshowModal(false);
          }
          setclosetTimer(true);
        })
        .catch((error: any) => {
          // console.log("error", error.response.data.message);
          setloading(false);
          setMessage(error.response.data.message);
          setshowModal(false);
          setTimeout(() => {
            setMessage("");
          }, 8000);
        });
    }
  }

  const handleStartOver = () => {
    if (window.confirm("Are you sure you want to restart")) {
      setstartOverloading(true);
      startOverAPICall()
        .then((response: any) => {
          const responseData = response.data;
          // console.log("responseData", response);
          setstartOverloading(false);
          navigate("/");
          window.location.reload();
        })
        .catch((error: any) => {
          setstartOverloading(false);
        });
    }
  };

  return (
    <>
      <div>
        <p style={{ color: "red" }}> {message}</p>
        <div className={AadhaarStyles.buttonStyles}>
          <div>
            {displayKyc === true && (
              <LoadingButton
                type="submit"
                variant="contained"
                className={AadhaarStyles.buttonKYC}
                onClick={handleOpenClick}
                loading={loading}
                id="aadharOpenLinkButton"
              >
                Link From Digilocker
              </LoadingButton>
            )}
          </div>

          <div className={AadhaarStyles.startOverSection}>
            <img src={icon} className={AadhaarStyles.iconImage} />
            <p className={AadhaarStyles.paratext}>
              {" "}
              if the above mentioned info not yours
              <span
                className={AadhaarStyles.startoverbutton}
                onClick={handleStartOver}
              >
                Start Over
              </span>
            </p>
          </div>
        </div>
      </div>

      {showModal && <Stack></Stack>}
    </>
  );
}