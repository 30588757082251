import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Avatar,
} from "@mui/material";
import DoneImage from "../../Components/assets/Images/doneicon.png";
import { useLocation } from "react-router-dom";
import styles from "./AccountClosureAddFunds.module.scss";

// import { digiLockerAuthenticationAPICall } from "../shared/APICalls";

import { useDispatch, useSelector } from "react-redux";
import { esignsaveDocumentAPICall } from "./APICallsAccountClosure";

export default function EsignSuccessPage() {
  const [countDown, setCountDown] = useState(3);

  const pathName = useLocation().search;
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlParams.entries());
  const doc_id = params.digio_doc_id;
  const state = params.state;

  const shareCode = params.shareCode;

  //  console.log(pathName)
  sessionStorage.setItem("access-token", state);

  function sendDetails() {
    let obj: any = {
      status: "success",
      doc_id: doc_id
    };
    esignsaveDocumentAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("res", responseData)
        sessionStorage.setItem("esigndoc",responseData.data.url)
        setTimeout(() => {
          window.close();
        }, 3000);
      })
      .catch((error: any) => {});
  }

  useEffect(() => {
    sendDetails();

  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountDown((prevCountDown) => prevCountDown - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };


  }, []);

  useEffect(() => {
    if (countDown < 0) {
      setCountDown(3);
    }
  


  }, [countDown]);


  return (
    <div className={styles.mainWrapperTop}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={DoneImage} style={{ width: "100px", height: "100px" }} />
        <div style={{ textAlign: "center", padding: "10px 10px" }}>
          <Typography variant="h5"> Success </Typography>
          <Typography style={{ marginTop: "10px" }}>
            We have obtained your documents
          </Typography>
        </div>
        <div style={{ textAlign: "center" }}>
          <Typography style={{ fontSize: "12px", padding: "0 5px" }}>
            The page will close automatically in {countDown} seconds, if not close manually
            to continue.
          </Typography>
        </div>
      </div>
    </div>
  );
}
