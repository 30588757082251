import React, { useState, useEffect } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  FormHelperText,
  IconButton,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { css } from "@emotion/react";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { checkUserLoggedIn } from "../shared/commonfunctions";
import { string } from "yargs";
import { IPanDetails } from "../shared/dtos";
import { panDetailsAPICall, stageDetailsAPICall } from "../shared/APICalls";
import routesData from "../shared/routes.json";
import PanStyles from "../pages/Styles/PanVerification.module.scss";
import StageLayout from "../Layouts/StageLayout";
import calenderImage from "../Components/assets/Images/calendar.svg";
import eyeIcon1 from "../Components/assets/Images/eye-slash.svg";
import SnackBar from "../Components/SnackBars/SnackBar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorSnackBar from "../Components/SnackBars/ErrorSnackBar";
import PanImage from "../Components/assets/Images/panpagelatestimage.svg";
import StageLayoutEkyc from "../Layouts/StageLayoutEkyc";

const schema = Yup.object().shape({
  // name: Yup.string()
  //   .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
  //   .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
  //   .required("Please enter your name"),
  pannumber: Yup.string()

    .required("PanNumber is Required")
    .max(10)
    .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Enter Valid PanNumber")
    .transform((value: any) => value && value.toUpperCase()),

  dateofBirth: Yup.string().required("Date of Birth is a required field"),
  // .max(9, "Wrong Date of Birth"),
});

function DateIcon(props) {
  return <img src={calenderImage} style={{ width: "25px" }} />;
}

export default function PanVerificationPage() {
  const navigate = useNavigate();
  const [pan, setPan] = useState("");
  // const [dob, setDob] = useState<any>(null);
  const [dobError, setDobError] = useState("");
  const [loading, setloading] = useState(false);
  const [message, setMessage] = useState<any>("");
  const [panPrefill, setPanPrefill] = useState("");
  const [namePrefill, setNamePrefill] = useState("");
  const [dobPrefill, setPanDobfill] = useState<any>("");
  const [completedStages, setCompletedStages] = useState<any>();
  const [prefilloading, setprefilloading] = useState(true);
  const [rejectMessage, setRejectMessage] = useState("");

  const [open, setOpen] = React.useState(true);

  const navigationData = routesData;

  useEffect(() => {
    window.scrollTo(0, 0);
    checkUserLoggedIn(navigate);

    let obj: any = {
      stage: "pan_details",
    };

    stageDetailsAPICall(obj)
      .then((response: any) => {
        setprefilloading(false);
        const responseData = response.data;
        // console.log("res", responseData.completed_stages);
        setCompletedStages(responseData.completed_stages);
        const inputDate = response.data.data.StageDetails.DOB;
        const parts = inputDate.split("-");
        const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
        // console.log(responseData);
        // console.log("res", responseData.completed_stages);
        setRejectMessage(response.data.data.StageDetails.ErrorMessage);
        // setNamePrefill(response.data.data.StageDetails.PanName);
        setPanPrefill(response.data.data.StageDetails.PanNumber);
        // setPanDobfill(formattedDate);
        if (response.data.data.StageDetails.DOB) {
          setPanDobfill(dayjs(response.data.data.StageDetails.DOB));
        }
      })
      .catch((error: any) => {
        // console.log("error", error);
        setprefilloading(false);
      });
  }, []);

  //console.log("dobPrefill", dobPrefill);

  function panDetails(date: any, pan: any, name: any) {
    setloading(true);
    let obj: IPanDetails = {
      name_as_pan: name.toUpperCase().trim(),
      pan_card: pan.toUpperCase(),
      dob: date,
    };
    // console.log("obj", obj);
    panDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log(response);
        // if(responseData.error.message){
        //   setMessage(responseData.error.message)
        // //     setTimeout(()=>{
        // //   setMessage("")
        // // },5000)
        // }

        //console.log("response", response);

        if (!responseData) {
          setMessage("Failed to get Data");
          setTimeout(() => {
            setMessage("");
          }, 5000);
        }

        setloading(false);
        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage]);
        }
      })
      .catch((error: any) => {
        //console.log("error", error);
        setloading(false);

        setMessage(
          error.message.includes("Network")
            ? "Error Occured, Please try again"
            : error.response.data.error.message
        );

        setTimeout(() => {
          setMessage("");
        }, 5000);

        // if (error.response.data.error.message) {
        //   setMessage(error.response.data.error.message);
        //   setloading(false);

        //   setTimeout(() => {
        //     setMessage("");
        //   }, 5000);
        // }

        // if (error.message && error.message.includes("Error")) {
        //   setMessage("Please Try again");
        //   setTimeout(() => {
        //     setMessage("");
        //   }, 5000);
        // }
      });
  }

  const handleClick = (
    dob: any,
    formatedDate: any,
    panNumber: any,
    name: any
  ) => {
    const dobLimit = new Date();
    dobLimit.setFullYear(dobLimit.getFullYear() - 18);
    if (!dob || dob > dobLimit) {
      // setDobError(true);

      setDobError("Your Age is Below 18");
      setTimeout(() => {
        setDobError("");
      }, 3000);
      // alert("Your Age is Below 18");
    } else {
      panDetails(formatedDate, panNumber, name);
    }
  };

  const eighteenYearsAgo = dayjs().subtract(18, "year");
  const maxDate = dayjs("1940-01-01");

  const renderYear = (year) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {year - maxDate.year()}
      </div>
    );
  };

  const handlePanChange = (event: any) => {
    const input = event.target.value;
    const regex = /^[a-zA-Z0-9]*$/;
    if (regex.test(input) && input.length <= 10) {
      setPan(input.toUpperCase());
    }
  };

  return (
    <>
      <div className={PanStyles.mainWrapper}>
        <StageLayoutEkyc
          completedStages={completedStages}
          stageName={"Pan Number"}
          navigatePath={"/panverification"}
        />
        <div className={PanStyles.wrapper}>
          {!prefilloading ? (
            <div className={PanStyles.LeftContainer}>
              <img src={PanImage} alt="Image" className={PanStyles.image} />
            </div>
          ) : (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <div className={PanStyles.RightContainer}>
            {!prefilloading ? (
              <>
                <div className={PanStyles.headerSection}>
                  <p className={PanStyles.signup}>Enter Your PAN Details</p>
                  <p className={PanStyles.subText}>
                    Regulatory Requirements: Safely Share Your PAN Card Details.
                    Upholding Financial Integrity for a Secure and Compliant
                    Transaction Experience.
                  </p>
                </div>

                <Formik
                  validationSchema={schema}
                  initialValues={{
                    // name: namePrefill === "" ? "" : namePrefill,
                    pannumber: panPrefill === "" ? "" : panPrefill,
                    dateofBirth: dobPrefill === "" ? null : dobPrefill,
                  }}
                  onSubmit={(values: any) => {
                    const date = new Date(values.dateofBirth.$d);
                    // console.log(date);
                    const formattedDate = date.toLocaleDateString("en-GB", {
                      year: "numeric",
                      day: "2-digit",
                      month: "2-digit",
                    });
                    const [month, day, year] = formattedDate.split("/");
                    const formatDate = `${year}-${day}-${month}`;
                    //  console.log(formatDate);
                    handleClick(
                      values.dateofBirth.$d,
                      formatDate,
                      values.pannumber,
                      "DEMO"
                    );
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => {
                    // console.log("errors", errors);
                    // console.log("values", values);
                    return (
                      <>
                        <form
                          noValidate
                          onSubmit={handleSubmit}
                          onKeyDown={(event) => {
                            event.code === "Enter" ||
                              (event.code === "NumpadEnter" && handleSubmit());
                            // event.code.includes("Enter") && handleSubmit()
                          }}
                          style={{ width: "100%" }}
                        >
                          <>
                            <div
                              // direction={"row"}
                              // alignItems={"center"}
                              // marginTop={"15px"}
                              // direction={{xs:'column', lg:'row'}}
                              className={PanStyles.textfeildSection}
                            >
                              {/* <div>
                                <TextField
                                  label="Name as per Pancard"
                                  className={PanStyles.textfeild}
                                  name="name"
                                  variant="outlined"
                                  id="outlined-basic"
                                  value={values.name.toUpperCase()}
                                  error={touched.name && Boolean(errors.name)}
                                  helperText={
                                    touched.name && errors.name?.toString()
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div> */}
                              <div>
                                <TextField
                                  className={PanStyles.textfeild}
                                  id="outlined-basic"
                                  label="Enter PAN Number"
                                  name="pannumber"
                                  variant="outlined"
                                  value={values.pannumber.toUpperCase()}
                                  error={
                                    touched.pannumber &&
                                    Boolean(errors.pannumber)
                                  }
                                  helperText={
                                    touched.pannumber &&
                                    errors.pannumber?.toString()
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  inputProps={{ maxLength: 10 }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Typography color="textSecondary">
                                          {values.pannumber.length}/10
                                        </Typography>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                              {/* <FormHelperText style={{ color: "#d32f2f" }}>
                            {message}
                          </FormHelperText> */}
                              <div>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                      className={PanStyles.datePicker}
                                      components={{ OpenPickerIcon: DateIcon }}
                                      minDate={maxDate}
                                      maxDate={eighteenYearsAgo}
                                      format="DD/MM/YYYY"
                                      renderYear={renderYear}
                                      views={["year", "month", "day"]}
                                      label="Enter Date of Birth As per Your PAN"
                                      name="dateofBirth"
                                      value={values.dateofBirth}
                                      // value={
                                      //   values.dateofBirth

                                      // }
                                      onChange={(date) => {
                                        handleChange({
                                          target: {
                                            name: "dateofBirth",
                                            value: date,
                                          },
                                        });
                                        //  console.log("date", date);
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        touched.dateofBirth &&
                                        Boolean(errors.dateofBirth)
                                      }
                                      helperText={
                                        touched.dateofBirth &&
                                        errors.dateofBirth
                                      }
                                      renderInput={(params: any) => (
                                        <TextField {...params} />
                                      )}
                                      {...(DatePicker as any)}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                                <FormHelperText style={{ color: "#d32f2f" }}>
                                  {dobError}
                                </FormHelperText>
                              </div>
                            </div>
                            <div className={PanStyles.mobileImageView}>
                              <img
                                src={PanImage}
                                alt="Image"
                                className={PanStyles.imageMobile}
                              />
                            </div>
                            <div className={PanStyles.buttonSection}>
                              {(values.dateofBirth && values.pannumber) ||
                              (dobPrefill && panPrefill) ? (
                                <LoadingButton
                                  type="submit"
                                  variant="contained"
                                  loading={loading}
                                  id="panVerificationContinueButton"
                                  className={PanStyles.button}
                                  onClick={() => {
                                    handleSubmit();
                                  }}
                                >
                                  Continue
                                </LoadingButton>
                              ) : (
                                <Button
                                  type="submit"
                                  variant="contained"
                                  disabled
                                  className={PanStyles.button}
                                >
                                  Continue
                                </Button>
                              )}
                            </div>
                            <ErrorSnackBar message={rejectMessage} />
                            <SnackBar message={message} />
                          </>
                        </form>
                      </>
                    );
                  }}
                </Formik>
              </>
            ) : (
              <>
                {/* <div
             
                >
                  <TextField
                    className={PanStyles.textfeild}
                    id="outlined-basic"
                    label="Enter PAN Number"
                    name="pannumber"
                    variant="outlined"
                    value={""}
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography color="textSecondary">{""}/10</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                 

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        className={PanStyles.datePicker}
                        components={{ OpenPickerIcon: DateIcon }}
                        minDate={maxDate}
                        maxDate={eighteenYearsAgo}
                        format="DD/MM/YYYY"
                        renderYear={renderYear}
                        label="Enter Date of Birth As per Your PAN"
                        name="dateofBirth"
                        value={""}
                        // value={
                        //   values.dateofBirth

                        // }

                        renderInput={(params: any) => <TextField {...params} />}
                        {...(DatePicker as any)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className={PanStyles.buttonSection}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled
                    className={PanStyles.button}
                  >
                    Continue
                  </Button> */}

                {/* </div> */}
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={PanStyles.bottomContentContainer}>
        <Divider />

        <div className={PanStyles.bottomContentSection}>
          <p className={PanStyles.bottomContent}>
            Online acount opening requires your mobile number to be linked to
            Aadhaar. You can check if your mobile number is linked to Aadhaar{" "}
            <a
              href="https://myaadhaar.uidai.gov.in/verify-email-mobile"
              target="_blank"
            >
              here
            </a>
            . If your mobile number isn't linked to Aadhaar, please open your
            account offline.
            <a
              href="https://www.thefirstock.com/getting_started.html#offline"
              target="_blank"
              className={PanStyles.aTag}
            >
              Know here.
            </a>
          </p>
          <p className={PanStyles.bottomContent}>
            Online account opening involves using Digilocker.gov.in to obtain
            your identity proof if you are not already KRA verified, and
            digitally signing your application from with Aadhaar eSign using
            Digio (licensed ASP). We don not collect or store your Aadhaar
            number, and neither of these services reveal your Aadhaar number to
            us. If you do not wish to do this, please use our{" "}
            <a
              href="https://www.thefirstock.com/getting_started.html#offline"
              target="_blank"
              className={PanStyles.aTag}
            >
              offline forms
            </a>{" "}
            for account opening. Firstock does not perform eKYC and does not
            collect or store Aadhaar numbers.
          </p>
          <p className={PanStyles.bottomContent}>
            Please Note : Your account name would be taken as per the name
            registered on the Income Tax database / as per your PAN.
          </p>
          {/* <p className={PanStyles.bottomContent}>
            If you are looking to open a HUF, Corporate, Partnership, or an NRI
            account, you have to use the{" "}
            <a href="" className={PanStyles.aTag}>
              offline forms
            </a>
            . For help,{" "}
            <a href="" className={PanStyles.aTag}>
              click here.
            </a>
          </p> */}
        </div>
      </div>
    </>
  );
}
