import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Avatar,
} from "@mui/material";
import DoneImage from "../Images/doneicon.png";
import { useNavigate } from "react-router-dom";
import completedImage from "../Components/assets/Images/completedimagenew.svg";
import Completedstyles from "../pages/Styles/Completed.module.scss";
import icon from "../Components/assets/Images/iconnew.svg";
import { LoadingButton } from "@mui/lab";
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import facebookImage from "../Components/assets/Images/Facebook.svg";
import twitterImage from "../Components/assets/Images/twitter.svg";
import linkedinImage from "../Components/assets/Images/linkedin.svg";
import whatsappImage from "../Components/assets/Images/Whatsappnew.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { stageDetailsAPICall } from "../shared/APICalls";
import ApprovedSnackBar from "../Components/SnackBars/ApprovedSnackBar";
import CopiedSnackBar from "../Components/SnackBars/CopiedSnackBar";
import GooglePlayStore from "../Components/assets/Images/GooglePlayStore.svg";
import AppStore from "../Components/assets/Images/AppStore.svg";
import MicroSoft from "../Components/assets/Images/MicrosoftStore.svg";


export default function CompletedPage() {
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [message, setmessage] = useState("");
  // const [clientCode, setClientCode] = useState("");
  // const[ ClientCode , setClientCode] = useState("")
  // const[ ClientName , setClientName] = useState("")

  const ClientCode = sessionStorage.getItem("client-id");
  const ClientName = sessionStorage.getItem("client_name");

 // console.log(ClientCode);


 
  const baseUrl = window.location.href.split('/completed')[0];
 // console.log(baseUrl);
  const urlToShare = `${baseUrl}?c=${ClientCode}`;

  return (
    <div className={Completedstyles.wrapper}>
      <div className={Completedstyles.container}>
        <p className={Completedstyles.header}>
          Congratulations! Your Account is Active
        </p>
        <img src={completedImage} className={Completedstyles.image} />

        <p className={Completedstyles.subText}>
      
        </p>

       
        <div className={Completedstyles.shareSection}>
          <div>
            <div className={Completedstyles.referralSection}>
              <div>
                <p className={Completedstyles.referraltext}>
                  Your Referral Link:
                </p>
              </div>
              <div className={Completedstyles.imageSection}>
                <FacebookShareButton url={urlToShare}>
                  <img src={facebookImage} />
                </FacebookShareButton>
                <LinkedinShareButton url={urlToShare}>
                  <img src={linkedinImage} />
                </LinkedinShareButton>
                <TwitterShareButton url={urlToShare}>
                  <img src={twitterImage} />
                </TwitterShareButton>
                <WhatsappShareButton url={urlToShare}>
                  <img src={whatsappImage} />
                </WhatsappShareButton>
              </div>
            </div>
            <div className={Completedstyles.copyreferral}>
   
              <div className={Completedstyles.copyreferralTextFeild}>
                <div className={Completedstyles.copyreferralurlText}>
                  {urlToShare}
                </div>
                <CopyToClipboard
                  text={urlToShare}
                  onCopy={() => {
                    setCopied(true);
                    setmessage("Copied to Clipboard");
                    setTimeout(() => {
                      setmessage("");
                    }, 4000);
                  }}
                >
                  <Button
                    className={Completedstyles.copyreferralButton}
                    variant="contained"
                    sx={{ height: "35px", borderRadius: "10px", width: "50px" }}
                  >
                    Copy
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div className={Completedstyles.appstoreSection}>
            <p className={Completedstyles.sharetext}>
              Introducing our Firstock apps
            </p>
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.noren.firstock"
                target="_blank"
              >
                <img
                  src={GooglePlayStore}
                  className={Completedstyles.Googlestoreimage}
                />
              </a>

              <a
                href="https://apps.apple.com/in/app/firstock-investing-simplified/id1567070436"
                target="_blank"
              >
                <img src={AppStore} className={Completedstyles.Appstoreimage} />
              </a>

              <a href="https://firstock-common.s3.ap-south-1.amazonaws.com/app/Firstock_INV_Live_Firstock_2023.7.4-setup.zip">
                <img
                  src={MicroSoft}
                  className={Completedstyles.Microstoreimage}
                />
              </a>
            </div>
          </div>
        </div>
       
        <CopiedSnackBar message={message} />

        <div className={Completedstyles.bottomTextSection}>
          <p className={Completedstyles.bottomText}>
            Free Equity Delivery :{" "}
            <span className={Completedstyles.bottomSubText}>
              All equity delivery investments (NSE, BSE), are absolutely free —
              Zero Commission.
            </span>
          </p>
          <p className={Completedstyles.bottomText}>
            Intraday and F&O trades :{" "}
            <span className={Completedstyles.bottomSubText}>
              Flat ₹ 20 or 0.03% (whichever is lower)per executed order across
              equity.
            </span>
          </p>
          <p className={Completedstyles.bottomText}>
            No Hidden Charges :{" "}
            <span className={Completedstyles.bottomSubText}>
              100% transparent.
            </span>
          </p>
        </div>

        <div className={Completedstyles.startOverSection}>
          <img src={icon} className={Completedstyles.iconImage} />
          <p className={Completedstyles.paratext}>
            {" "}
            Want to know more about us Check out our
            <span
              className={Completedstyles.startoverbutton}
              onClick={() => {}}
            >
              <a
                href="https://www.thefirstock.com/"
                target="_blank"
                style={{ textDecoration: "none" }}
                className={Completedstyles.startoverbutton}
              >
                {" "}
                Home Page{" "}
              </a>
            </span>
          </p>
        </div>
        <div>
          <LoadingButton
            id="tradingPreferencesContinueButton"
            variant="contained"
            type="submit"
            className={Completedstyles.approveButton}
            onClick={() => {
              window.open("https://login.thefirstock.com/#/", "_blank");
            }}
          >
            Login
          </LoadingButton>
        </div>
      </div>
    </div>


  );
}
