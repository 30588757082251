import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText,
} from "@mui/material";

interface Props {
  LabelName: string;
  menuItems: any;
  name: any;
  value: any;
  handleChange: any;
  error: any;
  helperText: any;
  setOthers?: any | undefined;
  disabled?: any;
}

const Dropdown: React.FC<Props> = ({
  LabelName,
  menuItems,
  name,
  value,
  handleChange,
  error,
  helperText,
  setOthers,
  disabled,
}) => {
  // console.log("value",value)

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{LabelName}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
                name={name}
        disabled={disabled}
        label={LabelName}
        error={error}
        onChange={(e) => {
          // console.log(e.target.value.value);
          if (e.target.value.value && setOthers) {
          //  console.log(e.target.value);
            setOthers(e.target.value.value);
          }

          handleChange(e);
        }}
        MenuProps={{
          MenuListProps: {
            sx: {
              "& li.Mui-selected": {
                backgroundColor: "#006779",
                color: "white",
              },
            },
          },
        }}
      >
        {menuItems.map((a: any, index: any) => {
          return (
            <MenuItem
              key={index}
              value={a}
              selected={a === value}
              sx={{ background: "#CCE1E4" }}
            >
              {a.value}
            </MenuItem>
          );
        })}
      </Select>
      {/* <div style={{fontSize:'12px', color:'red'}}> {helperText}</div> */}
      <FormHelperText style={{ color: "#d32f2f" }}>{helperText}</FormHelperText>
    </FormControl>
  );
};
export default Dropdown;
