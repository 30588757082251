import React, { useEffect, useState, useRef } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  FormHelperText,
  Snackbar,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  validateMobileOTPAPICall,
  reSendMobileOTPAPICall,
} from "../shared/APICalls";
import { IMobileOtpValidation, IReSendMobileOTP } from "../shared/dtos";
import routesData from "../shared/routes.json";
import ConfitmOtpStyles from "../pages/Styles/ConfitmOtpStyles.module.scss";
import pageimage from "../Components/assets/Images/newLeftImage.svg";
import SnackBar from "../Components/SnackBars/SnackBar";
import Slide, { SlideProps } from "@mui/material/Slide";
import { MuiOtpInput } from "mui-one-time-password-input";
import mobileviewimage from "../Components/assets/Images/mobileviewgiftbox.svg";
import axios from "axios";
type TransitionProps = Omit<SlideProps, "direction">;

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

export default function ConfirmOtpPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const mobile = state !== null ? state.mobile : "";
  const [otp, setOTP] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState<any>(30);
  const [timerId, setTimerId] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [wrongOTP, setWrongOTP] = useState(false);
  const [loading, setloading] = useState(false);
  const [message, setMessage] = useState("");

  const [IPAddress, setIPAddress] = useState("");
  const otpInputs = useRef<any>([]);
  const [transition, setTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined);

  // console.log("mobile", mobile);

  const [phoneNumber, setPhoneNumber] = React.useState("");

  const navigationData = routesData;
  useEffect(() => {
    window.scrollTo(0, 0);
    if (state === null) {
      navigate("/");
    }
    const fetchIPAddress = async () => {
      try {
        const response = await axios.get("https://checkip.amazonaws.com/");
        setIPAddress(response.data);
      } catch (err: any) {}
    };
    fetchIPAddress();
  }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0);

  //   const getLocation = async () => {
  //     try {
  //       const position: any = await new Promise((resolve, reject) =>
  //         navigator.geolocation.getCurrentPosition(resolve, reject)
  //       );
  //       setLatitude(position.coords.latitude);
  //       setLongitude(position.coords.longitude);
  //       // console.log("Latitude is :", position.coords.latitude);
  //       // console.log("Longitude is :", position.coords.longitude);
  //     } catch (error: any) {
  //       //  console.log(error);
  //     }
  //   };
  //   getLocation();

  //   if (state === null) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    setIsButtonDisabled(true);
    setCountdown(30);

    if (timerId === null) {
      let interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      setTimerId(interval);
    }
  }, []);
  useEffect(() => {
    if (countdown <= 0 && timerId !== null) {
      setIsButtonDisabled(false);

      clearInterval(timerId);
      setTimerId(null);
    }
  }, [countdown]);

  const handlePhoneNumberChange = (event: any) => {
    setPhoneNumber(event.target.value);
  };

  function validateMobileOTP(data: any) {
    setloading(true);

    const source = sessionStorage.getItem("source");
    const id = sessionStorage.getItem("id");

    let obj: IMobileOtpValidation = {
      mobile: mobile,
      mobile_otp: data,
      ref_source: source ? source : "web",
      ref_code: id ? id : "",
      latitude: "",
      longitude: "",
      ip_address: IPAddress?.trim(),
    };

    validateMobileOTPAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("response", response);
        if (responseData.data.access_token) {
          // console.log("token", responseData.data.access_token);
          sessionStorage.setItem(
            "access-token",
            responseData.data.access_token
          );
        }

        if (responseData.data.client_code) {
          sessionStorage.setItem("client-id", responseData.data.client_code);
          sessionStorage.setItem("client_name", responseData.data.client_name);
        }
        sessionStorage.removeItem("source");
        sessionStorage.removeItem("id");
        setloading(false);
        setMessage(responseData.data.message);
        setWrongOTP(false);
        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage], {
            state: { validationID: responseData.data.ValidationID },
          });
        }
      })
      .catch((error: any) => {
        // console.log(error.response.status);
        setWrongOTP(true);
        setloading(false);
        if (error.response.status === 404) {
          setMessage("Failed to Continue, Please try Again");
          setTimeout(() => {
            setMessage("");
          }, 5000);
        }
        setMessage(
          error.message.includes("Network")
            ? "Error occured, Please try again"
            : error.response.data.error.message
        );
        setTimeout(() => {
          setMessage("");
        }, 5000);
      });
  }

  function reSendMobileOTP() {
    let obj: IReSendMobileOTP = {
      mobile: mobile,
    };
    reSendMobileOTPAPICall(obj)
      .then((response: any) => {
        const responseData = response.data.data;
        setloading(false);
      })
      .catch((error: any) => {});
    setTransition(() => TransitionUp);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
    setIsButtonDisabled(true);
    setCountdown(30);

    if (timerId === null) {
      let interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      setTimerId(interval);
    }
  }
  const handleResendOTP = () => {
    setloading(true);
    reSendMobileOTP();
  };
  const handleBackClick = () => {
    navigate("/");
  };

  const handleClose = () => {
    setOpen(false);
  };

  function validateOTP(value: any) {
    let error: any;
    if (!value) {
      error = "OTP Required";
    } else if (!/^[0-9]{6}$/.test(value)) {
      error = "Must be a valid 6 digit OTP";
    }
    return error;
  }

  const handleChangeField = (
    name: any,
    value: any,
    setFieldValue: any,
    values: any
  ) => {
    setFieldValue(name, value);
    // console.log(value);
    if (value.length === 6) {
      validateMobileOTP(value);
    }
  };

  const handleKeyPress = (event: any) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /^\d+$/.test(keyValue);

    if (!isValidKey) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className={ConfitmOtpStyles.mobileHeader}>
        <p className={ConfitmOtpStyles.LeftContainerHeadermobileHeader}>
          Invest with Confidence, Anytime, Anywhere With Firstock
        </p>
        <p className={ConfitmOtpStyles.LeftContainerSubTextmobileHeader}>
          "Seamless. Secure. Smart Investing for Everyone."
        </p>
      </div>
      <div className={ConfitmOtpStyles.wrapper}>
        <div className={ConfitmOtpStyles.LeftContainer}>
          <p className={ConfitmOtpStyles.LeftContainerHeader}>
            Invest with Confidence, Anytime, Anywhere With Firstock
          </p>
          <p className={ConfitmOtpStyles.LeftContainerSubText}>
            "Seamless. Secure. Smart Investing for Everyone."
          </p>
          <img src={pageimage} alt="Image" className={ConfitmOtpStyles.image} />
        </div>
        <div className={ConfitmOtpStyles.RightContainer}>
          <div className={ConfitmOtpStyles.headerSection}>
            <p className={ConfitmOtpStyles.signup}>Verify Your OTP</p>
            <p className={ConfitmOtpStyles.subText}>
              Sent to <span>{mobile}</span>{" "}
              <a onClick={handleBackClick} className={ConfitmOtpStyles.change}>
                (Change)
              </a>
            </p>
          </div>

          <div>
            <Formik
              initialValues={{
                mobileotp: "",
              }}
              onSubmit={(values) => {
                // console.log(values);
                setloading(true);
                // Call the onConfirm function with the OTP value
                setOTP(values.mobileotp);
                validateMobileOTP(values.mobileotp);
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                values,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form
                  onKeyDown={(event) => {
                    // event.code === "Enter" ||
                    //   (event.code === "NumpadEnter" && handleSubmit());
                    event.code === "Enter" && handleSubmit();
                  }}
                >
                  <div
                    className={ConfitmOtpStyles.inputBlock}
                    // alignItems="center" marginTop="200px"
                  >
                    {/* <Field
                    name="mobileotp"
                    validate={validateOTP}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Enter OTP"
                        type="numeric"
                        className={ConfitmOtpStyles.textfield}
                        variant="outlined"
                        value={values.mobileotp}
                        onChange={(event) => {
                          const updatedValue = event.target.value;
                          handleChangeField(
                            "mobileotp",
                            updatedValue,
                            setFieldValue,
                            values
                          );
                        }}
                        inputProps={{
                          maxLength: 6,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        onKeyPress={(event) => {
                          const keyCode = event.keyCode || event.which;
                          const keyValue = String.fromCharCode(keyCode);
                          const isValidKey = /^\d+$/.test(keyValue);

                          if (!isValidKey) {
                            event.preventDefault();
                          }
                        }}
                      />
                    )}
                  /> */}
                    <MuiOtpInput
                      length={6}
                      value={values.mobileotp.toUpperCase()}
                      autoFocus
                      // pattern="[0-9]*"
                      //   name="partnercode"
                      onChange={(event: any) => {
                        const updatedValue = event;
                        handleChangeField(
                          "mobileotp",
                          updatedValue,
                          setFieldValue,
                          values
                        );
                      }}
                      inputMode="numeric"
                      onKeyPress={handleKeyPress}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderRadius: "10px",
                          border: "2px solid #9FA4A5",
                        },
                        ".MuiOutlinedInput-input": {
                          padding: "0px !important",
                          height: "60px",
                        },
                      }}
                    />
                    <FormHelperText style={{ color: "#d32f2f" }}>
                      {touched.mobileotp && errors.mobileotp?.toString()}
                    </FormHelperText>

                    <SnackBar message={message} />

                    {isButtonDisabled ? (
                      <div className={ConfitmOtpStyles.resendSection}>
                        <p className={ConfitmOtpStyles.clickhere}>
                          Resend OTP in {countdown}s
                        </p>
                      </div>
                    ) : (
                      <div className={ConfitmOtpStyles.resendSection}>
                        <p className={ConfitmOtpStyles.clickhere}>
                          Click here to{" "}
                        </p>
                        <LoadingButton
                          onClick={handleResendOTP}
                          //loading={loading}
                          variant="text"
                          id="confirmMobileOtpResendOtpButton"
                          sx={{ marginTop: "5px", color: "#006779" }}
                        >
                          Resend OTP
                        </LoadingButton>
                      </div>
                    )}

                    <div className={ConfitmOtpStyles.mobileImage}>
                      <img
                        src={mobileviewimage}
                        alt="Image"
                        className={ConfitmOtpStyles.imageMobileView}
                      />
                    </div>

                    <div
                      // width="150px"
                      //  marginTop={"5px"}
                      className={ConfitmOtpStyles.buttonSection}
                    >
                      {values.mobileotp.length === 6 ? (
                        <LoadingButton
                          variant="contained"
                          type="submit"
                          loading={loading}
                          id="confirmMobileOtpContinueButton"
                          className={ConfitmOtpStyles.button}
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          Continue
                        </LoadingButton>
                      ) : (
                        <Button
                          variant="contained"
                          disabled
                          id="confirmMobileOtpContinueDisabledButton"
                          className={ConfitmOtpStyles.button}
                        >
                          Continue
                        </Button>
                      )}
                    </div>

                    <Snackbar
                      open={open}
                      autoHideDuration={3000}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      TransitionComponent={transition}
                      sx={{ marginTop: "50px" }}
                    >
                      <div
                        style={{ background: "#54BF00", borderRadius: "10px " }}
                      >
                        <div style={{ marginLeft: "30px" }}>
                          <Alert
                            icon={false}
                            severity="success"
                            sx={{
                              width: "max-content",
                              height: "50px",
                              fontSize: "16px",
                              borderRadius: "0",
                            }}
                          >
                            Successfully sent the OTP to your Mobile.
                          </Alert>
                        </div>
                      </div>
                    </Snackbar>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
