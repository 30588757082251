import axios, { AxiosResponse } from "axios";

import { ACCOUNTCLOSURE, DOMIAN } from "../../shared/constants";
import { IAdminLogin } from "../../shared/dtos";
import { getCookie, removeAllCookies } from "./cookies";

const axiosInterceptor = axios.create({
  baseURL: ACCOUNTCLOSURE,
});

axiosInterceptor.interceptors.request.use(
  async (req) => {
    const token = document.cookie;

   // const token = "deviceId=2fac838b-eb73-4cb7-8df1-e5f51c819eff; userId=NP2997; finupdate_token=7vf0/f76upvfbIvVr7eNXTfHUBh5NrihrStNpWdZEh1d6GGb4mnJHTGMhc625B6zD4Cu49WJifWrLrbFIK37rh+MkXfwR+1kENOnzeS3VliqhZHXiyN80QQ1YPIJ+TtFZbZ5nTxT62Odo6wvKtkrMu/CvptqLuYorobc5azuU8w="

    req.headers.Authorization = `${token}`;
    return req;
  },
  (error) => Promise.reject(error)
);

axiosInterceptor.interceptors.response.use(
  (res) => res,
  (err) => {
    const status = err.response ? err.response.status : null;
    if (status === 401) {
      handleLogout();
    }
    return Promise.reject(err);
  }
);
const handleLogout = () => {
  removeAllCookies();
  window.location.href="/account_closure";
};

export const LocationCheckAPICall = (latitude: any,longitude:any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/common/locationcheck/${latitude}/${longitude}`, {
        // params: {
        //   userId: userId,
        // },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetFirstTimeLoginAPICall = (requestBody: any, userId:any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/firstTimeLogin/${userId}`,requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const uploadDocumentsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/documentupload/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const uploadDpdetailsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/update_dp_details`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const stageDetailsAPICall = (requestbody:any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/stagedetails/`,requestbody , {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const RequestEsignAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/esign/request_esign`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const esignsaveDocumentAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/save_document/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const esignsubmitAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/submit_esign/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const deleteDocumentAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/V1/documentdelete/`, {
        data: {
          // client_code: requestBody.client_code,
          document_purpose: requestBody.document_purpose,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};