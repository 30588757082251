import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Avatar,
} from "@mui/material";
import ErrorImage from "../../Components/assets/Images/errorImage.png";
import styles from "./AccountClosureAddFunds.module.scss";

export default function AccountClosureAddFunds() {
  const handleNavigate = () => {
    window.location.href = "https://login.thefirstock.com/";
  };
  return (
    <div className={styles.mainWrapperTop}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={ErrorImage} style={{ width: "100px", height: "100px" }} />
        <div style={{ textAlign: "center", padding: "10px 10px" }}>
          <Typography
            sx={{
              typography: {
                xs: "h4",
                sm: "h4",
              },
            }}
          >
            {" "}
            Your account currently has a debit balance.
          </Typography>
          {/* <Typography style={{ marginTop: "10px" }}>
            Your account having Negative Margin
          </Typography> */}
        </div>
        <div style={{ textAlign: "center" }}>
          <Typography style={{ fontSize: "16px", padding: "0 5px" }}>
          kindly add funds in order to close the account.
          </Typography>
        </div>

        <Button
          variant="contained"
          sx={{ width: "100px", marginTop: "20px" }}
          onClick={handleNavigate}
        >
          Login
        </Button>
      </div>
    </div>
  );
}
