import React, { useEffect, useState } from "react";
import Data from "../Data.json";
import { Avatar, Grid, Stack, TextField, Typography } from "@mui/material";
import { digiLockerStatusAPICall } from "../../shared/APICalls";
import JsonStyles from "./JsonData.module.scss";

interface Props {
  aadhaarData: any;
  aadaarFetched: any;
}

export default function JsonDataToAadhaarFormat({
  aadhaarData,
  aadaarFetched,
}: Props) {
  // const base64Image = Data.CertificateData.KycRes.UidData.Pht;
  const base64Image = aadhaarData.aadharPhotoBase64;

  const [fetechedDetails, setfetechedDetails] = useState(true);

  // console.log(aadhaarData);

  return (
    <>
      {fetechedDetails ? (
        <Stack alignItems={"center"} height={"max-content"}>
          <p className={JsonStyles.header}>Fetched Aadhaar KYC Details</p>

          <div className={JsonStyles.container}>
            <Grid margin={"10px 0"}>
              <img
                src={`data:image/jpeg;base64,${base64Image}`}
                alt="Certificate"
                style={{
                  width: "90px",
                  height: "90px",
                  border: "5px solid #F0EBEB",
                  borderRadius: "50%",
                }}
              />
            </Grid>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 5 }}
            >
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  // focused
                  id="outlined-basic"
                  label="Aadhaar No"
                  variant="outlined"
                  value={aadhaarData.AadhaarNumber}
                  sx={{
                    width: { xs: "100%", sm: "80%" },
                    input: {
                      color: "#344054",
                      fontSize: "14px",
                      "& .MuiOutlinedInput-input:focused": {
                        borderColor: "grey",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  // focused
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  value={aadhaarData.AadhaarName}
                  sx={{
                    width: { xs: "100%", sm: "80%" },
                    input: { color: "#344054", fontSize: "14px" },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  // focused
                  id="outlined-basic"
                  label="Date of Birth"
                  variant="outlined"
                  value={aadhaarData.date_of_birth}
                  sx={{
                    width: { xs: "100%", sm: "80%" },
                    input: { color: "#344054", fontSize: "14px" },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  // focused
                  id="outlined-basic"
                  label="Gender"
                  variant="outlined"
                  value={aadhaarData.gender}
                  sx={{
                    width: { xs: "100%", sm: "80%" },
                    input: { color: "#344054", fontSize: "14px" },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <TextField
                  fullWidth
                  // focused
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  value={aadhaarData.address}
                  sx={{ input: { color: "#344054", fontSize: "12px" } }}
                />
              </Grid>
            </Grid>
          </div>
        </Stack>
      ) : (
        <Stack>
          <Typography>No Details Fetched</Typography>
        </Stack>
      )}
    </>
  );
}
