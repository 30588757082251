import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";
import { checkUserLoggedIn } from "../shared/commonfunctions";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { sendEmialAPICall } from "../shared/APICalls";
import { ISendEmail } from "../shared/dtos";
import routesData from "../shared/routes.json";
import YourDetailsStyles from "../pages/Styles/YourDetails.module.scss";
import YourdetailsImage from "../Components/assets/Images/newLeftImage.svg";
import googleImage from "../Components/assets/Images/goggle.png";
import ContinueWithGoogle from "../Components/ContinueWithGoogle/ContinueWithGoogle";
import SnackBar from "../Components/SnackBars/SnackBar";
import mobileviewimage from "../Components/assets/Images/mobileviewgiftbox.svg";

export default function YourDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { validationID } = state;
  const [emailAddress, setemailAddress] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<any>(false);
  const [message, setMessage] = useState<any>("");
  const [loading, setloading] = useState(false);

  const navigationData = routesData;

  console.log("validationID", validationID);

  useEffect(() => {
    window.scrollTo(0, 0);
    checkUserLoggedIn(navigate);
  }, []);

  function sendEmailAddress( emailid: any ,name:any) {
    let obj: ISendEmail = {
      // validation_id: validationID,
      email: emailid,
      client_name: name,
      is_gmail_verified: false,
      google_client_id: "",
    };
    sendEmialAPICall(obj)
      .then((response: any) => {
        sessionStorage.setItem("emailID",emailid)
        const responseData = response.data;
        // console.log(response, "response");

        setloading(false);

        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage], {
            state: { emailID: emailid, validateID: validationID },
          });
        }
      })
      .catch((error: any) => {
        setloading(false);
        // console.log("error", error);

        setMessage(
          error.message.includes("Network")
            ? "Error occured, Please try again"
            : error.response.data.error.message
        );
        setTimeout(() => {
          setMessage("");
        }, 5000);
        // if (error.response.data.error.message) {
        //   setMessage(error.response.data.error.message);
        //   setloading(false);
        //   setErrorMessage(true);
        //   setTimeout(() => {
        //     setMessage("");
        //   }, 5000);
        // }
      });
  }

  const handleClick = () => {
    navigate("/confirmemailotp");
  };
  const callBackMessage = (data: any) => {
    setMessage(data);
  };

  function validateName(value: any) {
    let error: any;
    if (!value) {
      error = "Name Required";
    } else if (!/^[a-zA-Z\s]{1,50}$/.test(value)) {
      error = "Extra characters not allowed";
    }
    return error;
  }

  function validateEmail(value: any) {
    let error: any;
    if (!value) {
      error = "Email Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = "Invalid email address";
    } else if (value.length > 75) {
      error = "Not a valid email address";
    }
    return error;
  }

  return (
    <>
      <div className={YourDetailsStyles.mobileHeader}>
        <p className={YourDetailsStyles.LeftContainerHeadermobileHeader}>
          Invest with Confidence, Anytime, Anywhere With Firstock
        </p>
        <p className={YourDetailsStyles.LeftContainerSubTextmobileHeader}>
          "Seamless. Secure. Smart Investing for Everyone."
        </p>
      </div>
      <div className={YourDetailsStyles.wrapper}>
        <div className={YourDetailsStyles.LeftContainer}>
          <p className={YourDetailsStyles.LeftContainerHeader}>
            Invest with Confidence, Anytime, Anywhere With Firstock
          </p>
          <p className={YourDetailsStyles.LeftContainerSubText}>
            "Seamless. Secure. Smart Investing for Everyone."
          </p>
          <img
            src={YourdetailsImage}
            alt="Image"
            className={YourDetailsStyles.image}
          />
        </div>
        <div className={YourDetailsStyles.RightContainer}>
          <div className={YourDetailsStyles.headerSection}>
            <p className={YourDetailsStyles.signup}>
              Whats your Email address?
            </p>
            <p className={YourDetailsStyles.subText}>
              All Firstock account related information will be sent to this
              address.
            </p>
          </div>

          <div>
            <Formik
              initialValues={{
                 name: "",
                email: "",
              }}
              onSubmit={(values) => {
                //  console.log(values);
                setloading(true);
                setemailAddress(values.email);
                sendEmailAddress(values.email, values.name);
              }}
            >
              {({ errors, touched, handleChange, values, handleSubmit }) => (
                <Form
                  onKeyDown={(event) => {
                    event.code === "Enter" ||
                      (event.code === "NumpadEnter" && handleSubmit());
                    // event.code.includes("Enter") && handleSubmit()
                  }}
                >
                  <div className={YourDetailsStyles.textfeildSection}>
                    {/* <Typography variant="h5"> Your Details </Typography> */}

                    <Field
                      name="name"
                      validate={validateName}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className={YourDetailsStyles.textfeild}
                          id="outlined-basic"
                          label="Name"
                          variant="outlined"
                          InputProps={{
                            maxLength: 50,
                          }}
                          // helperText={
                          //   errors.name &&
                          //   touched.name && (
                          //     <FormHelperText style={{ color: "#d32f2f" }}>
                          //       {errors.name}
                          //     </FormHelperText>
                          //   )
                          // }
                        />
                      )}
                    />

                    <div
                      style={{
                        color: "#d32f2f",
                        fontSize: "12px",
                        height: "10px",
                        padding: "5px 0px 0px 15px",
                      }}
                    >
                      {errors.name && touched.name && errors.name}
                    </div>

                    <Field
                      name="email"
                      validate={validateEmail}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className={YourDetailsStyles.textfeild}
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          InputProps={{
                            maxLength: 75,
                          }}
                          // helperText={
                          //   errors.email &&
                          //   touched.email && (
                          //     <FormHelperText style={{ color: "#d32f2f" }}>
                          //       {errors.email}
                          //     </FormHelperText>
                          //   )
                          // }
                        />
                      )}
                    />
                    <div
                      style={{
                        color: "#d32f2f",
                        fontSize: "12px",
                        height: "10px",
                        padding: "5px 0px 0px 15px",
                      }}
                    >
                      {errors.email && touched.email && errors.email}
                    </div>

                    {/* <FormHelperText style={{ color: "#d32f2f" }}>
                    {message}
                  </FormHelperText> */}

                    <SnackBar message={message} />

                    <div className={YourDetailsStyles.mobileImage}>
                      <img
                        src={mobileviewimage}
                        alt="Image"
                        className={YourDetailsStyles.imageMobileView}
                      />
                    </div>

                    <div className={YourDetailsStyles.buttonSection}>
                      {/* <Button
                      sx={{ marginRight: "10px", color: "black" }}
                      variant="outlined"
                      className={YourDetailsStyles.buttonGoogle}
                      endIcon={<img src={googleImage} width={'20px'} />}
                    >
                      Continue with 
                    </Button> */}
                      <ContinueWithGoogle
                        validationID={validationID}
                        handleCallBack={callBackMessage}
                      />
                      <Typography sx={{ fontSize: "12px", marginLeft: "2px" }}>
                        (or)
                      </Typography>
                      { values.email && values.name ? (
                        <LoadingButton
                          variant="contained"
                          type="submit"
                          loading={loading}
                          id="yourDetailsContinueButton"
                          className={YourDetailsStyles.button}
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          Continue
                        </LoadingButton>
                      ) : (
                        <Button
                          variant="contained"
                          disabled
                          className={YourDetailsStyles.button}
                        >
                          Continue
                        </Button>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
