import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Avatar,
} from "@mui/material";
import DoneImage from "../Images/doneicon.png";
import { useLocation } from "react-router-dom";
import { digiLockerAuthenticationAPICall } from "../shared/APICalls";


export default function PageNotFound() {
  const pathName = useLocation().search;

  function sendMobileNumber() {
    let obj: any = {
      code: "",
    };
    digiLockerAuthenticationAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;

      })
      .catch((error: any) => {});
  }

  useEffect(() => {
    sendMobileNumber();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
      }}
    >
      <h2>Page Not Found</h2>
    </div>
  );
}
