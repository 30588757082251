import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Avatar,
  Fade,
  Box,
  IconButton,
  Modal,
} from "@mui/material";
import DoneImage from "../Images/doneicon.png";
import { useNavigate } from "react-router-dom";
import { checkUserLoggedIn } from "../../shared/commonfunctions";
import styles from "../../pages/Styles/Congratulation.module.scss";
import image from "../../Components/assets/Images/congralutionsImage.svg";
import KRAFetchedDetails from "../KRAFetchedDetails";
import { startOverAPICall } from "../../shared/APICalls";
import PdfModal from "../../Components/PdfModal/PdfModal";
import PDFViewerIframe from "../../Components/PDFIframe/PDFWithIframe";
import CloseIcon from "@mui/icons-material/Close";

import doneimage from "../../Components/assets/Images/donetickimage.svg";

import { Helmet } from "react-helmet";
import CopiedSnackBar from "../../Components/SnackBars/CopiedSnackBar";
import { stageDetailsAPICall } from "./APICallsAccountClosure";
import FetchedDetails from "./FetchDetails";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vW",
  height: "90vH",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  padding: "0px 0px 0px 5px",
};
const containerStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
};
const buttonStyle = {
  marginRight: "10px",
  padding: "8px",
  borderRadius: "5px",
  backgroundColor: "lightgray",
  color: "black",
  fontWeight: "bold",
};

export default function AccountClosureApproved() {
  const navigate = useNavigate();
  const [noEditableData, setnoEditableData] = useState({
    FullName: "",
    Email:"",
    Gender: "",
    DOB: "",
    Address: "",
  });
  const [showModal, setshowModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<any>("");
  const [open, setOpen] = React.useState(true);
  const [copied, setCopied] = useState(false);
  const [clientCode, setClientCode] = useState("");
  const [message, setmessage] = useState("");

  const esigndoc: any = sessionStorage.getItem("esigndoc");

  useEffect(() => {
    window.scrollTo(0, 0);
    // checkUserLoggedIn(navigate);
    StageDetails();
    let objNew: any = {
      stage: "congratulations",
    };

    // stageDetailsAPICall(objNew)
    //   .then((response: any) => {
    //     const responseData = response.data;

    //     if (responseData.data.StageDetails.Documents.e_sign_pdf) {
    //       setClientCode(
    //         responseData.data.StageDetails.Documents.e_sign_pdf.ClientCode
    //       );
    //       setnoEditableData(
    //         responseData.data.StageDetails.Documents.e_sign_pdf
    //       );
    //     }

    //     if (
    //       responseData.data.StageDetails.Documents.e_sign_pdf.e_sign_pdf_url
    //     ) {

    //       setPdfUrl(
    //         responseData.data.StageDetails.Documents.e_sign_pdf.e_sign_pdf_url
    //       );
    //     }

    //   })
    //   .catch((error: any) => {
    //     //   console.log("error", error);
    //   });
  }, []);

  const StageDetails = () => {
    let obj: any = {
      stage: "approval_stage",
    };
    stageDetailsAPICall(obj)
      .then((response: any) => {
        console.log("response", response.data.data.StageDetails.Documents);
        if (
          response.data.data.StageDetails.Documents.e_sign_pdf.e_sign_pdf_url
        ) {
          setPdfUrl(
            response.data.data.StageDetails.Documents.e_sign_pdf.e_sign_pdf_url
          );
        }
        if (response.data.data.StageDetails.Documents.e_sign_pdf) {
          setClientCode(
            response.data.data.StageDetails.Documents.e_sign_pdf.ClientCode
          );
          setnoEditableData(
            response.data.data.StageDetails.Documents.e_sign_pdf
          );
        }
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  };
  const handlePreviewClick = () => {
    setshowModal(true);
  };

  const handlePreviewClose = () => {
    setshowModal(false);
  };

  return (
    <div className={styles.mainWrapperTop}>
      <div className={styles.wrapper}>
        <div className={styles.leftContainer}>
          <div className={styles.headerSection}>
            <img src={doneimage} className={styles.donetick} />
            <p className={styles.signup}>
              {/* <img src={doneimage} className={styles.doneticknexttotext} />{" "} */}
              Congrats! Application Process is Submitted for Account Closure
            </p>
            <p className={styles.subText}>
              Account Closure process could take up to 42 hours.
            </p>
            <Button
              type="submit"
              variant="outlined"
              sx={{
                color: "#000000",
                borderRadius: "20px",
                background: "#CCE1E4",
                border: "none",
              }}
              className={styles.previewButton}
              id="aadharOpenLinkButton"
              onClick={handlePreviewClick}
            >
              View E-sign Document
            </Button>
          </div>

          <div style={containerStyle}></div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.imagediv}>
            <img src={image} className={styles.image} />
          </div>
          <div className={styles.kradetails}>
            <FetchedDetails data={noEditableData} />
          </div>
        </div>
      </div>
      <CopiedSnackBar message={message} />

      {showModal && (
        <Stack>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                {/* <PdfModal
                // url={"https://arxiv.org/pdf/quant-ph/0410100.pdf"}
                url={documentURL}
              /> */}
                <Stack
                  alignItems={"flex-end"}
                  marginRight={"10px"}
                  marginTop={"5px"}
                >
                  <IconButton onClick={handlePreviewClose}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <div className={styles.pdfMobilePreview}>
                  <PdfModal url={pdfUrl} />
                  <PDFViewerIframe urldata={pdfUrl} />
                </div>
                <div className={styles.pdfDesktopPreview}>
                  <PDFViewerIframe urldata={pdfUrl} />
                </div>
              </Box>
            </Fade>
          </Modal>
        </Stack>
      )}
    </div>
  );
}
