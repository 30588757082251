import React, { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";

import {
  GetFirstTimeLoginAPICall,
  LocationCheckAPICall,
  stageDetailsAPICall,
} from "./APICallsAccountClosure";

import BankStyles from "../../pages/Finupdate/BankDetails.module.scss";
import StageLayout from "../Finupdate/StageLayout";

import { APPID, ENV, SSOLINK, TOKENNAME } from "../../shared/constants";
import { browserName } from "react-device-detect";
import { getCookie } from "../Finupdate/cookies";
import routesData from "../../shared/routes.json";
import BackDrop from "../../Components/BackDrop";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function AccountClosureProfile() {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const params: any = Object.fromEntries(urlParams.entries());
  const pathName = useLocation().pathname;
  const [loading, setloading] = useState(false);
  const [userDeniedLocation, setuserDeniedLocation] = useState(false);
  const [intervalId, setintervalId] = useState<any>(null);
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [backgroundLoading, setBackgroundLoading] = useState(false);

  const [completedStages, setCompletedStages] = useState<any>();
  const [userData, setUserData] = useState({
    message: "",
    clientCode: "",
    fullName: "",
    email: "",
    mobile: "",
    pan: "",
    TradingPreference: "",
    isCompleted: false,
    fst_dp_id: "",
  });
  const [tradingPreference, setTradingPreference] = useState([]);
  const navigationData = routesData;
  const callBackURL = document.location.href;

  const cookieData = getCookie(TOKENNAME);
  // const cookieData =
  //  "finupdate_token=7vf0/f76upvfbIvVr7eNXTfHUBh5NrihrStNpWdZEh1d6GGb4mnJHTGMhc625B6zD4Cu49WJifWrLrbFIK37rh+MkXfwR+1kENOnzeS3VliqhZHXiyN80QQ1YPIJ+TtFZbZ5nTxT62Odo6wvKtkrMu/CvptqLuYorobc5azuU8w=";
  const userId = getCookie("userId");
  //  const userId = "NP2997";

  useEffect(() => {
    // console.log("params", params);
    if (cookieData) {
      // sessionStorage.setItem("access-token", cookieData);
      // navigate("/dashboard");
    } else {
       window.location.href = `${SSOLINK}?appId=${APPID}&callback=${callBackURL}&env=${ENV}`;
    }
  }, [cookieData]);

  useEffect(() => {
    getLocation();

    return () => clearInterval(intervalId);
  }, [pathName]);

  useEffect(() => {
    if (
      userDeniedLocation &&
      intervalId === null &&
      browserName !== "Firefox"
    ) {
      let interval = setInterval(() => {
        getLocation();
      }, 1000);
      setintervalId(interval);
    }
  }, [userDeniedLocation]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userId && latitude && longitude) {
      LocationCheck();
    }
  }, [userId, latitude, longitude]);

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
    }
  }

  function showPosition(position) {
    setuserDeniedLocation(false);

    const latitudeCoords = position.coords.latitude;
    const longitudeCoords = position.coords.longitude;
    setlatitude(latitudeCoords);
    setlongitude(longitudeCoords);
    sessionStorage.setItem("latitude", latitudeCoords);
    sessionStorage.setItem("longitude", longitudeCoords);

    if (intervalId) {
      clearInterval(intervalId);
    }
  }

  const LocationCheck = () => {
    setBackgroundLoading(true);
    LocationCheckAPICall(latitude, longitude)
      .then((response: any) => {
        GetFirstTimeLogin();
      })
      .catch((error: any) => {
        setBackgroundLoading(false);
      });
  };

  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setuserDeniedLocation(true);
        if (browserName === "Firefox") {
        }

        break;

      case error.POSITION_UNAVAILABLE:
        break;
      case error.TIMEOUT:
        break;
      case error.UNKNOWN_ERROR:
        break;
    }
  }

  const GetFirstTimeLogin = () => {
    let obj: any = {
      latitude: latitude.toString(),
      longitude: longitude.toString(),
    };
    GetFirstTimeLoginAPICall(obj, userId)
      .then((response: any) => {
        setBackgroundLoading(false);
        const TP = response.data.data.TradingPreference;

        const filteredData = Object.keys(TP).filter((key) => TP[key] === true);

        const newData: any = filteredData.map((a) => {
          if (a.includes("bse_equity") || a.includes("nse_equity")) {
            return "Equity";
          } else if (
            a.includes("bse_futures_and_options") ||
            a.includes("nse_futures_and_options")
          ) {
            return "Futures and Options";
          }
        });

        const uniqueArray = newData.filter(
          (value: any, index: any) => newData.indexOf(value) === index
        );
        // console.log("TP", uniqueArray);
        setTradingPreference(uniqueArray);

        if (response.data?.data) {
          setUserData(response.data.data);
        }
      })
      .catch((error: any) => {
        setBackgroundLoading(false);
        // console.log("error", error);
      });
  };

  const StageDetails = () => {
    let obj: any = {
      stage: "profile_details",
    };
    stageDetailsAPICall(obj)
      .then((response: any) => {
        console.log("response", response.data.data);
        if (
          response.data.data.stage &&
          navigationData.hasOwnProperty(response.data.data.stage)
        ) {
          navigate(navigationData[response.data.data.stage]);
        }
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  };

  const handleNext = () => {
    StageDetails();
    // navigate("/account_closure/esign");
  };

  return (
    <div className={BankStyles.mainWrapper}>
      <StageLayout
        completedStages={completedStages}
        stageName={"Profile Details"}
        navigatePath={""}
      />

      <div className={BankStyles.wrapper}>
        <div>
          <p className={BankStyles.Header}>Your Profile Details</p>
        </div>
        <p className={BankStyles.subText}>
          View your personal information below.
          <br /> Note: You can't edit this information
        </p>
        {backgroundLoading && <BackDrop />}

        <div className={BankStyles.textFeildwrappernew}>
          <div className={BankStyles.pDetailsField}>
            Client Code
            <TextField
              placeholder="Client Code"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={userData.clientCode}
              fullWidth
              //   onChange={handleEmailName}
              InputProps={{
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                // width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={BankStyles.pDetailsField}>
            Full Name
            <TextField
              placeholder="Username"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={userData.fullName}
              fullWidth
              //   onChange={handleEmailName}
              InputProps={{
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                // width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
        </div>
        <div className={BankStyles.textFeildwrappernew}>
          <div className={BankStyles.pDetailsField}>
            Email
            <TextField
              placeholder="Client Code"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={userData.email}
              fullWidth
              //   onChange={handleEmailName}
              InputProps={{
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                // width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={BankStyles.pDetailsField}>
            Mobile
            <TextField
              placeholder="Username"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={userData.mobile}
              fullWidth
              //   onChange={handleEmailName}
              InputProps={{
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                // width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
        </div>
        <div className={BankStyles.textFeildwrappernew}>
          <div className={BankStyles.pDetailsField}>
            PAN
            <TextField
              placeholder="Client Code"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={userData.pan}
              fullWidth
              //   onChange={handleEmailName}
              InputProps={{
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                // width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={BankStyles.pDetailsField}>
            Trading Preference
            <TextField
              placeholder="Trading Preference"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={
                tradingPreference?.length > 0
                  ? tradingPreference.join(", ")
                  : "-"
              }
              fullWidth
              //   onChange={handleEmailName}
              InputProps={{
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                // width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={BankStyles.pDetailsField}>
            DP ID
            <TextField
              placeholder="Client Dp Id"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={userData.fst_dp_id}
              fullWidth
              //   onChange={handleEmailName}
              InputProps={{
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                // width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
        </div>

        <div className={BankStyles.buttonStack}>
          <div>
            {userDeniedLocation ? (
              <p style={{ textAlign: "center" }}>
                Kindly grant the Location Access to proceed further
              </p>
            ) : (
              <LoadingButton
                loading={loading}
                variant="contained"
                type="submit"
                id="linkUsingIFSCContinueButton"
                className={BankStyles.approveButton}
                sx={{
                  textTransform: "uppercase",
                  fontSize: "12px",
                  marginTop: "20px",
                }}
                onClick={handleNext}
              >
                Continue to Account Closure Esign
              </LoadingButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
