import { Navigate, useRoutes } from "react-router-dom";
import SignupPage from "./pages/SignupPage";
import ConfirmOtpPage from "./pages/ConfirmOTPPage";
import YourDetails from "./pages/YourDetailsPage";
import ConfirmEmailOtpPage from "./pages/ConfirmEmailOtp";
import PanVerificationPage from "./pages/PanVerificationPage";
import AadhaarKYCPage from "./pages/AadharKYCPage";
import YourProfilePage from "./pages/YourProfilePage";
import LinkUsingIFSC from "./pages/LinkUsingIFSC";
import UploadDocumentsPage from "./pages/UploadDocumentsPage";
import NomineesPage from "./pages/NomineePage";
import EsignPage from "./pages/EsignPage";
import CongratulationsPage from "./pages/CongratulationsPage";
import CameraModule from "./Components/CameraModule/CameraModule";
import WebcamDemo from "./Components/CameraModule/WebCamFaceDetection";
import TradingPreference from "./pages/TradingPreference";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPathName } from "./Components/redux/actions/pathNameActions";
import { useEffect } from "react";
import TermsandConditionsPage from "./pages/TermsandConditionsPage";
import KraEsignPage from "./pages/kraEsign";
import CompletedPage from "./pages/CompletedPage";
import StageLayout from "./Layouts/StageLayout";
import NewNominee from "./pages/NewNominee";
import PageNotFound from "./pages/PageNotFound";
import Profile from "./pages/Finupdate/LinkUsingIFSC";
import UploadDocumentsPageFinUpdate from "./pages/Finupdate/UploadDocumentsPage";
import AccountClosureProfile from "./pages/AccountClosure/AccountClosureProfile";
import AccountClosureEsign from "./pages/AccountClosure/AccountClosureEsign";
import AccountClosureCMRUpload from "./pages/AccountClosure/AccountClosureCMRUpload";
import AccountClosureAddFunds from "./pages/AccountClosure/AccountClosureAddFunds";
import AccountClosureApproved from "./pages/AccountClosure/AccountClosureApproved";
import EsignSuccessPage from "./pages/AccountClosure/AccountClosureEsignResponseSuccess";
import EsignErrorPage from "./pages/AccountClosure/AccountClosureEsignError";
import EsignResponse from "./pages/AccountClosure/AccountClosureResponse";
import PANCR from "./pages/PanCRDigilocker";
import HomePage from "./Pages copy/HomePage";
import HomePageEsign from "./Pages copy/esign/HomePageEsign";
import FetchIncomeProof from "./pages/FetchIncomeProof";
import FacialDetectionApp from "./Components/CameraModule/FaceDetection";

export default function Router() {
  const pathName = useLocation().pathname;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPathName(pathName));
  }, [pathName]);

  const routes = useRoutes([
    {
      path: "",
      element: <SignupPage />,
    },
    {
      path: "token/:id",
      element: <SignupPage />,
    },
    {
      path: "confirm_otp",
      element: <ConfirmOtpPage />,
    },
    {
      path: "yourdetails",
      element: <YourDetails />,
    },
    {
      path: "confirmemailotp",
      element: <ConfirmEmailOtpPage />,
    },

    {
      path: "panverification",
      element: <PanVerificationPage />,
    },
    {
      path: "aadhaar",
      element: <AadhaarKYCPage />,
    },
    {
      path: "tradingPreference",
      element: <TradingPreference />,
    },
    {
      path: "yourprofile",
      element: <YourProfilePage />,
    },
    {
      path: "linkusingifsc",
      element: <LinkUsingIFSC />,
    },
    {
      path: "cameramodule",
      element: <WebcamDemo />,
    },
    // {
    //   path: "face",
    //   element: <FacialDetectionApp />,
    // },

    {
      path: "uploaddocuments",
      element: <UploadDocumentsPage />,
    },
    {
      path: "nomineepage",
      // element: <NewNominee />
      element: <NomineesPage />,
    },
    {
      path: "kraesign",
      element: <KraEsignPage />,
    },
    {
      path: "esign",
      element: <EsignPage />,
    },

    {
      path: "termsandconditions",
      element: <TermsandConditionsPage />,
    },
    {
      path: "pancr/*",
      element: <PANCR />,
    },
    {
      path: "account_aggregator/*",
      element: <FetchIncomeProof />,
    },
    {
      path: "aadhaarkyc/*",
      element: <HomePage />,
    },
    {
      path: "ekyc_esign/*",
      element: <HomePageEsign />,
    },
    {
      path: "/*",
      element: <PageNotFound />,
    },

    {
      path: "congratulations",
      element: <CongratulationsPage />,
    },
    {
      path: "completed",
      element: <CompletedPage />,
    },
    {
      path: "/callback/accountclosure/esign/*",
      element: <EsignResponse />,
    },
    // {
    //   path: "callback/accountclosure/esign/error/*",
    //   element: <EsignErrorPage />,
    // },
    {
      path: "finupdate",
      element: "",
      children: [
        { element: <Navigate to="profile" />, index: true },
        {
          path: `profile`,
          element: <Profile />,
        },
        {
          path: `documents_upload`,
          element: <UploadDocumentsPageFinUpdate />,
        },
      ],
    },
    {
      path: "account_closure",
      element: "",
      children: [
        { element: <Navigate to="profiledetails" />, index: true },
        {
          path: `profiledetails`,
          element: <AccountClosureProfile />,
        },
        {
          path: `cmr_upload`,
          element: <AccountClosureCMRUpload />,
        },
        {
          path: `add_funds`,
          element: <AccountClosureAddFunds />,
        },
        {
          path: `approved`,
          element: <AccountClosureApproved />,
        },
        {
          path: `esign`,
          element: <AccountClosureEsign />,
        },
      ],
    },
  ]);

  return routes;
}
