import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Modal,
  Fade,
  Box,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  startOverAPICall,
  segmentDetailsAPICall,
  segmentDetailsPostAPICall,
  stageDetailsAPICall,
} from "../shared/APICalls";
import routesData from "../shared/routes.json";
import TradingStyles from "../pages/Styles/TradingPreference.module.scss";
import BackButtonListener from "../Components/PreventBackNavigation/PreventBackNavigation";
import StageLayout from "../Layouts/StageLayout";
import icon from "../Components/assets/Images/iconnew.svg";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import TickCircle from "../Components/assets/Images/tick-circle.svg";
import EmptyCircle from "../Components/assets/Images/TickEmptyCircle.svg";
import BackDrop from "../Components/BackDrop";
import StartOverBackDrop from "../Components/StartOverBackDrop";
import ErrorSnackBar from "../Components/SnackBars/ErrorSnackBar";
import StageLayoutEkyc from "../Layouts/StageLayoutEkyc";
import Close from "@mui/icons-material/Close";

const TradingPreferenceSchema = Yup.object().shape({
  Equity: Yup.boolean().oneOf([true], "Equity is required"),
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  height: "90vh",
  bgcolor: "background.paper",
  p: 1,
  boxShadow: 24,
  overflowY: "scroll",
  borderRadius: "20px",
};

export default function TradingPreference() {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [segmentData, setSegmentData] = useState<any>([]);
  const [completedStages, setCompletedStages] = useState<any>();
  const [prefilloading, setprefilloading] = useState(true);
  const [backdrop, setBackDrop] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [showModal, setshowModal] = useState(false);
  const [tradingPreferenceData, setTradingPreferneceData] = useState<any>();

  const [prefillData, setprefillData] = useState<any>({
    equity: true,
    futures_and_options: true,
    currency: false,
    commodity: true,
    mutual_funds: true,
  });

  const navigationData = routesData;

  useEffect(() => {
    window.scrollTo(0, 0);
    segmentDetailsAPICall()
      .then((response: any) => {
        const responseData = response.data.data;
        console.log("responseData", responseData);
        setSegmentData(
          responseData.filter(
            (a: any) =>
              a.segmentLink !== "currency" && a.segmentLink !== "commodity"
          )
        );
      })
      .catch((error: any) => {});

    let obj: any = {
      stage: "segment_details",
    };

    stageDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        setCompletedStages(responseData.completed_stages);
        setprefilloading(false);

        // console.log("response", response.data.data.StageDetails);
        //  console.log("response", response.data.data.StageDetails.map((data: any) =>  data.IsOpted));
        setprefillData((prev) => {
          return {
            ...prev,
            equity: responseData.data.StageDetails.EquityOpted,
            futures_and_options:
              responseData.data.StageDetails.Futures_and_optionsOpted,
            currency: responseData.data.StageDetails.CurrencyOpted,
            commodity: responseData.data.StageDetails.CommodityOpted,
            mutual_funds: responseData.data.StageDetails.Mutual_fundsOpted,
          };
        });
        if (response.data.data.StageDetails.ErrorMessage) {
          setRejectMessage(response.data.data.StageDetails.ErrorMessage);
        }
      })
      .catch((error: any) => {
        setprefilloading(false);
        // console.log("error", error);
      });
  }, []);

  function segmentDetailsPost(data: any) {
    setloading(true);

    let obj = {
      segment_details: [
        {
          SegmentLink: data[0].segmentLink,
          IsOpted: data[0].isOpted,
        },
        {
          SegmentLink: data[1].segmentLink,
          IsOpted: data[1].isOpted,
        },
        {
          SegmentLink: data[2].segmentLink,
          IsOpted: data[2].isOpted,
        },
        {
          SegmentLink: "commodity",
          IsOpted: false,
        },
        {
          SegmentLink: "currency",
          IsOpted: false,
        },
      ],
    };

    if (data[1].isOpted) {
      setshowModal(true);
      setTradingPreferneceData(obj);
    } else {
      segmentDetailsPostAPICall(obj)
        .then((response: any) => {
          const responseData = response.data;
          // console.log("responseData", responseData);
          setloading(false);
          if (
            responseData.data.stage &&
            navigationData.hasOwnProperty(responseData.data.stage)
          ) {
            navigate(navigationData[responseData.data.stage]);
          }
        })
        .catch((error: any) => {
          setloading(false);
        });
    }
  }

  const handleRiskDisclosure = () => {
    segmentDetailsPostAPICall(tradingPreferenceData)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setloading(false);

        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage]);
        }
        setshowModal(false);
      })
      .catch((error: any) => {
        setloading(false);
      });
  };

  const handleClick = () => {
    navigate("/yourprofile");
  };
  //console.log(prefillData.mutual_funds);

  const handleStartOver = () => {
    if (
      window.confirm(
        "Are you sure you want to restart, on pressing Yes all the Data will be deleted"
      )
    ) {
      setBackDrop(true);
      startOverAPICall()
        .then((response: any) => {
          const responseData = response.data;
          // console.log("responseData", response);
          setBackDrop(false);
          navigate("/");
          window.location.reload();
        })
        .catch((error: any) => {
          setBackDrop(false);
        });
    }
  };

  const InitialValues = {
    equity: true,
    futures_and_options: prefillData.futures_and_options,
    // currency: !prefillData.currency,
    // commodity: prefillData.commodity,
    mutual_funds: prefillData.mutual_funds,
  };

  //console.log(selectedCheckboxes);

  const handleCheckBoxClick = (name: any, callback: any, values: any) => {
    callback(name, !values[name]);
  };

  return (
    <div className={TradingStyles.mainWrapper}>
      <StageLayoutEkyc
        completedStages={completedStages}
        stageName={"Trading Preferences"}
        navigatePath={"/yourprofile"}
      />
      <ErrorSnackBar message={rejectMessage} />
      <div className={TradingStyles.wrapper}>
        {!prefilloading ? (
          <Formik
            initialValues={InitialValues}
            validationSchema={TradingPreferenceSchema}
            onSubmit={(values) => {
              console.log("vlaue", values);
              const selectedSegments = segmentData
                .filter((segment: any) => values[segment.segmentLink])
                .map((segment: any) => ({
                  segmentName: segment.segmentName,

                  segmentLink: segment.segmentLink,
                }));

              const updatedSegmentData = segmentData.map((a: any) => {
                const isOpted = selectedSegments.some(
                  (b: any) => b.segmentLink === a.segmentLink
                );
                return { ...a, isOpted };
              });
              segmentDetailsPost(updatedSegmentData);
              // console.log(updatedSegmentData);

              // navigate("/yourprofile");
            }}
          >
            {({ errors, touched, handleSubmit, setFieldValue, values }) => {
              // console.log("values", values, errors);
              return (
                <Form
                  onKeyDown={(event) => {
                    event.code === "Enter" ||
                      (event.code === "NumpadEnter" && handleSubmit());
                  }}
                >
                  <Stack alignItems={"center"}>
                    <div className={TradingStyles.headerSection}>
                      <p className={TradingStyles.signup}>
                        Choose Your Trading Preference
                      </p>
                    </div>
                    <Stack marginTop={"30px"}>
                      <div
                        className={TradingStyles.checkBoxFeild}
                        style={{
                          background: "#CCE1E4",
                        }}
                      >
                        <FormControlLabel
                          sx={{ marginRight: "10px", width: "100%" }}
                          control={
                            <Checkbox
                              icon={<img src={TickCircle} width={"24px"} />}
                              checkedIcon={
                                <img src={TickCircle} width={"24px"} />
                              }
                              name={"equity"}
                              //disabled={segment.segmentLink === "equity"}
                              style={{ height: "20px", width: "20px" }}
                              checked
                            />
                          }
                          label={"Equity"}
                        />

                        <span className={TradingStyles.defaultLabel}>
                          Default
                        </span>
                      </div>
                      {/* <div
                        className={TradingStyles.checkBoxFeild}
                        style={{
                          background: "#ECFDF3",
                        }}
                      >
                        <FormControlLabel
                          sx={{ marginRight: "10px", width: "100%" }}
                          control={
                            <Checkbox
                              icon={<img src={TickCircle} width={"24px"} />}
                              checkedIcon={
                                <img src={TickCircle} width={"24px"} />
                              }
                              name={"futures_and_options"}
                              //disabled={segment.segmentLink === "equity"}
                              style={{ height: "20px", width: "20px" }}
                              checked
                            />
                          }
                          label={"F & O"}
                        />
                      </div> */}
                      {/* <div
                        className={TradingStyles.checkBoxFeild}
                        style={{
                          background: "#ECFDF3",
                        }}
                      >
                        <FormControlLabel
                          sx={{ marginRight: "10px", width: "100%" }}
                          control={
                            <Checkbox
                              icon={<img src={TickCircle} width={"24px"} />}
                              checkedIcon={
                                <img src={TickCircle} width={"24px"} />
                              }
                              name={"currency"}
                              //disabled={segment.segmentLink === "equity"}
                              style={{ height: "20px", width: "20px" }}
                              checked
                            />
                          }
                          label={"Currency"}
                        />
                      </div> */}
                      {/* <div
                        className={TradingStyles.checkBoxFeild}
                        style={{
                          background: "#ECFDF3",
                        }}
                      >
                        <FormControlLabel
                          sx={{ marginRight: "10px", width: "100%" }}
                          control={
                            <Checkbox
                              icon={<img src={TickCircle} width={"24px"} />}
                              checkedIcon={
                                <img src={TickCircle} width={"24px"} />
                              }
                              name={"commidity"}
                              //disabled={segment.segmentLink === "equity"}
                              style={{ height: "20px", width: "20px" }}
                              checked
                            />
                          }
                          label={"Commidity"}
                        />
                      </div> */}
                      {/* <div
                        className={TradingStyles.checkBoxFeild}
                        style={{
                          background: "#ECFDF3",
                        }}
                      >
                        <FormControlLabel
                          sx={{ marginRight: "10px", width: "100%" }}
                          control={
                            <Checkbox
                              icon={<img src={TickCircle} width={"24px"} />}
                              checkedIcon={
                                <img src={TickCircle} width={"24px"} />
                              }
                              name={"mutualfunds"}
                              //disabled={segment.segmentLink === "equity"}
                              style={{ height: "20px", width: "20px" }}
                              checked
                            />
                          }
                          label={"Mutual Funds"}
                        />
                      </div> */}

                      <FormGroup
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        {[
                          ...segmentData.filter(
                            (segment) =>
                              segment.segmentLink === "futures_and_options"
                          ),
                          ...segmentData.filter(
                            (segment) =>
                              segment.segmentLink !== "equity" &&
                              segment.segmentLink !== "futures_and_options"
                          ),
                        ].map((segment: any, index: any) => (
                          <div
                            className={TradingStyles.checkBoxFeild}
                            style={{
                              background: values[segment.segmentLink]
                                ? "#CCE1E4"
                                : "white",
                            }}
                            key={segment.segmentLink}
                          >
                            <FormControlLabel
                              sx={{ marginRight: "10px", width: "100%" }}
                              control={
                                <Checkbox
                                  icon={
                                    values[segment.segmentLink] === true ? (
                                      <img src={TickCircle} width={"24px"} />
                                    ) : (
                                      <img src={EmptyCircle} width={"24px"} />
                                    )
                                  }
                                  checkedIcon={
                                    values[segment.segmentLink] === "equity" ? (
                                      <img src={EmptyCircle} width={"24px"} />
                                    ) : (
                                      <img src={TickCircle} width={"24px"} />
                                    )
                                  }
                                  name={segment.segmentLink}
                                  onClick={(e) => {
                                    if (segment.segmentLink !== "equity") {
                                      e.stopPropagation();
                                      handleCheckBoxClick(
                                        segment.segmentLink,
                                        setFieldValue,
                                        values
                                      );
                                    }
                                  }}
                                  //disabled={segment.segmentLink === "equity"}
                                  style={{ height: "20px", width: "20px" }}
                                  value={values[segment.segmentLink]}
                                  checked={values[segment.segmentLink]}
                                />
                              }
                              label={segment.segmentName}
                            />
                            {segment.segmentLink === "equity" && (
                              <span className={TradingStyles.defaultLabel}>
                                Default
                              </span>
                            )}
                          </div>
                        ))}
                      </FormGroup>
                    </Stack>
                    {backdrop && <StartOverBackDrop />}
                    <div className={TradingStyles.buttonStack}>
                      <div>
                        <LoadingButton
                          id="tradingPreferencesContinueButton"
                          variant="contained"
                          // type="submit"
                          onClick={() => {
                            handleSubmit();
                          }}
                          className={TradingStyles.approveButton}
                        >
                          Continue
                        </LoadingButton>
                      </div>
                      <div className={TradingStyles.startOverSection}>
                        <img src={icon} className={TradingStyles.iconImage} />
                        <p className={TradingStyles.paratext}>
                          {" "}
                          if the above mentioned info not yours
                          <span
                            className={TradingStyles.startoverbutton}
                            onClick={handleStartOver}
                          >
                            Start Over
                          </span>
                        </p>
                      </div>
                    </div>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <div className={TradingStyles.wrapper}>
            <BackDrop />
          </div>
        )}

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={showModal}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={showModal}>
            <Box className={TradingStyles.boxModalWrapper}>
              <Stack sx={{ position: "absolute", right: "20px", top: "20px" }}>
                <IconButton onClick={()=>{setshowModal(false)}}>
                  <Close />
                </IconButton>
              </Stack>
              <div className={TradingStyles.riskdisclosuremodalwrapper}>
                <div className={TradingStyles.riskDisclosuresWrapper}>
                  <div className={TradingStyles.riskdisclosuretopsection}>
                    {/* <i
                      className="fa fa-newspaper-o"
                      style="font-size: 20px; margin-top: 3px"
                    ></i> */}
                    <p className={TradingStyles.riskdisclosuresheader}>
                      Risk disclosures on derivatives
                    </p>
                  </div>

                  <div className={TradingStyles.riskdisclosurecentersection}>
                    <ul>
                      <li className={TradingStyles.riskdiclosureulli}>
                        9 out of 10 individual traders in equity Futures and
                        Options Segment, incurred net losses.
                      </li>
                      <li className={TradingStyles.riskdiclosureulli}>
                        On an average, loss makers registered net trading loss
                        close to ₹50,000.
                      </li>
                      <li className={TradingStyles.riskdiclosureulli}>
                        Over and above the net trading losses incurred, loss
                        makers expended an additional 28% of net trading losses
                        as transaction costs.
                      </li>
                      <li className={TradingStyles.riskdiclosureulli}>
                        Those making net trading profits, incurred between 15%
                        to 50% of such profits as transaction cost.
                      </li>
                    </ul>

                    <p className={TradingStyles.riskdiclosureparadesc}>
                      Source:
                      <a
                        href="https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html"
                        target="_blank"
                        className="riskdisclosureatag"
                      >
                        SEBI study{" "}
                      </a>{" "}
                      dated january 25, 2023 on "Analysis of Profit and Loss of
                      Individual Traders dealing in equity Futures and Options
                      (F&O) Segment",wherein Aggregate Level findings are based
                      on annual Profit/Loss invurred by individual traders in
                      equity F&O during FY 2021-22.
                    </p>
                  </div>

                  <div className={TradingStyles.riskdisclosurebuttonsection}>
                    <Button
                      className={TradingStyles.riskdisclosurebutton}
                      variant="contained"
                      sx={{ borderRadius: "4px" }}
                      onClick={handleRiskDisclosure}
                    >
                      I understand
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}
