import React, { useEffect, useState } from "react";
import { Stack, TextField, Typography, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useNavigate } from "react-router-dom";
import { checkUserLoggedIn } from "../../shared/commonfunctions";
import { Formik, Form, FieldArray } from "formik";
import {
  fetchGetNomineeDropdownValuesAPICall,
  startOverAPICall,
  stageDetailsAPICall,
  nomineeAPICall,
} from "../../shared/APICalls";
import { schema } from "../../shared/FormikSchema";
import StageLayout from "../../Layouts/StageLayout";
import routesData from "../../shared/routes.json";
import Styles from "../../pages/Styles/NomineeStyles.module.scss";
import { INominee } from "../../shared/dtos";
import icon from "../../Components/assets/Images/iconnew.svg";
import NomineeForm from "./NomineeForm";
import StartOverBackDrop from "../../Components/StartOverBackDrop";
import BackDrop from "../../Components/BackDrop";
import { v4 as uuidv4 } from "uuid";
import dayjs, { Dayjs } from "dayjs";
import { nomineeID } from "../../shared/commonData";
import ErrorSnackBar from "../../Components/SnackBars/ErrorSnackBar";
import StageLayoutEkyc from "../../Layouts/StageLayoutEkyc";

const statesData = [
  {
    id: "Jammu and Kashmir",
    sequence: 1,
    value: "Jammu and Kashmir",
  },
  {
    id: "Himachal Pradesh",
    sequence: 2,
    value: "Himachal Pradesh",
  },
  {
    id: "Punjab",
    sequence: 3,
    value: "Punjab",
  },
  {
    id: "Chandigarh",
    sequence: 4,
    value: "Chandigarh",
  },
  {
    id: "Uttarakhand",
    sequence: 5,
    value: "Uttarakhand",
  },
  {
    id: "Haryana",
    sequence: 6,
    value: "Haryana",
  },
  {
    id: "Delhi",
    sequence: 7,
    value: "Delhi",
  },
  {
    id: "Rajasthan",
    sequence: 8,
    value: "Rajasthan",
  },
  {
    id: "Uttar Pradesh",
    sequence: 9,
    value: "Uttar Pradesh",
  },
  {
    id: "Bihar",
    sequence: 10,
    value: "Bihar",
  },
  {
    id: "Sikkim",
    sequence: 11,
    value: "Sikkim",
  },
  {
    id: "Arunachal Pradesh",
    sequence: 12,
    value: "Arunachal Pradesh",
  },
  {
    id: "Assam",
    sequence: 13,
    value: "Assam",
  },
  {
    id: "Manipur",
    sequence: 14,
    value: "Manipur",
  },
  {
    id: "Mizoram",
    sequence: 15,
    value: "Mizoram",
  },
  {
    id: "Tripura",
    sequence: 16,
    value: "Tripura",
  },
  {
    id: "Meghalaya",
    sequence: 17,
    value: "Meghalaya",
  },
  {
    id: "Nagaland",
    sequence: 18,
    value: "Nagaland",
  },
  {
    id: "West Bengal",
    sequence: 19,
    value: "West Bengal",
  },
  {
    id: "Jharkhand",
    sequence: 20,
    value: "Jharkhand",
  },
  {
    id: "Orissa",
    sequence: 21,
    value: "Orissa",
  },
  {
    id: "Chhattisgarh",
    sequence: 22,
    value: "Chhattisgarh",
  },
  {
    id: "Madhya Pradesh",
    sequence: 23,
    value: "Madhya Pradesh",
  },
  {
    id: "Gujarat",
    sequence: 24,
    value: "Gujarat",
  },
  {
    id: "Daman and Diu",
    sequence: 25,
    value: "Daman and Diu",
  },
  {
    id: "Dadra and Nagar Haveli",
    sequence: 26,
    value: "Dadra and Nagar Haveli",
  },
  {
    id: "Maharashtra",
    sequence: 27,
    value: "Maharashtra",
  },
  {
    id: "Andhra Pradesh",
    sequence: 28,
    value: "Andhra Pradesh",
  },
  {
    id: "Karnataka",
    sequence: 29,
    value: "Karnataka",
  },
  {
    id: "Goa",
    sequence: 30,
    value: "Goa",
  },
  {
    id: "Lakshadweep",
    sequence: 31,
    value: "Lakshadweep",
  },
  {
    id: "Kerala",
    sequence: 32,
    value: "Kerala",
  },
  {
    id: "Tamil Nadu",
    sequence: 33,
    value: "Tamil Nadu",
  },
  {
    id: "Puducherry",
    sequence: 34,
    value: "Puducherry",
  },
  {
    id: "Andaman and Nicobar Islands",
    sequence: 35,
    value: "Andaman and Nicobar Islands",
  },
  {
    id: "APO",
    sequence: 36,
    value: "APO",
  },
  {
    id: "Telangana",
    sequence: 37,
    value: "Telangana",
  },
  {
    id: "Ladakh",
    sequence: 38,
    value: "Ladakh",
  },
];

export default function NomineesPage() {
  const [address, setaddress] = useState({
    address: " ",
    city: "",
    pincode: "",
    State: "",
  });
  const navigate = useNavigate();
  //   const [percentageTotal, setPercentageTotal] = useState(0);
  const [assignIndex, setAssignIndex] = useState(0);
  const [relationShipData, setRelationShipData] = useState<any>([]);
  const [loading, setloading] = useState(false);
  const [startOverloading, setstartOverloading] = useState(false);
  const [ageError, setageError] = useState(false);
  const [gaurdianData, setgaurdianData] = useState(false);
  const [relationError, setrelationError] = useState(false);
  const [dobError, setDobError] = useState("");
  const [completedStages, setCompletedStages] = useState<any>();
  const [backdrop, setBackDrop] = useState(false);
  const [prefilloading, setprefilloading] = useState(true);
  const [rejectMessage, setRejectMessage] = useState("");
  const [prefillData, setPrefillData] = useState<any>([
    {
      id: uuidv4(),
      nomineeFullName: "",
      nomineeEmail: "",
      nomineeAddress: "",
      nomineeDateofBirth: null,
      nomineeMobileNumber: "",
      percentageofShare: "100",
      relationShip: "",
      relationshipIfOthers: "",
      gaurdianFullName: "",
      gaurdianDateofBirth: "",
      gaurdianEmail: "",
      gaurdianMobileNumber: "",
      gaurdianRelationWithNominee: "",
      gaurdianAddress: "",
      gaurdianAddressPinCode: "",
      gaurdianAddressCity: "",
      gaurdianAddressState: "",
      nomineeIDProof: "",
      nomineeIDProofNo: "",
      nomineeAddressCity: "",
      nomineeAddressPinCode: "",
      nomineeAddressState: "",
    },
  ]);
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const navigationData = routesData;

  // console.log("assignIndex", assignIndex);

  const convertDayJsToDate = (dateValue: any) => {
    const date = new Date(dateValue);

    const formattedDate = date.toLocaleDateString("en-GB", {
      year: "numeric",
      day: "2-digit",
      month: "2-digit",
    });
    const [month, day, year] = formattedDate.split("/");
    const formatDate = `${year}-${day}-${month}`;
    return formatDate;
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    checkUserLoggedIn(navigate);
    //console.log("data");
    fetchGetNomineeDropdownValuesAPICall()
      .then((response: any) => {
        const relationshipDataTemp = response.data.data;
        //console.log("responseData", responseData);
        setRelationShipData([...relationshipDataTemp]);
        let obj: any = {
          stage: "nominee_details",
        };

        stageDetailsAPICall(obj)
          .then((response: any) => {
            const responseData = response.data;
            // console.log("responseData", responseData);
            if (response.data.data.StageDetails.ErrorMessage) {
              setRejectMessage(response.data.data.StageDetails.ErrorMessage);
            }

            setprefilloading(false);
            const addr = responseData.data.StageDetails.CustomerAddress.Address;
            const city = responseData.data.StageDetails.CustomerAddress.City;
            const state = responseData.data.StageDetails.CustomerAddress.State;
            const pincode =
              responseData.data.StageDetails.CustomerAddress.Pincode;
            const clientEmail =
              responseData.data.StageDetails.CustomerAddress.ClientEmail;
            const clientMobile =
              responseData.data.StageDetails.CustomerAddress.ClientMobile;
            setCustomerEmail(clientEmail);
            setCustomerPhoneNumber(clientMobile);
            setPrefillData([
              {
                id: uuidv4(),
                nomineeFullName: "",
                nomineeEmail: clientEmail,
                nomineeAddress: "",
                nomineeDateofBirth: null,
                nomineeMobileNumber: clientMobile,
                percentageofShare: "100",
                relationShip: "",
                relationshipIfOthers: "",
                gaurdianFullName: "",
                gaurdianDateofBirth: "",
                gaurdianEmail: "",
                gaurdianMobileNumber: "",
                gaurdianRelationWithNominee: "",
                gaurdianAddress: "",
                gaurdianAddressPinCode: "",
                gaurdianAddressCity: "",
                gaurdianAddressState: "",
                nomineeIDProof: "",
                nomineeIDProofNo: "",
                nomineeAddressCity: "",
                nomineeAddressPinCode: "",
                nomineeAddressState: "",
              },
            ]);

            setCompletedStages(responseData.completed_stages);
            setaddress({
              address: addr.toUpperCase(),
              city: city.toUpperCase(),
              pincode: pincode.toUpperCase(),
              State: state.toUpperCase(),
            });
            //console.log(responseData.data.StageDetails.NomineeDetails.length)

            if (responseData.data.StageDetails.NomineeDetails) {
              // console.log(
              //   "NomineeDetails",
              //   responseData.data.StageDetails.NomineeDetails
              // );

              let nomineeDetails =
                responseData.data.StageDetails.NomineeDetails.map(
                  (data: any, index: number) => ({
                    id: data.id,
                    nomineeFullName: data.FullName,
                    nomineeEmail: data.Email,
                    nomineeAddress: data.Address,
                    nomineeDateofBirth: dayjs(data.DOB),
                    nomineeMobileNumber: data.Mobile,
                    percentageofShare: data.SharePercentage,
                    relationShip: relationshipDataTemp.find(
                      (a: any) => a.id === data.Relationship
                    ),
                    relationshipIfOthers: !data.RelationshipOthersValue
                      ? ""
                      : data.RelationshipOthersValue,
                    gaurdianFullName: !data.GuradianFullName
                      ? ""
                      : data.GuradianFullName,
                    gaurdianDateofBirth: !data.GuradianDOB
                      ? ""
                      : dayjs(data.GuradianDOB),
                    gaurdianEmail: !data.GuradianEmail
                      ? ""
                      : data.GuradianEmail,
                    gaurdianMobileNumber: !data.GuradianMobile
                      ? ""
                      : data.GuradianMobile,
                    gaurdianRelationWithNominee:
                      !data.GuradianRealtionWithNominee
                        ? ""
                        : relationshipDataTemp.find(
                            (a: any) =>
                              a.value.toUpperCase() ===
                              data.GuradianRealtionWithNominee.toUpperCase()
                          ),
                    gaurdianAddress: !data.GuradianAddress
                      ? ""
                      : data.GuradianAddress,
                    gaurdianAddressPinCode: !data.GuradianAddressPinCode
                      ? ""
                      : data.GuradianAddressPinCode,
                    gaurdianAddressCity: !data.GuradianAddressCity
                      ? ""
                      : data.GuradianAddressCity,

                    gaurdianAddressState: !data.GuradianAddressState
                      ? ""
                      : statesData.filter(
                          (a: any) =>
                            a.value.toUpperCase() ===
                            data.GuradianAddressState.toUpperCase()
                        )[0],
                    nomineeIDProof: data.NomineeIDProof,
                    nomineeIDProofNo: !data.NomineeIDProofNo
                      ? ""
                      : data.NomineeIDProofNo,
                    nomineeAddressPinCode: data.Pincode,
                    nomineeAddressCity: data.City,
                    nomineeAddressState: statesData.filter(
                      (a: any) =>
                        a.value.toUpperCase() === data.State.toUpperCase()
                    )[0],
                  })
                );
              setPrefillData(nomineeDetails);
              // console.log("relationshipDataTemp", relationshipDataTemp);
            }
          })
          .catch((error: any) => {
            //console.log("error", error);
            setprefilloading(false);
          });
      })
      .catch((error: any) => {});
    setprefilloading(true);
  }, []);

  //   useEffect(() => {
  //     if (percentage) {
  //       const percentageValues: any = Object.values(percentage);
  //       const total = percentageValues.reduce((accumulator, currentValue) => {
  //         return accumulator + parseFloat(currentValue);
  //       }, 0);
  //       setPercentageTotal(total);
  //     }
  //   }, [percentage]);

  const handleNomineeAdd = (values: any, callBack: any) => {
    setAssignIndex((prev) => prev + 1);
    const obj: INominee = {
      id: uuidv4(),
      nomineeFullName: "",
      nomineeEmail: customerEmail,
      nomineeAddress: "",
      nomineeDateofBirth: "",
      nomineeMobileNumber: customerPhoneNumber,
      percentageofShare: "",
      relationShip: "",
      relationshipIfOthers: "",
      gaurdianFullName: "",
      gaurdianDateofBirth: "",
      gaurdianEmail: "",
      gaurdianMobileNumber: "",
      gaurdianRelationWithNominee: "",
      nomineeIDProof: "",
      nomineeIDProofNo: "",
      nomineeAddressCity: "",
      nomineeAddressPinCode: "",
      nomineeAddressState: "",
    };

    const nomineeCopy: any = [...values.nominees];
    nomineeCopy.push(obj);
    callBack("nominees", nomineeCopy);
  };

  const handleNomineeDelete = (values: any, callBack: any, index: any) => {
    const nomineeCopy: any = [...values.nominees];
    nomineeCopy.splice(index, 1);
    callBack("nominees", nomineeCopy);
    setAssignIndex(index - 1);
  };
  const handleChangeField = (
    name: any,
    value: any,
    callBack: any,
    index: any,
    values: any,
    setErrors: any,
    errors: any
  ) => {
    let nomineeCopy: any = [...values.nominees];
    nomineeCopy[index][name] = value;
    callBack("nominees", nomineeCopy);
    if (name === "nomineeDateofBirth") {
      if (
        values.nominees[index].nomineeDateofBirth &&
        values.nominees[index].relationShip &&
        !values.nominees[index].relationShip.isGuardianReq
      ) {
        const dobLimit = new Date();
        dobLimit.setFullYear(dobLimit.getFullYear() - 18);
        if (
          !values.nominees[index].nomineeDateofBirth ||
          values.nominees[index].nomineeDateofBirth > dobLimit
        ) {
          console.log("DOB is not 18 years");
        }
      }
    }
    if (name === "relationShip") {
      // Clearing Others Data
      if (
        value.value !== "Others" &&
        nomineeCopy[index]["relationshipIfOthers"]
      ) {
        nomineeCopy[index]["relationshipIfOthers"] = "";
        callBack("nominees", nomineeCopy);
      }
      if (
        values.nominees[index].nomineeDateofBirth &&
        values.nominees[index].relationShip &&
        !values.nominees[index].relationShip.isGuardianReq
      ) {
        const dobLimit = new Date();
        dobLimit.setFullYear(dobLimit.getFullYear() - 18);
        if (
          !values.nominees[index].nomineeDateofBirth ||
          values.nominees[index].nomineeDateofBirth > dobLimit
        ) {
          console.log("DOB is not 18 years");
        }
      }
    }
    // if (name === "nomineeDateofBirth") {
    //   if (
    //     (values.nominees[index].relationShip &&
    //       values.nominees[index].relationShip.value === "Son") ||
    //     values.nominees[index].relationShip.value === "Daughter" ||
    //     values.nominees[index].relationShip.value === "Grand son" ||
    //     values.nominees[index].relationShip.value === "Grand daughter" ||
    //     values.nominees[index].relationShip.value === "Others" ||
    //     values.nominees[index].relationShip.value === "Brother" ||
    //     values.nominees[index].relationShip.value === "Sister"
    //   ) {
    //     const dobLimit = new Date();
    //     dobLimit.setFullYear(dobLimit.getFullYear() - 18);
    //     if (
    //       !values.nominees[index].nomineeDateofBirth ||
    //       values.nominees[index].nomineeDateofBirth > dobLimit
    //     ) {
    //       setgaurdianData(true);
    //     } else {
    //       setgaurdianData(false);
    //     }
    //   } else {
    //     setgaurdianData(false);
    //   }
    // }
    // if (name === "relationShip") {
    //   if (
    //     (values.nominees[index].nomineeDateofBirth &&
    //       values.nominees[index].relationShip.value === "Son") ||
    //     values.nominees[index].relationShip.value === "Daughter" ||
    //     values.nominees[index].relationShip.value === "Grand son" ||
    //     values.nominees[index].relationShip.value === "Grand daughter" ||
    //     values.nominees[index].relationShip.value === "Others" ||
    //     values.nominees[index].relationShip.value === "Brother" ||
    //     values.nominees[index].relationShip.value === "Sister"
    //   ) {
    //     const dobLimit = new Date();
    //     dobLimit.setFullYear(dobLimit.getFullYear() - 18);
    //     if (
    //       !values.nominees[index].nomineeDateofBirth ||
    //       values.nominees[index].nomineeDateofBirth > dobLimit
    //     ) {
    //       setgaurdianData(true);
    //     } else {
    //       setgaurdianData(false);
    //     }
    //   }
    //   else {
    //     setgaurdianData(false);
    //   }
    // }
    if (name === "nomineeFullName") {
      const currentRelationShip = values.nominees[index].nomineeFullName;

      const isDuplicateRelationship = values.nominees.some((nominee, i) => {
        if (i !== index && nominee.nomineeFullName === currentRelationShip) {
          return true;
        }
        return false;
      });

      if (isDuplicateRelationship) {
        setrelationError(true);

        setTimeout(() => {
          setrelationError(false);
        }, 8000);
      }
    }
    if (name === "gaurdianDateofBirth") {
      const dobLimit = new Date();
      dobLimit.setFullYear(dobLimit.getFullYear() - 18);
      if (!value || value > dobLimit) {
        // setDobError(true);

        setDobError("Gaurdian Age should not be Below 18");
        setTimeout(() => {
          setDobError("");
        }, 10000);
      }
    }
    // if (name === "percentageofShare") {
    //   const percentageTotal = values.nominees
    //     .map((a: any) =>
    //       a.percentageofShare === "" ? 0 : parseFloat(a.percentageofShare)
    //     )
    //     .reduce((sum: any, current: any) => sum + current, 0);
    //   console.log("errors new", errors);

    // }
  };

  const handleStartOver = () => {
    if (
      window.confirm(
        "Are you sure you want to restart, on pressing Yes all the Data will be deleted"
      )
    ) {
      setBackDrop(true);
      setstartOverloading(true);
      startOverAPICall()
        .then((response: any) => {
          const responseData = response.data;

          setstartOverloading(false);
          setBackDrop(false);
          navigate("/");
          window.location.reload();
        })
        .catch((error: any) => {
          setBackDrop(false);
          setstartOverloading(false);
        });
    }
  };

  const handleNomineeEdit = (index: any) => {
    setAssignIndex(index);
  };

  const checkAgeLimit = (enteredDate: any) => {
    const dobLimit = new Date();
    dobLimit.setFullYear(dobLimit.getFullYear() - 18);
    if (!enteredDate || enteredDate > dobLimit) {
      return true;
    } else {
      return false;
    }
  };

  function nomineeDetailsSubmit(values: any) {
    let requestObject = values.map((a: any) => ({
      ...a,
      relationShip: a.relationShip.id,
      nomineeDateofBirth: convertDayJsToDate(a.nomineeDateofBirth),
    }));

    let ArrayObject = values.map((a: any) => ({
      id: a.id,
      FullName: a.nomineeFullName.toUpperCase().trim(),
      Email: a.nomineeEmail,
      Address: a.nomineeAddress.toUpperCase().trim(),
      DOB: convertDayJsToDate(a.nomineeDateofBirth),
      Mobile: a.nomineeMobileNumber,
      SharePercentage: a.percentageofShare,
      Relationship: a.relationShip.id,
      RelationshipName: a.relationShip.value.toUpperCase(),
      RelationshipOthersValue: a.relationshipIfOthers
        ? a.relationshipIfOthers.toUpperCase().trim()
        : "",
      GuradianFullName: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianFullName
          ? a.gaurdianFullName.toUpperCase().trim()
          : ""
        : "",
      GuradianDOB: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianDateofBirth === ""
          ? ""
          : convertDayJsToDate(a.gaurdianDateofBirth)
        : "",
      GuradianEmail: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianEmail
        : "",
      GuradianMobile: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianMobileNumber
        : "",
      GuradianRealtionWithNominee: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianRelationWithNominee.value
          ? a.gaurdianRelationWithNominee.value.toUpperCase()
          : ""
        : "",
      GuradianAddress: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianAddress
          ? a.gaurdianAddress.toUpperCase().trim()
          : ""
        : "",
      GuradianAddressPinCode: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianAddressPinCode
        : "",
      GuradianAddressCity: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianAddressCity
          ? a.gaurdianAddressCity.toUpperCase().trim()
          : ""
        : "",
      GuradianAddressState: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianAddressState
          ? a.gaurdianAddressState.value.toUpperCase()
          : ""
        : "",
      NomineeIDProof: !a.nomineeIDProof ? "" : a.nomineeIDProof,
      NomineeIDProofNo: a.nomineeIDProofNo.toUpperCase(),
      Pincode: a.nomineeAddressPinCode,
      City: a.nomineeAddressCity.toUpperCase().trim(),
      State: a.nomineeAddressState.value.toUpperCase(),
    }));
    // console.log("date", checkAgeLimit(new Date("2000-01-01")));
    console.log("valuesAfter", ArrayObject);
    let obj: any = {
      nominee_json: ArrayObject,
    };

    nomineeAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log("responseData", responseData);
        setloading(false);
        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
           navigate(navigationData[responseData.data.stage]);
        }
      })
      .catch((error: any) => {
        setloading(false);
      });
  }
  // console.log("prefillData", prefillData);
  return (
    <div className={Styles.mainWrapperTop}>
      <StageLayoutEkyc
        completedStages={completedStages}
        stageName={"Nominee"}
        navigatePath={"/termsandconditions"}
      />
      <div className={Styles.mainWrapper}>
        <div>
          <p className={Styles.Header}>
            {assignIndex === 0
              ? "Nominee Details"
              : `Adding Nominee ${assignIndex + 1}`}
          </p>
        </div>
        {!prefilloading ? (
          <Formik
            validationSchema={schema}
            initialValues={{
              nominees: [...prefillData],
            }}
            onSubmit={(values: any) => {
              // console.log("submit", values.nominees);
              nomineeDetailsSubmit(values.nominees);
              // setnomineeData(values);
              // console.log("values", values.nominees.map((value:any , index:any)=>
              //   value.nomineeFullName

              // ));

              //navigate("/termsandconditions");
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldError,
              setErrors,
            }) => {
              const { nominees } = values;
              // console.log("errors", errors);
              // console.log("values", values);

              const percentageTotal = values.nominees
                .map((a: any) =>
                  a.percentageofShare === ""
                    ? 0
                    : parseFloat(a.percentageofShare)
                )
                .reduce((sum: any, current: any) => sum + current, 0);
              // console.log("values", values);
              return (
                <Form>
                  <div className={Styles.wrapper}>
                    <div className={Styles.leftContainer}>
                      <FieldArray
                        name="nominees"
                        render={(arrayHelpers) => (
                          <div>
                            {nominees.map((nominee, index) => {
                              const touchedData = touched.nominees
                                ? touched.nominees[index]
                                : {};
                              const errorData = errors.nominees
                                ? errors.nominees[index]
                                : {};

                              return (
                                <NomineeForm
                                  handleNomineeDelete={handleNomineeDelete}
                                  handleNomineeEdit={handleNomineeEdit}
                                  index={index}
                                  currentIndex={assignIndex}
                                  nominee={nominee}
                                  touchedData={touchedData}
                                  errorData={errorData}
                                  handleChangeField={handleChangeField}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  handleBlur={handleBlur}
                                  relationError={false}
                                  ageError={false}
                                  relationShipData={relationShipData}
                                  address={address}
                                  setErrors={setErrors}
                                  errors={errors}
                                  gaurdianData={gaurdianData}
                                  statesData={statesData}
                                />

                                //   <>
                                //     <div className={Styles.leftSubContainer}>
                                //       <div>
                                //         <NomineeBar
                                //           index={index}
                                //           onEditClick={undefined}
                                //           onDeleteClick={undefined}
                                //         />

                                //         <div
                                //           style={{
                                //             display:
                                //               assignIndex !== index
                                //                 ? "none"
                                //                 : "block",
                                //           }}
                                //           className={Styles.fieldsSection}
                                //         >
                                //           <div className={Styles.fields}>
                                //             <div>
                                //               <TextField
                                //                 className={Styles.textfields}
                                //                 id="outlined-basic"
                                //                 label="Nominee's Fullname"
                                //                 variant="outlined"
                                //                 name="nomineeFullName"
                                //                 value={nominee.nomineeFullName}
                                //                 error={
                                //                   touchedData.nomineeFullName &&
                                //                   Boolean(
                                //                     errorData["nomineeFullName"]
                                //                   )
                                //                 }
                                //                 helperText={
                                //                   touchedData.nomineeFullName &&
                                //                   errorData["nomineeFullName"]
                                //                 }
                                //                 onChange={(event) => {
                                //                   handleChangeField(
                                //                     "nomineeFullName",
                                //                     event.target.value,
                                //                     setFieldValue,
                                //                     index,
                                //                     values
                                //                   );
                                //                 }}
                                //                 onBlur={handleBlur}
                                //                 inputProps={{ maxLength: 50 }}
                                //               />
                                //               <FormHelperText
                                //                 style={{ color: "#d32f2f" }}
                                //               >
                                //                 {relationError
                                //                   ? "Nominees  Cannot be same"
                                //                   : ""}
                                //               </FormHelperText>
                                //             </div>
                                //             <div>
                                //               <LocalizationProvider
                                //                   dateAdapter={AdapterDayjs}
                                //                 >
                                //               {/* <DatePickerComp /> */}
                                //               <DatePicker
                                //                 disableFuture
                                //                 components={{
                                //                   OpenPickerIcon: DateIcon,
                                //                 }}
                                //                 id="dateofbirthNomineeDateofBirth"
                                //                 label="Nominee Date of Birth"
                                //                 name="nomineeDateofBirth"
                                //                 format="DD/MM/YYYY"
                                //                 value={nominee.nomineeDateofBirth}
                                //                 className={Styles.datepicker}
                                //                 onChange={(date) => {
                                //                   //console.log("date", date);
                                //                   handleChangeField(
                                //                     "nomineeDateofBirth",
                                //                     date,
                                //                     setFieldValue,
                                //                     index,
                                //                     values
                                //                   );
                                //                 }}
                                //                 onBlur={handleBlur}
                                //                 error={
                                //                   touchedData.nomineeDateofBirth &&
                                //                   Boolean(
                                //                     errorData["nomineeDateofBirth"]
                                //                   )
                                //                 }
                                //                 helperText={
                                //                   touchedData.nomineeDateofBirth &&
                                //                   errorData["nomineeDateofBirth"]
                                //                 }
                                //                 renderInput={(params: any) => (
                                //                   <TextField {...params} />
                                //                 )}
                                //              {...(DatePicker as any)}
                                //               />
                                //               </LocalizationProvider>
                                //               <FormHelperText
                                //                 style={{ color: "#d32f2f" }}
                                //               >
                                //                 {ageError
                                //                   ? "Age of Nominee is Below 18Years"
                                //                   : ""}
                                //               </FormHelperText>
                                //             </div>
                                //           </div>
                                //           <div className={Styles.fields}>
                                //             <div>
                                //               <TextField
                                //                 className={Styles.textfields}
                                //                 id="outlined-basic"
                                //                 label="Nominee's Email"
                                //                 variant="outlined"
                                //                 name="nomineeEmail"
                                //                 value={nominee.nomineeEmail}
                                //                 error={
                                //                   touchedData.nomineeEmail &&
                                //                   Boolean(errorData["nomineeEmail"])
                                //                 }
                                //                 helperText={
                                //                   touchedData.nomineeEmail &&
                                //                   errorData["nomineeEmail"]
                                //                 }
                                //                 onChange={(event) => {
                                //                   handleChangeField(
                                //                     "nomineeEmail",
                                //                     event.target.value,
                                //                     setFieldValue,
                                //                     index,
                                //                     values
                                //                   );
                                //                 }}
                                //                 onBlur={handleBlur}
                                //                 inputProps={{ maxLength: 50 }}
                                //               />
                                //             </div>
                                //             <div>
                                //               <TextField
                                //                 className={Styles.textfields}
                                //                 id="outlined-basic"
                                //                 label="Nominee's Mobile Number"
                                //                 variant="outlined"
                                //                 name="nomineeMobileNumber"
                                //                 value={nominee.nomineeMobileNumber}
                                //                 error={
                                //                   touchedData.nomineeMobileNumber &&
                                //                   Boolean(
                                //                     errorData["nomineeMobileNumber"]
                                //                   )
                                //                 }
                                //                 helperText={
                                //                   touchedData.nomineeMobileNumber &&
                                //                   errorData["nomineeMobileNumber"]
                                //                 }
                                //                 onChange={(event) => {
                                //                   handleChangeField(
                                //                     "nomineeMobileNumber",
                                //                     event.target.value,
                                //                     setFieldValue,
                                //                     index,
                                //                     values
                                //                   );
                                //                 }}
                                //                 onBlur={handleBlur}
                                //                 inputProps={{
                                //                   maxLength: 10,
                                //                   inputMode: "numeric",
                                //                   pattern: "[0-9]*",
                                //                 }}
                                //                 onKeyPress={(event) => {
                                //                   const keyCode =
                                //                     event.keyCode || event.which;
                                //                   const keyValue =
                                //                     String.fromCharCode(keyCode);
                                //                   const isValidKey = /^\d+$/.test(
                                //                     keyValue
                                //                   );

                                //                   if (!isValidKey) {
                                //                     event.preventDefault();
                                //                   }
                                //                 }}
                                //               />
                                //             </div>
                                //           </div>
                                //           <div>
                                //             <div className={Styles.fields}>
                                //               <div>
                                //                 <TextField
                                //                   className={Styles.textfields}
                                //                   id="outlined-basic"
                                //                   label="Percentage of Share"
                                //                   variant="outlined"
                                //                   name="percentageofShare"
                                //                   value={nominee.percentageofShare}
                                //                   error={
                                //                     touchedData.percentageofShare &&
                                //                     Boolean(
                                //                       errorData["percentageofShare"]
                                //                     )
                                //                   }
                                //                   helperText={
                                //                     touchedData.percentageofShare &&
                                //                     errorData["percentageofShare"]
                                //                   }
                                //                   onChange={(event) => {
                                //                     const { value } = event.target;

                                //                     // console.log("event",event.target.value, index)
                                //                     handleChangeField(
                                //                       "percentageofShare",
                                //                       event.target.value,
                                //                       setFieldValue,
                                //                       index,
                                //                       values
                                //                     );
                                //                   }}
                                //                   onBlur={handleBlur}
                                //                   inputProps={{
                                //                     maxLength: 3,
                                //                     inputMode: "numeric",
                                //                     pattern: "[0-9]*",
                                //                     onInput: (
                                //                       event: React.ChangeEvent<HTMLInputElement>
                                //                     ) => {
                                //                       const value: any =
                                //                         event.target.value;
                                //                       if (value > 100) {
                                //                         event.target.value = "100";
                                //                       }
                                //                     },
                                //                   }}
                                //                   onKeyPress={(event) => {
                                //                     const keyCode =
                                //                       event.keyCode || event.which;
                                //                     const keyValue =
                                //                       String.fromCharCode(keyCode);
                                //                     const isValidKey = /^\d+$/.test(
                                //                       keyValue
                                //                     );

                                //                     if (!isValidKey) {
                                //                       event.preventDefault();
                                //                     }
                                //                   }}
                                //                 />

                                //                 <FormHelperText
                                //                   style={{ color: "#d32f2f" }}
                                //                 >
                                //                   {percentageTotal > 100 &&
                                //                     "Percenatge of Share should not exceed 100%"}
                                //                 </FormHelperText>
                                //               </div>
                                //               <div>
                                //                 <div className={Styles.textfields}>
                                //                   {/* <Typography>RelationShip</Typography> */}
                                //                   <Dropdown
                                //                     LabelName="Relationship"
                                //                     menuItems={relationShipData}
                                //                     name="relationShip"
                                //                     value={nominee.relationShip}
                                //                     handleChange={(event: any) => {
                                //                       handleChangeField(
                                //                         "relationShip",
                                //                         event.target.value,
                                //                         setFieldValue,
                                //                         index,
                                //                         values
                                //                       );
                                //                     }}
                                //                     error={
                                //                       touchedData.relationShip &&
                                //                       Boolean(
                                //                         errorData["relationShip"]
                                //                       )
                                //                     }
                                //                     helperText={
                                //                       touchedData.relationShip &&
                                //                       errorData["relationShip"]
                                //                     }
                                //                     //   setOthers={setOccupationOthers}
                                //                   />
                                //                 </div>
                                //               </div>
                                //             </div>

                                //             {gaurdianData ? (
                                //               <>
                                //                 <div className={Styles.fields}>
                                //                   <div>
                                //                     <TextField
                                //                       className={Styles.textfields}
                                //                       id="outlined-basic"
                                //                       label="Gaurdian's Fullname"
                                //                       variant="outlined"
                                //                       name="gaurdianFullName"
                                //                       value={
                                //                         nominee.gaurdianFullName
                                //                       }
                                //                       error={
                                //                         touchedData.gaurdianFullName &&
                                //                         Boolean(
                                //                           errorData[
                                //                             "gaurdianFullName"
                                //                           ]
                                //                         )
                                //                       }
                                //                       helperText={
                                //                         touchedData.gaurdianFullName &&
                                //                         errorData[
                                //                           "gaurdianFullName"
                                //                         ]
                                //                       }
                                //                       onChange={(event) => {
                                //                         handleChangeField(
                                //                           "gaurdianFullName",
                                //                           event.target.value,
                                //                           setFieldValue,
                                //                           index,
                                //                           values
                                //                         );
                                //                       }}
                                //                       onBlur={handleBlur}
                                //                       inputProps={{
                                //                         maxLength: 50,
                                //                       }}
                                //                     />
                                //                   </div>
                                //                   <div>
                                //                     <LocalizationProvider
                                //                         dateAdapter={AdapterDayjs}
                                //                       >

                                //                     <DatePicker
                                //                       // disableFuture
                                //                       components={{
                                //                         OpenPickerIcon: DateIcon,
                                //                       }}
                                //                       minDate={maxDate}
                                //                       maxDate={eighteenYearsAgo}
                                //                       views={[
                                //                         "year",
                                //                         "month",
                                //                         "day",
                                //                       ]}
                                //                       id="dateofbirthGaurdianDateofBirth"
                                //                       label="Gaurdian Date of Birth"
                                //                       name="gaurdianDateofBirth"
                                //                       format="DD/MM/YYYY"
                                //                       value={
                                //                         nominee.gaurdianDateofBirth
                                //                       }
                                //                       className={Styles.datepicker}
                                //                       onChange={(date) => {
                                //                         //console.log("date", date);
                                //                         handleChangeField(
                                //                           "gaurdianDateofBirth",
                                //                           date,
                                //                           setFieldValue,
                                //                           index,
                                //                           values
                                //                         );
                                //                       }}
                                //                       onBlur={handleBlur}
                                //                       error={
                                //                         touchedData.gaurdianDateofBirth &&
                                //                         Boolean(
                                //                           errorData[
                                //                             "nomineeDateofBirth"
                                //                           ]
                                //                         )
                                //                       }
                                //                       helperText={
                                //                         touchedData.gaurdianDateofBirth &&
                                //                         errorData[
                                //                           "gaurdianDateofBirth"
                                //                         ]
                                //                       }
                                //                       renderInput={(
                                //                         params: any
                                //                       ) => (
                                //                         <TextField {...params} />
                                //                       )}
                                //                       {...(DatePicker as any)}
                                //                     />
                                //                     {/* </DemoContainer> */}
                                //                     </LocalizationProvider>
                                //                     <FormHelperText
                                //                       style={{ color: "#d32f2f" }}
                                //                     >
                                //                       {dobError}
                                //                     </FormHelperText>
                                //                   </div>
                                //                 </div>
                                //                 <div className={Styles.fields}>
                                //                   <div>
                                //                     <TextField
                                //                       className={Styles.textfields}
                                //                       id="outlined-basic"
                                //                       label="Gaurdian's Email"
                                //                       variant="outlined"
                                //                       name="gaurdianEmail"
                                //                       value={nominee.gaurdianEmail}
                                //                       error={
                                //                         touchedData.gaurdianEmail &&
                                //                         Boolean(
                                //                           errorData["gaurdianEmail"]
                                //                         )
                                //                       }
                                //                       helperText={
                                //                         touchedData.gaurdianEmail &&
                                //                         errorData["gaurdianEmail"]
                                //                       }
                                //                       onChange={(event) => {
                                //                         handleChangeField(
                                //                           "gaurdianEmail",
                                //                           event.target.value,
                                //                           setFieldValue,
                                //                           index,
                                //                           values
                                //                         );
                                //                       }}
                                //                       onBlur={handleBlur}
                                //                       inputProps={{
                                //                         maxLength: 50,
                                //                       }}
                                //                     />
                                //                   </div>
                                //                   <div>
                                //                     <TextField
                                //                       className={Styles.textfields}
                                //                       id="outlined-basic"
                                //                       type="number"
                                //                       label="Gaurdian's Mobile Number"
                                //                       variant="outlined"
                                //                       name="gaurdianMobileNumber"
                                //                       value={
                                //                         nominee.gaurdianMobileNumber
                                //                       }
                                //                       error={
                                //                         touchedData.gaurdianMobileNumber &&
                                //                         Boolean(
                                //                           errorData[
                                //                             "gaurdianMobileNumber"
                                //                           ]
                                //                         )
                                //                       }
                                //                       helperText={
                                //                         touchedData.gaurdianMobileNumber &&
                                //                         errorData[
                                //                           "gaurdianMobileNumber"
                                //                         ]
                                //                       }
                                //                       onChange={(event) => {
                                //                         handleChangeField(
                                //                           "gaurdianMobileNumber",
                                //                           event.target.value,
                                //                           setFieldValue,
                                //                           index,
                                //                           values
                                //                         );
                                //                       }}
                                //                       onBlur={handleBlur}
                                //                       inputProps={{
                                //                         maxLength: 10,
                                //                         inputMode: "numeric",
                                //                         pattern: "[0-9]*",
                                //                       }}
                                //                     />
                                //                   </div>
                                //                 </div>
                                //                 <div>
                                //                   <TextField
                                //                     className={
                                //                       Styles.gaurdianRelation
                                //                     }
                                //                     id="outlined-basic"
                                //                     label="Gaurdian Relation with Nominee"
                                //                     variant="outlined"
                                //                     name="gaurdianRelationWithNominee"
                                //                     value={
                                //                       nominee.gaurdianRelationWithNominee
                                //                     }
                                //                     error={
                                //                       touchedData.gaurdianRelationWithNominee &&
                                //                       Boolean(
                                //                         errorData[
                                //                           "gaurdianRelationWithNominee"
                                //                         ]
                                //                       )
                                //                     }
                                //                     helperText={
                                //                       touchedData.gaurdianRelationWithNominee &&
                                //                       errorData[
                                //                         "gaurdianRelationWithNominee"
                                //                       ]
                                //                     }
                                //                     onChange={(event) => {
                                //                       handleChangeField(
                                //                         "gaurdianRelationWithNominee",
                                //                         event.target.value,
                                //                         setFieldValue,
                                //                         index,
                                //                         values
                                //                       );
                                //                     }}
                                //                     onBlur={handleBlur}
                                //                     inputProps={{
                                //                       maxLength: 50,
                                //                     }}
                                //                   />
                                //                 </div>
                                //               </>
                                //             ) : (
                                //               ""
                                //             )}

                                //             <div>
                                //               {occupationOthers === "Others" ? (
                                //                 <TextField
                                //                   className={
                                //                     Styles.textfieldsOthers
                                //                   }
                                //                   id="outlined-basic"
                                //                   label="RelationShip Others"
                                //                   variant="outlined"
                                //                   name="relationshipIfOthers"
                                //                   value={nominee.relationshipIfOthers.toUpperCase()}
                                //                   error={
                                //                     touchedData.relationshipIfOthers &&
                                //                     Boolean(
                                //                       errorData[
                                //                         "relationshipIfOthers"
                                //                       ]
                                //                     )
                                //                   }
                                //                   sx={{ marginTop: "15px" }}
                                //                   helperText={
                                //                     touchedData.relationshipIfOthers &&
                                //                     errorData[
                                //                       "relationshipIfOthers"
                                //                     ]
                                //                   }
                                //                   onChange={(event) => {
                                //                     handleChangeField(
                                //                       "relationshipIfOthers",
                                //                       event.target.value,
                                //                       setFieldValue,
                                //                       index,
                                //                       values
                                //                     );
                                //                   }}
                                //                   onBlur={handleBlur}
                                //                   inputProps={{ maxLength: 50 }}
                                //                 />
                                //               ) : (
                                //                 ""
                                //               )}
                                //             </div>
                                //           </div>

                                //           <TextField
                                //             id="nomineeAddress"
                                //             className={Styles.addressfields}
                                //             name="nomineeAddress"
                                //             label="Address"
                                //             variant="outlined"
                                //             placeholder="Nominee Address"
                                //             value={nominee.nomineeAddress}
                                //             onChange={(event) => {
                                //               handleChangeField(
                                //                 "nomineeAddress",
                                //                 event.target.value,
                                //                 setFieldValue,
                                //                 index,
                                //                 values
                                //               );
                                //             }}
                                //             disabled={
                                //               address === nominee.nomineeAddress
                                //             }
                                //           />

                                //           <div className={Styles.checkBox}>
                                //             <Checkbox
                                //               checked={
                                //                 address === nominee.nomineeAddress
                                //               }
                                //               // onChange={(event) => {
                                //               //   handleSetAddress(
                                //               //     values,
                                //               //     setFieldValue,
                                //               //     index,
                                //               //     event.target.checked
                                //               //   );
                                //               // }}
                                //             />
                                //             <Typography sx={{ color: "grey" }}>
                                //               Make the address same as mine
                                //             </Typography>
                                //           </div>
                                //         </div>
                                //       </div>
                                //     </div>
                                //     <SelectProof
                                //       textFieldName={"nomineeIDProofNo"}
                                //       textFieldValue={nominee.nomineeIDProofNo.toUpperCase()}
                                //       textFieldLabel={nominee.nomineeIDProof.value}
                                //       textFieldError={
                                //         touchedData.nomineeIDProofNo &&
                                //         Boolean(errorData["nomineeIDProofNo"])
                                //       }
                                //       onTextFieldChange={(event) => {
                                //         handleChangeField(
                                //           "nomineeIDProofNo",
                                //           event.target.value,
                                //           setFieldValue,
                                //           index,
                                //           values
                                //         );
                                //       }}
                                //       textFieldHelperText={
                                //         touchedData.nomineeIDProofNo &&
                                //         errorData["nomineeIDProofNo"]
                                //       }
                                //       radioGroups={nomineeID}
                                //       index={index}
                                //       currentIndex={assignIndex}
                                //       name={"nomineeIDProof"}
                                //       handleRadioButtonFieldChange={(event) => {
                                //         handleChangeField(
                                //           "nomineeIDProof",
                                //           event.target.value,
                                //           setFieldValue,
                                //           index,
                                //           values
                                //         );
                                //       }}
                                //       variant={"outlined"}
                                //     />
                                //   </>
                              );
                            })}
                          </div>
                        )}
                      />
                    </div>
                    <ErrorSnackBar message={rejectMessage} />
                    {backdrop && <StartOverBackDrop />}
                    <div className={Styles.rightContainer}>
                      <div className={Styles.ButtonSection}>
                        <div className={Styles.Buttons}>
                          <div>
                            <Button
                              className={Styles.addNomineeButton}
                              onClick={() => {
                                handleNomineeAdd(values, setFieldValue);
                                window.scrollTo(0, 0);
                              }}
                              disabled={
                                percentageTotal >= 100 ||
                                assignIndex === 2 ||
                                Object.keys(errors).length > 0 ||
                                Object.keys(touched).length === 0
                              }
                              id="nomineesAddNomineeButton"
                              sx={{
                                color: "black",
                                borderColor: "black",
                                visibility:
                                  assignIndex !== 2 ? "visible" : "hidden",
                              }}
                              variant="outlined"
                              size="small"
                            >
                              Add Nominee {assignIndex + 2}
                            </Button>
                          </div>
                          <div>
                            <LoadingButton
                              variant="contained"
                              type="submit"
                              // onClick={nomineeDetailsSubmit}
                              disabled={
                                values.nominees[0].nomineeFullName === "" ||
                                values.nominees[0].relationShip === "" ||
                                percentageTotal !== 100 ||
                                Object.keys(errors).length > 0
                              }
                              loading={loading}
                              id="nomineesContinueButton"
                              className={Styles.addNomineeButton}
                            >
                              Continue
                            </LoadingButton>
                          </div>
                        </div>
                        <div className={Styles.startOverSection}>
                          <img src={icon} className={Styles.iconImage} />
                          <p className={Styles.paratext}>
                            {" "}
                            if the above mentioned info not yours
                            <span
                              className={Styles.startoverbutton}
                              onClick={handleStartOver}
                            >
                              Start Over
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <Stack height={"90vh"}>
            <BackDrop />
          </Stack>
        )}
      </div>
    </div>
  );
}
