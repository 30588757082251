import React from "react";
import {
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { useFormik } from "formik";
import styles from "./Selectdropdown.module.scss";

interface Props {
  LabelName: string;
  menuItems: any;
  name: any;
  value: any;
  handleChange: any;
  error: any;
  helperText: any;
  setOthers?: any | undefined;
  padding?: any;
}

const SelectDropdown: React.FC<Props> = ({
  LabelName,
  menuItems,
  name,
  value,
  handleChange,
  error,
  helperText,
  setOthers,
  padding,
}) => {
  return (
    <FormControl style={{width:'100%'}}>
      {/* <InputLabel id="demo-chips-label">{LabelName}</InputLabel> */}
      <div className={styles.mainWrapper} style={{padding : !padding ? "20px 20px" : "20px 20px"}}>
        {menuItems.map((a: any, index: any) => (
          <Chip
            key={index}
            label={a.value}
            onClick={() => {
              handleChange({ target: { name, value: a } });
              setOthers({ target: { name, value: a } });
            }}
            color={value === a ? "primary" : undefined}
            variant={value === a ? "filled" : "outlined"}
            className={styles.chipstyle}
            style={{
              margin: "5px",
              color: value === a ? "white" : "black",
              padding: "0 5px",
              borderRadius: "10px",
            }}
          />
        ))}
      </div>
      <FormHelperText style={{ color: "#d32f2f" }}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectDropdown;
