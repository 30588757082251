import axios, { AxiosResponse } from "axios";

import { FINUPDATE, DOMIAN } from "../../shared/constants";
import { IAdminLogin } from "../../shared/dtos";
import { getCookie, removeAllCookies } from "./cookies";

const axiosInterceptor = axios.create({
  baseURL: FINUPDATE,
});

axiosInterceptor.interceptors.request.use(
  async (req) => {
    const token = document.cookie;

    // const token = "finupdate_token=eSRYumznqlmYiKN3qHBCZFY5mzDRBhiZ5Z0/jzqCq1vT3gGBqoNrCH2y+0XedPTtO/0u6MdlOgzkaWfSAbHxM6hbKiF+FJ4RLj8Ry8SMVBVpVkKJyeIulAzpbdoBaN2mIvGJNsVPEZflynaWZiAxBDd1kcP5/03QpsMBpg7WF7w=; userId=KC1593; deviceId=9f038914-7501-4e7a-a5f0-9ad8f8f879bb"

    req.headers.Authorization = `${token}`;
    return req;
  },
  (error) => Promise.reject(error)
);

axiosInterceptor.interceptors.response.use(
  (res) => res,
  (err) => {
    const status = err.response ? err.response.status : null;
    if (status === 401) {
      handleLogout();
    }
    return Promise.reject(err);
  }
);
const handleLogout = () => {
  removeAllCookies();
  window.location.href = "/";
};

export const GetFirstTimeLoginAPICall = (userId: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/firstTimeLogin/${userId}`, {
        // params: {
        //   userId: userId,
        // },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetIncomeSlabAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/getincomeslab`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetIncomeProofAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/getDropdown`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const SubmitDetailsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/submitdetails`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const stageDetailsAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/stagedetails/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const deleteDocumentAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/V1/documentdelete/`, {
        data: {
          // client_code: requestBody.client_code,
          document_purpose: requestBody.document_purpose,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const uploadDocumentsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/documentupload/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const AdminLoginToClientAccountAPICall = (requestBody: IAdminLogin) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/rekyc/admin_login/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const AccountAggregatorFetchDataFinupdateAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/account_aggregator/fetch_data/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
