import React, { useEffect, useRef, useState } from "react";
import {
  Stack,
  TextField,
  Typography,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Modal,
  Fade,
  Box,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import FormatterChecker from "./FormatChecker";
import { useNavigate } from "react-router-dom";
import { checkUserLoggedIn } from "../shared/commonfunctions";
import {
  startOverAPICall,
  documentupdateAPICall,
  getDocumentTypeDropDownAPICall,
  stageDetailsAPICall,
  PanDocumentRequestAPICall,
  PanDigilockerDocumentGetAPICall,
  IncomeProofRequestAPICall,
} from "../shared/APICalls";
import routesData from "../shared/routes.json";
import StageLayout from "../Layouts/StageLayout";
import PreviewModalPopup from "../Components/Modals/PreviewModalPopup";
import uploadStyles from "../pages/Styles/Uploads.module.scss";
import icon from "../Components/assets/Images/iconnew.svg";
import UploadImage from "../Components/assets/Images/document-upload.svg";
import SnackBar from "../Components/SnackBars/SnackBar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import StartOverBackDrop from "../Components/StartOverBackDrop";
import SucessSnackBar from "../Components/SnackBars/SucesssnackBar";
import BackDrop from "../Components/BackDrop";
import ErrorSnackBar from "../Components/SnackBars/ErrorSnackBar";
import { UploadSizes } from "../shared/constants";
import FormatterCheckerPanFetch from "./FormatCheckerPanFetch";
import { IGetDocumentDigilocker } from "../shared/dtos";
import StageLayoutEkyc from "../Layouts/StageLayoutEkyc";
import FormatterCheckerIncomeFetch from "./FormatCheckerIncomeFetch";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vW",
  height: "80vH",
  bgcolor: "background.paper",
  p: 1,
  boxShadow: 24,
};

export default function UploadDocumentsPage() {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [message, setmessage] = React.useState("");
  const [infoMessage, setinfoMessage] = React.useState(false);
  const [incomeProofDropdown, setincomeProofDropdown] = useState<any>([]);
  const [incomeProofDocRef, setincomeProofDocRef] = useState<any>();
  const [signatureDocRef, setSignatureDocRef] = useState<any>();
  const [panCardDocRef, setPanCardDocRef] = useState<any>();
  const [showUpload, setShowUpload] = useState(false);
  const [disableContinueOption1, setdisableContinueOption1] = useState("");
  const [disableContinueOption2, setdisableContinueOption2] = useState("");
  const [disableContinueOption3, setdisableContinueOption3] = useState("");
  // const [disableContinueOption4, setdisableContinueOption4] = useState("");
  const [displayDropdownOptions, setDisplayDropdownOptions] = useState(true);
  const [completedStages, setCompletedStages] = useState<any>();
  const [incomeProofUrl, setincomeProofUrl] = useState<any>();
  const [signatureUrl, setsignatureUrl] = useState<any>();
  const [bankProofeUrl, setbankProofUrl] = useState<any>();
  const [pancardUrl, setpancardUrl] = useState<any>();
  const [IncomeProof, setIncomeProof] = useState<any>("");
  const [InformationMessage, setInformationMessage] = useState("");
  const [backdrop, setBackDrop] = useState(false);
  const rupeeSymbol = String.fromCharCode(8377);
  const [uploadmessage, setUploadmessage] = useState("");
  const [backLoading, setBackDropLoading] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [IncomeProofDocTypeRef, setIncomeProofDocTypeRef] = useState("");
  const [bankDetails, setBankDetails] = useState({
    AccountNumber: "",
    KraStatus: "",
    IFSCCode: "",
  });

  const [initialdropdowndata, setinitialdropdowndata] = useState<any>([]);
  const [childWindowRef, setchildWindowRef] = useState<any>(null);
  const [closetTimer, setclosetTimer] = useState(false);
  const timerRef = useRef<any>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileIncomeProof, setSelectedFileIncomeProof] =
    useState<File | null>(null);
  const [fetchLodaing, setFetchLoading] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  const navigationData = routesData;

  const chequeFormat = ["png", "jpg", "jpeg"];
  const formats = ["pdf", "png", "jpg", "jpeg"];

  const handleChange = (event: any) => {
    setIncomeProofDocTypeRef(event.target.value.docTypeRef);
    //  console.log(event.target.value.docTypeRef);

    setIncomeProof(event.target.value);
    setincomeProofDocRef(event.target.value.purpose);

    setShowUpload(true);

    switch (event.target.value.value) {
      case "Copy of ITR acknowledgement":
        setInformationMessage(
          `ITR acknowledgment with gross annual income exceeding ${rupeeSymbol}1,20,000`
        );
        break;
      case "Salary Slip for last 3 months":
        setInformationMessage(
          `The latest salary slip with gross monthly income exceeding ${rupeeSymbol}15,000`
        );
        break;
      case "Copy of Form 16 in case of salary income":
        setInformationMessage(
          `Form 16 with gross annual income exceeding ${rupeeSymbol}1,20,000`
        );
        break;
      case "Copy of cancelled cheque":
        setInformationMessage(`Copy of cancelled cheque of your bank account`);
        break;
      case "Bank account statement for last 6 month":
        setInformationMessage(
          `Bank statement for the last 6 months with average balance of more than ${rupeeSymbol}10,000`
        );
        break;
    }
  };
  const handleClick = () => {
    setloading(true);
    let obj: any = {
      stage: "document_upload",
    };

    stageDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;

        // console.log(responseData.data.StageDetails.Documents);

        if (responseData.data.StageDetails.Documents === null) {
          setmessage("Please Upload The Documents");
          setTimeout(() => {
            setmessage("");
          }, 5000);
        }

        const futuresAndOptionsObject =
          responseData.data.StageDetails.TradingPref.find(
            (item) => item.TradingPreLink === "futures_and_options"
          );

        if (futuresAndOptionsObject && futuresAndOptionsObject.IsOpted) {
          if (
            responseData.data.StageDetails.Documents.pancard &&
            responseData.data.StageDetails.Documents.pancard.pancard_url &&
            responseData.data.StageDetails.Documents.signature &&
            responseData.data.StageDetails.Documents.signature.signature_url &&
            responseData.data.StageDetails.Documents.income_proof &&
            responseData.data.StageDetails.Documents.income_proof
              .income_proof_url
          ) {
            handleStageAPI();
            setloading(false);
          } else {
            setmessage("Upload All  Documents");
            setTimeout(() => {
              setmessage("");
            }, 5000);
            setloading(false);
          }
        } else {
          if (
            responseData.data.StageDetails.Documents.pancard &&
            responseData.data.StageDetails.Documents.pancard.pancard_url &&
            responseData.data.StageDetails.Documents.signature &&
            responseData.data.StageDetails.Documents.signature.signature_url
          ) {
            handleStageAPI();
            setloading(false);
          } else {
            setmessage("Upload PanCard and Signature, Bank Proof Documents");
            setTimeout(() => {
              setmessage("");
            }, 5000);
            setloading(false);
          }
        }
      })
      .catch((error: any) => {
        // console.log("error", error);
        setloading(false);
      });

    //navigate("/nomineepage");
  };
  const handleStartOver = () => {
    if (
      window.confirm(
        "Are you sure you want to restart, on pressing Yes all the Data will be deleted"
      )
    ) {
      setBackDrop(true);

      startOverAPICall()
        .then((response: any) => {
          const responseData = response.data;
          //  console.log("responseData", responseData);

          setBackDrop(false);
          navigate("/");
          window.location.reload();
        })
        .catch((error: any) => {
          setBackDrop(false);
        });
    }
  };

  const handleStageAPI = () => {
    setloading(true);
    documentupdateAPICall()
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", response);
        setloading(false);
        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage]);
        }
      })
      .catch((error: any) => {
        setloading(false);
      });
  };

  const handlePreview = (data: any) => {
    //console.log(data);
  };
  const handleDisplayOptions = (data: any) => {
    setDisplayDropdownOptions(data);
    if (data === true) {
      setIncomeProof("");
      setIncomeProof(initialdropdowndata[0]);

      setIncomeProofDocTypeRef(initialdropdowndata[0].docTypeRef);
      setincomeProofDocRef(initialdropdowndata[0].purpose);
      // setShowUpload(false);
      setincomeProofUrl("");
      setInformationMessage("");
    }
  };
  useEffect(() => {
    if (!closetTimer && childWindowRef) {
      //  console.log("timer trigger");
      timerRef.current = setInterval(checkChild, 500);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [childWindowRef, closetTimer]);

  function checkChild() {
    // console.log("timer");

    if (childWindowRef && childWindowRef.closed) {
      clearInterval(timerRef.current);
      StageDetailsInitial();
      setFetchLoading(false);
    }
  }

  const handleOpenClick = () => {
    setFetchLoading(true);
    let digilink: string = "";

    PanDocumentRequestAPICall()
      .then((response: any) => {
        const responseData = response.data;

        console.log("responseData", responseData.data);
        digilink = responseData.data;
        setTimeout(() => {
          const childWindow: any = window.open(
            digilink,
            "_blank",
            "width=500,height=700"
          );
          setchildWindowRef(childWindow);
        });
      })
      .catch((error: any) => {
        setloading(false);
      });
  };
  const handleOpenClickIncomeProof = () => {
    setFetchLoading(true);
    let digilink: string = "";

    IncomeProofRequestAPICall()
      .then((response: any) => {
        const responseData = response.data;

        console.log("responseData", responseData.data.link);
        digilink = responseData.data.link;
        setTimeout(()=>{
          const childWindow: any = window.open(
            digilink,
            "_blank",
            "width=500,height=700"
          );
          setchildWindowRef(childWindow);
        })
   
      })
      .catch((error: any) => {
        setloading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    checkUserLoggedIn(navigate);
    StageDetailsInitial();
  }, []);

  const StageDetailsInitial = () => {
    let dropDowndata: any;
    setBackDropLoading(true);
    getDocumentTypeDropDownAPICall()
      .then((response: any) => {
        const responseData = response.data;

        const incomeProofdata = responseData.data.income_proof;
        const filtredData = incomeProofdata.sort(
          (a: any, b: any) => a.sequence - b.sequence
        );
        // console.log("responseData", incomeProofdata);
        setIncomeProof(filtredData[0]);
        setinitialdropdowndata(filtredData);
        setIncomeProofDocTypeRef(filtredData[0].docTypeRef);
        setincomeProofDocRef(filtredData[0].purpose);
        setShowUpload(true);
        setincomeProofDropdown(filtredData);
        dropDowndata = responseData.data.income_proof;
        setSignatureDocRef(responseData.data.signature.docTypeRef);
        setPanCardDocRef(responseData.data.pancard.docTypeRef);
        setincomeProofDocRef(responseData.data.income_proof[0].purpose);
      })
      .catch((error: any) => {});
    let obj: any = {
      stage: "document_upload",
    };

    stageDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;

        setCompletedStages(responseData.completed_stages);
        const futuresAndOptionsObject =
          responseData.data.StageDetails.TradingPref.find(
            (item) => item.TradingPreLink === "futures_and_options"
          );
        setBackDropLoading(false);
        if (response.data.data.StageDetails.ClientDetails) {
          setBankDetails({
            AccountNumber: response.data.data.StageDetails.ClientDetails[0]
              .AccountNumber
              ? response.data.data.StageDetails.ClientDetails[0].AccountNumber
              : "",
            KraStatus: response.data.data.StageDetails.ClientDetails[0]
              .KraStatus
              ? response.data.data.StageDetails.ClientDetails[0].KraStatus
              : "",
            IFSCCode: response.data.data.StageDetails.ClientDetails[0].IFSC
              ? response.data.data.StageDetails.ClientDetails[0].IFSC
              : "",
          });
        }

        if (futuresAndOptionsObject && futuresAndOptionsObject.IsOpted) {
          setinfoMessage(true);
          setdisableContinueOption3("");
        } else {
          setinfoMessage(false);
          setdisableContinueOption3("Document Successfully Uploaded");
        }
        if (response.data.data.StageDetails.ErrorMessage) {
          setRejectMessage(response.data.data.StageDetails.ErrorMessage);
        }

        if (responseData.data.StageDetails.Documents.income_proof) {
          setdisableContinueOption3("Document Successfully Uploaded");
        }
        if (responseData.data.StageDetails.Documents.signature) {
          setdisableContinueOption1("Document Successfully Uploaded");
        }
        if (responseData.data.StageDetails.Documents.pancard) {
          setdisableContinueOption2("Document Successfully Uploaded");
        }
        // if (responseData.data.StageDetails.Documents.bank_proof) {
        //   setdisableContinueOption4("Document Successfully Uploaded");
        // }

        if (
          responseData.data.StageDetails.Documents.pancard &&
          responseData.data.StageDetails.Documents.pancard.pancard_url
        ) {
          // const meta = "data:application/pdf;base64,";
          // const data =
          //   meta +
          //   responseData.data.StageDetails.Documents.pancard.pancard_base64;

          setpancardUrl(
            responseData.data.StageDetails.Documents.pancard.pancard_url
          );
        }
        // else if (
        //   responseData.data.StageDetails.Documents.pancard &&
        //   responseData.data.StageDetails.Documents.pancard.pancard_base64 === ""
        // ) {
        //   setpancardUrl(
        //     responseData.data.StageDetails.Documents.pancard.pancard_url
        //   );
        // }

        if (
          responseData.data.StageDetails.Documents.signature &&
          responseData.data.StageDetails.Documents.signature.signature_url
        ) {
          setsignatureUrl(
            responseData.data.StageDetails.Documents.signature.signature_url
          );
        }
        if (
          responseData.data.StageDetails.Documents.bank_proof &&
          responseData.data.StageDetails.Documents.bank_proof.bank_proof_url
        ) {
          setbankProofUrl(
            responseData.data.StageDetails.Documents.bank_proof.bank_proof_url
          );
        }

        if (
          responseData.data.StageDetails.Documents.income_proof &&
          responseData.data.StageDetails.Documents.income_proof.income_proof_url
        ) {
          setShowUpload(true);

          // const meta = "data:application/pdf;base64,";
          // const data =
          //   meta +
          //   responseData.data.StageDetails.Documents.income_proof
          //     .income_proof_base64;
          setincomeProofUrl(
            responseData.data.StageDetails.Documents.income_proof
              .income_proof_url
          );
        }
        // else if (
        //   responseData.data.StageDetails.Documents.income_proof &&
        //   responseData.data.StageDetails.Documents.income_proof
        //     .income_proof_base64 === ""
        // ) {
        //   setShowUpload(true);

        //   setincomeProofUrl(
        //     responseData.data.StageDetails.Documents.income_proof
        //       .income_proof_url
        //   );
        // }

        if (
          dropDowndata &&
          responseData.data.StageDetails.Documents.income_proof
        ) {
          // console.log("incomeProofDropdown", incomeProofDropdown);
          const SetSelectData = dropDowndata.find(
            (item) =>
              item.docTypeRef ===
              responseData.data.StageDetails.Documents.income_proof.DocTypeRef
          );

          setIncomeProof(SetSelectData);
          setDisplayDropdownOptions(false);
          // console.log("SetSelectData", SetSelectData);
        }
      })
      .catch((error: any) => {
        // console.log("error", error);
        setBackDropLoading(false);
      });
  };

  const handleSignatureDocumentID = (data: any) => {
    //  console.log("dataSignature", data);

    setdisableContinueOption1(data);

    setUploadmessage(data);
    setTimeout(() => {
      setUploadmessage("");
    }, 5000);
  };
  // const handleBankProofDocumentID = (data: any) => {
  //   setdisableContinueOption4(data);
  //   setUploadmessage(data);
  //   setTimeout(() => {
  //     setUploadmessage("");
  //   }, 5000);
  // };

  const handlePanDocumentID = (data: any) => {
    // console.log("panCradData", data);

    setdisableContinueOption2(data);

    setUploadmessage(data);
    setTimeout(() => {
      setUploadmessage("");
    }, 5000);
  };

  const dataArray: any[] = [];
  const handleDocumentID = (data: any) => {
    // console.log("documentID", data);
    setdisableContinueOption3(data);

    setUploadmessage(data);

    setTimeout(() => {
      setUploadmessage("");
    }, 5000);
    dataArray.push(data);

    const nameCounts = dataArray.reduce((countMap, name) => {
      countMap[name] = (countMap[name] || 0) + 1;
      return countMap;
    }, {});
    const uniqueNames = dataArray.filter((name) => nameCounts[name] === 1);
    // console.log("uniqueNames", uniqueNames);

    if (dataArray[0] === "Document Successfully Uploaded") {
    }
    if (uniqueNames.length === 3) {
    } else {
    }
  };

  // console.log("IncomeProof", IncomeProof);

  return (
    <div className={uploadStyles.mainWrapperTop}>
      <StageLayoutEkyc
        completedStages={completedStages}
        stageName={"Upload Documents"}
        navigatePath={"/nomineepage"}
      />
      <ErrorSnackBar message={errorMessage} />

      <div className={uploadStyles.wrapper}>
        <div className={uploadStyles.headerSection}>
          <p className={uploadStyles.signup}>Upload Your Documents</p>
        </div>
        {!backLoading ? (
          <>
            <div className={uploadStyles.uploadSignatureSection}>
              <div>
                <p className={uploadStyles.subText}> Upload Your Signature </p>
              </div>
              <div>
                <FormatterChecker
                  previewImage={handlePreview}
                  acceptedFormats={chequeFormat}
                  documentRef={signatureDocRef}
                  documentID={handleSignatureDocumentID}
                  displayOptions={""}
                  stageDetailsData={signatureUrl}
                  name="signature"
                  purpose={"signature"}
                  pdfSize={UploadSizes["signature"].pdfSize}
                  imageSize={UploadSizes["signature"].imageSize}
                />
              </div>
            </div>
            {(bankDetails.KraStatus === "KRA_NEW" ||
              bankDetails.KraStatus === "KRA_MODIFIED") &&
              rejectMessage === "" &&
              !pancardUrl && (
                <div className={uploadStyles.uploadPanFetchSection}>
                  <div>
                    <p className={uploadStyles.subText}> Upload Pan Card </p>
                  </div>
                  <div className={uploadStyles.buttonssectionpan}>
                    {/* {!selectedFile && !pancardUrl && (
                      <LoadingButton
                        variant="contained"
                        component="label"
                        style={{
                          height: "35px",
                          color: "white",
                          borderRadius: "20px",
                          boxShadow: "none",
                        }}
                        sx={{ width: { xs: "max-content" } }}
                        onClick={handleOpenClick}
                      >
                        Digilocker
                      </LoadingButton>
                    )} */}
                    {/* {!selectedFile && !pancardUrl && "(or)"} */}
                    <FormatterCheckerPanFetch
                      previewImage={handlePreview}
                      acceptedFormats={formats}
                      documentRef={panCardDocRef}
                      documentID={handlePanDocumentID}
                      displayOptions={""}
                      stageDetailsData={pancardUrl}
                      name="pancard"
                      purpose={"pancard"}
                      pdfSize={UploadSizes["pancard"].pdfSize}
                      imageSize={UploadSizes["pancard"].imageSize}
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      setpancardUrl={setpancardUrl}
                    />
                  </div>
                </div>
              )}
            {bankDetails.KraStatus === "KRA_EXISTING" &&
              rejectMessage === "" && (
                <div className={uploadStyles.uploadPanFetchSection}>
                  <div>
                    <p className={uploadStyles.subText}> Upload Pan Card </p>
                  </div>
                  <div className={uploadStyles.buttonssectionpan}>
                    {!selectedFile && !pancardUrl && (
                      <LoadingButton
                        variant="contained"
                        component="label"
                        style={{
                          height: "35px",
                          color: "white",
                          borderRadius: "20px",
                          boxShadow: "none",
                        }}
                        sx={{ width: { xs: "max-content" } }}
                        onClick={handleOpenClick}
                      >
                        Digilocker
                      </LoadingButton>
                    )}
                    {!selectedFile && !pancardUrl && "(or)"}
                    <FormatterCheckerPanFetch
                      previewImage={handlePreview}
                      acceptedFormats={formats}
                      documentRef={panCardDocRef}
                      documentID={handlePanDocumentID}
                      displayOptions={""}
                      stageDetailsData={pancardUrl}
                      name="pancard"
                      purpose={"pancard"}
                      pdfSize={UploadSizes["pancard"].pdfSize}
                      imageSize={UploadSizes["pancard"].imageSize}
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      setpancardUrl={setpancardUrl}
                    />
                  </div>
                </div>
              )}
            {rejectMessage !== "" && (
              <div className={uploadStyles.uploadPanFetchSection}>
                <div>
                  <p className={uploadStyles.subText}> Upload Pan Card </p>
                </div>
                <div className={uploadStyles.buttonssectionpan}>
                  {!selectedFile &&
                    !pancardUrl &&
                    bankDetails.KraStatus === "KRA_EXISTING" && (
                      <LoadingButton
                        variant="contained"
                        component="label"
                        style={{
                          height: "35px",
                          color: "white",
                          borderRadius: "20px",
                          boxShadow: "none",
                        }}
                        sx={{ width: { xs: "max-content" } }}
                        onClick={handleOpenClick}
                      >
                        Digilocker
                      </LoadingButton>
                    )}
                  {!selectedFile &&
                    !pancardUrl &&
                    bankDetails.KraStatus === "KRA_EXISTING" &&
                    "(or)"}
                  <FormatterCheckerPanFetch
                    previewImage={handlePreview}
                    acceptedFormats={formats}
                    documentRef={panCardDocRef}
                    documentID={handlePanDocumentID}
                    displayOptions={""}
                    stageDetailsData={pancardUrl}
                    name="pancard"
                    purpose={"pancard"}
                    pdfSize={UploadSizes["pancard"].pdfSize}
                    imageSize={UploadSizes["pancard"].imageSize}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    setpancardUrl={setpancardUrl}
                  />
                </div>
              </div>
            )}

            {infoMessage && (
              <>
                <div className={uploadStyles.messageSection}>
                  Since you selected F & O IncomeProof is Mandate *
                </div>
                <div className={uploadStyles.uploadSection}>
                  <div>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Choose your Income Proof
                      </InputLabel>

                      <Select
                        //  style={{ width: "300px", margin: "25px !important" }}
                        className={uploadStyles.uploadSectionSelectDropDown}
                        disableUnderline
                        sx={{
                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={IncomeProof}
                        label="Select Proof Type"
                        onChange={handleChange}
                        disabled={displayDropdownOptions === false}
                      >
                        {incomeProofDropdown.map((data: any, index: any) => {
                          // console.log(data)
                          return (
                            <MenuItem value={data} key={index}>
                              {data.purpose === "income_proof" && data.value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <ErrorSnackBar message={rejectMessage} />
                  <div className={uploadStyles.buttonssectionpan}>
                    {/* {IncomeProof.value ===
                      "Bank account statement for last 6 month" &&
                      disableContinueOption3 !==
                        "Document Successfully Uploaded" &&
                      !incomeProofUrl && (
                        <LoadingButton
                          variant="contained"
                          component="label"
                          style={{
                            height: "35px",
                            color: "white",
                            borderRadius: "20px",
                            boxShadow: "none",
                          }}
                          sx={{ width: { xs: "max-content" } }}
                          onClick={handleOpenClickIncomeProof}
                        >
                          Fetch IncomeProof
                        </LoadingButton>
                      )} */}

                    <>
                      {" "}
                      {showUpload ? (
                        <FormatterChecker
                          previewImage={handlePreview}
                          acceptedFormats={formats}
                          documentRef={incomeProofDocRef}
                          documentID={handleDocumentID}
                          displayOptions={handleDisplayOptions}
                          stageDetailsData={incomeProofUrl}
                          name="incomeproof"
                          purpose={IncomeProofDocTypeRef}
                          pdfSize={8.5}
                          imageSize={8}
                        />
                      ) : (
                        // <FormatterCheckerIncomeFetch
                        //   previewImage={handlePreview}
                        //   acceptedFormats={formats}
                        //   documentRef={incomeProofDocRef}
                        //   documentID={handleDocumentID}
                        //   displayOptions={handleDisplayOptions}
                        //   stageDetailsData={incomeProofUrl}
                        //   name="incomeproof"
                        //   purpose={IncomeProofDocTypeRef}
                        //   pdfSize={8.5}
                        //   imageSize={8}
                        //   selectedFile={selectedFileIncomeProof}
                        //   setSelectedFile={setSelectedFileIncomeProof}

                        // />
                        <LoadingButton
                          variant="contained"
                          component="label"
                          disabled
                          startIcon={<img src={UploadImage} width={"20px"} />}
                          style={{
                            width: "120px",
                            height: "35px",
                            color: "white",
                            borderRadius: "20px",
                            boxShadow: "none",
                          }}
                        >
                          Upload
                        </LoadingButton>
                      )}{" "}
                    </>
                  </div>
                </div>
              </>
            )}

            <SucessSnackBar message={uploadmessage} />
            <div className={uploadStyles.infoSection}>
              <>
                {InformationMessage !== "" && <InfoOutlinedIcon />}{" "}
                {InformationMessage}
              </>
            </div>
            {/* <div className={uploadStyles.uploadSignatureSection}>
              <div>
                <p className={uploadStyles.subText}>
                  {" "}
                  Copy of Cancelled Cheque/Statement{" "}
                </p>
              </div>
              <div>
                <FormatterChecker
                  previewImage={handlePreview}
                  acceptedFormats={formats}
                  documentRef={"bank_proof"}
                  documentID={handleBankProofDocumentID}
                  displayOptions={""}
                  stageDetailsData={bankProofeUrl}
                  name="bank_proof"
                  purpose={"bank_proof"}
                  pdfSize={UploadSizes["bank_proof"].pdfSize}
                  imageSize={UploadSizes["bank_proof"].imageSize}
                />
              </div>
            </div> */}

            {/* <div
              className={uploadStyles.messageSection}
              style={{ alignItems: "center", display: "flex", marginTop: 0 }}
            >
              <InfoOutlinedIcon />{" "}
              <span>
                Copy of cancelled cheque with your name or latest bank statement
                that shows
              </span>
            </div>
            <div className={uploadStyles.messageSection}>
              <ul
                style={{
                  marginTop: "10px",
                  paddingLeft: "15px",
                  fontWeight: "bold",
                }}
              >
                <li> Your bank account number {bankDetails.AccountNumber} </li>
              </ul>
            </div>
            <div className={uploadStyles.messageSection}>
              <ul
                style={{
                  marginTop: 0,
                  paddingLeft: "15px",
                  fontWeight: "bold",
                }}
              >
                <li> IFSC Code {bankDetails.IFSCCode} </li>
              </ul>
            </div> */}

            <SnackBar message={message} />
            {backdrop && <StartOverBackDrop />}
            <div className={uploadStyles.buttonStack}>
              <div>
                {disableContinueOption1 === "Document Successfully Uploaded" &&
                disableContinueOption2 === "Document Successfully Uploaded" &&
                disableContinueOption3 === "Document Successfully Uploaded" ? (
                  <LoadingButton
                    variant="contained"
                    onClick={handleClick}
                    loading={loading}
                    id="upLoadDocumentsContinueButton"
                    className={uploadStyles.approveButton}
                  >
                    {" "}
                    Continue
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    variant="contained"
                    onClick={handleClick}
                    loading={loading}
                    disabled
                    id="upLoadDocumentsContinueButton"
                    className={uploadStyles.approveButton}
                  >
                    {" "}
                    Continue
                  </LoadingButton>
                )}
              </div>
              <div className={uploadStyles.startOverSection}>
                <img src={icon} className={uploadStyles.iconImage} />
                <p className={uploadStyles.paratext}>
                  {" "}
                  if the above mentioned info not yours
                  <span
                    className={uploadStyles.startoverbutton}
                    onClick={handleStartOver}
                  >
                    Start Over
                  </span>
                </p>
              </div>
            </div>
          </>
        ) : (
          <div style={{ height: "65vh" }}>
            <BackDrop />
          </div>
        )}
      </div>
    </div>
  );
}
