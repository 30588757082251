import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import Slide, { SlideProps } from "@mui/material/Slide";
import errorimage from "../../Components/assets/Images/errorcircle.png";
type TransitionProps = Omit<SlideProps, "direction">;

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

interface props {
  message: any;
}

export default function ErrorSnackBar({ message }: props) {
  const [transition, setTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message.length > 1) {
      setOpen(true);
      setTransition(() => TransitionUp);
    }
    if (message.length === 0) {
      setOpen(false);
      setTransition(() => TransitionUp);
    }
  }, [message]);

 
  return (
    <div>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={transition}
        sx={{ marginTop: "50px" }}
      >
        <div style={{ background: "tansparent", borderRadius: "10px " }}>
          <div style={{ marginLeft: "30px" }}>
            <Alert
              icon={false}
              severity="success"
              sx={{
                width: "max-content",
                height: "max-content",
                fontSize: "16px",
                borderRadius: "20px",
                display: "flex",
                alignItems: "center",
                background: "#F9E7E7",
                padding: "0 10px !important",
              }}
            >
              
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={errorimage} style={{ marginRight: "10px" }} />
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "14px",
                      color: "#EA3535",
                    }}
                  >
                    {message}
                  </p>
                </div>
             
            </Alert>
          </div>
        </div>
      </Snackbar>
    </div>
  );
}
