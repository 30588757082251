import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  FormGroup,
  FormControlLabel,
  Button,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Modal,
  Box,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import routesData from "../shared/routes.json";
import {
  stageDetailsAPICall,
  startOverAPICall,
  termsAndConditionsAPICall,
} from "../shared/APICalls";
import { ITermsandConditions } from "../shared/dtos";
import { checkUserLoggedIn } from "../shared/commonfunctions";
import StageLayout from "../Layouts/StageLayout";
import Styles from "../pages/Styles/TermsandConditions.module.scss";
import image from "../Components/assets/Images/TCImage.svg";
import icon from "../Components/assets/Images/iconnew.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import TickCircle from "../Components/assets/Images/tick-circle.svg";
import StartOverBackDrop from "../Components/StartOverBackDrop";
import BackDrop from "../Components/BackDrop";
import Dropdown from "../Components/SelectDropdown/DropDown";
import CloseIcon from "@mui/icons-material/Close";
import StageLayoutEkyc from "../Layouts/StageLayoutEkyc";

const TradingPreferenceSchema = Yup.object().shape({
  tariff: Yup.boolean().oneOf([true], "tariff is required"),
  condition1: Yup.boolean().oneOf([true], "Accept the condition"),
  condition2: Yup.boolean().oneOf([true], "Accept the condition"),
  condition3: Yup.boolean().oneOf([true], "Accept the condition"),
  condition4: Yup.boolean().oneOf([true], "Accept the condition"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  // height: "80vh",
  // bgcolor: "background.paper",
  borderRadius: "10px",
  // boxShadow: 24,
  padding: "0px 0px",
};

const Settlements = [
  {
    id: "quarter",
    sequence: 1,
    value: "Quarterly",
  },
  {
    id: "monthly",
    sequence: 2,
    value: "Monthly",
  },
];
const dpselect = [
  {
    id: true,
    sequence: 1,
    value: "Yes",
  },
  {
    id: false,
    sequence: 2,
    value: "No",
  },
];
const rta = [
  {
    id: true,
    sequence: 1,
    value: "Yes",
  },
  {
    id: false,
    sequence: 2,
    value: "No",
  },
];
const ecs = [
  {
    id: true,
    sequence: 1,
    value: "Yes",
  },
  {
    id: false,
    sequence: 2,
    value: "No",
  },
];
const email = [
  {
    id: true,
    sequence: 1,
    value: "Yes",
  },
  {
    id: false,
    sequence: 2,
    value: "No",
  },
];
const bsda = [
  {
    id: true,
    sequence: 1,
    value: "Yes",
  },
  {
    id: false,
    sequence: 2,
    value: "No",
  },
];
const options = [
  {
    id: "option1",
    sequence: 1,
    value: "Option 1",
  },
  {
    id: "option2",
    sequence: 2,
    value: "Option 2",
  },
];
const sms = [
  {
    id: true,
    sequence: 1,
    value: "Yes",
  },
  {
    id: false,
    sequence: 2,
    value: "No",
  },
];
const easi = [
  {
    id: true,
    sequence: 1,
    value: "Yes",
  },
  {
    id: false,
    sequence: 2,
    value: "No",
  },
];
const annualreport = [
  {
    id: "physical",
    sequence: 1,
    value: "Physically",
  },
  {
    id: "electronic",
    sequence: 2,
    value: "Electronically",
  },
  {
    id: "both",
    sequence: 3,
    value: "Both Physical and Electronic",
  },
];
const etch = [
  {
    id: true,
    sequence: 1,
    value: "Yes",
  },
  {
    id: false,
    sequence: 2,
    value: "No",
  },
];
const dpaccepect = [
  {
    id: true,
    sequence: 1,
    value: "Yes",
  },
  {
    id: false,
    sequence: 2,
    value: "No",
  },
];
const period = [
  {
    id: "daily",
    sequence: 1,
    value: "Daily",
  },
  {
    id: "weekly",
    sequence: 2,
    value: "Weekly",
  },
  {
    id: "fortnightly",
    sequence: 3,
    value: "Fortnightly",
  },
  {
    id: "monthly",
    sequence: 4,
    value: "Monthly",
  },
];

const tariff = [
  {
    chargehead: "Equity Intraday",
    charges: "Rs.20 per executed order or 0.03% of Turnover whichever is lower",
  },
  {
    chargehead: "Equity Delivery",
    charges: "Zero Brokerage",
  },
  {
    chargehead: "Futures",
    charges: "Rs.20 per executed order or 0.03% of Turnover whichever is lower",
  },
  {
    chargehead: "Options",
    charges: "Rs.20 per executed order",
  },
  {
    chargehead: "Call & Trade Services",
    charges: "Rs.50 + GST per order placed through a Dealer at NCO",
  },
  {
    chargehead: "NRI - Equity Delivery",
    charges: "Rs.200 per executed order or 0.3% of Turnover whichever is lower",
  },
  {
    chargehead: "NRI - Futures & Options",
    charges: "Rs.100 per executed order placed using a CP code",
  },
  {
    chargehead: "NRI - NRO non-PIS account",
    charges: "Rs.100 per executed order or 0.5% of Turnover whichever is lower",
  },
];

export default function TermsandConditionsPage() {
  const [startOverloading, setstartOverloading] = useState(false);
  const [loading, setloading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [completedStages, setCompletedStages] = useState<any>();
  const [backdrop, setBackDrop] = useState(false);
  const [prefilloading, setprefilloading] = useState(true);
  const [dropDownValue, setDropDownValue] = useState<any>(Settlements[0]);
  const [dropDownValuedpselect, setDropDownValuedpselect] = useState<any>(
    dpselect[0]
  );
  const [dropDownValuedpaccepect, setDropDownValuedpaccepect] = useState<any>(
    dpaccepect[0]
  );
  const [etchvalue, setetchvalue] = useState<any>(etch[0]);
  const [rtavalue, setrtavalue] = useState<any>(rta[0]);
  const [ecsvalue, setecsvalue] = useState<any>(ecs[0]);
  const [emailvalue, setemailvalue] = useState<any>(email[0]);
  const [smsvalue, setsmsvalue] = useState<any>(sms[0]);
  const [easivalue, seteasivalue] = useState<any>(easi[0]);
  const [bsdavalue, setbsdavalue] = useState<any>(bsda[1]);
  const [optionsvalue, setoptionsvalue] = useState<any>("option2");
  const [annualreportvalue, setannualreportvalue] = useState<any>(
    annualreport[1]
  );
  const [regulationperiod, setregulationperiod] = useState<any>(period[3]);
  const [opentariffModal, setopentariffModal] = useState(false);
  const navigate = useNavigate();
  const navigationData = routesData;
  const [prefilldata, setprefillData] = useState({
    condition1: true,
    condition2: true,
    condition3: true,
    condition4: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    checkUserLoggedIn(navigate);

    let obj: any = {
      stage: "t_and_c",
    };

    stageDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        setCompletedStages(responseData.completed_stages);

        setTimeout(() => {
          setprefilloading(false);
        }, 500);

        // console.log(
        //   "responseData",
        //   responseData.data.StageDetails.terms_and_conditions
        // );
        if (responseData.data.StageDetails.terms_and_conditions) {
          const dpfilterd = dpselect.filter(
            (a: any) =>
              a.id ===
              responseData.data.StageDetails.terms_and_conditions
                .dp_automatic_credit
          );
          const dpaccepectfilter = dpaccepect.filter(
            (a: any) =>
              a.id ===
              responseData.data.StageDetails.terms_and_conditions
                .accept_all_pledge_instructions
          );
          const periodfilter = period.filter(
            (a: any) =>
              a.id ===
              responseData.data.StageDetails.terms_and_conditions
                .account_statement_requirement
          );
          const etchfilter = etch.filter(
            (a: any) =>
              a.id ===
              responseData.data.StageDetails.terms_and_conditions
                .electronic_transaction_holding_statement
          );
          const emailfilter = email.filter(
            (a: any) =>
              a.id ===
              responseData.data.StageDetails.terms_and_conditions
                .electronic_transaction_holding_statement
          );
          const annualreportfilter = annualreport.filter(
            (a: any) =>
              a.id ===
              responseData.data.StageDetails.terms_and_conditions
                .receive_annual_report
          );
          const bsdafilter = bsda.filter(
            (a: any) =>
              a.id ===
              responseData.data.StageDetails.terms_and_conditions
                .is_bsda_required
          );
          const ecsfilter = ecs.filter(
            (a: any) =>
              a.id ===
              responseData.data.StageDetails.terms_and_conditions
                .receive_dividend_to_bank_account
          );
          const smsfilter = sms.filter(
            (a: any) =>
              a.id ===
              responseData.data.StageDetails.terms_and_conditions
                .mobile_sms_alert_facility
          );
          const easifilter = easi.filter(
            (a: any) =>
              a.id ===
              responseData.data.StageDetails.terms_and_conditions.easi_facility
          );
          const rtafilter = rta.filter(
            (a: any) =>
              a.id ===
              responseData.data.StageDetails.terms_and_conditions
                .share_email_with_rta
          );

          setDropDownValuedpselect(dpfilterd[0]);
          setDropDownValuedpaccepect(dpaccepectfilter[0]);
          setregulationperiod(periodfilter[0] ? periodfilter[0] : period[3]);
          setetchvalue(etchfilter[0]);
          setemailvalue(emailfilter[0]);
          setannualreportvalue(annualreportfilter[0]);
          setbsdavalue(bsdafilter[0]);
          setecsvalue(ecsfilter[0]);
          setsmsvalue(smsfilter[0]);
          seteasivalue(easifilter[0]);
          setrtavalue(rtafilter[0]);
          setoptionsvalue(
            responseData.data.StageDetails.terms_and_conditions.dis_booklet ===
              "option_2"
              ? "option2"
              : "option1"
          );
        }
        if (responseData.data.StageDetails.TandC === true) {
          setprefillData((prev) => {
            // console.log("prev", prev);
            return {
              ...prev,
              condition1: true,
              condition2: true,
              condition3: true,
              condition4: true,
            };
          });

          setSelectAll(true);
        }
        if (responseData.data.StageDetails.SettlementPreference) {
          const preferenceType = Settlements.filter((data) =>
            data.id.includes(
              responseData.data.StageDetails.SettlementPreference
            )
          )[0];
          setDropDownValue(preferenceType);
        }
      })
      .catch((error: any) => {
        // console.log("error", error);
        setprefilloading(false);
      });
  }, []);

  const handleClosetariffModal = () => {
    setopentariffModal(false);
  };
  const handleOpentariffModal = () => {
    setopentariffModal(true);
  };

  function termsAndConditions() {
    console.log("easivalue.id", easivalue);
    let obj: any = {
      is_t_and_c: true,
      settlement_preference: dropDownValue.id,
      terms_and_conditions: {
        dp_automatic_credit: dropDownValuedpselect.id,
        accept_all_pledge_instructions: dropDownValuedpaccepect.id,
        account_statement_requirement: regulationperiod.id,
        electronic_transaction_holding_statement: etchvalue.id,
        share_email_with_rta: rtavalue.id,
        receive_annual_report: annualreportvalue.id,
        is_bsda_required: bsdavalue.id,
        receive_dividend_to_bank_account: ecsvalue.id,
        mobile_sms_alert_facility: smsvalue.id,
        dis_booklet: optionsvalue === "option2" ? "option_2" : "option_1",
        easi_facility: easivalue.id,
        elect_trans_of_demat_holding_email: emailvalue.id,
      },
    };
    setloading(true);
    termsAndConditionsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", response);
        setloading(false);
        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage]);
        }
      })
      .catch((error: any) => {
        setloading(false);
      });
  }

  const handleStartOver = () => {
    if (
      window.confirm(
        "Are you sure you want to restart, on pressing Yes all the Data will be deleted"
      )
    ) {
      setBackDrop(true);
      setstartOverloading(true);
      startOverAPICall()
        .then((response: any) => {
          const responseData = response.data;
          // console.log("responseData", response);
          setstartOverloading(false);
          setBackDrop(false);
          navigate("/");
          window.location.reload();
        })
        .catch((error: any) => {
          setBackDrop(false);
          setstartOverloading(false);
        });
    }
  };

  const handleSelectAllChange = (event, setFieldValue) => {
    const { checked } = event.target;
    setSelectAll(checked);

    setFieldValue("condition1", checked);
    setFieldValue("condition2", checked);
    setFieldValue("condition3", checked);
    setFieldValue("condition4", checked);
  };

  const handleChangeSelectChange = (event: any) => {
    setDropDownValue(event.target.value);
  };

  const InitialValues = {
    condition1: prefilldata.condition1,
    condition2: prefilldata.condition2,
    condition3: prefilldata.condition3,
    condition4: prefilldata.condition4,
  };
  console.log("rtavalue", rtavalue);
  return (
    <div className={Styles.mainWrapperTop}>
      <StageLayoutEkyc
        completedStages={completedStages}
        stageName={"Terms & Conditions"}
        navigatePath={"/linkusingifsc"}
      />
      <div className={Styles.wrapper}>
        <div className={Styles.leftContainer}>
          {!prefilloading ? (
            <Formik
              initialValues={InitialValues}
              validationSchema={TradingPreferenceSchema}
              onSubmit={(values) => {
                // console.log(values);

                termsAndConditions();
              }}
            >
              {({ values, errors, touched, setFieldValue }) => {
                // console.log("values", values);
                {
                  values.condition1 &&
                  values.condition2 &&
                  values.condition3 &&
                  values.condition4
                    ? setSelectAll(true)
                    : setSelectAll(false);
                }
                return (
                  <Form>
                    <div>
                      <div className={Styles.mainContainer}>
                        <div className={Styles.headerSection}>
                          <p className={Styles.signup}>
                            Declarations and Standing Instructions for Your
                            Trading and Demat Account
                          </p>
                          <p className={Styles.subText}>
                            By pressing continue, you acknowledge and confirm
                            that the information provided by you is true &
                            Correct.
                          </p>
                          <p className={Styles.subText}>
                          Click on Bold Items to change options.
                          </p>
                        </div>
                        <div className={Styles.contentContaineWrapper}>
                          <div className={Styles.contentContainer}>
                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="selectAll"
                                      checked={selectAll}
                                      sx={{
                                        padding: "0px",
                                        display: "none",
                                        //marginBottom: "15px",
                                      }}
                                      onChange={(event) =>
                                        handleSelectAllChange(
                                          event,
                                          setFieldValue
                                        )
                                      }
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      {/* Select All */}
                                    </span>
                                  }
                                />
                              </FormGroup>
                            </div>
                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition1"
                                      checked={selectAll || values.condition1}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      sx={{ padding: "0px", display: "none" }}
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition1", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      1. My sole country of tax residency is
                                      India. I / We have understood the
                                      information requirements of this Form
                                      (read along with the{" "}
                                      <a
                                        href="https://firstock-common.s3.ap-south-1.amazonaws.com/ekyc/FATCAforIndividuals.pdf"
                                        target="_blank"
                                        className={Styles.aTag}
                                      >
                                        FATCA & CRS Instructions
                                      </a>
                                      ) and hereby confirm that the information
                                      provided by me/us on this Form is true,
                                      correct, and complete. I / We also confirm
                                      that I / We have read and understood the
                                      FATCA & CRS Terms and Conditions below and
                                      hereby accept the same. Name for your
                                      account will be updated as per the Income
                                      Tax database due to Exchange regulations.
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition1 && touched.condition1 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition1}
                                </Typography>
                              )}
                            </div>
                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition2"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition2}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition2", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      2. I authorise NCO Securities to operate
                                      the movement of securities from my demat
                                      account for any obligation created at the
                                      Exchange through my order(s)/trade(s) as
                                      per the{" "}
                                      <a
                                        href="https://www.thefirstock.com/terms_and_conditions.html"
                                        target="_blank"
                                        className={Styles.aTag}
                                      >
                                        Terms & Conditions
                                      </a>{" "}
                                      of the Online Delivery Instruction.
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition2 && touched.condition2 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition2}
                                </Typography>
                              )}
                            </div>
                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition3"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition3}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition3", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      3. I confirm and understand that my name
                                      as per the IT Department will be taken as
                                      my default NCO Securities account name, in
                                      case there is any mismatch of my name as
                                      per the IT department and my name as per
                                      the KRA database/Aadhaar/Bank account. I
                                      further confirm that the linked bank
                                      accounts, Aadhaar, and PAN belong to me.
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition3 && touched.condition3 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition3}
                                </Typography>
                              )}
                            </div>

                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition4"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition4}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition4", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      4. I confirm to have read and understood
                                      the contents of the{" "}
                                      <a
                                        href=" https://firstock-common.s3.ap-south-1.amazonaws.com/ekyc/EquityAnnexure.pdf"
                                        target="_blank"
                                        className={Styles.aTag}
                                      >
                                        Equity Annexure
                                      </a>{" "}
                                      documents.
                                      {/* which include the below annexures:
                                    <span
                                      style={{
                                        color: "#05603A",
                                        padding: "0 3px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      90days
                                    </span>{" "} */}
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition4 && touched.condition4 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition4}
                                </Typography>
                              )}
                            </div>

                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition3"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition3}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition3", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      5. As per{" "}
                                      <span style={{ fontWeight: "bold" }}>
                                        “Digital Personal Data Protection Act”
                                      </span>
                                      , 2023 all the Reporting Entities
                                      registered with CKYCRR were requested to
                                      obtain the consent from the customer for
                                      downloading their CKYC record from CKYCRR.
                                      Therefore, obtaining customer consent is
                                      also mandatory as per RBI Master
                                      Directions for the entities regulated by
                                      RBI. This means that when you sign up for
                                      a service or become a client of a NCO
                                      Securities and Share Broking Private
                                      Limited, subject to KYC regulations, you
                                      automatically agree to allow them to
                                      access your KYC records stored in the
                                      CKYCR.
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition3 && touched.condition3 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition3}
                                </Typography>
                              )}
                            </div>
                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition3"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition3}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition3", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      <span>
                                        {" "}
                                        6. I Confirm to take the settlement of
                                        my unused funds on every{" "}
                                      </span>
                                      <FormControl size="small">
                                        <Select
                                          style={{ width: "75px" }}
                                          inputProps={{
                                            IconComponent: () => "",
                                          }}
                                          disableUnderline
                                          sx={{
                                            fontSize: "14px",

                                            color: "#475467",
                                            fontWeight: 600,
                                            boxShadow: "none",
                                            ".MuiSvgIcon-root-393": {
                                              visibility: "hidden",
                                            },
                                            ".MuiOutlinedInput-input": {
                                              padding: "3.5px 5px !important",
                                            },
                                            ".MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                          }}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={dropDownValue}
                                          label=""
                                          onChange={(event) => {
                                            handleChangeSelectChange(event);
                                          }}
                                        >
                                          {Settlements.map(
                                            (data: any, index: any) => {
                                              // console.log(data)
                                              return (
                                                <MenuItem
                                                  value={data}
                                                  key={index}
                                                >
                                                  {data.value}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                        </Select>
                                      </FormControl>
                                      basis from my NCO securities trading
                                      account.
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition3 && touched.condition3 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition3}
                                </Typography>
                              )}
                            </div>
                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition3"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition3}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition3", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      <span>
                                        {" "}
                                        7. I instruct the DP to receive each and
                                        every credit in my account:
                                      </span>
                                      <FormControl size="small">
                                        <Select
                                          style={{ width: "45px" }}
                                          inputProps={{
                                            IconComponent: () => "",
                                          }}
                                          disableUnderline
                                          sx={{
                                            fontSize: "14px",

                                            color: "#475467",
                                            fontWeight: 600,
                                            boxShadow: "none",
                                            ".MuiSvgIcon-root-393": {
                                              visibility: "hidden",
                                            },
                                            ".MuiOutlinedInput-input": {
                                              padding: "3.5px 5px !important",
                                            },
                                            ".MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                          }}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={dropDownValuedpselect}
                                          label=""
                                          onChange={(event) => {
                                            setDropDownValuedpselect(
                                              event.target.value
                                            );
                                          }}
                                        >
                                          {dpselect.map(
                                            (data: any, index: any) => {
                                              // console.log(data)
                                              return (
                                                <MenuItem
                                                  value={data}
                                                  key={index}
                                                >
                                                  {data.value}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                        </Select>
                                      </FormControl>
                                      {/* basis from my NCO securities trading
                                      account. */}
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition3 && touched.condition3 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition3}
                                </Typography>
                              )}
                            </div>
                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition3"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition3}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition3", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      <span>
                                        {" "}
                                        8. I would like to instruct the DP to
                                        accept all the pledge instructions in my
                                        account without any other Further
                                        instruction from my end:
                                      </span>
                                      <FormControl size="small">
                                        <Select
                                          style={{ width: "45px" }}
                                          inputProps={{
                                            IconComponent: () => "",
                                          }}
                                          disableUnderline
                                          sx={{
                                            fontSize: "14px",

                                            color: "#475467",
                                            fontWeight: 600,
                                            boxShadow: "none",
                                            ".MuiSvgIcon-root-393": {
                                              visibility: "hidden",
                                            },
                                            ".MuiOutlinedInput-input": {
                                              padding: "3.5px 5px !important",
                                            },
                                            ".MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                          }}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={dropDownValuedpaccepect}
                                          label=""
                                          onChange={(event) => {
                                            setDropDownValuedpaccepect(
                                              event.target.value
                                            );
                                          }}
                                        >
                                          {dpaccepect.map(
                                            (data: any, index: any) => {
                                              // console.log(data)
                                              return (
                                                <MenuItem
                                                  value={data}
                                                  key={index}
                                                >
                                                  {data.value}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                        </Select>
                                      </FormControl>
                                      {/* basis from my NCO securities trading
                                      account. */}
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition3 && touched.condition3 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition3}
                                </Typography>
                              )}
                            </div>
                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition3"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition3}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition3", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      <span>
                                        {" "}
                                        9. Account Statement Requirement :
                                      </span>
                                      <FormControl size="small">
                                        <Select
                                          style={{ width: "105px" }}
                                          inputProps={{
                                            IconComponent: () => "",
                                          }}
                                          disableUnderline
                                          sx={{
                                            fontSize: "14px",

                                            color: "#475467",
                                            fontWeight: 600,
                                            boxShadow: "none",
                                            ".MuiSvgIcon-root-393": {
                                              visibility: "hidden",
                                            },
                                            ".MuiOutlinedInput-input": {
                                              padding: "3.5px 5px !important",
                                            },
                                            ".MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                          }}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={regulationperiod}
                                          label=""
                                          onChange={(event) => {
                                            setregulationperiod(
                                              event.target.value
                                            );
                                          }}
                                        >
                                          {period.map(
                                            (data: any, index: any) => {
                                              // console.log(data)
                                              return (
                                                <MenuItem
                                                  value={data}
                                                  key={index}
                                                >
                                                  {data.value}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                        </Select>
                                      </FormControl>
                                      {/* basis from my NCO securities trading
                                      account. */}
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition3 && touched.condition3 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition3}
                                </Typography>
                              )}
                            </div>

                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition3"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition3}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition3", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      <span>
                                        {" "}
                                        10. I request you to send Electronic
                                        Transaction-cum-Holding Statement at the
                                        email ID:
                                      </span>
                                      <FormControl size="small">
                                        <Select
                                          style={{ width: "45px" }}
                                          inputProps={{
                                            IconComponent: () => "",
                                          }}
                                          disableUnderline
                                          sx={{
                                            fontSize: "14px",

                                            color: "#475467",
                                            fontWeight: 600,
                                            boxShadow: "none",
                                            ".MuiSvgIcon-root-393": {
                                              visibility: "hidden",
                                            },
                                            ".MuiOutlinedInput-input": {
                                              padding: "3.5px 5px !important",
                                            },
                                            ".MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                          }}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={etchvalue}
                                          label=""
                                          onChange={(event) => {
                                            setetchvalue(event.target.value);
                                          }}
                                        >
                                          {etch.map((data: any, index: any) => {
                                            // console.log(data)
                                            return (
                                              <MenuItem
                                                value={data}
                                                key={index}
                                              >
                                                {data.value}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                      {/* basis from my NCO securities trading
                                      account. */}
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition3 && touched.condition3 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition3}
                                </Typography>
                              )}
                            </div>

                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition3"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition3}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition3", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      <span>
                                        {" "}
                                        11.I would like to share the email ID
                                        with the RTA:
                                      </span>
                                      <FormControl size="small">
                                        <Select
                                          style={{ width: "45px" }}
                                          inputProps={{
                                            IconComponent: () => "",
                                          }}
                                          disableUnderline
                                          sx={{
                                            fontSize: "14px",

                                            color: "#475467",
                                            fontWeight: 600,
                                            boxShadow: "none",
                                            ".MuiSvgIcon-root-393": {
                                              visibility: "hidden",
                                            },
                                            ".MuiOutlinedInput-input": {
                                              padding: "3.5px 5px !important",
                                            },
                                            ".MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                          }}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={rtavalue}
                                          label=""
                                          onChange={(event) => {
                                            setrtavalue(event.target.value);
                                          }}
                                        >
                                          {rta.map((data: any, index: any) => {
                                            // console.log(data)
                                            return (
                                              <MenuItem
                                                value={data}
                                                key={index}
                                              >
                                                {data.value}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                      {/* basis from my NCO securities trading
                                      account. */}
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition3 && touched.condition3 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition3}
                                </Typography>
                              )}
                            </div>

                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition3"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition3}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition3", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      <span>
                                        {" "}
                                        12. I would like to receive the Annual
                                        Report:
                                      </span>
                                      <FormControl size="small">
                                        <Select
                                          // style={{ width: "45px" }}
                                          inputProps={{
                                            IconComponent: () => "",
                                          }}
                                          disableUnderline
                                          sx={{
                                            fontSize: "14px",

                                            color: "#475467",
                                            fontWeight: 600,
                                            boxShadow: "none",
                                            ".MuiSvgIcon-root-393": {
                                              visibility: "hidden",
                                            },
                                            ".MuiOutlinedInput-input": {
                                              padding: "3.5px 5px !important",
                                            },
                                            ".MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                          }}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={annualreportvalue}
                                          label=""
                                          onChange={(event) => {
                                            setannualreportvalue(
                                              event.target.value
                                            );
                                          }}
                                        >
                                          {annualreport.map(
                                            (data: any, index: any) => {
                                              // console.log(data)
                                              return (
                                                <MenuItem
                                                  value={data}
                                                  key={index}
                                                >
                                                  {data.value}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                        </Select>
                                      </FormControl>
                                      {/* basis from my NCO securities trading
                                      account. */}
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition3 && touched.condition3 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition3}
                                </Typography>
                              )}
                            </div>

                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition3"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition3}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition3", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      <span>
                                        {" "}
                                        13. I/We wish to receive dividend
                                        interest directed into my bank account
                                        as given below through ECS:
                                      </span>
                                      <FormControl size="small">
                                        <Select
                                          style={{ width: "45px" }}
                                          inputProps={{
                                            IconComponent: () => "",
                                          }}
                                          disableUnderline
                                          sx={{
                                            fontSize: "14px",

                                            color: "#475467",
                                            fontWeight: 600,
                                            boxShadow: "none",
                                            ".MuiSvgIcon-root-393": {
                                              visibility: "hidden",
                                            },
                                            ".MuiOutlinedInput-input": {
                                              padding: "3.5px 5px !important",
                                            },
                                            ".MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                          }}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={ecsvalue}
                                          label=""
                                          onChange={(event) => {
                                            setecsvalue(event.target.value);
                                          }}
                                        >
                                          {ecs.map((data: any, index: any) => {
                                            // console.log(data)
                                            return (
                                              <MenuItem
                                                value={data}
                                                key={index}
                                              >
                                                {data.value}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                      {/* basis from my NCO securities trading
                                      account. */}
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition3 && touched.condition3 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition3}
                                </Typography>
                              )}
                            </div>
                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition3"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition3}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition3", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      <span>
                                        {" "}
                                        14. I request you to send Electronic
                                        Transaction cum Holding statement of my
                                        Demat Account through EMAIL:
                                      </span>
                                      <FormControl size="small">
                                        <Select
                                          style={{ width: "45px" }}
                                          inputProps={{
                                            IconComponent: () => "",
                                          }}
                                          disableUnderline
                                          sx={{
                                            fontSize: "14px",

                                            color: "#475467",
                                            fontWeight: 600,
                                            boxShadow: "none",
                                            ".MuiSvgIcon-root-393": {
                                              visibility: "hidden",
                                            },
                                            ".MuiOutlinedInput-input": {
                                              padding: "3.5px 5px !important",
                                            },
                                            ".MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                          }}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={emailvalue}
                                          label=""
                                          onChange={(event) => {
                                            setemailvalue(event.target.value);
                                          }}
                                        >
                                          {email.map(
                                            (data: any, index: any) => {
                                              // console.log(data)
                                              return (
                                                <MenuItem
                                                  value={data}
                                                  key={index}
                                                >
                                                  {data.value}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                        </Select>
                                      </FormControl>
                                      {/* basis from my NCO securities trading
                                      account. */}
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition3 && touched.condition3 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition3}
                                </Typography>
                              )}
                            </div>
                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition3"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition3}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition3", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      <span>
                                        {" "}
                                        15. I wish to avail Trust Facility using
                                        the Mobile number Registered for SMS
                                        alert Facility:
                                      </span>
                                      <FormControl size="small">
                                        <Select
                                          style={{ width: "45px" }}
                                          inputProps={{
                                            IconComponent: () => "",
                                          }}
                                          disableUnderline
                                          sx={{
                                            fontSize: "14px",

                                            color: "#475467",
                                            fontWeight: 600,
                                            boxShadow: "none",
                                            ".MuiSvgIcon-root-393": {
                                              visibility: "hidden",
                                            },
                                            ".MuiOutlinedInput-input": {
                                              padding: "3.5px 5px !important",
                                            },
                                            ".MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                          }}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={smsvalue}
                                          label=""
                                          onChange={(event) => {
                                            setsmsvalue(event.target.value);
                                          }}
                                        >
                                          {sms.map((data: any, index: any) => {
                                            // console.log(data)
                                            return (
                                              <MenuItem
                                                value={data}
                                                key={index}
                                              >
                                                {data.value}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                      {/* basis from my NCO securities trading
                                      account. */}
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition3 && touched.condition3 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition3}
                                </Typography>
                              )}
                            </div>
                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition3"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition3}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition3", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      <span>
                                        {" "}
                                        16. I would like to register for EASI
                                        Facility from CDSL INDIA:
                                      </span>
                                      <FormControl size="small">
                                        <Select
                                          style={{ width: "45px" }}
                                          inputProps={{
                                            IconComponent: () => "",
                                          }}
                                          disableUnderline
                                          sx={{
                                            fontSize: "14px",

                                            color: "#475467",
                                            fontWeight: 600,
                                            boxShadow: "none",
                                            ".MuiSvgIcon-root-393": {
                                              visibility: "hidden",
                                            },
                                            ".MuiOutlinedInput-input": {
                                              padding: "3.5px 5px !important",
                                            },
                                            ".MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                          }}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={easivalue}
                                          label=""
                                          onChange={(event) => {
                                            seteasivalue(event.target.value);
                                          }}
                                        >
                                          {easi.map((data: any, index: any) => {
                                            // console.log(data)
                                            return (
                                              <MenuItem
                                                value={data}
                                                key={index}
                                              >
                                                {data.value}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                      {/* basis from my NCO securities trading
                                      account. */}
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition3 && touched.condition3 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition3}
                                </Typography>
                              )}
                            </div>

                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition4"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition4}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition4", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      17.
                                      <a
                                        className={Styles.aTag}
                                        // onClick={handleOpentariffModal}
                                        href="https://firstock-common.s3.ap-south-1.amazonaws.com/ekyc/TradingTariff.pdf"
                                        target="_blank"
                                      >
                                        {" "}
                                        View
                                      </a>{" "}
                                      Trading tariff details
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition4 && touched.condition4 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition4}
                                </Typography>
                              )}
                            </div>
                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition4"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition4}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition4", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      18.
                                      <a
                                        className={Styles.aTag}
                                        href="https://firstock-common.s3.ap-south-1.amazonaws.com/ekyc/BrokingTariff.pdf"
                                        target="_blank"
                                      >
                                        {" "}
                                        View
                                      </a>{" "}
                                      DP Tariff details
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition4 && touched.condition4 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition4}
                                </Typography>
                              )}
                            </div>

                            <div className={Styles.checkBoxDiv}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition3"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition3}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition3", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      <span>
                                        {" "}
                                        19.I would wish to avail the following
                                        facility: Basic Service Demat Account
                                        Faciality (BSDA):
                                      </span>
                                      <FormControl size="small">
                                        <Select
                                          style={{ width: "45px" }}
                                          inputProps={{
                                            IconComponent: () => "",
                                          }}
                                          disableUnderline
                                          sx={{
                                            fontSize: "14px",

                                            color: "#475467",
                                            fontWeight: 600,
                                            boxShadow: "none",
                                            ".MuiSvgIcon-root-393": {
                                              visibility: "hidden",
                                            },
                                            ".MuiOutlinedInput-input": {
                                              padding: "3.5px 5px !important",
                                            },
                                            ".MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: 0,
                                              },
                                          }}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={bsdavalue}
                                          label=""
                                          onChange={(event) => {
                                            setbsdavalue(event.target.value);
                                          }}
                                        >
                                          {bsda.map((data: any, index: any) => {
                                            // console.log(data)
                                            return (
                                              <MenuItem
                                                value={data}
                                                key={index}
                                              >
                                                {data.value}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                      {/* basis from my NCO securities trading
                                      account. */}
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition3 && touched.condition3 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition3}
                                </Typography>
                              )}
                            </div>

                            <div className={Styles.checkBoxDiv}>
                              <FormGroup >
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name="condition3"
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition3}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setFieldValue("condition3", true);
                                      }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      <span>
                                        {" "}
                                        20.Option form for issue of DIS Booklet
                                        (Voluntary):
                                        <a
                                          className={Styles.aTag}
                                          onClick={handleOpentariffModal}
                                        >
                                          {" "}
                                          {optionsvalue === "option2"
                                            ? "Option 2"
                                            : "Option 1"}
                                        </a>
                                      </span>

                                      {/* basis from my NCO securities trading
                                      account. */}
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition3 && touched.condition3 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition3}
                                </Typography>
                              )}
                            </div>
                            <div className={Styles.checkBoxDiv}>
                              <FormGroup >
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      name=""
                                      sx={{ padding: "0px", display: "none" }}
                                      checked={selectAll || values.condition3}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={
                                        <img src={TickCircle} width={"24px"} />
                                      }
                                      // onChange={(event) => {
                                      //   const { checked } = event.target;
                                      //   setFieldValue("condition3", true);
                                      // }}
                                    />
                                  }
                                  label={
                                    <span className={Styles.label}>
                                      <span>
                                        {" "}
                                        21.I am a Resident of India
                                        
                                      </span>

                                      {/* basis from my NCO securities trading
                                      account. */}
                                    </span>
                                  }
                                />
                              </FormGroup>
                              {errors.condition3 && touched.condition3 && (
                                <Typography variant="body2" color="error">
                                  {errors.condition3}
                                </Typography>
                              )}
                            </div>
                          </div>
                          {/* <div className={Styles.imageContainer}>
                            <img src={image} className={Styles.image} />
                          </div> */}
                        </div>
                      </div>

                      {backdrop && <StartOverBackDrop />}
                      <div className={Styles.buttonStack}>
                        <div>
                          {(values.condition1 &&
                            values.condition2 &&
                            values.condition3 &&
                            values.condition4) ||
                          selectAll ? (
                            <LoadingButton
                              variant="contained"
                              type="submit"
                              loading={loading}
                              id="termsandconditionsContinueButton"
                              className={Styles.approveButton}
                            >
                              Continue
                            </LoadingButton>
                          ) : (
                            <Button
                              variant="contained"
                              type="submit"
                              disabled
                              className={Styles.approveButton}
                            >
                              Continue
                            </Button>
                          )}
                        </div>
                        <div className={Styles.startOverSection}>
                          <img src={icon} className={Styles.iconImage} />
                          <p className={Styles.paratext}>
                            {" "}
                            if the above mentioned info not yours
                            <span
                              className={Styles.startoverbutton}
                              onClick={handleStartOver}
                            >
                              Start Over
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <div style={{ height: "100vh" }}>
              <BackDrop />
            </div>
          )}
        </div>
        <Modal
          open={opentariffModal}
          onClose={handleClosetariffModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className={Styles.tablewrapper}>
              <h4 style={{ margin: 0, textDecoration: "underline" }}>
                Select Options
              </h4>
              <IconButton
                size="small"
                sx={{ position: "absolute", right: "10px", top: "10px" }}
                onClick={handleClosetariffModal}
              >
                <CloseIcon />
              </IconButton>

              {/* <table className={Styles.tablemain}>
                <tr>
                  <th className={Styles.tablehead}>Charge Head</th>
                  <th className={Styles.tablehead}>Brokerage/Charges</th>
                </tr>

                {tariff.map((a: any) => (
                  <tr>
                    <td className={Styles.tablebodyhead}>{a.chargehead}</td>
                    <td className={Styles.tablebodyhead}>{a.charges}</td>
                  </tr>
                ))}
              </table> */}
              <div className={Styles.tablemain}>
                <div className={Styles.checkBoxDiv}>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ alignItems: "flex-start" }}
                      control={
                        <Checkbox
                          name="condition3"
                          sx={{ padding: "0px" }}
                          checked={optionsvalue === "option1"}
                          icon={<PanoramaFishEyeIcon />}
                          checkedIcon={<img src={TickCircle} width={"24px"} />}
                          onChange={(event) => {
                            const { checked } = event.target;
                            setoptionsvalue(checked ? "option1" : "option2");
                          }}
                        />
                      }
                      label={
                        <span className={Styles.label}>
                          <span>
                            {" "}
                            <span style={{ fontWeight: "bold" }}>Option 1</span>
                            : I/We require you to issue Delivery Instruction
                            Slip (DIS) booklet to me/us immediately on opening
                            my/our CDSL account though I/We have issued a Power
                            of Attorney (POA) in favor of NCO Securities & Share
                            Broking Pvt. Ltd. for executing delivery
                            instructions for settling stock exchange trades
                            (Settlement related transactions) effected through
                            such Clearing Member.
                          </span>

                          {/* basis from my NCO securities trading
                                      account. */}
                        </span>
                      }
                    />
                  </FormGroup>
                </div>
                <p
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  (Or)
                </p>
                <div className={Styles.checkBoxDiv}>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ alignItems: "flex-start" }}
                      control={
                        <Checkbox
                          name="condition3"
                          sx={{ padding: "0px" }}
                          checked={optionsvalue === "option2"}
                          icon={<PanoramaFishEyeIcon />}
                          checkedIcon={<img src={TickCircle} width={"24px"} />}
                          onChange={(event) => {
                            const { checked } = event.target;
                            setoptionsvalue(checked ? "option2" : "option1");
                          }}
                        />
                      }
                      label={
                        <span className={Styles.label}>
                          <span>
                            {" "}
                            <span style={{ fontWeight: "bold" }}>Option 2</span>
                            : I/We do not require Delivery Instruction Slip
                            (DIS) for the time being. Since I/We have issued a
                            POA/EDIS in favor of NCO Securities & Share Broking
                            Pvt. Ltd. for executing delivery instructions for
                            settling stock exchange trades (Settlement related
                            transactions) effected through such Clearing Member.
                            However, the Delivery Instruction Slip (DIS) booklet
                            should be issued to me/us immediately on my/our
                            request at any later date.
                          </span>

                          {/* basis from my NCO securities trading
                                      account. */}
                        </span>
                      }
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
