import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Avatar,
  Fade,
  Box,
  IconButton,
  Modal,
} from "@mui/material";
import DoneImage from "../Images/doneicon.png";
import { useNavigate } from "react-router-dom";
import { checkUserLoggedIn } from "../shared/commonfunctions";
import styles from "../pages/Styles/Congratulation.module.scss";
import image from "../Components/assets/Images/congralutionsImage.svg";
import KRAFetchedDetails from "./KRAFetchedDetails";
import { stageDetailsAPICall, startOverAPICall } from "../shared/APICalls";
import PdfModal from "../Components/PdfModal/PdfModal";
import PDFViewerIframe from "../Components/PDFIframe/PDFWithIframe";
import CloseIcon from "@mui/icons-material/Close";
import icon from "../Components/assets/Images/iconnew.svg";
import facebookImage from "../Components/assets/Images/Facebook.svg";
import twitterImage from "../Components/assets/Images/twitter.svg";
import linkedinImage from "../Components/assets/Images/linkedin.svg";
import whatsappImage from "../Components/assets/Images/Whatsappnew.svg";
import doneimage from "../Components/assets/Images/donetickimage.svg";
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Helmet } from "react-helmet";
import CopiedSnackBar from "../Components/SnackBars/CopiedSnackBar";
import esignImage from "../Components/assets/Images/new esign_page-0001 - Copy - Copy.jpg";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vW",
  height: "90vH",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  padding: "0px 0px 0px 5px",
};
const containerStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
};
const buttonStyle = {
  marginRight: "10px",
  padding: "8px",
  borderRadius: "5px",
  backgroundColor: "lightgray",
  color: "black",
  fontWeight: "bold",
};

export default function CongratulationsPage() {
  const navigate = useNavigate();
  const [noEditableData, setnoEditableData] = useState({
    FullName: "",
    Gender: "",
    DOB: "",
    Address: "",
  });
  const [showModal, setshowModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<any>("");
  const [open, setOpen] = React.useState(true);
  const [copied, setCopied] = useState(false);
  const [clientCode, setClientCode] = useState("");
  const [message, setmessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    checkUserLoggedIn(navigate);

    let objNew: any = {
      stage: "congratulations",
    };

    stageDetailsAPICall(objNew)
      .then((response: any) => {
        const responseData = response.data;

        if (responseData.data.StageDetails.Documents.e_sign_pdf) {
          setClientCode(
            responseData.data.StageDetails.Documents.e_sign_pdf.ClientCode
          );
          setnoEditableData(
            responseData.data.StageDetails.Documents.e_sign_pdf
          );
        }

        if (
          responseData.data.StageDetails.Documents.e_sign_pdf.e_sign_pdf_url
        ) {
          // const meta = "data:application/pdf;base64,";
          // const data = meta + responseData.data.StageDetails.Documents.e_sign_pdf.e_sign_pdf_base64;
          setPdfUrl(
            responseData.data.StageDetails.Documents.e_sign_pdf.e_sign_pdf_url
          );
        }

        //console.log("responseData", responseData.data.StageDetails[0].e_sign_pdf_url);
      })
      .catch((error: any) => {
        //   console.log("error", error);
      });
  }, []);

  const handlePreviewClick = () => {
    setshowModal(true);
  };

  const handlePreviewClose = () => {
    setshowModal(false);
  };

  const handleStartOver = () => {
    if (
      window.confirm(
        "Are you sure you want to restart, on pressing Yes all the Data will be deleted"
      )
    ) {
      startOverAPICall()
        .then((response: any) => {
          const responseData = response.data;
          //  console.log("responseData", response);

          navigate("/");
          window.location.reload();
        })
        .catch((error: any) => {});
    }
  };
  const textFieldStyles = {
    borderRadius: "0px",
  };
  const baseUrl = window.location.href.split("/congratulations")[0];
  // console.log(baseUrl);
  const urlToShare = `${baseUrl}?c=${clientCode}`;

  return (
    <div className={styles.mainWrapperTop}>
      <div className={styles.wrapper}>
        <div className={styles.leftContainer}>
          <div className={styles.headerSection}>
            <img src={doneimage} className={styles.donetick} />
            <p className={styles.signup}>
              {/* <img src={doneimage} className={styles.doneticknexttotext} />{" "} */}
              Congrats! Application Process is Submitted for Approval
            </p>
            <p className={styles.subText}>
              Verification of the application could take up to 42 hours at the
              exchanges on your KYC status.After verification,You will receive
              an email with your credentials.
            </p>
            <Button
              type="submit"
              variant="outlined"
              sx={{
                color: "#000000",
                borderRadius: "20px",
                background: "#CCE1E4",
                border: "none",
              }}
              className={styles.previewButton}
              id="aadharOpenLinkButton"
              onClick={handlePreviewClick}
            >
              View E-sign Document
            </Button>
          </div>
          <div className={styles.shareWrapper}>
            <div className={styles.referralSection}>
              <div>
                <p className={styles.referraltext}>Your Referral Link:</p>
              </div>
              <div className={styles.imageSection}>
                <FacebookShareButton url={urlToShare}>
                  <img src={facebookImage} className={styles.shareimageicon} />
                </FacebookShareButton>
                <LinkedinShareButton url={urlToShare}>
                  <img src={linkedinImage} className={styles.shareimageicon} />
                </LinkedinShareButton>
                <TwitterShareButton url={urlToShare}>
                  <img src={twitterImage} className={styles.shareimageicon} />
                </TwitterShareButton>
                <WhatsappShareButton url={urlToShare}>
                  <img src={whatsappImage} className={styles.shareimageicon} />
                </WhatsappShareButton>
              </div>
            </div>
            <div className={styles.copyreferral}>
              <div className={styles.copyreferralTextFeild}>
                <div className={styles.copyreferralurlText}>{urlToShare}</div>
                <CopyToClipboard
                  text={urlToShare}
                  onCopy={() => {
                    setCopied(true);
                    setmessage("Copied to Clipboard");
                    setTimeout(() => {
                      setmessage("");
                    }, 4000);
                  }}
                >
                  <Button
                    className={styles.copyreferralButton}
                    variant="contained"
                    sx={{ height: "35px", borderRadius: "10px", width: "50px" }}
                  >
                    Copy
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div style={containerStyle}></div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.imagediv}>
            <img src={image} className={styles.image} />
          </div>
          <div className={styles.kradetails}>
            <KRAFetchedDetails data={noEditableData} />
          </div>
        </div>
      </div>
      <CopiedSnackBar message={message} />

      {showModal && (
        <Stack>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                {/* <PdfModal
                // url={"https://arxiv.org/pdf/quant-ph/0410100.pdf"}
                url={documentURL}
              /> */}
                <Stack
                  alignItems={"flex-end"}
                  marginRight={"10px"}
                  marginTop={"5px"}
                >
                  <IconButton onClick={handlePreviewClose}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <div className={styles.pdfMobilePreview}>
                  <img
                    src={esignImage}
                    style={{
                      width: "95%",
                      height: "100%",
                      objectFit: "inherit",
                      filter: "brightness(80%)",
                      marginBottom: "20px",
                    }}
                  />

                  <a
                    href={pdfUrl}
                    download="Esign-PDF-document"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.previewdownlaodesign}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        background: "#1976d2",
                        "&:hover": { background: "#1976d2" },
                      }}
                    >
                      View Esign Document
                    </Button>
                  </a>
                </div>
                <div className={styles.pdfDesktopPreview}>
                  <PDFViewerIframe urldata={pdfUrl} />
                </div>
              </Box>
            </Fade>
          </Modal>
        </Stack>
      )}
    </div>
  );
}
