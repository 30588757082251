export const EKYCURL = process.env.REACT_APP_EKYC_API;
export const APPID = process.env.REACT_APP_APP_ID;
export const SSOLINK = process.env.REACT_APP_SSO_LINK;
export const DOMIAN = process.env.REACT_APP_DOMIAN;
export const TOKENNAME = process.env.REACT_APP_TOKEN_TYPE;
export const ENV = "prod";
export const FINUPDATE = process.env.REACT_APP_FINUPDATE_API;
export const ACCOUNTCLOSURE = process.env.REACT_APP_ACCOUNTCLOSURE_API;
export const UPLOADSIZELIMIT: number =
  process.env.REACT_APP_UPLOAD_SIZE_LIMIT !== undefined
    ? parseInt(process.env.REACT_APP_UPLOAD_SIZE_LIMIT)
    : 8;
export const UploadSizes = {
  signature: {
    pdfSize: 2,
    imageSize: 2,
  },
  pancard: {
    pdfSize: 2,
    imageSize: 2,
  },
  incomeproof: {
    pdfSize: 7,
    imageSize: 3,
  },
  bank_proof: {
    pdfSize: 3,
    imageSize: 3,
  },
};
