import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import EsignSuccessPage from "./AccountClosureEsignResponseSuccess";
import EsignErrorPage from "./AccountClosureEsignError";


const MapRoutes: any = {
  success: <EsignSuccessPage/>,
  error: <EsignErrorPage />,

};

export default function EsignResponse() {
  let page = "not_found";

  const currentLink = window.location.href;

  // console.log("currentLink",currentLink)

  const getCurrentPage = () => {
    if (currentLink.includes(`&status=success`)) {
      return "success";
    } else if (currentLink.includes(`&status=error`)) {
      return "error";
    } else {
      return "error";
    }
  };

  page = getCurrentPage();

  return <div>{MapRoutes[page]}</div>;
}
